import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import LangProvider from "../Providers/LangProvider/LangProvider"
import { useUpdateEffect, useWindowSize } from "../CustomHooks"
import { RootState } from "../Interfaces/Redux"
import { setScreen } from "../Redux/Reducers"
import { PageCommandeEnCour, PageDevenirCommercant, PageMenuCommercant, PageMesCommercants, PageMesRecharges, PagePublicCommercants } from "../Pages"
import ModalProvider from "../Providers/ModalProvider/ModalProvider"
import PageCompte from "../Pages/PageCompte/PageCompte"
import { ModalContactUs } from "../Modal"
import AlerteProvider from "../Providers/AlerteProvider/AlerteProvider"
import PageSites from "../Pages/PageSites/PageSites"
import ShopPublicRoute from "./ShopRoutes/ShopPublicRoute"
import ShopPrivateRoute from "./ShopRoutes/ShopPrivateRoute"
import ShopCustomRoute from "./ShopRoutes/ShopCustomRoute"

const ShopRoute = (props: any): JSX.Element => {
  const auth = useSelector((state: RootState) => state?.auth)
  const dispatch = useDispatch()
  const { width, height } = useWindowSize()

  useUpdateEffect(() => {
    dispatch(setScreen({ width: width, height: height }))
  }, [width])

  function PrivateRouteRender(props: any) {
    return auth?.token === "" ? (
      <Navigate to="/menuCommercant/:suid" />
    ) : (
      props.children
    )
  }
  function PublicRouteRender(props: any) {
    return props.children
  }
  return (
    <div style={{ width }} className="ds-hv-100">
      <LangProvider>
        <BrowserRouter>
          <ModalProvider />
          <AlerteProvider />
          <Routes>
            <Route
              element={
                <PublicRouteRender>
                  <ShopPublicRoute />
                </PublicRouteRender>
              }
            >
              <Route path="/" element={<PageSites sites={props.sites} />} />
            </Route>
            <Route
              element={
                <PrivateRouteRender >
                  <ShopPrivateRoute />
                </PrivateRouteRender>
              }
            >
              <Route path="/compte" element={<PageCompte />} />
              <Route path="/mes-commandes" element={<PageCommandeEnCour />} />
              <Route path="/mes-recharges" element={<PageMesRecharges />} />
              <Route path="/contact-us" element={<ModalContactUs />} />
            </Route>
            <Route
              element={
                <PublicRouteRender >
                  <ShopCustomRoute />
                </PublicRouteRender>
              }
            >
              <Route path="/menuCommercant/:suid" element={<PageMenuCommercant />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </LangProvider>
    </div>
  )
}

export default ShopRoute
