import React, { useEffect, useRef } from "react"

export function useUpdateEffect(callback: any, dependencies: React.DependencyList | undefined) {
    const firstRenderRef = useRef(true)
    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            if (firstRenderRef.current) {
                firstRenderRef.current = false
                return
            } else {
                callback()
            }
        } else {
            callback()
        }
    }, dependencies)
}
