import { Ref, forwardRef, useContext, useEffect, useState } from "react";
import { Modal, ModalRefType } from "../../DesignSystem";
import { IModalInscriptionEmail } from "../../Interfaces/Modal";
import { EListFunction, IUseFormResult } from "../../CustomHooks/interfaces";
import { useForm } from "../../CustomHooks/hooks/useForm/useForm"
import { AuthAPI } from "../../Api/Auth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Interfaces";
import { openModal, showOptionSignUpPhone } from "../../Redux/Reducers/ModalReducer/ModalReducer";
import { setUserRegistration } from "../../Redux/Reducers/AuthReducer/AuthReducer";
import { TemplateModalInscriptionMail } from "../../Template";

import { LangContext } from "../../Lang";
const ModalInscriptionEmail = (props: IModalInscriptionEmail, ref: Ref<ModalRefType | undefined>) => {
    const langue = useContext(LangContext)
    const authapi = new AuthAPI()
    const dispatch = useDispatch()
    const [showErrorSpan, setShowErrorSpan] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const { show } = useSelector((state: RootState) => state.modal)
    const { commercant } = useSelector((state: RootState) => state.commercant)
    const { lang } = useSelector((state: RootState) => state.setting)

    const { state: form,
        onChange, onUpdateState
    }: IUseFormResult = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "email",
                value: "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.emptyMessage,
                    priority: 1,
                },
                {
                    function: EListFunction.isMail,
                    messageError: langue.email_invalid,
                    priority: 2,
                }],
            },
            {
                key: "phone",
                value: "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.phone_required,
                    priority: 1,
                },
                {
                    function: EListFunction.isNumber,
                    messageError: langue.phone_invalid,
                    priority: 1,
                },
                ],
            },
        ]
    })

    const onOpenModal = () => {
        dispatch(openModal({ name: "modalInscriptionConfirmationRef" }))
    }
    const onOpenModalConnexion = () => {
        dispatch(openModal({ name: "modalConnexionRef" }))
    }
    const onClose = () => {
        if (props.onExit) {
            props.onExit()
        }
        onUpdateInitialState()
        setShowErrorSpan(false);
    }

    const onUpdateInitialState = () => {
        setShowErrorSpan(false);
        onUpdateState({
            email: {
                errorMessage: "",
                successMessage: "",
                value: "",
                isInvalid: false,
            },
            phone: {
                errorMessage: "",
                successMessage: "",
                value: "",
                isInvalid: false,
            },
        })
    }

    useEffect(() => {
        if (!props.isOpen && isOpen) {
            setShowErrorSpan(false)
            onUpdateInitialState()
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }, [props.isOpen])
    const switchPhoneEmailInput = () => {
        dispatch(showOptionSignUpPhone({ show: !show }));
        onUpdateInitialState()
    }
    const onSubmitEmail = async () => {
        if (form?.email?.value && String(form.email.value).length >= 8) {
            const res = await authapi.VerifyUser({
                user: form?.email?.value,
            },
                {
                    suid: commercant?.suid,
                    lang: lang
                })

            if (res.status === 200) {
                if (!res.data) {
                    const response = await authapi.signUp({
                        email: form?.email?.value,
                    },
                        {
                            suid: commercant?.suid,
                            lang: lang
                        })
                    if (response.status === 200) {
                        dispatch(setUserRegistration({ user: response.data }));
                        dispatch(showOptionSignUpPhone({ show: false }));
                        onClose();
                        onOpenModal();
                        setShowErrorSpan(false);
                        onUpdateInitialState()
                    }
                }
                else {
                    setShowErrorSpan(true);
                }
            }
        }
        else {
            setShowErrorSpan(true);
        }
    }
    const onSubmitPhone = async () => {
        if (form?.phone?.value && String(form.phone.value).length >= 8) {
            const res = await authapi.VerifyUserWithPhone({
                phone: form?.phone?.value,
            },
                {
                    suid: commercant?.suid,
                    lang: lang
                })
            if (res.status === 200) {
                if (!res.data) {
                    const response = await authapi.signUp({
                        phone: form?.phone?.value,
                    },
                        {
                            suid: commercant?.suid,
                            lang: lang
                        })

                    if (response.status === 200) {
                        dispatch(setUserRegistration({ user: response.data }));
                        dispatch(showOptionSignUpPhone({ show: true }));
                        onClose();
                        onOpenModal();
                        setShowErrorSpan(false);
                        onUpdateInitialState()
                    }
                } else {
                    setShowErrorSpan(true);
                }
            }
        } else {
            setShowErrorSpan(true);
        }
    }
    const onSubmit = async () => {
        setShowErrorSpan(false);
        if (!show) {
            await onSubmitEmail()
        }
        else {
            await onSubmitPhone()
        }
    }

    const handleOnClickProvider = async (providerName: string) => {
        if (providerName === "facebook") {
            authapi.connectWithFacebook()
        } else {
            authapi.connectWithGoogle()
        }
    }

    return <Modal
        contentClassName={"subscription-modal overflow-hidden"}
        withCloseIcon
        ref={ref}
        onExit={() => { onUpdateInitialState(); props.onExit && props.onExit() }}
        onShow={props.onShow}
    >
        <TemplateModalInscriptionMail
            show={show}
            form={form}
            onSubmit={onSubmit}
            onChange={onChange}
            switchPhoneEmailInput={switchPhoneEmailInput}
            onOpenModalConnexion={onOpenModalConnexion}
            onClose={onClose}
            showErrorSpan={showErrorSpan}
            langue={langue}
            onClickProvider={handleOnClickProvider}
        />
    </Modal >

}

export default forwardRef(ModalInscriptionEmail)


