import { Navbar, Sidebar } from "../../DesignSystem"
import { useDispatch, useSelector } from "react-redux"
import { Outlet } from "react-router-dom"
import Drawer from 'react-modern-drawer'
import { RootState } from "../../Interfaces/Redux"
import React, { useContext } from "react"
import { LangContext } from "../../Lang"
import { Data } from "../../Common"
import { Basket } from "../../DesignSystem/Organisms"
import { EOrganismRight } from "../../DesignSystem/Interfaces"
import { setShowBasket, setShowRecharge, setSideBar } from "../../Redux/Reducers"

const CustomRoute = (): JSX.Element => {
  const lang = useContext(LangContext)
  const { user, token } = useSelector((state: RootState) => state?.auth)
  const { show } = useSelector((state: RootState) => state?.sideBar)
  const { showBasket, showRecharge } = useSelector((state: RootState) => state?.basket)
  const { commercant } = useSelector((state: RootState) => state?.commercant)
  const className = ["ds-w-100"]

  const dispatch = useDispatch()
  const handleCloseDrawer = () => {
    dispatch(setSideBar(show));
  };
  const handleCloseBasket = () => {
    dispatch(setShowBasket(showBasket));
  };
  const handleCloseRecharge = () => {
    dispatch(setShowRecharge(showRecharge));
  };
  React.useEffect(() => {
    if (show || showBasket || showRecharge) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'auto';
    }
  }, [show, showBasket, showRecharge])
  return (
    <>
      <div
        className=" ds-flex sidebar-container  ds-w-100">
        <div className={className.join(' ')} style={{ height: '100%' }}>
          <Navbar />
          <div className={`ds-w-100 ds-h-100 ds-flex-col ds-pt-94`}>
            <Outlet />

          </div>
        </div>
      </div>
      {show && <Drawer
        open={show}
        direction='left'
        size={300}
        onClose={() => { handleCloseDrawer(); }}
        enableOverlay={show}
      >
        <Sidebar
          style={{ width: window.innerWidth }}
          items={Data.getSideBarItems(lang, user, token, commercant)}
        />
      </Drawer>}
      {showRecharge && <Drawer
        open={showRecharge}
        direction='right'
        size={300}
        onClose={() => { handleCloseRecharge() }}
        enableOverlay={showRecharge}
      >
        <Basket type={EOrganismRight.recharge} />
      </Drawer>}
      {showBasket && <Drawer
        open={showBasket}
        direction='right'
        size={300}
        onClose={() => { handleCloseBasket(); }}
        enableOverlay={showBasket}
      >
        <Basket type={EOrganismRight.basket} />
      </Drawer>}
    </>
  )
}

export default CustomRoute
