import moment from "moment"
import { Avatar, Button, Icon, Input, Sidebar } from "../../DesignSystem"
import { Size, SizeAvatar, Type } from "../../DesignSystem/Interfaces"
import { SidebarType } from "../../DesignSystem/Interfaces/Organisms/IOrganismSidebar/IOrganismSidebar"
import { ITemplateCompte } from "../../Interfaces/Template"
import { CommonFunction, Data } from "../../Common"
import React, { useRef } from "react"
import { Elang, RootState } from "../../Interfaces"
import { useSelector } from "react-redux"


const TemplateCompte = (props: ITemplateCompte): JSX.Element => {
    const scrollDiv1 = useRef<HTMLDivElement>(null);
    const scrollDiv2 = useRef<HTMLDivElement>(null);
    const scrollDiv3 = useRef<HTMLDivElement>(null);
    const scrollDiv4 = useRef<HTMLDivElement>(null);

    const sidebarItems = [
        {
            label: props.langue.title_perso,
            onClick: () => {
                scrollDiv1?.current?.scrollIntoView({ behavior: "smooth", block: "start" });

            }


        },
        {
            label: props.langue.title_address,
            onClick: () => {
                scrollDiv2?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
            }

        },
        {
            label: props.langue.partnershipCode_label,
            onClick: () => {
                scrollDiv3?.current?.scrollIntoView({ behavior: "smooth", block: "start" });

            }
        },
        {
            label: props.langue.title_payment,
            onClick: () => {
                scrollDiv4?.current?.scrollIntoView({ behavior: "smooth", block: "start" });

            }
        }

    ];

    const labelClassName = ['ds-text-primary100 ds-text-line-17 ds-text-size-14 text-size-responsive ds-text-weight400 text-align-right ds-wp-140']
    const textResponsiveClassName = ['ds-text-line-19 ds-text-size-16 ds-text-weight300 ds-flex ds-mb-4']
    const titleClassName = ['ds-text-primary100 ds-text-line-24 ds-text-size-20 ds-text-weight500 ds-mb-18']
    const btnPayClassName = ['ds-ml-24 ds-wp-235 primary-70-btn']
    const btnPayResponsiveClassName = ['ds-mt-4 ds-w-100 primary-70-btn']
    const blocClassName = ['ds-flex ds-align-center ds-mb-18 ds-hp-40 ds-p-24']
    const textValueClassName = ['ds-text-primary100 ds-ml-24 ds-text-line-19 ds-text-size-16 ds-text-weight300']
    const inputClassName = ['ds-ml-24 ds-wp-420']

    const { width } = useSelector((state: RootState) => state?.screen)

    const renderCompteTemplate = () => {
        return (<div className="ds-flex ds-private-content">
            {
                props.width >= 992 &&
                <Sidebar
                    type={SidebarType.type2}
                    ItemsType2={sidebarItems}
                    selectedItem={props.selectedItem}
                    isMaxSize={false}
                />
            }

            <div className="compte-container">
                {props.width < 992 && <span className="ds-text-line-29 ds-text-size-24 ds-text-weight700" >{props.langue.profile}</span>}
                <div className="ds-flex ds-align-center ds-justify-between">
                    <Avatar
                        user={{ firstname: props?.user?.firstname, lastname: props?.user?.lastname, email: props?.user?.username }}
                        size={SizeAvatar.xlarge}
                        isNameText={true}
                    />
                    <Button
                        icon={"logout"}
                        type={Type.tertiary}
                        text={props.width >= 1200 ? props.langue.disconnect : ""}
                        className="primary-70-btn"
                        onClick={() => props.logout()}
                    />
                </div>
                <div className="ds-border-primary30 ds-mt-14 ds-mb-24"></div>

                <div className="ds-height-container ds-overflow-y"
                    onScroll={(e: any) => {
                        props.onScroll(e.currentTarget.scrollTop)
                    }}
                >
                    <div ref={scrollDiv1}>
                        <h6 className={titleClassName.join(' ')}>{props.langue.title_perso}</h6>

                        <div className={props.width >= 1200 ? blocClassName.join(' ') : ''}>

                            <span
                                className={labelClassName.join(' ')}>{props.langue.email_label}</span>

                            {!props.showEditField ? <span className={props.width >= 1200 ? textValueClassName.join(' ') : textResponsiveClassName.join(' ')}>{props?.user?.username}</span> :

                                <Input
                                    {...CommonFunction.getInputProps(props?.form.username)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "username", value: e.target.value })
                                    }
                                    containerClassName={props.width >= 1200 ? inputClassName.join(' ') : ' '}
                                    className={(!props?.form?.username?.isInvalid && !props?.form?.username?.isValid) ? "ds-border-primary20" : ""}
                                />}

                        </div>
                        <div className={props.width >= 1200 ? blocClassName.join(' ') : ''}>
                            <span className={labelClassName.join(' ')}>{props.langue.lastName}</span>

                            {!props.showEditField ? <span className={props.width >= 1200 ? textValueClassName.join(' ') : textResponsiveClassName.join(' ')}>{props?.user?.lastname}</span>
                                :
                                <Input
                                    {...CommonFunction.getInputProps(props?.form?.lastname)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "lastname", value: e.target.value })


                                    }

                                    containerClassName={props.width >= 1200 ? inputClassName.join(' ') : ' '}
                                    className={(!props?.form?.lastname?.isInvalid && !props?.form?.lastname?.isValid) ? "ds-border-primary20" : ""}
                                />}

                        </div>

                        <div className={props.width >= 1200 ? blocClassName.join(' ') : ''}>
                            <span className={labelClassName.join(' ')}>{props.langue.firstName}</span>
                            {!props.showEditField ? <span className={props.width >= 1200 ? textValueClassName.join(' ') : textResponsiveClassName.join(' ')}>{props?.user?.firstname}</span> :
                                <Input
                                    {...CommonFunction.getInputProps(props?.form?.firstname)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "firstname", value: e.target.value })
                                    }
                                    containerClassName={props.width >= 1200 ? inputClassName.join(' ') : ' '}
                                    className={(!props?.form?.firstname?.isInvalid && !props?.form?.firstname?.isValid) ? "ds-border-primary20" : ""}
                                />
                            }

                        </div>
                        <div className={props.width >= 1200 ? blocClassName.join(' ') : ''}>
                            <span className={labelClassName.join(' ')}>{props.langue.phone_label}</span>
                            {!props.showEditField ? <span className={props.width >= 1200 ? textValueClassName.join(' ') : textResponsiveClassName.join(' ')}>{`${"+"}${props?.user?.phone}`}</span>
                                : <Input
                                    {...CommonFunction.getInputProps(props?.form?.phone)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "phone", value: e.target.value })
                                    }
                                    containerClassName={props.width >= 1200 ? inputClassName.join(' ') : ' '}
                                    className={(!props?.form?.phone?.isInvalid && !props?.form?.phone?.isValid) ? "ds-border-primary20" : ""}
                                />
                            }

                        </div>
                        <div className={props.width >= 1200 ? blocClassName.join(' ') : ''}>
                            <span className={labelClassName.join(' ')}>{props.langue.dob_label}</span>
                            {!props.showEditField ? <span className={props.width >= 1200 ? textValueClassName.join(' ') : textResponsiveClassName.join(' ')}>{props?.user?.dob ? moment(props?.user?.dob).format('DD/MM/YYYY') : ""}</span> :
                                <Input
                                    {...CommonFunction.getInputProps(props?.form?.dob)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "dob", value: e.target.value })
                                    }
                                    placeholder={props.langue.dob_placeholder}
                                    containerClassName={props.width >= 1200 ? inputClassName.join(' ') : ' '}
                                    className={(!props?.form?.dob?.isInvalid && !props?.form?.dob?.isValid) ? "ds-border-primary20" : ""}

                                />
                            }

                        </div>
                        <div className={props.width >= 1200 ? blocClassName.join(' ') : 'ds-mb-18'}>
                            <span className={labelClassName.join(' ')}>{props.langue.company_label}</span>
                            {!props.showEditField ? <span className={props.width >= 1200 ? textValueClassName.join(' ') : textResponsiveClassName.join(' ')}>{props?.user?.company}</span> :
                                <Input
                                    {...CommonFunction.getInputProps(props?.form?.company)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "company", value: e.target.value })
                                    }
                                    containerClassName={props.width >= 1200 ? inputClassName.join(' ') : ' '}
                                    className="ds-border-primary20"
                                />
                            }
                        </div>
                    </div>
                    <div ref={scrollDiv2}

                    >
                        <h6 className={titleClassName.join(' ')}>{props.langue.title_address}</h6>

                        <div className={props.width >= 1200 ? blocClassName.join(' ') : ''}>
                            <span className={labelClassName.join(' ')}>{props.langue.facturation_address}
                            </span>
                            {!props.showEditField ? <span className={props.width >= 1200 ? textValueClassName.join(' ') : textResponsiveClassName.join(' ')}>{props?.user?.address}</span> :
                                <Input
                                    {...CommonFunction.getInputProps(props?.form?.adrFact)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "adrFact", value: e.target.value })
                                    }
                                    containerClassName={props.width >= 1200 ? inputClassName.join(' ') : ' '}
                                    className="ds-border-primary20"
                                />
                            }
                        </div>
                        <div className={props.width >= 1200 ? blocClassName.join(' ') : 'ds-mb-18'}>
                            <span className={labelClassName.join(' ')}>{props.langue.delivery_address}
                            </span>
                            {!props.showEditField ? <span className={props.width >= 1200 ? textValueClassName.join(' ') : textResponsiveClassName.join(' ')}>{props?.user?.delivery_address}</span> :
                                <Input
                                    {...CommonFunction.getInputProps(props?.form?.delivery_address)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "delivery_address", value: e.target.value })
                                    }
                                    containerClassName={props.width >= 1200 ? inputClassName.join(' ') : ' '}
                                    className="ds-border-primary20"
                                />
                            }

                        </div>


                    </div>
                    <div ref={scrollDiv3}>
                        <h6 className={titleClassName.join(' ')}>{props.langue.partnershipCode_label}</h6>

                        <div className={props.width >= 1200 ? "ds-flex ds-mb-18" : "ds-mb-18"}>
                            <span className={props.width >= 1200 ? "ds-text-line-17 ds-text-size-14 ds-text-weight400 text-align-right ds-wp-140 ds-mt-3" : "ds-text-line-17 ds-text-size-14 ds-text-weight400"}>
                                {props.langue.company_label}
                            </span>

                            {!props.showInputCodePromo && <div className={props.width >= 1200 ? "ds-ml-24" : "ds-mt-4"}>
                                {props.customerMember?.partnership?.active ?
                                    <span className="ds-text-line-20 ds-text-size-18 ds-text-weight700"> {props.customerMember?.partnership?.name} - {props.customerMember?.partnership?.discount} {props.customerMember?.partnership?.discount_type_label}  {props.langue.partnership_discount}</span>
                                    : <span className="ds-text-primary60 ds-text-line-15 ds-text-size-12 ds-text-weight400">{props.langue.partnershipCode_help}</span>}
                                <Button
                                    className={props.width >= 1200 ? "ds-mt-14 primary-70-btn" : "ds-mt-14 primary-70-btn ds-w-100"}
                                    text={props.langue.partnershipCode_add}
                                    type={Type.tertiary}
                                    onClick={() => {
                                        props.setShowInputCodePromo(true)
                                    }}
                                />
                            </div>}
                            {props.showInputCodePromo &&

                                <Input
                                    {...CommonFunction.getInputProps(props?.form?.partnership)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.onChangeCode(e.target.value)
                                    }}
                                    containerClassName={props.width >= 1200 ? inputClassName.join(' ') : ' '}
                                    className="ds-border-primary20"
                                    ref={props.codeRef}
                                />
                            }
                        </div>


                    </div>
                    <div ref={scrollDiv4}>
                        <h6 className={titleClassName.join(' ')}>{props.langue.title_payment}</h6>

                        <p className="ds-text-primary60 ds-text-line-15 ds-text-size-12 ds-text-weight400 ds-mt-12">{props.langue.credit_card_helper2}</p>


                        <div className={props.width >= 1200 ? blocClassName.join(' ') : ''}>
                            <span className={labelClassName.join(' ')}>
                                {props.langue.bank_title}

                            </span>
                            <Button
                                className={props.width >= 1200 ? btnPayClassName.join(' ') : btnPayResponsiveClassName.join(' ')}
                                text={props.langue.add_title}
                                type={Type.tertiary}
                            />
                        </div>
                        <div className={props.width >= 1200 ? blocClassName.join(' ') : ''}>
                            <span className={labelClassName.join(' ')}>
                                Google Pay
                            </span>
                            <Button
                                className={props.width >= 1200 ? btnPayClassName.join(' ') : btnPayResponsiveClassName.join(' ')}
                                text="Payer avec "
                                icon="g-pay"
                                type={Type.tertiary}
                                isRightIcon
                                size={Size.large}
                            />
                        </div>
                        <div className={props.width >= 1200 ? blocClassName.join(' ') : ''}>
                            <span className={labelClassName.join(' ')}>
                                Apple Pay
                            </span>
                            <Button
                                className={props.width >= 1200 ? btnPayClassName.join(' ') : btnPayResponsiveClassName.join(' ')}
                                text="Payer avec "
                                icon="apple-pay"
                                type={Type.tertiary}
                                isRightIcon
                                size={Size.large}
                            />
                        </div>
                    </div>
                    {props.width >= 992 && <div className="ds-height-scrol-container"></div>}
                </div>
                <div className={`ds-flex ds-pt-16 ${width <= 768 && props.showEditField ? 'ds-justify-center' : 'ds-justify-end'}`}>
                    {!props.showEditField && <Button
                        type={Type.tertiary}
                        text={props.langue.modify}
                        size={Size.large}
                        className="ds-wp-140"
                        onClick={() => { props.setShowEditField(true) }
                        }
                    />}
                    {props.showEditField && <><Button
                        text={props.langue.cancel}
                        size={Size.large}
                        type={Type.tertiary}
                        className="primary-10-btn ds-wp-140"

                        onClick={() => {
                            props.setShowEditField(false);
                            props.onUpdateData();
                        }
                        }
                    />
                        <Button
                            size={Size.large}
                            text={props.langue.save}
                            className="primary-20-btn ds-ml-14 ds-wp-140"
                            onClick={() => { props.onChangeProfile() }}
                            disabled={!props.isFormValid}
                        />
                    </>}
                </div>

                {props.width < 992 && <div className='footer-container ds-w-100 ds-mt-20 '>
                    <div className='horizontal-line'></div>
                    <div className='ds-flex ds-align-center ds-m-5'>
                        <Icon
                            icon='global1'
                            size={"20px"}
                            className='ds-text-neutral70 '
                        />
                        <span
                            className='ds-text-neutral70 ds-flex-grow1 ds-text-size-16 ds-text-line-19 ds-ml-12'
                        >{props.langue.title_lang}
                        </span>
                        <Input
                            isSelect={true}
                            containerClassName='ds-wp-80 border-none'
                            className='border-none'
                            selectValue={props.lang}
                            selectOption={Data.getSelectOption()}
                            onChangeSelect={(e: Elang) => {
                                props.changeLang(e)
                            }
                            }
                            containerOptionClassName='lang-select'
                        />
                    </div>
                    <div className='horizontal-line'></div>
                    <div className='ds-flex-col ds-mt-24'>
                        <span className='ds-text-neutral100 ds-text-size-14 ds-text-line-16 ds-mb-8'>
                            {props.langue.label_delete_profil}
                        </span>
                        <span className='ds-text-neutral40 ds-text-size-14 ds-text-line-16'>
                            {props.langue.delete_profile_description}
                        </span>
                        <span className='ds-pointer ds-text-error70 ds-text-size-14 ds-text-line-16 ds-flex ds-justify-end underlined-Text ds-mt-24'
                            onClick={() => {
                                props.onOpenModal();
                            }
                            }
                        >
                            {props.langue.delete_compte}
                        </span>
                    </div>

                </div>}
            </div>

        </div>
        )
    }
    return (
        <div>
            {renderCompteTemplate()}
        </div>
    )
}
export default TemplateCompte