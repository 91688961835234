import { IDayTimeType, IModalDeliveryOrder } from "../../Interfaces/Modal";
import { Button, Modal, ModalRefType } from "../../DesignSystem";
import { Ref, forwardRef, useContext, useRef, useState } from "react";
import { LangContext } from "../../Lang";
import { Type } from "../../DesignSystem/Interfaces";
import { ElementDatePicker } from "../../DesignSystem/Atoms";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Interfaces";
import { ShopAPI } from "../../Api/Shop/shop";
import { UserAPI } from "../../Api/User/user";
import { CommercantAPI } from "../../Api/Commercants/Commercants";
import React from "react";
import { openAlert, resetToken, setBasket, setBasketItems, setCategoryCommercant, setDeliveryDate, setGoodDeals, setNbreItems, setProductCommercant, setShowBasket, setUserLoyalty } from "../../Redux/Reducers";
import moment from "moment";
const DIV_Height = 52
const ModalDeliveryOrder = (props: IModalDeliveryOrder, ref: Ref<ModalRefType | undefined>) => {
    const topLigne = useRef<HTMLDivElement>(null)
    const { times, commercant } = useSelector((state: RootState) => state.commercant);
    const { delievryMode, deliveryAddress, deliveryDate } = useSelector((state: RootState) => state.paiement);
    const { lang } = useSelector((state: RootState) => state.setting);
    const { baskett, showBasket, pointRetrait, zone } = useSelector((state: RootState) => state.basket);
    const dispatch = useDispatch()
    const [shopHours, setShopHours] = useState<number[]>([])
    const [minutesShop, setMinutesShop] = useState<number[]>([])
    const [indexHour, setindexHour] = useState<number>(1)
    const [indexMinutes, setindexMinutes] = useState<number>(1)
    const [indexDay, setindexDay] = useState<number>(1)
    const shopApi = new ShopAPI()
    const [dayShop, setDayShop] = useState<number[]>([])
    const langue = useContext(LangContext)
    const userApi = new UserAPI()
    const commercantAPi = new CommercantAPI()
    const [isLoading, setIsLoading] = useState<boolean>(false)


    const getBasket = async () => {
        const res = await shopApi.getBasketOrder({ suid: commercant?.suid, lang: lang, zone: zone })
        if (res.status === 200) {
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
        }
    }
    const getUserLoloyalty = async () => {
        const response = await userApi.getUserLoyalty({
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            dispatch(setUserLoyalty({ userLoyalty: response.data }))
        }
    }
    const getDeliveryDetails = async (dateDelivery: any) => {
        const response = await shopApi.setCollectedAt({
            suid: commercant?.suid,
            lang: lang,
            zone: zone
        },
            {
                collected_at: dateDelivery,
                delivery: !delievryMode ? 3 : 1,
                delivery_address: !delievryMode && pointRetrait !== "" ? pointRetrait.address : deliveryAddress
            }
        )
        if (response.status === 200) {
            await getBasket()
            await getUserLoloyalty()
            await getCatalogueCommercant(commercant?.suid)
            const momentObj = moment(response?.data?.collected_at);
            const formatted = momentObj.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (heure normale d’Europe centrale)");
            const dateObj = new Date(formatted);
            const now = new Date();
            let futureTimeCollect = new Date();
            if (pointRetrait !== "") {
                const futureTime = new Date(now.getTime() + (commercant?.delivery_options?.delivery_min_time * 60000));
                if (futureTime > dateObj) {
                    dispatch(openAlert({ type: 'info', message: langue.error_collected_at }))
                }
                else {
                    setIsLoading(false)
                    onCloseModal()
                }
            }
            else {
                if (zone === 1) { futureTimeCollect = new Date(now.getTime() + (commercant?.delivery_options?.delivery_min_time * 60000)); }
                else if (zone === 2) { futureTimeCollect = new Date(now.getTime() + (commercant?.delivery_options?.delivery_min_time_2 * 60000)); }
                else if (zone === 3) { futureTimeCollect = new Date(now.getTime() + (commercant?.delivery_options?.delivery_min_time_3 * 60000)); }
                else if (zone === 4) { futureTimeCollect = new Date(now.getTime() + (commercant?.delivery_options?.delivery_min_time_4 * 60000)); }
                if (futureTimeCollect > dateObj) {
                    dispatch(openAlert({ type: 'info', message: langue.error_collected_at }))
                }
                else {
                    setIsLoading(false)
                    onCloseModal()
                }
            }

        } else if (response.status === 401) {
            setIsLoading(false)
            onCloseModal()
            dispatch(resetToken())
            dispatch(setShowBasket(showBasket))
        }
    }
    const getCatalogueCommercant = async (suid: string) => {
        const response = await commercantAPi.getCommercantCatalogue({
            suid: suid,
            lang: lang,
            collected_at: deliveryDate,
            delivery_method: delievryMode ? 1 : 3
        })
        if (response.status === 200) {
            setIsLoading(false)
            onCloseModal()
            dispatch(setGoodDeals({ goodDeals: response?.data }))
            const aux: any = []
            Object.values(response?.data?.categories).map((el: any) => {
                aux.push(el?.products)
            })
            dispatch(setProductCommercant({ productCommercant: aux }))
            let arr: string[] = []
            if (response?.data?.categories) {
                Object.values(response?.data?.categories).map((el: any) => {
                    arr.push(el?.name)
                })
                const formattedData: { index: number, label: string }[] = arr.map((label: string, index: number) => ({ index, label }));
                dispatch(setCategoryCommercant({ categoryCommercant: formattedData }))
            }
        } else if (response.status === 401) {
            setIsLoading(false)
            onCloseModal()
            dispatch(resetToken())
        }
    }
    const onClickPlanifier = () => {
        let date = parseCustomDate(dayShop[indexDay])
        date.setHours(shopHours[indexHour])
        date.setMinutes(minutesShop[indexMinutes])
        if (getDayDifference(date, new Date()) > 7) {
            date.setFullYear(new Date().getFullYear() + 1)
        }
        const dateDelivery = Math.floor(date.getTime() / 1000)
        dispatch(setDeliveryDate({ deliveryDate: dateDelivery }))
        setIsLoading(true)
        if (baskett?.error?.message === "Not Found") {
            getCatalogueCommercant(commercant?.suid)
        } else {
            if (Object.keys(baskett).length !== 0) {
                getDeliveryDetails(dateDelivery)
            }
        }
    }
    const onSelectDate = (index: number, key: string) => {
        if (key === "days") {
            setindexDay(index)
        }
        if (key === "hours") {
            setindexHour(index)
        }
        if (key === "minutes") {
            setindexMinutes(index)
        }

    }
    React.useEffect(() => {
        setIsLoading(false)
    }, [])
    React.useEffect(() => {
        onsetSHopHours(new Date().toISOString())
    }, [times])
    const deleteIfRepeated = (arr: number[], element: number) => {
        return arr.includes(element)
    }

    const onsetSHopHours = (date: string) => {
        const hoursArray: number[] = [-1]
        const minutesArray: number[] = [-1]
        const daysArray: number[] = [-1]
        if (Object.keys(times).length) {
            Object.values(times[2]).forEach((element: IDayTimeType | any) => {
                if (new Date(date).getDay() === new Date(element.value).getDay()) {
                    if (!deleteIfRepeated(hoursArray, element.hour)) {
                        hoursArray.push(element?.hour)
                    }
                    if (!deleteIfRepeated(minutesArray, element.text)) {
                        minutesArray.push(element?.text)
                    }
                    setMinutesShop(minutesArray)
                    setShopHours(hoursArray)
                }
            })
            Object.values(times[0]).forEach((element: IDayTimeType | any) => {
                daysArray.push(element?.text)
                setDayShop(daysArray)
            })
            for (let index = 0; index < 3; index++) {
                hoursArray.push(-1)
                minutesArray.push(-1)
                daysArray.push(-1)
            }
        }
    }

    function parseCustomDate(input: any) {
        const months: any = {
            "Jan": 0, "Feb": 1, "Mar": 2, "Apr": 3, "May": 4, "Jun": 5,
            "Jul": 6, "Aug": 7, "Sep": 8, "Oct": 9, "Nov": 10, "Dec": 11
        };

        const parts: any = input.split(" ");
        if (parts.length !== 3) {
            return new Date()
        }

        const day = parseInt(parts[1]);
        const month = months[parts[2]];
        if (isNaN(day) || month === undefined) {
            throw new Error("Invalid day or month");
        }

        const year = new Date().getFullYear();

        return new Date(year, month, day);
    }

    function getDayDifference(date1: any, date2: any) {
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());

        return Math.floor(timeDiff / (1000 * 3600 * 24));
    }

    const onClickCancel = () => {
        onCloseModal()
    }
    const onCloseModal = () => {
        if (props?.onExit) {
            props?.onExit()
        }
        setIsLoading(false)
    }

    const renderDeliveryItems = () => {
        return (
            <div className="shop-times">
                <div className="ds-w-100 grid-times ds-relative height-300">
                    <div className="ds-absolute  horizontal-line-100 ds-w-100" style={{ top: 52 }} ref={topLigne}></div>
                    <div className="ds-absolute  horizontal-line-100 ds-w-100" style={{ top: 104 }}></div>
                    <ElementDatePicker
                        data={dayShop}
                        height={DIV_Height}
                        topLigne={topLigne}
                        onChange={onSelectDate}
                        type="days"
                        withTranslation
                    />
                    <ElementDatePicker
                        data={shopHours}
                        height={DIV_Height}
                        topLigne={topLigne}
                        onChange={onSelectDate}
                        type="hours"
                        hours
                    />
                    <ElementDatePicker
                        data={minutesShop}
                        height={DIV_Height}
                        topLigne={topLigne}
                        onChange={onSelectDate}
                        type="minutes"
                    />
                </div>
            </div>
        )
    }
    const renderDeliveryOrder = () => {
        const list: JSX.Element[] = []
        list.push(
            <div className="ds-flex-col ds-w-100 ds-justify-between ds-pointer ds-p-24 delivery-date">
                <div className="ds-flex-col ds-flex-grow1 ds-justify-between ds-w-100">
                    <span className="ds-text-size-28 ds-text-line-33 ds-text-weight700 ds-text-primary100 ds-mb-30 title-planning" style={{ letterSpacing: "0.5px" }}>
                        {langue.planning_order}
                    </span>

                    {renderDeliveryItems()}

                </div>
                <div className="ds-flex-col ds-w-100">
                    <Button
                        text={langue.validate}
                        className="ds-w-100 ds-mt-40"
                        onClick={() =>
                            onClickPlanifier()
                        }
                        isLoading={isLoading}
                    />
                    <Button
                        text={langue.cancel}
                        className="ds-w-100 ds-mt-8 ds-bg-primary10 ds-borad-8 modal-Button"
                        type={Type.quaternary}
                        onClick={() => onClickCancel()}
                    />
                </div>
            </div>
        )


        return (<>{list}</>)

    }
    return (
        <Modal
            contentClassName="overflow-hidden ds-borad-14 modal-Delivery-order ds-pointer"
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
        >
            <div>
                {renderDeliveryOrder()}
            </div>
        </Modal>
    )

}
export default forwardRef(ModalDeliveryOrder)