enum TextType {
    "type-1" = "type-1",
    "type-2" = "type-2",
    "type-3" = "type-3",
    "type-4" = "type-4",
    "type-5" = "type-5",
    "type-6" = "type-6",
    "subtitle-1" = "subtitle-1",
    "subtitle-2" = "subtitle-2",
    "body-1" = "body-1",
    "body-2" = "body-2",
    "body-3" = "body-3",
    "body-4" = "body-4",
    "btn-text-1" = "btn-text-1",
    "btn-text-2" = "btn-text-2",
    "btn-text-3" = "btn-text-3",
    "caption" = "caption",
    "overline" = "overline",
}



interface ITextProps extends React.HTMLProps<HTMLElement> {
    isLabel?: boolean;
    isSpan?: boolean;
    text?: string;
    id?: string;
    isLoading?: boolean;
    bsPrefix?: string;
    type?: TextType;
    weight?: string;
}

export type {
    ITextProps
}
export {
    TextType
}