import React, { useContext, useState } from "react";
import { Avatar, Checkbox, Icon } from "../../Atoms"
import { DEFAULTBASKETPROPS } from "../../DefaultProps/Organisms"
import { IBasketItem, IBasketTypeItem, IFooterElement, IOrganismBasket, EOrganismRight, IRechargeItem, IRechargeItemType, SizeAvatar, Type } from "../../Interfaces"
import { Button, Input } from "../../Molecules";
import vector from '../../../Assets/images/Vector.svg';
import logo from '../../../Assets/images/Icone_Digi-M.svg';
import { useUpdateEffect } from "../../../CustomHooks";
import { useDispatch, useSelector } from "react-redux";
import { openAlert, resetToken, setBasket, setBasketItems, setCardSaved, setCreditPoint, setCustomerMember, setDeliveryAddress, setEmptyBasket, setFromRecharge, setGeneralConditon, setMethodePiement, setNbreItems, setPayementMethodes, setProduct, setShowBasket, setShowRecharge, setTabOptions, setTotalCommande, setUserLoyalty } from "../../../Redux/Reducers";
import { RootState } from "../../../Interfaces";
import { openModal } from "../../../Redux/Reducers/ModalReducer/ModalReducer";
import { ShopAPI } from "../../../Api/Shop/shop";
import { UserAPI } from "../../../Api/User/user";
import { PaiementApi } from "../../../Api/Paiement/Paiement";
import { CommonFunction } from "../../../Common";
import moment from 'moment';
import { LangContext } from "../../../Lang";
import { toNumber } from "lodash";

function OrganismBasket(props: IOrganismBasket): JSX.Element {
    moment.locale('fr');
    const dispatch = useDispatch()
    const shopApi = new ShopAPI()
    const userApi = new UserAPI()
    const paiementApi = new PaiementApi()
    const shopAPI = new ShopAPI()
    const basketItems = useSelector((state: RootState) => state?.basket?.items)
    const { baskett, commentItem, zone } = useSelector((state: RootState) => state?.basket)
    const { showBasket, showRecharge } = useSelector((state: RootState) => state?.basket)
    const { deliveryAddress, delievryMode, deliveryDate, cardSaved } = useSelector((state: RootState) => state.paiement)
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    const myCredit = useSelector((state: RootState) => state?.commercant?.customerMember?.credit)
    const { user } = useSelector((state: RootState) => state?.auth)
    const { lang } = useSelector((state: RootState) => state?.setting)
    const className = ["default-basket ds-absolute ds-bg-white ds-p-30 ds-pointer ds-flex-col ds-flex-grow1 overflow-auto "]
    const headerClassName = ["ds-flex-col ds-w-100 ds-pointer"]
    const [credit, setCredit] = useState<string | undefined>(props.credit)
    const [selectedCheckbox, setSelectedCheckbox] = useState<number | null>(null)
    const [nbreItemsBasket, setNbreItemsBasket] = useState<number>(baskett?.quantity)
    const [items, setItems] = useState<any>(basketItems)
    const [itemsRecharge, setItemsRecharge] = useState<IRechargeItemType | undefined>(props.RechargeItem)
    const [total, setTotal] = useState<number | undefined>(props.basketTotal);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const langue = useContext(LangContext)
    const [isMinPrix, setIsMinPrix] = useState<boolean>(false);
    const [btnQtn, setBtnQtn] = useState<boolean>(false);
    const [spanMinBasket, setSpanMinBasket] = useState<string>("");

    const verifMinPrix = () => {
        switch (zone) {
            case 2:
                if (commercant?.delivery_options?.delivery_min_basket_2 <= baskett?.total - (baskett?.fees + baskett?.delivery_fees)) {
                    setIsMinPrix(true)
                }
                else {
                    setIsMinPrix(false)
                    setSpanMinBasket(commercant?.delivery_options?.delivery_min_basket_2)
                }
                break;
            case 3:
                if (commercant?.delivery_options?.delivery_min_basket_3 <= baskett?.total - (baskett?.fees + baskett?.delivery_fees)) {
                    setIsMinPrix(true)
                }
                else {
                    setIsMinPrix(false)
                    setSpanMinBasket(commercant?.delivery_options?.delivery_min_basket_3)
                }
                break;
            case 4:
                if (commercant?.delivery_options?.delivery_min_basket_4 <= baskett?.total - (baskett?.fees + baskett?.delivery_fees)) {
                    setIsMinPrix(true)
                }
                else {
                    setIsMinPrix(false)
                    setSpanMinBasket(commercant?.delivery_options?.delivery_min_basket_4)
                }
                break;
            default:
                if (commercant?.delivery_options?.delivery_min_basket <= baskett?.total - (baskett?.fees + baskett?.delivery_fees)) {
                    setIsMinPrix(true)
                }
                else {

                    setIsMinPrix(false)
                    setSpanMinBasket(commercant?.delivery_options?.delivery_min_basket)

                }
                break;
        }
    };
    React.useEffect(() => {
        if (basketItems) {
            setItems(basketItems)
        }
    }, [basketItems])
    React.useEffect(() => {
        if (baskett) {
            setNbreItemsBasket(baskett?.quantity)
        }
        verifMinPrix()
    }, [baskett])
    React.useEffect(() => {
        dispatch(setCardSaved())
        dispatch(setGeneralConditon())
        dispatch(setMethodePiement({ methodePaiment: null }))
        getBasket()
    }, [])

    const updateOrderQuantity = async (item: any, quantity: number) => {
        const response = await shopAPI.updateQuantityBasket({ suid: commercant?.suid },
            {
                order_line_id: item?.id,
                new_quantity: quantity,
                remarque: commentItem
            }
        )
        if (response?.status === 200) {
            getBasket()
        } else if (response?.status === 401) {
            dispatch(resetToken())
        }

    }
    const onSetOrderQuantity = async (decrement: boolean, index: number) => {

        let formatedTotal;
        let aux = { ...items }
        let qtyMax = 0;

        if (aux[index].product?.attribute?.availability?.quantity) {
            qtyMax = aux[index].product?.attribute?.availability?.quantity;
        } else if (aux[index].product?.attribute?.current_stock) {
            qtyMax = aux[index].product?.attribute?.current_stock
        } else {
            qtyMax = 250
        }
        if (aux[index].product?.unity !== null && aux[index].product?.unity?.id !== 1) {
            qtyMax = 5000;
        }
        if (decrement) {
            let newQuantity = aux[index]?.quantity * 1000;
            let newPriceQuantity = aux[index]?.price;
            if (newQuantity !== undefined && newPriceQuantity !== undefined) {
                if ((aux[index]?.product?.unity?.id === 2)) {
                    if ((aux[index]?.quantity * 1000) >= 100) {
                        newQuantity = (aux[index]?.quantity * 1000) - 50 - ((aux[index]?.quantity * 1000) % 50);
                        newPriceQuantity = (aux[index]?.price * newQuantity) / 1000;
                    } else {
                        newQuantity = 0.05
                        newPriceQuantity = (aux[index]?.price * newQuantity);
                    }
                } else {
                    if (aux[index]?.quantity > 1) {
                        newQuantity = aux[index]?.quantity - 1;
                        newPriceQuantity = aux[index]?.price * newQuantity;
                    }
                }
                const discountPrice = newPriceQuantity - (newPriceQuantity * aux[index]?.product?.vat?.amount)
                if (newPriceQuantity !== undefined && CommonFunction.setFormatNumber(newPriceQuantity) !== undefined) {
                    formatedTotal = CommonFunction.setFormatNumber(newPriceQuantity)?.toString()
                }
                aux[index] = {
                    ...aux[index],
                    quantity: aux[index]?.product?.unity?.id === 2 ? newQuantity / 1000 : newQuantity,
                    quantity_with_discount: newQuantity,
                    quantity_formated: newQuantity.toFixed(2) + "x",
                    total_discounted: newPriceQuantity,
                    total_exc_vat: discountPrice,
                    total_formated: formatedTotal,
                    total: newPriceQuantity
                }
                if (aux[index]?.product?.unity?.id === 2) {
                    if (aux[index]?.quantity > 0.05) {
                        updateOrderQuantity(aux[index], newQuantity / 1000)
                    }
                } else {
                    updateOrderQuantity(aux[index], newQuantity)
                }
            }
        }
        if (!decrement) {
            if (aux[index]?.quantity < qtyMax) {
                let newQuantity;
                let newPriceQuantity;
                if ((aux[index]?.product?.unity?.id === 2)) {
                    newQuantity = (aux[index]?.quantity * 1000) + 50 - ((aux[index]?.quantity * 1000) % 50);
                    newPriceQuantity = (aux[index]?.price * newQuantity) / 1000;
                } else {
                    newQuantity = aux[index]?.quantity + 1;
                    newPriceQuantity = aux[index]?.price * newQuantity;
                }
                const discountPrice = newPriceQuantity - (newPriceQuantity * aux[index]?.product?.vat?.amount)
                if (newPriceQuantity !== undefined && CommonFunction.setFormatNumber(newPriceQuantity) !== undefined) {
                    formatedTotal = CommonFunction.setFormatNumber(newPriceQuantity)?.toString()
                }
                aux[index] = {
                    ...aux[index],
                    quantity: aux[index]?.product?.unity?.id === 2 ? newQuantity / 1000 : newQuantity,
                    quantity_with_discount: newQuantity,
                    quantity_formated: newQuantity.toFixed(2) + "x",
                    total_discounted: newPriceQuantity,
                    total_exc_vat: discountPrice,
                    total_formated: formatedTotal,
                    total: newPriceQuantity
                }
                if (aux[index]?.product?.unity?.id === 2) {
                    updateOrderQuantity(aux[index], newQuantity / 1000)
                } else {
                    updateOrderQuantity(aux[index], newQuantity)
                }
            } else {
                dispatch(openAlert({ type: 'info', message: langue.empty_disponiblity }))
            }
        }
        setItems(aux)
    }

    const getBasket = async () => {
        const res = await shopAPI.getBasketOrder({ suid: commercant?.suid, lang: lang, zone: zone })
        if (res.status === 200) {
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
            setBtnQtn(false)
            // } else if (res?.status === 401) {
            //     dispatch(resetToken())
        }
    }
    const deleteItem = async (el: IBasketItem) => {
        const response = await shopApi.deleteItem({ uid: el?.uniq, suid: commercant?.suid, lang: lang, zone: zone })
        if (response.status === 200) {
            if (response?.data.length === 0) {
                dispatch(setShowBasket(showBasket))
                dispatch(setBasket({ baskett: response.data, basketId: response?.data?.uniq }))
                dispatch(setBasketItems({ items: {} }))
            } else {
                dispatch(setBasket({ baskett: response.data, basketId: response?.data?.uniq }))
                dispatch(setBasketItems({ items: response.data.items }))
            }
        } else if (response?.status === 401) {
            dispatch(setShowBasket(showBasket))
            dispatch(resetToken())
        }
    }
    const onSetBasketTotalandFees = () => {
        const aux = { ...items }
        let som = 0;
        let som2 = 0;
        const res = Object.values(aux).map((item: IBasketItem | any) => {
            if (item?.total) {
                som = som + (item?.total)
            } else {
                som = som + parseFloat(item.price)
            }
            som2 = som2 + item.quantity
            if (props.fraisService) {
                som = som + parseFloat(baskett?.fees)
            }
            setTotal(som)
            setNbreItemsBasket(som2)
            dispatch(setNbreItems({ nbreItems: som2 }))
            return aux
        })
    }
    useUpdateEffect(() => {
        onSetBasketTotalandFees()
    }, [items])


    const onEmptyBasket = async () => {
        const response = await shopAPI.emptyBasket({ suid: commercant?.suid, lang: 'fr' })
        if (response.status === 200) {
            dispatch(setDeliveryAddress({ deliveryAddress: '' }))
            setItems({})
            dispatch(setEmptyBasket())
            dispatch(setShowBasket(showBasket))
        }

    }
    const getUserLoloyalty = async () => {
        const response = await userApi.getUserLoyalty({
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            dispatch(setUserLoyalty({ userLoyalty: response.data }))
        }
    }
    const getCustomerMemeber = async () => {
        const response = await userApi.getCustomerMember({
            userId: user?.uniq,
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            dispatch(setCustomerMember({ customerMember: response.data }))
        }
    }
    const getPaiementMethode = async () => {
        const response = await paiementApi.getPaiementMethode({
            userId: user?.uniq,
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            dispatch(setPayementMethodes({
                paymentMethodes: response?.data?.data
                [0]
            }))
        }
    }
    const onClickCommander = async () => {
        const now = new Date();
        const momentObj = moment(baskett.collected_at);
        const formatted = momentObj.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (heure normale d’Europe centrale)");
        const dateObj = new Date(formatted);
        let futureTimeCollect = new Date();
        if (zone === 1) { futureTimeCollect = new Date(now.getTime() + (commercant?.delivery_options?.delivery_min_time * 60000)); }
        else if (zone === 2) { futureTimeCollect = new Date(now.getTime() + (commercant?.delivery_options?.delivery_min_time_2 * 60000)); }
        else if (zone === 3) { futureTimeCollect = new Date(now.getTime() + (commercant?.delivery_options?.delivery_min_time_3 * 60000)); }
        else if (zone === 4) { futureTimeCollect = new Date(now.getTime() + (commercant?.delivery_options?.delivery_min_time_4 * 60000)); }

        if (cardSaved) {
            dispatch(setCardSaved(cardSaved))
        }

        if (!delievryMode && commercant?.delivery_points?.length !== 0) {
            const isFutureTime = new Date(now.getTime() + (commercant?.delivery_options?.delivery_min_time * 60000));

            if (deliveryAddress === "" || deliveryDate === "") {
                dispatch(openModal({ name: 'modalDelivery' }))
            }

            else if (commercant?.delivery_options?.delivery_min_basket >= baskett?.total - (baskett?.fees + baskett?.delivery_fees)) {
                dispatch(openAlert({ type: 'info', message: langue.minimum_basket + ` ${commercant?.delivery_options?.delivery_min_basket} € ` + langue.hors_service }))
            }
            else if (isFutureTime > dateObj) {
                dispatch(openAlert({ type: 'info', message: langue.error_collected_at }))
                dispatch(openModal({ name: 'modalDeliveryOrder' }))
            }
            else {
                if (total) {
                    setIsLoading(true)
                    dispatch(setTotalCommande({ totalCommande: baskett?.total }));
                    const response = await shopApi.getVoucherCheck({
                        suid: commercant?.suid,
                        lang: lang,
                        zone: zone
                    })
                    if (response.status === 200) {
                        setIsLoading(false)
                        getUserLoloyalty()
                        getCustomerMemeber()
                        getPaiementMethode()
                        dispatch(setShowBasket(showBasket))
                        dispatch(openModal({ name: 'modalPaiment' }));
                        dispatch(setFromRecharge({ fromRecharge: false }))
                    }
                }
            }
        }
        else

            if (!delievryMode && commercant?.delivery_points?.length === 0) {

                if (deliveryAddress === "" || deliveryDate === "") {
                    dispatch(openModal({ name: 'modalDelivery' }))
                }
                else if (!isMinPrix) {
                    dispatch(openAlert({ type: 'info', message: langue.minimum_basket + ` ${spanMinBasket} € ` + langue.hors_service }))

                }
                else if (futureTimeCollect > dateObj) {
                    dispatch(openAlert({ type: 'info', message: langue.error_collected_at }))
                    dispatch(openModal({ name: 'modalDeliveryOrder' }))
                }
                else {
                    if (total) {
                        setIsLoading(true)
                        dispatch(setTotalCommande({ totalCommande: baskett?.total }));
                        const response = await shopApi.getVoucherCheck({
                            suid: commercant?.suid,
                            lang: lang,
                            zone: zone
                        })
                        if (response.status === 200) {
                            setIsLoading(false)
                            getUserLoloyalty()
                            getCustomerMemeber()
                            getPaiementMethode()
                            dispatch(setShowBasket(showBasket))
                            dispatch(openModal({ name: 'modalPaiment' }));
                            dispatch(setFromRecharge({ fromRecharge: false }))
                        }
                    }
                }
            } else {
                const futureTimeCollect = new Date(now.getTime() + (commercant?.collect_min_time * 60000));
                if (deliveryDate === "") {
                    dispatch(openModal({ name: 'modalDelivery' }))
                } else if (futureTimeCollect > dateObj) {
                    dispatch(openAlert({ type: 'info', message: langue.error_collected_at }))
                    dispatch(openModal({ name: 'modalDeliveryOrder' }))
                }
                else if (total) {
                    setIsLoading(true)
                    dispatch(setTotalCommande({ totalCommande: baskett?.total }));
                    const response = await shopApi.getVoucherCheck({
                        suid: commercant?.suid,
                        lang: lang,
                        zone: zone
                    })
                    if (response.status === 200) {
                        setIsLoading(false)
                        getUserLoloyalty()
                        getCustomerMemeber()
                        getPaiementMethode()
                        dispatch(setShowBasket(showBasket))
                        dispatch(openModal({ name: 'modalPaiment' }));
                        dispatch(setFromRecharge({ fromRecharge: false }))
                        dispatch(setMethodePiement({ methodePaiment: '' }))
                    }
                }

            }

    }
    const onClickCharger = async () => {
        if (credit) {
            if (cardSaved) {
                dispatch(setCardSaved(cardSaved))
            }
            dispatch(setMethodePiement({ methodePaiment: '' }))
            dispatch(setCreditPoint({ credit: parseFloat(credit.replace(",", ".")) }));
            getUserLoloyalty()
            getCustomerMemeber()
            getPaiementMethode()
            dispatch(setShowRecharge(showRecharge))
            dispatch(openModal({ name: 'modalPaiment', data: { showCradDetails: true } }));
            dispatch(setFromRecharge({ fromRecharge: true }))
        }
    }
    const onClickBtnFooter = (btnText: string | undefined, index: number) => {
        switch (index) {
            case 0:
                dispatch(openModal({ name: 'modalDelivery' }));
                break;
            case 1:
                dispatch(openModal({ name: 'modalAddNote', data: { isTrue: true, basket: baskett } }));
                break;
            case 2:
                if (btnText === langue.validate || btnText === langue.modify) {
                    dispatch(openModal({ name: 'modalCouponReduction', data: true }));
                }
                break;
            default:
                break;
        }
    }
    const onCloseBasetOrRecharge = () => {
        if (showBasket) {
            dispatch(setShowBasket(showBasket))
        } else {
            dispatch(setShowRecharge(showRecharge))
        }
    }

    const renderBasketHeader = () => {
        const list: JSX.Element[] = []
        if (props.type === EOrganismRight.recharge) {
            headerClassName.push('recharge-header-class')
        }
        if (props.type === EOrganismRight.recharge) {
            list.push(
                <div className={headerClassName.join(' ')}>
                    <div className="ds-flex ds-justify-end ds-align-end">
                        <Icon
                            icon="close"
                            size={"24px"}
                            onClick={() => onCloseBasetOrRecharge()}
                        />
                    </div>
                    <span className="ds-text-size-28 ds-text-line-33 ds-text-primary100 ds-text-weight700">{langue.title_prepaye}
                    </span>
                    <span className="ds-text-size-20 ds-text-line-24 ds-text-primary40 ds-text-weight500 ds-mb-16">
                        {commercant?.name}
                        <span className="ds-text-size-16 ds-text-line-19 ds-text-primary40 ds-text-weight500">{commercant?.city}</span>
                    </span>
                    <div className="horizontal-line grey-line ds-mt-16 ds-mb-25"></div>
                    <Avatar
                        user={{ firstname: user?.firstname, lastname: user?.lastname, email: user?.username }}
                        size={SizeAvatar.medium}
                        isNameText
                    />
                    <span className="ds-mt-16 ds-mb-8 ds-text-size-16 ds-text-line-19 ds-text-primary100 ds-text-weight700">{langue.account_credit}</span>
                    <div className="ds-flex ds-w-100 ds-mb-8">
                        <span className="ds-flex-grow1 ds-text-size-28 ds-text-line-33 ds-text-primary100 ds-text-weight300">{`${CommonFunction.setFormatNumber(myCredit)} €`}</span>
                        <span className="ds-text-size-16 ds-text-line-19 ds-text-primary100 ds-text-weight400">{"0 " + langue.point}</span>
                    </div>
                    <div className="grey-line"></div>
                </div>
            )
        } else {
            list.push(<div className={headerClassName.join(' ')}>
                <div className=" ds-flex ds-justify-end ds-align-end">
                    <Icon
                        icon="close"
                        size={"24px"}
                        onClick={() => onCloseBasetOrRecharge()}
                    />
                </div>
                <span className="ds-text-size-16 ds-text-line-19 ds-text-primary100 ds-text-weight500">{langue.basket}
                    <span className="ds-text-size-16 ds-text-line-19 ds-text-primary100 ds-text-weight700">
                        {` (${nbreItemsBasket})`}
                    </span>
                </span>
                <span className="ds-mt-8 ds-mb-16 ds-text-size-24 ds-text-line-28 ds-text-primary100 ds-text-weight700">
                    {commercant?.name}
                    <span className="ds-text-size-16 ds-text-line-19 ds-text-primary100 ds-text-weight700">{` - ${commercant?.city}`}</span>
                </span>
                <div className="horizontal-line ds-mb-16"></div>
                {baskett.delivery_fees !== 0 &&
                    <>
                        <div className="ds-flex ds-w-100 ds-align-center ds-justify-between">
                            <span className="ds-text-secondary100 ds-text-weight700 ds-text-size-16" >{langue.delivery_price}</span>
                            <span>{baskett.delivery_fees} €</span>
                        </div>
                        <div className="horizontal-line ds-mt-16 ds-mb-12"></div>
                    </>}

            </div>
            )
        }
        return <>{list}</>
    }
    const onClickBasketItem = (prod: IBasketItem) => {
        dispatch(setProduct({ product: prod?.product }))
        dispatch(setShowBasket(showBasket))
        if (prod?.product?.menu_categories.length !== 0) {
            dispatch(openModal({ name: 'modalComposeFormule', data: { product: null, item: prod, fromBasket: true } }))
        } else {
            dispatch(openModal({ name: 'modalProductDetails', data: { product: null, item: prod, fromBasket: true } }))
        }

    }
    const renderBasketItem = (category: string) => {
        const list: JSX.Element[] = []
        {
            Object.values(items)?.forEach((el: IBasketItem | any, index: number) => {
                if (el?.product?.category_slug === category) {
                    list.push(
                        <div key={index} className="ds-flex-col ds-mb-24 ds-p-4 basket-overflow">
                            <div className="ds-flex image-btn" >
                                <div className="ds-flex ds-w-100" >
                                    <div className="ds-flex-col ds-align-start basket-item-class ds-wp-172 ds-hp-92" onClick={() => {
                                        onClickBasketItem(el)
                                    }}>
                                        <span className="ds-mb-8 ds-text-size-16 ds-text-line-19 ds-text-primary100 ds-text-weight700">
                                            {el?.name}
                                        </span>
                                        {el?.discount_quantity !== null && el?.quantity !== 0 && <span className="ds-mb-8 ds-text-size-12 ds-text-line-16 ds-text-primary100 ds-text-weight400">
                                            {langue.including + ` ${el?.discount_quantity} ` + langue.free}
                                        </span>}
                                        <span className="ds-text-size-12 ds-text-line-14 ds-text-primary70 ds-text-weight400 description">{el?.decription}</span>
                                    </div>
                                </div>
                                {
                                    el?.quantity === 0 ?
                                        <div className="ds-ml-10 ds-flex ds-w-100 ds-justify-between">
                                            <span className="ds-text-size-16 ds-text-line-19 ds-text-primary40 ds-text-weight400">{langue.gratuit}</span>
                                            <Button
                                                icon="trash"
                                                className="btn-delete  ds-text-error100 ds-mr-9"
                                                onClick={() => deleteItem(el)}
                                                type={Type.quaternary}

                                            />
                                        </div> :
                                        <div className="action-btn ds-flex-col ds-justify-between">
                                            {renderQuantite(el, index)}
                                            <span className="price-class ds-text-size-16 ds-text-line-19 ds-text-primary100 ds-text-weight400 ds-flex ds-align-end ds-justify-end">
                                                {`${el?.total && CommonFunction.setFormatNumber(el?.total && el?.total)} €`}
                                            </span>
                                        </div>
                                }
                            </div>
                        </div>
                    )
                }
            })

        }
        return <>{list}</>
    }
    const renderCategoryElement = (category: string) => {
        if (category) {
            return (
                <div className="ds-flex ds-w-100 ds-bg-secondary10 ds-borad-4 ds-hp-24 ds-center ds-p-4 ds-mb-16">
                    <div className="ds-flex ds-align-center ds-w-100">
                        <span className="dot ds-mr-8"></span>
                        <span className="ds-text-size-16 ds-text-line-19 ds-text-primary70 ds-text-weight500">{category}</span>
                    </div>
                </div>
            )
        }
    }
    const onChangeCheckbox = (index: number) => {
        if (itemsRecharge) {
            const aux = { ...itemsRecharge }
            aux[index] = { ...aux[index], checked: !aux[index].checked }
            setItemsRecharge(aux)
            setCredit(aux[index].value)
            setSelectedCheckbox(index)
        }
    }

    useUpdateEffect(() => {
        if (props.RechargeItem) {
            setItemsRecharge(props.RechargeItem)
        }
    }, [props.RechargeItem])

    const renderRechargeElement = () => {
        const list: JSX.Element[] = []
        let classNameRecharge = ['ds-flex ds-w-100 ']
        {
            props.RechargeItem?.forEach((el: IRechargeItem, index: number) => {
                const basketMinAmount = commercant?.basket_min_amount ? toNumber(commercant?.basket_min_amount) : 0
                if (toNumber(el?.value) >= basketMinAmount) {
                    list.push(
                        <div className={`ds-flex-col ds-w-100 ds-p-16 ${selectedCheckbox === index ? 'ds-bg-neutral4' : 'ds-bg-white'}`} onClick={() => onChangeCheckbox(index)}>
                            <div className={classNameRecharge.join(' ')}>
                                <span className="ds-flex-grow1">{el.label}</span>
                                <Checkbox
                                    checked={selectedCheckbox === index}
                                    onChange={() => onChangeCheckbox(index)}
                                    className="ds-pointer checkbox-recharge"
                                    containerClassName="ds-pointer checkbox-recharge"
                                />
                            </div>
                        </div >
                    )
                    list.push(
                        <div className="horizontal-line "></div>
                    )
                }
            })
        }
        return <>{list}</>
    }

    const checkCouponBlock = (el: any, index: any) => {
        return baskett?.discount !== 0 && index === 2 && el?.description !== langue.voucher_placeholder
    }
    const renderBasketElement = () => {
        const list: JSX.Element[] | any = []
        const classNameBasketItem = [' ']
        if (props.type === EOrganismRight.recharge) {
            classNameBasketItem.push('ds-w-100 recharge-class')
        }
        if (props.type === EOrganismRight.basket) {

            if (baskett.delivery_fees !== 0) {
                classNameBasketItem.push('ds-overflow-y bassket-class-frais')
            }
            else {
                classNameBasketItem.push('ds-overflow-y bassket-class')
            }
        }
        if (props.type === EOrganismRight.recharge) {
            list.push(
                <div className="ds-flex-col ds-w-100">
                    <span className="ds-mt-32 ds-text-size-16 ds-text-line-19 ds-text-primary70 ds-text-weight700">
                        {langue.credit_other}
                    </span>
                    {renderRechargeElement()}
                </div>
            )
        } else {
            let arr: string[] = []
            if (basketItems) {
                Object.values(basketItems)?.forEach((el: IBasketItem | any, i: number) => {
                    const category = el?.product?.category_slug
                    if (category) {
                        arr.push(category)
                    }
                })
            }
            const uniqueArray = arr.filter((item: any, index: number) => arr.indexOf(item) === index);
            uniqueArray.forEach((category: any) => {
                list.push(renderCategoryElement(category));
                list.push(renderBasketItem(category))
            });
        }
        return <div className={classNameBasketItem.join(' ')}>{list}</div>
    }

    const renderBasketFooter = () => {
        const list: JSX.Element[] = []
        if (props.type === EOrganismRight.recharge) {
            list.push(
                <div className=" ds-w-100 ds-pt-20 ds-mt-23">
                    <Button
                        text={langue.charger + ` -${credit ? credit : ''}`}
                        type={Type.primary}
                        className="ds-w-100"
                        onClick={() => onClickCharger()}
                        disabled={selectedCheckbox === null}
                    />
                </div>
            )
        } else {
            list.push(
                <div className="ds-flex-col ds-w-100 ds-relative remove-class">
                    <span className="ds-mb-30 ds-flex ds-justify-end underlined-Text ds-text-size-14 ds-text-line-16 ds-text-primary40 ds-text-weight400"
                        onClick={() => onEmptyBasket()}
                    >{langue.empty_your_basket}</span>
                    {CommonFunction?.renderFooterItem(langue, baskett)?.map((el: IFooterElement, index: number) => {
                        if (index === 0) {
                            return (
                                <div key={index}>
                                    <div className="ds-flex ds-w-100 ds-align-center ds-justify-between">
                                        <div className="ds-flex ds-flex-grow1 ds-align-center">
                                            <Icon
                                                icon={el.iconItem}
                                                size={"20px"}
                                                className="ds-text-secondary100 ds-mr-8 ds-mb-13"
                                            />
                                            <div className="ds-flex-col">
                                                <span className="ds-text-size-16 ds-text-line-19 ds-flex ds-text-primary100 ds-text-weight500">{el.title}</span>
                                                <span className="ds-flex ds-align-center ds-text-size-12 ds-text-line-14 ds-text-primary40">{el.description}</span>
                                            </div>
                                        </div>
                                        <Button
                                            text={el.textBtn}
                                            type={Type.tertiary}
                                            onClick={() => onClickBtnFooter(el.textBtn, index)}
                                            className="border-primary10 ds-text-primary70 ds-text-size-12 ds-text-line-14 ds-mb-1"
                                        />
                                    </div>
                                    <div className="ds-flex-col ds-w-100 basket-class">
                                        {baskett?.delivery_method == 3 && baskett?.delivery_address !== '' && <div className="ds-flex ds-align-center">
                                            <Icon
                                                icon="location"
                                                className="ds-text-secondary100 ds-mb-5"
                                                size={"16px"}
                                            />
                                            <span className="ds-ml-5 ds-text-size-14 ds-text-line-16 ds-flex ds-text-primary70 ds-text-weight400">{baskett?.delivery_address}</span>
                                        </div>}
                                        {deliveryDate && <div className="ds-flex ds-align-center ">
                                            <Icon
                                                icon="clock"
                                                className="ds-text-secondary100 ds-mb-5"
                                                size={"16px"}
                                            />
                                            <span className="ds-ml-5 ds-text-size-14 ds-text-line-16 ds-flex ds-text-primary70 ds-text-weight400">{CommonFunction.timestampToDate(deliveryDate, langue)}</span>
                                        </div>}
                                        <div className="horizontal-line ds-mb-10"></div>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div key={el.deliveryDate} className="comment-coupon-class">
                                    <div className="ds-flex ds-w-100 ds-align-center ds-justify-between">
                                        <div className="ds-flex ds-flex-grow1 ds-align-center">
                                            <Icon
                                                icon={el.iconItem}
                                                size={"20px"}
                                                className="ds-text-secondary100 ds-mr-8 ds-mb-13"
                                            />
                                            <div className="ds-flex-col">
                                                <span className="ds-text-size-16 ds-text-line-19 ds-flex ds-text-primary100 ds-text-weight500">{el.title}</span>
                                                <span className={`ds-flex ds-align-center ds-text-size-12 ds-text-line-14 ds-text-primary40 
                                                    description-basket${checkCouponBlock(el, index) && '-reduction'}`}>{el.description}</span>
                                            </div>
                                            {index === 2 && baskett?.discount !== 0 &&
                                                <Button
                                                    text={`-${baskett?.discount}%`}
                                                    type={checkCouponBlock(el, index) ? Type.secondary : Type.tertiary}
                                                    className={`ds-text-primary70 ds-mr-5 ds-text-size-12 ds-text-line-14 ds-mb-20 border-secondary`}
                                                />
                                            }
                                        </div>
                                        <Button
                                            text={el.textBtn}
                                            type={Type.tertiary}
                                            onClick={() => onClickBtnFooter(el.textBtn, index)}
                                            className={`ds-text-primary70 ds-text-size-12 ds-text-line-14 ds-mb-20 border-primary10`}
                                        />
                                    </div>
                                    <div className="horizontal-line ds-mb-10"></div>
                                </div>
                            )
                        }
                    })}
                    <div className="ds-relative ">
                        <div className="ds-flex ds-align-center ds-justify-between ">
                            <div className="ds-flex ds-align-center ds-flex-grow1">
                                <img src={logo} height={24} width={24} />
                                <span className="ds-ml-8 ds-mr-8 ds-text-size-16 ds-text-weight500 ds-text-line-19">{langue.fees}</span>
                                <img src={vector} onClick={() => setShowPopever(!showPopever)} />
                            </div>
                            <span>{`${baskett?.fees && CommonFunction.setFormatNumber(baskett?.fees)} €`}</span>
                        </div>
                        {showPopever && renderPopOver()}
                    </div>
                    <div className="horizontal-line fees-class ds-mt-10 ds-mb-36"></div>
                    <div className="ds-w-100 button-cmd">
                        <Button
                            text={langue.order + ` - ${(baskett.total_formated)} €`}
                            type={Type.primary}
                            className="ds-w-100 ds-text-size-16 ds-text-weight700 ds-text-line-19"
                            onClick={() => onClickCommander()}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            )
        }
        return <>{list}</>
    }

    const [showPopever, setShowPopever] = useState(false)
    const renderPopOver = () => {
        return (
            <div className="ds-text-size-10 ds-text-height300 ds-text-primary70 ds-bg-white ds-absolute top-00 ds-p-16">
                {langue.description_fees}
            </div>
        )
    }
    const renderQuantite = (el: IBasketItem, i: number) => {
        return (
            <div className="ds-flex ds-align-center ds-justify-between">
                <Button
                    icon="trash"
                    className="btn-delete  ds-text-error100 ds-mr-2"
                    onClick={() => deleteItem(el)}
                    type={Type.quaternary}

                />
                {((el?.quantity ?? 0) >= 2 && (el?.product?.unity?.id == 1 || el?.product?.unity == null)) && <Button
                    type={Type.tertiary}
                    icon="minus"
                    onClick={() => {
                        setBtnQtn(true)
                        onSetOrderQuantity(true, i)
                    }
                    }
                    disabled={btnQtn}
                    className="ds-text-primary40 quantity-btn"
                />}
                {((el?.quantity ?? 0) > 0.05 && el?.product?.unity?.id == 2) && <Button
                    type={Type.tertiary}
                    icon="minus"
                    onClick={() => {
                        setBtnQtn(true)
                        onSetOrderQuantity(true, i)
                    }
                    }
                    disabled={btnQtn}
                    className="ds-text-primary40 quantity-btn"
                />}
                <Input
                    value={el.quantity}
                    className="quatity-input-basket"
                    containerClassName="quatity-input-basket ds-ml-2 ds-mr-2"
                    disabled={true}
                />
                <Button
                    type={Type.tertiary}
                    icon="add"
                    onClick={() => {
                        setBtnQtn(true)
                        onSetOrderQuantity(false, i)
                    }
                    }
                    disabled={btnQtn}
                    className="ds-text-primary40 quantity-btn"
                />
            </div>
        )
    }
    if (props.className) className.push(props.className)

    if (props.type === EOrganismRight.recharge) {
        className.push('ds-overflow-x')
    }
    return (
        <div className={className.join(' ')} style={{ height: window.innerHeight }}>
            <div className="ds-flex-grow1 ds-flex-col">
                {renderBasketHeader()}
                {renderBasketElement()}
            </div>
            {renderBasketFooter()}
        </div>
    )
}
OrganismBasket.defaultProps = {
    ...DEFAULTBASKETPROPS
}

export default OrganismBasket