import { ITemplateMentionLegales } from "../../Interfaces"

const TemplateMentionLegales = (props: ITemplateMentionLegales): JSX.Element => {
    return (
        <div className="ds-flex-col ds-align-center ds-overflow-x ds-w-100 height-40  ds-p-40">
            <div dangerouslySetInnerHTML={{ __html: (props?.mentionLegales) }}>
            </div>
        </div>
    )
}
export default TemplateMentionLegales