import { Ref, forwardRef, useContext } from "react";
import { Modal, ModalRefType } from "../../DesignSystem";
import { IModalResetPassword } from "../../Interfaces/Modal";
import { TemplateModalResetPassword } from "../../Template";
import { EListFunction, IUseFormResult, useForm } from "../../CustomHooks";
import { LangContext } from "../../Lang";
import { UserAPI } from "../../Api/User/user";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Interfaces";
import { openAlert } from "../../Redux/Reducers";

const ModalResetPassword = (props: IModalResetPassword, ref: Ref<ModalRefType | undefined>) => {
    const langue = useContext(LangContext)
    const userAPi = new UserAPI()
    const { commercant } = useSelector((state: RootState) => state.commercant)
    const { lang } = useSelector((state: RootState) => state.setting)
    const { resetEmail } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch();

    const { state: form, onChange }: IUseFormResult = useForm({
        isRealTimeValidation: true,

        data: [
            {
                key: "code",
                value: "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.code_required,
                    priority: 1,
                },
                {
                    function: EListFunction.checkLenghtCodeConfirmation,
                    messageError: langue.code_invalid,
                    priority: 2,
                }
                ],
            },
            {
                key: "password",
                value: "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.password_required,
                    priority: 1,
                },
                {
                    function: EListFunction.checkLenghtPassword,
                    messageError: langue.password_minlength,
                    priority: 2,
                },
                ],
            },
            {
                key: "confirmPassword",
                value: "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: "",
                    priority: 1,
                },
                {
                    function: EListFunction.checkLenghtPassword,
                    messageError: "",
                    priority: 2,
                },
                ],
            },
        ]
    })

    const getIsinValidConfirmPassword = () => {
        return (
            form?.password.isInvalid ||
            form?.confirmPassword.isInvalid ||
            form?.password.value !== form?.confirmPassword.value
        )
    }
    const getIsValidConfirmPassword = () => {
        return (
            form?.password.isValid &&
            form?.confirmPassword.isValid &&
            form?.password.value === form?.confirmPassword.value
        )
    }
    const resetPassword = async () => {
        const response = await userAPi.resetPassword({
            suid: commercant?.suid,
            lang: lang
        }, { user: { username: resetEmail, password: form?.password.value, code: form?.code.value } })

        if (response.status === 200 && response?.data === true) {
            !!props?.onExit && props.onExit()
        } else {
            dispatch(openAlert({ type: 'info', message: langue.rest }))
        }
    }
    return <Modal
        contentClassName="modal-reset-password ds-p-16 overflow-hidden"
        withCloseIcon
        ref={ref}
        onExit={props.onExit}
        onShow={props.onShow}
    >
        <TemplateModalResetPassword
            form={form}
            onChange={onChange}
            getIsinValidConfirmPassword={getIsinValidConfirmPassword}
            getIsValidConfirmPassword={getIsValidConfirmPassword}
            langue={langue}
            resetPassword={resetPassword}
        />
    </Modal >
}
export default forwardRef(ModalResetPassword)