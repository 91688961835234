import { useEventListener, useUpdateEffect, useWindowSize } from '../../../CustomHooks'
import React, { Ref, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button } from '..'
import { Icon, Portal } from '../../Atoms'
import { DEFAULTMODALPROPS } from '../../DefaultProps/Molecules'
import { IMoleculeModal, RefType } from '../../Interfaces'
const MoleculeModal = React.forwardRef((props: IMoleculeModal, ref: Ref<RefType | undefined | null>): JSX.Element => {
    const containerRef = useRef<HTMLElement | null | undefined>() as React.MutableRefObject<HTMLDivElement>;
    const contentRef = useRef<HTMLElement | null | undefined>() as React.MutableRefObject<HTMLDivElement>;
    const childrenContainerClassName = []
    const [open, setOpen] = useState<boolean>(false)
    const { width } = useWindowSize()
    const containerClassName: string[] = ["container-modal", "ds-fixed", "ds-flex"]
    const contentClassName: string[] = ["content-modal", "ds-flex-col", "ds-relative", "ds-p-10", "ds-bg-white", "ds-borad-14"]
    const containerBtnClassName: string[] = ["ds-flex", "ds-w-100", "ds-justify-between ", "ds-pt-10", "ds-align-center"]
    if (props.containerClassName) {
        containerClassName.push(props.containerClassName)
    }
    if (props.contentClassName) {
        contentClassName.push(props.contentClassName)
    }
    if (props.containerBtnClassName) {
        containerBtnClassName.push(props.containerBtnClassName)
    }

    useUpdateEffect(() => {
        if (open && props.onShow) {
            props.onShow()
            document.body.style.overflow = 'hidden';
        } else {
            if (props.onExit) {
                props.onExit()
            }
            document.body.style.overflow = 'auto';
        }
    }, [open])
    const onClose = () => {
        setOpen(false)
        if (props.onClickClose) {
            props.onClickClose()
        }
    };
    useEventListener("beforeunload", onClose, window)
    const onOpen = () => {
        setOpen(true)
    };
    useImperativeHandle(ref, () => ({
        onClose,
        onOpen,
        contentRef: contentRef,
        containerRef: containerRef
    }));
    function handleClickOutside(event: any) {


        if (contentRef.current && !contentRef.current.contains(event.target)) {

            onClose();
        }
    }
    useEffect(() => {


        document.addEventListener('mousedown', handleClickOutside);
    }, [])
    const renderCloseIcon = () => {
        if (props.withCloseIcon) {
            return <Icon className={"ds-absolute ds-pointer"} size={"20px"} icon={"close1"} onClick={onClose} />
        }
        return null
    }
    const clickandClose = () => {
        if (props.btnResetProps?.onClick) {
            props.btnResetProps.onClick()
        }
        onClose()
    }
    const renderButton = () => {
        if (props.withSubmitAction && props.withCloseAction) {
            return (
                <div className={containerBtnClassName.join(' ')}>
                    <Button
                        {...props.btnResetProps}
                        onClick={clickandClose}

                    />
                    <Button
                        {...props.btnSubmitProps}
                    />
                </div>
            )
        }
        else if (props.withSubmitAction) {
            return (
                <div className={"ds-flex ds-pt-10 ds-center"}>
                    <Button
                        {...props.btnSubmitProps}
                    />
                </div>
            )
        }
        else if (props.withCloseAction) {
            return (
                <div className={"ds-flex ds-pt-10 ds-justify-start"}>
                    <Button
                        {...props.btnResetProps}
                        onClick={props.btnResetProps?.onClick ? props.btnResetProps.onClick : onClose}
                    />
                </div>
            )
        }
        else return null
    }
    if (props.childrenContainerClassName) {
        childrenContainerClassName.push(props.childrenContainerClassName)
    } else {
        childrenContainerClassName.push('ds-flex-grow1')
    }
    return (
        <>
            {
                open &&
                <Portal>
                    <div
                        ref={containerRef}
                        style={{ width: width, height: window.innerHeight }}
                        className={containerClassName.join(' ')}
                    >
                        <div className='ds-flex ds-flex-grow1 ds-center content-padding'>
                            <div
                                ref={contentRef}
                                className={contentClassName.join(' ')}>
                                <div className='ds-flex ds-pr-20 ds-pb-20 ds-justify-end'>
                                    {renderCloseIcon()}
                                </div>
                                <div className={childrenContainerClassName.join(' ')}>
                                    {props.children}
                                </div>
                                {renderButton()}
                            </div>
                        </div>
                    </div>
                </Portal>
            }
        </>
    )
})
MoleculeModal.defaultProps = {
    ...DEFAULTMODALPROPS
}
export default MoleculeModal
