import { ITemplateModalGeneralCondition, RootState } from "../../Interfaces"
import { Icon } from "../../DesignSystem"
import { CommonFunction, Config } from "../../Common"
import { useSelector } from "react-redux"
const TemplateModalGeneralCondition = (props: ITemplateModalGeneralCondition): JSX.Element => {
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    return (
        <div className="ds-flex-col ds-align-center ds-overflow-x ds-w-100  height-60 ">
            <div className="ds-flex ds-justify-between ds-w-100 ds-px-40 ds-mt-8">
                <div className="ds-flex ds-align-start" onClick={()=>CommonFunction.OnOpenNewBlank(`${Config.getInstance().download_url+commercant?.suid}`)}>
                    <Icon
                        icon="document"
                        size={"24px"}
                        className="ds-text-secondary100"
                    />
                    <span className="ds-text-size-20 ds-text-line-24 ds-text-weight500 ds-text-secondary100 ds-mr-20">{'PDF'}</span>
                </div>
            </div>
            <div className="width-60" dangerouslySetInnerHTML={{ __html: (props?.modalContent.toString()) }}>
            </div>
        </div>
    )
}
export default TemplateModalGeneralCondition