import { Api } from "../Common"
import {  Config } from "../Common"
import { ILogin, ISubscription,IConfirmationCode,ISubscriptionParams, ISendNewCode, ICheckValidate, IUserAccount,ICommon } from "../Interfaces"
import { CommonFunction } from "../Common"

class AuthAPI {
    _api: Api
    constructor() {
        this._api = new Api(Config.getInstance().url)
    }
    async login(body: ILogin) {
        return await this._api.post("authentication/signin", JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async Subscribe(body: ISubscription, params: ISubscriptionParams) {
        return await this._api.post(`v1/public/user/register-new?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async VerifyUser(body: ICheckValidate, params: ISubscriptionParams) {
        return await this._api.post(`v1/public/user/username?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async VerifyUserWithPhone(body: ISubscription, params: ISubscriptionParams) {
        return await this._api.post(`v1/public/user/phone?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async signUp(body: ISubscription, params: ISubscriptionParams) {
        return await this._api.post(`v1/public/user/register-creation?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async sendConfirmationCode(body: IConfirmationCode, params: ISubscriptionParams) {
        return await this._api.post(`v1/public/user/code?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async sendNewCode(body: ISendNewCode, params: ISubscriptionParams) {
        return await this._api.post(`v1/public/user/sendnewcode-user-creation?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async checkValidate(body: ICheckValidate, params: ISubscriptionParams) {
        return await this._api.post(`v1/public/check/validate/notLogin?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async userToken(body: IUserAccount) {
        return await this._api.post(`v1/auth/user/token`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async sendPassword(body: IUserAccount, params: ISubscriptionParams) {
        return await this._api.post(`v1/public/user/password/send?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async getUserProfile(body: ICommon) {
        return await this._api.get(`v1/customer/profile?suid=${body.suid}`, CommonFunction.createHeaders({ withToken: true }));
    }
    connectWithGoogle(){
        window.location.href = Config.getInstance().url + "/v1/public/auth/check-google"
    }
    connectWithFacebook(){
        window.location.href = Config.getInstance().url + "/v1/public/auth/check-facebook"
    }

}
export { AuthAPI }