type typeState = string | number | boolean | Array<string> | Array<never>

enum EListFunction {
    isMail = "isMail",
    hasUperCase = "hasUperCase",
    hasLowerCase = "hasLowerCase",
    hasNumber = "hasNumber",
    isEmpty = "isEmpty",
    isNumber = "isNumber",
    isDate = "isDate",
    isUrl = "isUrl",
    isDateValidation = "isDateValidation",
    hasAlpha = "hasAlpha",
    hasSpecial = "hasSpecial",
    hasSpecialDate = "hasSpecialDate",
    checkIsDate = "checkIsDate",
    isNotEmpty = "isNotEmpty",
    isArray = "isArray",
    isTrue = "isTrue",
    checkLenghtNumber = "checkLenghtNumber",
    checkLenghtCvc = "checkLenghtCvc",
    checkLenghtPassword = "checkLenghtPassword",
    checkLenghtCodeConfirmation = "checkLenghtCodeConfirmation",
    isPhoneNumber = "isPhoneNumber",
    isNumberSpace = "isNumberSpace",
    isDateValid = "isDateValid",
    checkLenghtMessage = "isDateValid",
    IsEightCharLength = "IsEightCharLength"
}
interface IRule {
    priority: number,
    function: EListFunction,
    messageError: string,
}

interface IListRules extends Array<IRule> { }

interface IElementUseForm {
    value: typeState,
    isRealTimeValidation?: boolean,
    rules?: IListRules,
    successMessage?: string,
    key: string
}

interface IListElementUseForm extends Array<IElementUseForm> { }

interface IUseForm {
    isRealTimeValidation?: boolean,
    data: IListElementUseForm
}
interface IElementState {
    value?: typeState,
    isValid?: boolean,
    successMessage?: string,
    isInvalid?: boolean,
    errorMessage?: string,
}

interface IState {
    [key: string]: IElementState;
}

interface IOnChnage {
    value: typeState,
    key: string
}
interface IUseFormResult {
    state: IState,
    onChange: Function,
    onValidForm: Function,
    isFormValid: boolean,
    onReset: Function,
    onUpdateData: Function,
    onUpdateState: Function
}

interface ISuccessReturn {
    isValid: boolean,
    successMessage: string
}

interface IRulesTreatment {
    rules: IListRules,
    element: IElementUseForm,
    update?: boolean,
    value: typeState
}

interface IRulesTreatmentReturn {
    obj: IElementState,
    verif: boolean,
}

interface IValidForm {
    update: boolean,
    form: IState
}

interface IErrorStatus {
    res: boolean,
    rule: IRule,
    update?: boolean
}

interface ISuccessStatus {
    res: boolean,
    element: IElementUseForm
}

interface IonValidateValue {
    rule: IRule,
    value: any
}

interface IGetState {
    isEmpty?: boolean,
    currentValue?: boolean
}

export type {
    IUseForm,
    IUseFormResult,
    ISuccessReturn,
    IListElementUseForm,
    IElementUseForm,
    IListRules,
    IState,
    IElementState,
    IOnChnage,
    IRule,
    typeState,
    IRulesTreatment,
    IRulesTreatmentReturn,
    IValidForm,
    IErrorStatus,
    ISuccessStatus,
    IonValidateValue,
    IGetState
}
export {
    EListFunction
}