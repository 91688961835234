import { IInputMessage } from "../../Molecule/IMoleculeInput/IMoleculeInput";

export const enum TypeInputFile {
    type1 = 'type1',
    type2 = 'type2',

}
export const enum EInputAccept {
    png = "png",
    jpg = "jpg",
    gif = "gif",
    svg = "svg",
    jpeg = "jpeg",
    txt = "txt",
    pdf = "pdf",
    xlsx = "xlsx",
    docx = "docx"
}
export interface IFile extends File {
    path?: string
}

export interface IAtomInputFile extends IInputMessage {
    bsPrefix?: string,
    className?: string,
    containerClassName?: string,
    onChange?: Function,
    isInvalid?: boolean,
    isValid?: boolean,
    isDisabled?: boolean,
    type?: TypeInputFile,
    files?: IFile[],
    isMulti?: boolean,
    id?: string
    text?: string,
    onClickFile?: Function
    accept?: EInputAccept[]
    textClassName?:string
}