import { createSlice } from '@reduxjs/toolkit';
import { IModalReducer } from '../../../Interfaces';

const initiaState: IModalReducer = {
  data: null,
  name: "",
  show: false,
};

const ModalReducer = createSlice({
  name: 'modal',
  initialState: initiaState,
  reducers: {
    openModal: (state: IModalReducer, action) => {
      state.data = action.payload.data
      state.name = action.payload.name
    },
    closeModal: (state: IModalReducer) => {
      // state.data = null
      state.name = ""
    },
    showOptionSignUpPhone: (state: IModalReducer, action) => {
      state.show = action.payload.show
    },
  }
});

export const { openModal, closeModal, showOptionSignUpPhone } = ModalReducer.actions;

export default ModalReducer.reducer;
