import React, { useContext, useState } from 'react'
import { Ref, forwardRef } from "react";
import { List, Modal, ModalRefType } from "../../DesignSystem";
import { IModalBonPlan } from "../../Interfaces/Modal";
import { TemplateModalBonPlan } from "../../Template";
import { useDispatch, useSelector } from "react-redux";
import { IFiltredProduct, RootState } from "../../Interfaces";
import { useUpdateEffect } from '../../CustomHooks';
import { openModal,  setProduct,  setTabOptions } from '../../Redux/Reducers';
import { LangContext } from '../../Lang';


const ModalBonPlan = (props: IModalBonPlan, ref: Ref<ModalRefType | undefined>) => {
    const dispatch = useDispatch();
    const { commercant } = useSelector((state: RootState) => state.commercant);
    const { user, token } = useSelector((state: RootState) => state?.auth);
    const { name } = useSelector((state: RootState) => state?.modal);
    const { goodDeals } = useSelector((state: RootState) => state.commercant);
    const { width } = useSelector((state: RootState) => state.screen);
    const [cardList, setcardList] = useState<IFiltredProduct[]>([])
    const langue=useContext(LangContext)
    const [tabList, settabList] = useState<List>([
        { index: 0, label: langue.deals_title1 },
        { index: 1, label: langue.deals_title2 },
        { index: 2, label: langue.deals_title3 },
        { index: 3, label: langue.deals_title4 },
    ])
    const [selectedTab, setselectedTab] = useState<number>(0)
    const onSelectOptionTab = (index: number) => {
        switch (index) {
            case 0:
                setcardList(goodDeals?.good_deals?.favorite)
                break;
            case 1:
                setcardList(goodDeals?.good_deals?.loyalty_multiple)
                break;
            case 2:
                setcardList(goodDeals?.good_deals?.loyalty_single)
                break;
            case 3:
                setcardList([])
                break;

            default:
                break;
        }
    }
    const onCloseModal = () => {
        if (props.onExit) {
            props?.onExit()
        }
    }
    const onGetOptionCategories = (data: any) => {
        let arr: string[] = []
        if (data) {
            Object.values(data).map((el: any) => {
                arr.push(el?.name)
            })
            const formattedData: { index: number, label: string }[] = arr.map((label: string, index: number) => ({ index, label }));
            return formattedData;
        }
    }
    const onAddProductToBasket = (uniq: string) => {
        const filtredproduct: any = Object.values(cardList).filter((el: IFiltredProduct) => {
            if (el?.uniq) {
                return el?.uniq.toLowerCase() === uniq.toLowerCase();
            }
        })
        dispatch(setProduct({ product: filtredproduct[0] }))
        if (Object.keys(filtredproduct[0]).length !== 0) {
            if (token !== '' && user !== null) {
                if (filtredproduct[0]?.menu_categories.length !== 0) {
                    onCloseModal()
                    dispatch(setTabOptions({ listOptions: onGetOptionCategories(filtredproduct[0]?.menu_categories) }))
                    Promise.resolve().then(() => {
                        dispatch(openModal({ name: 'modalComposeFormule', data: { product: filtredproduct, item: null, fromMenu: true } }));
                    });
                } else {
                    if (name === "modalBonPlan") {
                        onCloseModal()
                        Promise.resolve().then(() => {
                            dispatch(openModal({ name: 'modalProductDetails', data: { product: filtredproduct, item: null, fromMenu: true }}));
                        });
                    }
                }
            } else {
                onCloseModal()
                dispatch(openModal({ name: 'modalConnexionRef' }))
            }
        }
    }
    return (
        <Modal
            contentClassName={'modal-Bon-plan ds-borad-14 ds-pointer overflow-hidden ds-flex-col'}
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            childrenContainerClassName={"modal-plan-container"}
            onShow={props.onShow}
        >
            <TemplateModalBonPlan
                gooddeals={goodDeals}
                commercant={commercant}
                tabList={tabList}
                selectedTab={selectedTab}
                onSelectOptionTab={onSelectOptionTab}
                cardList={cardList}
                onAddProductToBasket={onAddProductToBasket}
            />
        </Modal>
    )
}
export default forwardRef(ModalBonPlan)