import { IKeys } from "../../Interfaces";

const lang: IKeys = {
    fr: "Frensh",
    en: "English",
    ar: "Arabic",
    connexion: "Log In",
    connexionTitle: "Log In",
    inscriptionTitle: "Register",
    forgetPass: "Forgot password?",
    register: "Sign up",
    haveAccount: "Do you have already an account",
    haventAccount: "You do not have an account ?",
    haventCode: "Didn't receive the code?",
    continue_with: "OR CONTINUE WITH",
    identifiant: "Identifier",
    mdp: "Password",
    rememberMe: "Remember me",
    firstName: "First name",
    lastName: "Last name",
    confirmPassword: "Confirm password",
    acceptCondition: "I accept the conditions of use",
    validationMessage: "Yes",
    emptyMessage: "Required Field",
    invalidMailMessage: "it's not an email",
    forgetPassBtn: "Send mail",
    resetPassTitle: "Chage password",
    resetPassBtn: "CHange",
    reliabilityMessage: "Password strength: low",
    ForgetPassTitle: "Find your account ",
    default: "Default",
    overview: "Overview",
    project: "Projects",
    eCommerce: "eCommerce",
    logOut: "Log out",
    info_register: " Order online !",
    info_login: " One account, all my merchants ",
    intro: "Register or login to fully benefite from the online service",
    i_order: "Order ",
    baseline: "You don't need an account to have acces to the stores ",
    welcome: "Welcome",
    shops: "My merchants ",
    shops2: "The stores",
    orders: "My purchases",
    profile: "My account",
    map: "Near me",
    map_search_around: "Search for a city, a merchant",
    about: "About ",
    tnc: "Legal notices ",
    notice: "General conditions of sale",
    litigation: "Dispute resolution",
    copyright: "Digicommerce © {{year}} _ All rights reserved",
    contact: "Contact us",
    i_search: "Search",
    i_search_text: "a merchant near me",
    i_recommend: "Recommend",
    i_recommend_text: "my favorite merchant",
    for: "for",
    //register
    number_mobile: "Mobile",
    email_address: "E_mail address",
    phone_code_info: "A code will be sent by SMS to verify your phone number",
    email_code_info: "A code will be sent by email to verify your email address",
    is_account: "already have an account?",
    button_continue: "Continue",
    button_ignorer: "Ignore",
    button_continue_partner: "Validate my code",
    button_ignorer_partner: "Continue without code",
    code_label1: "Confirm",
    code_label2: "Your account",
    code_entry_phone_label: "Enter the code you received at ",
    code_entry_email_label: "Enter the code you received on ",
    code_resend: "RESEND A CODE",
    use_email: "USE A MAIL ADDRESS",
    use_phone: "USE A MOBILE PHONE NUMBER",
    finalize_label: "Finalize",
    finalize_label2: "My registration",
    form_firstname: "First name",
    form_lastname: "Last name",
    form_email: "Email",
    email: "name@email.com",
    form_mobile: "Mobile",
    form_password: "Password",
    optin_infos: "To receive good deals and promotions from merchants",
    optout_infos: "To receive offers from partners",
    cgv_label1: "By validating this form, I declare that I have read and accepted the ",
    cgv_label2: "terms and conditions",
    button_end: "Finish",
    code_required: "The code is mandatory",
    code_invalid: "The code is made up of 4 digits",
    code_wrong: "The code is not valid",
    firstname_required: "The first name is compulsory.",
    lastname_required: "The name is obligatory.",
    password_required: "The password is required.",
    password_minlength: "The password must contain at least 8 characters.",
    godfatherCodeUsed_label: "Advantage",
    godfatherCodeUsed_label2: "Affiliation code",
    godfatherCodeUsed_placeholder: "Affiliation code",
    godfatherCodeUsed_info: "Enter the sponsorship code and offer points to your sponsor for your first online order.",
    continueWith_fb: "Continue with Facebook",
    continueWith_gmail: "Continue with Gmail",
    or: "or",
    //button
    validate: "Validate",
    cancel: "Cancel",
    next: "Next",
    previous: "Previous",
    add: "Add",
    send: "Sent",
    connect: "Login",
    connect2: "Login",
    disconnect: "Logout",
    modify: "Edit",
    delete: "Delete",
    menu: "Menu",
    read_more: "More about",
    reduce: "reduce",
    passed: "Skip",
    empty: "Nothing for now",
    save: "Save",

    //allergerns
    title: "Allergens",
    text1: "You will find bellow the matching 14 colors to the principal allergerns ingredients identified by the French decree of November 7th 2008 n°2008_1153. We invite you to consult regulary our allergerns' informations, since the compositions of our products may change. We can not exclude the adventitious presence of others allergerns ingredients during the production of our products.",
    text2: "Regarding the Glutten: Cereals which contain gluten: Wheat, Rye, Barley, Oat, Spelt, Kamut,... Regarding the Nuts: Almonds, Hazelnuts, Nuts, Pecan Nuts, Brazil Nuts, Pistachios, Macadamia Nuts and Queensland Nuts Regarding the Sulphites: Only if the quantity of sulphur dioxide and sulphites is superior to 10 mg / kg of finished product.",
    //catalog 
    //points
    buy: "Buy with points",
    cancel_point: "Cancel the purchase in points",
    deals_title1: "Membership plans",
    deals_title2: "The good deals",
    deals_title3: "The best offers",
    deals_title4: "To find",
    myaccount_title: "My client account",
    mycredit: "My credit and my points",
    pos_link_title: "A membership card ?",
    pos_link_desc: "Add your card number to accumulate your loyalty",
    pos_link_btn: "Add now",
    myedenred: "My Edenred",
    restoflash: "Resto Flash",
    restaurant_card_not_enough: " The amount available on your restaurant card is insuficient.",
    reload: "Refill",
    return: "Show catalogue",
    not_enough_credit: "You don't have enough credit.",
    invalid: "The hour choosen for withdrawal is not valid.",
    //product
    free: "free",
    free_plural: "free",
    minor_prohibited: "This product is reserved to adults",
    composed: " Creat your",
    promo: "Promotion",
    discount_rule: "For {{repetition}} {{name}} bought, {{quantity}} offered,  subject to availability..",
    warning: "Attention, the products offered may differ from the one purchased.",
    composition: "Composition",
    for_100gr: " 100gr",
    by_united: "Per unit",
    defrost_text: "Thawed product. Never refreeze a defrosted product!",
    title_time: "Time of withdrawal",
    today: "Today",
    mandatory_choice: "You have to choose a option",
    basket_amount: "Your basket amount",
    title_basket: "Shopping cart",
    comment_placeholder: "Comment",
    empty_basket: "Your shopping cart is empty.",
    min: "shopping cart min.",
    voucher_placeholder: "Discount coupon",
    delivery_method_mandatory: "You have to choose a withdrawal mode.",
    delivery_method_title: "Choisir un mode de retrait",
    delivery_method_subtitle: "Delivery starting at {{amount}}",
    delivery_method_collect: "Takeway",
    delivery_method_eatin: "On_site",
    delivery_method_delivery: "Delivery",
    delivery_method_delivery_point: "Point of withdrawal",
    delivery_method_picking: "Drive",
    delivery_method_none: "Mode of withdrawal",
    delivery_price: "Delivery charges",
    delivery_order_in_advance: "Remise commande anticipée",
    delivery_free: "Free",
    discounts_first_order: "Advantages firsts orders",
    discount_first_order: "Advantage first order",
    order_method_title: "Choose a payment mode",
    order_method_title2: "Order with payment obligation",
    order_method_credit: "With my account credit",
    order_method_bankcard: "With a credit card",
    order_method_upon_receipt: "Au comptoir",
    order_method_upon_delivery: "A réception",
    order_method_restaurant_card: "WIth a restaurant card",
    order_method_restaurant_restoflash: "Restoflash",
    order_method_restoflash: "Restoflash",
    order_method_free: "For free ",
    order: "Order",
    time_placeholder: "Choose a schedule",
    partnership_title: " Company benefit ",
    partnership_text: "discount applied",
    discount_title: " Discount ",
    discount_text: "discount applied",
    add_basket: "Add to cart",
    add_panier: "Add",
    amount_not_enough: "Amount of the shopping cart insufficient.",
    min_amount: "Your shopping cart must be at least {{amount}}",
    //voucher
    not_for_you: "This coupon it is not for you.",
    already_used: "Discount coupon already used.",
    success: "Your coupon has been taken to account.",
    invalid_voucher: "Your coupon it is invalid.",
    validate_voucher: "I confirm my code",
    add_succes: "successfully added:",
    error_delivery: "Delivery problem!",
    info_quantity: "you have {{number}} product(s) in your basket",
    label_number_product: "TOTAL PRODUCTS",
    label_price_delivery: "DELIVERY PRICE",
    label_total: "TOTAL TCC",
    free_delivery: "Again {{price}} for free delivery!",
    label_free_delivery: "Free delivery!",
    info_price_variation: "Delivery costs depends on the distance",
    info_distance_delivery: "The indicated address is between {{a}} and {{b}} km",
    info_max_distance_delivery: "The indicated address is beyond {{number}} km",
    info_remove_product: "Please remove items from basket or change the withdrawal mode..",
    continue_shopping: "Continue my shopping",
    pay_items: "Pay my order",
    fees: "Service charge",
    description_fees: "Les frais de services sont redistribués à la plateforme Digicommerce et permettent d'assurer la maintenance et le développement de ce service.",
    title_rda: "Daily intake",
    title_composition: "Composition",
    title_recipe: "Suggestions",
    title_availability: "Availability",
    text1_availability: "For a quantity superior to",
    text2_availability: "products, please allow a delay greater than ",
    text2_single_availability: "product, please allow a delay greater than ",
    text1_contact: "Feel free to contact us for a clarification, a claim or a suggestion.",
    text2_contact: "We undertake to respond to you as soon as possible.",
    label_mail: "Your email",
    required_mail: "Mail adress mandatory.",
    invalid_mail: "Mail adress incorrect.",
    label_firstname: "First name",
    required_firstname: "FIrst name is mandatory.",
    label_lastname: "Last name",
    required_lastnam: "Last name is mandatory.",
    label_message: "Message",
    required_message: "Please enter a message.",
    success_msg: "Your message was sent",
    maxSize: "Your file exceeds 5 MB",
    h1_send: "Your password",
    pwd_send: "Reset your password",
    code_validation: "Validate my account",
    new_code_validation: "Resend my code",
    new_link_validation: "Resend email",
    lost_password: "Forgot your password?",
    invalid_mail_password: "Email or password invalid",
    email_send: "An email was sent to you.",
    email_invalid: "Email invalid.",
    password_change: "Your password was reset.",
    test_used: "Your 3 tries are used. Please try again.",
    rest: "\nYour confirmation code is incorrect.",
    //map
    search: "Search",
    list: "Search a merchant",
    open: "Store hours ",
    open_soon: "Open soon",
    bluebox_title: "My merchants at a click",
    bluebox_subtitle1: "Keep waiting, what a strage. idea!",
    bluebox_text1: "Enjoy a line_cutting service in store. Finish the queue. Double everyone, it is the principle here",
    bluebox_subtitle2: "Book your products in advance",
    bluebox_text2: "No more bad surprises, order your favorite products in advance or… for right away. On any occasion.",
    bluebox_subtitle3: "Take advantage of good plans",
    bluebox_text3: "Benefit from all promotional offers starting now. You are informed first hand of good plans and loyalty benefits.",
    bluebox_subtitle4: "You don't need currency anymore ",
    bluebox_text4: "Pay for your purchases online or in_store with your customer account. Refillable with cash, restaurant tickets or CB.",
    bluebox_button: "Find my merchant",
    comming_soon: "comming_soon",
    comming_soon_text: "Our merchant will be open soon\nThank you for your patience .",
    to_collect: "Expected in ",
    collect: "Ready",
    ready_on: "The",
    amount: "amount",
    article: " article",
    article_plural: " articles",
    order_passed: "Order placed on the ",
    item_delivery: "Delivery cost",
    error_collected_at: "The time of your order is no longer valid, please change the time...",
    error_products_collected_at: "You can not order your cart before the {{date}}. Please change the time of your order or remove the products unavailables...",
    error_product_collected_at: "You can not order your cart before the {{date}}. Please change the time of your order or remove the product unavailable ...",
    reco_title: "Recommend",
    placeholder: "Look for here your merchant",
    reco_text1: "Recommend and win 10€ at your merchant if he joins Food & Collect Digicommerce.",
    reco_text2: "Look for the address of your merchant ir give yout mail address so we can contact you.",
    success_recommandation: "Thank you, your recommendation has been sent.",
    title_find_shop: "Search a merchant",
    p_find_shop: "Search a merchant near you, order and he will be in yout favorites.",
    title_recommendation: "Recommend a merchant",
    p_recommendation: "and win 10€ at your merchant if he joins Food & Collect Digicommerce.",
    title_profile: "My account",
    title_register: "Create a new account",
    title_validation: "Validation the account",
    email_label: "Email",
    email_placeholder: "your email",
    email_required: "E_mail address required.",
    email_valid_error: "E_mail not available.",
    password_new_label: "New password",
    password2_label: "Verification of the password",
    password2_invalid: "Verification of the password invalid.",
    company_label: "Company",
    company_placeholder: "Company (optional)",
    phone_label: "Phone number",
    phone_placeholder: " 020 4134 5678.",
    phone_required: "Phone num required.",
    phone_invalid: "The phone number is invalid.",
    phone_not_mobile: "Please enter a mobile phone number.",
    phone_not_available: "An account already exists for this phone number.",
    mail_not_available: "An account already exists for this email.",
    dob_label: "Date of birth",
    dob_placeholder: "jj/mm/aaaa",
    dob_invalid: "Date of birth invalid",
    countryCode_label: "Country",
    countryCode_placeholder: "Country",
    membershipCard_label: "Number of badge or loyalty card",
    membershipCard_placeholder: "Number of badge or loyalty card",
    membershipCard_label2: "Number of badge or loyalty card",
    membershipCard_placeholder2: "Number of badge or loyalty card",
    membershipCard_valid: "The number is valid",
    membershipCard_invalid: "The number does not exist ",
    label_godfatherCodeUsed: "Affiliation code",
    placeholder_godfatherCodeUsed: "Affiliation code",
    godfatherCodeUsed_valid: "The code is valid",
    godfatherCodeUsed_invalid: "The code is invalid",
    partnershipCode_label: "Company discount",
    partnershipCode_placeholder: "Discount code partnership",
    partnershipCode_placeholder2: "Insert your code",
    partnershipCode_add: "Add a code",
    partnershipCode_valid: "The code is valid",
    partnershipCode_invalid: "The code is invalid",
    partnershipCode_help: "Add your company code here to enjoy exceptional benefits, discounts or promotions on your order",
    student_label: "I am a student (your student card will be asked at each withdrawal).",
    optin_label: "I wish to receive promotional information",
    optout_label: "I wish to receive partner offers",
    accept_label: "I have read and accept the general conditions of sale of the site.",
    accept_required: "You have to accept the general conditions of sales.",
    button_register: "Register",
    facturation_address: "Billing address",
    delivery_address: "Delivery address",
    saved_delivery_address: "Saved delivery address",
    address: "Adress",
    postcode: "Postal code",
    city: "City",
    cellphone: "Phone number",
    born: "born on the ",
    credit_card: "Credit card",
    credit_card_helper: "You can delete the credit card you registered during a previous purchase",
    credit_card_helper2: "Registration will be offered when you purchase",
    edenred_card: "Ticket Restaurant®",
    edenred_card_helper: "Login to pay your purcahses with your Ticket Restaurant® at your merchant",
    restoflash_card: "The meal voucher without limit",
    restoflash_card_helper: "Pay your purchases with Resto Flash at your merchant, when finalizing your shopping cart choose the option \"Resto Flash\"",
    godfather_text: "Share your affiliate code and win {{site.credit_godfather}} points thanks to the first order of your godchild",
    godfather_subject: "Affiliation offer",
    godfather_message: "{{profile.firstname}} offers you X points on your next order online with the code {{profile.godfather_code}}",
    smscode_label: "Confirmation code",
    smscode_placeholder: "Ex: XXXX",
    smscode_text: "You will receive a conformation code via Sms to validate your account",
    smscode_required: "The code is mandatory",
    smscode_invalid: "The code is made up of 4 digits",
    link_validation_label: "Lien de validation",
    send_activate_link: "An activation link has been sent to your mailbox.",
    button_scan: "Scan my card",
    and_connected: "and connected",
    profile_success: "Your profile has been registered.",
    profile_error: "Unable to update. Please try again.",
    scan_error: "Unable to scan your card, please try again.",
    register_success: "Your account has been created. You will receive an account activation code on your mobile or mailbox",
    title_payment: "Payment mode",
    title_address: "Contact details ",
    title_perso: "Personal information",
    title_lang: "Language ",
    title_god_father: "My Affiliation code",
    voucher_success: "Your partnership code has been taken into account.",
    share: "{{firstname}} offers you {{credit_godson}} points on your next online order with the code {{godfather_code}}. Visite our site{{url}}",
    share_title: "Affiliation offer",
    send_activate_code: "You will receive an account activation code on your mobile or mailbox.",
    not_validate_code: "Your registration has not been confirmed.",
    account_validate: "Your account has been validated.",
    new_email_account_validate: "Votre nouvel e_mail a été pris en compte.",
    can_connect: "You can now login.",
    code_error: "Your code is incorrect. Please try again.",
    membership_card_lastname: "For more security, please enter your lastname.",
    already_leave: "Are you leaving us already ?",
    modify_email: "Je confirme la modification de mon adresse mail.",
    modify_email_description: "Après validation du formulaire, nous vous invitons à vous déconnecter. Rendez_vous dans la partie validé mon compte munis de votre nouveau code de validation afin de rendre le changement d'adresse mail effectif. ",
    delete_profile: "Je confirme la suppression de mon compte.",
    label_delete_profil: "Account deletion ",
    delete_profile_description: "We remind you that any deletion of an account is an irreversible action.",
    username_not_valid: "Cette adresse mail n'est pas disponible. Veuillez en saisir une autre.",
    new_email_label: "Saisir votre nouvel e_mail",
    info_code_label: "Veuillez saisir le nouveau code reçu",
    info_send: "Veuillez réessayer dans {{minute}} minutes",
    got: "You have ",
    points: "points",
    credit: "credit",
    title_prepaye: "My prepaid account",
    my_credit: "My credit",
    charger: "Charge",
    my_total_credit: "Votre crédit total restant",
    my_points: "My loyalty points",
    points_text: "To be used in your shopping cart to enjoy discounts",
    credit_offers: "Refill offer",
    credit_other: "Refill my account",
    credit_added: "credit bought",
    credit_given: "offered",
    partnership: "Partnership benefits",
    partnership_discount: " of reduction",
    partnership_text1: "Thanks to {{value.name}}, on your online orders",
    discount: "Discount",
    discount_title1: "- of reduction ",
    discount_text1: "for your loyalty, on your online orders",
    not_benefite: "You don't have any discounts for now.",
    only_shop: "only in the stores",
    refill_confirm: "You are about to reload",
    bank_confirm: "You are about to pay",
    refill_thanks: "On your credit account and we thank you.",
    bank_thanks: "By credit card and we thank you.",
    bank_confirm_title: "Payment confirmation",
    refill_bankcard_carrefully: "Warning !",
    refill_bankcard_saved: "A credit card is registered.",
    refill_paiement_send: "The payment will be directly submitted.",
    refill_confirm_button: "I confirm",
    refill_no_card_confirm: "By confirming,",
    refill_no_card_redirection: "You will be redirected to the payment page",
    //payment
    done: "Payment made.",
    error: "Payment error.",
    loading: "Payment in progress.",
    wrong: "Opps ! Something Went Wrong",
    verify_your_account: "Please verify your account and try again.",
    home_page: "Go to home page",
    success_pay: "Your payment has been made.",
    validate_pay: "Your order is validated",
    title_pay: "Rules",
    subtitle: "order with payment obligation",
    cards_title: "Registered cards",
    add_title: "Add a bank card",
    add_desc: "bank or restaurant voucher",
    empty_title: "No card saved",
    empty_desc: "Add a card to simplify your next purchase",
    loading_title: "Loading",
    loading_desc: "your registered cards",
    add_card: "New payment method?",
    exp_on: "Expire on",
    card_selected: "selected",
    card_choose: "choose",
    card_save: "Save my card",
    with_card_save: "The saved card will be used",
    pay: "Pay",
    confirm: "Confirm",
    bank_title: "Bank card",
    bank_desc: "pay online",
    bancontact_title: "Bancontact",
    bancontact_desc: "Pay using bancontact",
    credit_title: "Credit account",
    credit_desc: "available on your account",
    free_title: "Without paying",
    free_desc: "thanks to my loyalty",
    uponDelivery_title: "Upon receipt",
    uponDelivery_desc: "pay upon delivery",
    uponReceipt_title: "At the store",
    uponReceipt_desc: "pay at your merchant",
    edenred_title: "MyEdenred",
    edenred_desc: "with your Edenred account",
    restoflash_title: "Restoflash",
    restoflash_desc: "The restaurant title without limit",
    error_delivery_pay: "Please note, the delivery amount has changed! Please confirm the payment again to confirm the new price.",
    error_generic: "A problem has occurred, please try again later.",
    error_prepare_intention: "An error occurred while validating your payment, please try again later.",
    error_pm_unknown: "Please select a payment method",
    error_already_charged: "Your order has already been payed.",
    error_insufficient_amount: "The minimum amount is {{ min_amount }}€ in order to be able to pay by credit card!",
    //weekdays
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
    title_timetable: "Opening hours of the store",
    text_timetable: "from {{open1}}{{close1}} _ {{open2}}{{close2}}",
    title2: "Opening hours of the service",
    close: "Closed",
    //delivery_modal
    delivery_modal_title: "For a delivery to the",
    not_add: "bo extra charges",
    distance_danger: "The distance is too high ({{distance}} km)",
    distance_to_far: "Delivery is not supported at the selected address",
    distance_delivery: "The delivery disstance can't be more than",
    and_buy: "and it costs",
    delivery_is: "The delivery is",
    delivery_modal_free: "free",
    at: "from",
    of_purchase: "of purchase.",
    delivery_modal_text1: "To all the orders passed before 00h00 you will benefit from ",
    delivery_modal_text2: "between 00h00 and",
    text3: "and between {{start}} and {{end}}",
    incomplete_address: "The selected address is incomplete for the delivery man !",
    invalid_address: "Address not found, please check the address.",
    address_to_far: "The selected address is too far from your merchant !",
    to_large: "The volume of the order is too large for the delivery person!",
    number: "Number:",
    street: "Street:",
    postal_code: "Postal code:",
    delivery_modal_city: "City:",
    back: "Back",
    confirm_address: "I confirm my address",
    save_address: "Save address",
    confirm_phone: "Confirmation of the phone number for the delivery person",
    confirm_informations: "I validate my information",
    infos_text: "Informations:",
    but: " but ",
    possible_cities_1: "It is also possible in the cities of:",
    possible_cities_2: "It is possible in the cities of:",
    possible_cities_3: "It is only possible in the cities of:",
    possible_postal_codes_1: "It is also possible in cities with the following postal codes:",
    possible_postal_codes_2: "It is possible in cities with the following postal codes:",
    possible_postal_codes_3: "It is only possible in cities with the following postal codes:",
    //banner
    banner_title: "Our good plans !",
    banner_to_find: "To discover",
    banner_promotions: "Sales promotions",





    incoming: "In progress",
    not_enough_points: "You don't have enough points.",
    incoming_text: "Your order is being prepere (fin {{dateFormat}})",
    order_ready: "Your order is ready",
    waiting_job: "Waiting for a deliveryman",
    job_canceled: "The delivery guy did not manage to contact you and could not deliver you.",
    job_voided: "Delivery problem, your order will be refunded.",
    job_expired: "No delivery available for your order, your order will be refunded.",
    waiting_processing: "Waiting for validation of your bank.",
    delivery_follow: "Follow my deliveryman",
    ordering_at: "Ordered on",
    for_to: "For the",
    deliveree_support: "You can contact the customer service at (+33) 09 75 18 21 01",



    title_history: "My orders",
    invoice_history: "INVOICE",
    order_again_history: "Order again",



    mes_recharge: "My refills",
    createdAt: "Refill on",
    allLoaded: "All of the data has been load",


    //order_modal
    Re_commander: "Reorder",
    warning_dispo: "The product may no longer be available",
    not_delivery_time: "Delivery hours aren't configurated.",
    add_item_basket: "Article added to the shopping cart.",
    item_not_available: "None of the products is available.",


    //other
    cgv: "general conditions of sales",
    ml: "Legal notices",
    to: "To",
    other_at: "at",
    between: "between",
    and: "and",
    deliveree_week_between: "Expected delivery {{text}} between {{start}} and {{end}}",
    deliveree_far_between: "Expected delivery between {{start}} and {{end}}",
    today2: "today",
    total: "Total",
    ours: "Ours",
    delete_compte: "Remove my account",
    cookies: "This site uses cookies so that you can place orders, by using it, you accept our cookies policy available in the legal notice."
    ,
    //msg alert
    empty_disponiblity: `There are not enough products available.`,
    opening_soon: 'Our shop will be open soon. Thank you for your patience.',
    merchants: 'My merchants',
    search_merchant: 'Search for a merchant',
    //mes commercant categories
    all: 'All',
    snacking: 'Snacking',
    restaurant: 'Restaurant',
    bakery: 'Bakery',
    butcher: 'Butcher',
    // recharges
    refills: 'My refills',
    recharged: 'Recharged',
    // basket
    element_added: 'An item has been added to the basket.',
    minimum_basket: 'Your basket must be at least',
    // status commercant
    open_now: 'Open now',
    closed_now: 'Closed now',
    last_order: 'Last order at',
    plan: 'Plan',
    avis: 'reviews',
    search_address: 'Search by city, category, retailer',
    location_search: 'Enter your location',
    menu_search: 'Search for dishes and products',
    become_retailer: 'Becoming a retailer',
    // commande en cours 
    minutes: 'minutes',
    hours: 'Hours',
    in: 'In',
    delivered: 'Delivered',
    invoice: 'Invoice',
    my_orders: 'My orders',
    add_comments: 'Add comments',
    complaint_comments: 'Would you like to know more about our products or simply get in touch with us? Please complete the form below.',
    committed_serving: 'We are committed to serving you under the best recommended conditions.',
    loyalty: 'Loyalty',
    good_deals: 'Good Deals',
    advantage_offers: 'Advantage Offers',
    contact_us: 'Contact-us',
    numbre_characters: 'Number of characters remaining : ',
    attachment: 'Attachment',
    select_file: 'Choose a file',
    amount_insufficient: 'your basket amount is insufficient for this coupon code ',
    u_have: 'You have',
    discount_percent: '% discount on basket ',
    promo_code_label: 'Enter promo code',
    apply: 'Apply',
    no_promotion: `You currently have no promotion`,
    articles: ` item(s) `,
    message_sent: `Message sent`,
    message_sent_successfully: `Your message has been sent successfully.`,
    bank_card: `Visa Debit Card`,
    add_payment_card: `Add a payment card`,
    pay_boncontact: `Pay using Bancontact`,
    credit_account: `Credit account`,
    on_site: `On site`,
    pay_your_merchant: 'Pay at your merchant',
    time_expired: 'Your pickup time has expired.',
    owner_name: `Owner's name`,
    card_name: 'Name on the card',
    card_number: 'Card number',
    expired_date: `Expiration date`,
    save_card: `Save my card`,
    conditions_of_sale: `I accept the terms and conditions of sale`,
    payment_method: `Payment method`,
    payment_obligation: `Order with payment obligation`,
    opennow: 'Open',
    closenow: 'Closed',
    accepts_orders: `Accepts orders until`,
    fair_prices: `Fair prices • Healthy`,
    call: `Call`,
    opening_hours: `Opening hours`,
    pickup_hours: `Pickup  hours`,
    account_credit: 'My credit',
    point: 'point(s)',
    basket: 'My basket',
    empty_your_basket: 'Empty the basket',
    compose_formula: 'Compose your formula',
    without_additional_sauce: 'Without additional sauce',
    selected_sauces: 'Selected sauce(s)',
    add_to_basket: 'Add to basket',
    comments: 'Comments',
    pickup_time_after_hours: 'Please select a pick-up time of more than 2 hour(s)',
    weight: 'Weight',
    no_of_pieces: 'No. of pieces :',
    no_file_chosen: 'No files chosen',
    planning_order: 'Planning an order',
    delivery: 'Delivery',
    takeaway: 'Takeaway',
    insert_comment: 'Insert your comments by clicking on the "Add" button ',
    insert_promo_code: 'Enter your promotional code by clicking on the "Validate" button ',
    search_retailer: 'Search Retailer',
    cmd_incomming: 'Orders in progress',
    invoices: 'My invoices',
    discount_received: `You are entitled to a discount of `,
    purchases_percent: '% off your purchases',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    Order_ready: 'Order ready',
    conatct_help: `If you would like to become one of our connected merchants and would like more information, please contact us by completing this form.`,
    message: 'Message',
    business_sector: 'Your business sector',
    activity_sector: 'Sector of activity',
    phone_no: 'Phone N°.',
    including: 'including ',
    selected_items: 'Selected item(s)',
    delivery_not_allowed: 'Delivery not allowed',
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
    Lun: "Monday",
    Mar: "Tuesday",
    Mer: "Wednesday",
    Jeu: "Thursday",
    Ven: "Friday",
    Sam: "Saturday",
    Dim: "Sunday",
    le: "",
    delivery_on: "Delivery on",
    des: "of the",
    achat_livraison: "of purchase > (delivery",
    offert_des: "/ offered from",
    point_retrait: "Collection points",
    adresses: "addresses of",
    saved_address: "Save address",
    minimum: "for minimum",
    hors_service: "(excluding service charges)"

}
export default lang;