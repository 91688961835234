import { CommonFunction } from "../../Common"
import { ESizeInput, Size } from "../../DesignSystem/Interfaces";
import { Button, Input } from "../../DesignSystem"
import { Text } from "../../DesignSystem/Atoms";
import { ITemplateModalResetPassword } from "../../Interfaces/Template"

const TemplateModalResetPassword = (props: ITemplateModalResetPassword): JSX.Element => {
    return (<div className="ds-p-40 ds-flex-col ds-center">


        <Text
            className="ds-mb-40 ds-text-line-34 ds-text-size-28 ds-text-weight700"
            text={props.langue.pwd_send}
        >
        </Text>
        <Input
            {...CommonFunction.getInputProps(props.form?.code)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "code", value: e.target.value })}
            placeholder={props.langue.smscode_placeholder}
            label={props.langue.smscode_label}
            inputSize={ESizeInput.large}
            containerClassName="ds-mb-18"
        />
        <Input
            {...CommonFunction.getInputProps(props.form?.password)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "password", value: e.target.value })}
            placeholder={props.langue.form_password}
            label={props.langue.password_new_label}
            isPassword
            inputSize={ESizeInput.large}
            containerClassName="ds-mb-18"
        />
        <Input
            {...CommonFunction.getInputProps(props.form?.confirmPassword)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "confirmPassword", value: e.target.value })}
            isInvalid={props.getIsinValidConfirmPassword?.()}
            isValid={props.getIsValidConfirmPassword?.()}
            placeholder={props.langue.password2_label}
            label={props.langue.password2_label}
            isPassword
            inputSize={ESizeInput.large}
        />
        <Button
            disabled={props.form?.code.value === "" || props.form?.code.isInvalid || props.getIsinValidConfirmPassword()}
            size={Size.large}
            className="ds-w-100 ds-mt-24"
            text={props.langue.send}
            onClick={() => props.resetPassword()}
        />
    </div>)

}
export default TemplateModalResetPassword