import React, { useState } from 'react' 
import { Ref, forwardRef } from "react";
import { IModalDetailsCommandeOrRecharge } from '../../Interfaces/Modal';
import { Modal, ModalRefType } from '../../DesignSystem';
import { TemplateDetailsCommandeOrRecharge } from '../../Template';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Interfaces';
import { Config } from '../../Common';
import { ShopAPI } from '../../Api/Shop/shop';
import { useNavigate } from 'react-router-dom';
import { resetToken } from '../../Redux/Reducers';
const ModalDetailsCommandeOrRecharge = (props: IModalDetailsCommandeOrRecharge, ref: Ref<ModalRefType | undefined>) => {
    const { data } = useSelector((state: RootState) => state?.modal)
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    const { lang } = useSelector((state: RootState) => state?.setting)
    const { deliveryDate } = useSelector((state: RootState) => state?.paiement)
    const shopApi = new ShopAPI()
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const OnDowloadInvoice = (uniq: string) => {
        let url = `${Config.getInstance().API_URL}/public/invoice/pdf/${uniq}`
        const newlink = document.createElement('a');
        newlink.setAttribute('href', url);
        newlink.setAttribute('target', "_blank");
        newlink.click()
        if(props?.onExit){
            props?.onExit()
        }
    }
    const getCommandeExpress = async (uniq: string) => {
        const response = await shopApi.setCommandeExpress(
            { uniq: uniq, suid: commercant?.suid, lang: lang }, {
            collected_at: deliveryDate,
            delivery_method: { method: 1, address: "" }
        }
        )
        if (response.status === 200) {
            if(props?.onExit){
                props?.onExit()
            }
            if (response?.data) {
                navigate(`/menuCommercant/${commercant?.suid}`, { state: { fromHistory: true } })
            }
        }else if(response?.status === 401){
            if(props?.onExit){
                props?.onExit()
            }
            dispatch(resetToken())
        }
    }
    return (
        <Modal
            contentClassName={'modal-details-cmd ds-p-16 ds-borad-14 ds-pointer'}
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
        >
            <TemplateDetailsCommandeOrRecharge
                data={data}
                OnDowloadInvoice={OnDowloadInvoice}
                getCommandeExpress={getCommandeExpress}
            />
        </Modal>
    )
}
export default forwardRef(ModalDetailsCommandeOrRecharge)