
import React from 'react';
import { DEFAULTRADIOPROPS } from '../../DefaultProps/Atoms';
import { IAtomRadio } from '../../Interfaces';

function AtomRadio(props: IAtomRadio): JSX.Element {
    const containerClassName = []
    if (props.isVertical) {
        containerClassName.push("ds-flex-col ds-align-start")
    } else {
        containerClassName.push("ds-flex ds-align-center")
    }
    if (props.containerClassName) containerClassName.push(props.containerClassName)

    const renderRadio = () => {
        const list: JSX.Element[] = []
        props.data?.forEach((item, i) => {

            const className = [];
            const labelClassName = [];

            if (props.bsPrefix) {
                className.push(props.bsPrefix)
            } else {
                className.push("ds-radio ds-wp-16 ds-hp-16 ds-borad-8")
            }
            if (props.labelBsPrefix) {
                labelClassName.push(props.labelBsPrefix)
            } else {
                labelClassName.push("ds-mx-4 ds-text-line-19 ds-text-weight400 ds-text-size-16 ")
            }

            if (props.isInvalid) className.push('invalid')
            if (props.isValid) className.push('valid')
            if (props.disabled) labelClassName.push("ds-text-neutral10")
            if (props.className) className.push(props.className)
            if (props.labelClassName) labelClassName.push(props.labelClassName)


            list.push(<div className='ds-flex ds-align-center'>
                <input type="radio"
                    disabled={props.disabled}
                    name={props.name}
                    value={item.value}
                    id={item.value}
                    onClick={props.onClick}
                    onChange={props.onChange}
                    className={className.join(' ')}
                    checked={props.value !== null && props.value !== undefined && props.value !== '' && props.value === item.value}

                />
                <label
                    className={labelClassName.join(' ')}
                >
                    {item.label}
                </label>
            </div>)
        })
        return <>{list}</>;
    }
    return <div className={containerClassName.join(' ')}>
        {renderRadio()}
    </div>
}

AtomRadio.defaultProps = {
    ...DEFAULTRADIOPROPS
}
export default AtomRadio
