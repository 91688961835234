import React from "react"
import langFr from "../Fr/fr"
import langEn from "../En/en"
import langNl from "../Nl/nl"
import { IKeys } from "../../Interfaces"
const LANG = {
  fr: langFr,
  en: langEn,
  nl: langNl,
}
const LangContext = React.createContext<IKeys>(langFr)
export { LANG, LangContext}
