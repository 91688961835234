import React from 'react'
import { InputLabel } from '..'
import { AtomNumber } from '../../Atoms'
import { DEFAULTNUMBERPROPS } from '../../DefaultProps/Atoms'
import { DEFAULTINPUTMESSAGEPROPS } from '../../DefaultProps/Molecules'
import { ETextInput } from '../../Interfaces'
import { IMoleculeNumber } from '../../Interfaces/Molecule/IMoleculeNumber/IMoleculeNumber'

const MoleculeNumber = React.forwardRef((props: IMoleculeNumber, ref: React.Ref<HTMLInputElement>): JSX.Element => {
    const containerClassName = ["ds-w-100"]

    if (props.containerClassName) {
        containerClassName.push(props.containerClassName)
    }

    return (
        <div className={containerClassName.join(' ')}>
            <InputLabel text={props.label} type={ETextInput.label} className={props.labelClassName} />
            <AtomNumber
                thousandsGroupStyle={props.thousandsGroupStyle}
                decimalSeparator={props.decimalSeparator}
                thousandSeparator={props.thousandSeparator}
                className={props.className}
                disabled={props.disabled}
                readOnly={props.readOnly}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                id={props.id}
                ref={ref}
                prefix={props.prefix}
                suffix={props.suffix}
                displayType={props.displayType}
                customInput={props.customInput}
                valueIsNumericString={props.valueIsNumericString}
                allowLeadingZeros={props.allowLeadingZeros}
                allowNegative={props.allowNegative}
                isInvalid={props.isInvalid}
                isValid={props.isValid}
                bsPrefix={props.bsPrefix}
            />
            {props.isValid && <InputLabel text={props.success} type={ETextInput.success} className={props.successClassName} />}
            {!props.isValid && props.isInvalid && <InputLabel text={props.error} type={ETextInput.error} className={props.errorClassName} />}
        </div>
    )
})

MoleculeNumber.defaultProps = {
    ...DEFAULTNUMBERPROPS,
    ...DEFAULTINPUTMESSAGEPROPS
}

export default MoleculeNumber