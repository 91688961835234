import { ModalRefType } from "../../DesignSystem";
import { Modal } from "../../DesignSystem";
import { IModalDetailsProduct, IProduct } from "../../Interfaces/Modal";
import React, { Ref, forwardRef, useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Interfaces";
import { UserAPI } from "../../Api/User/user";
import { openAlert, openModal, resetToken, setBasket, setBasketItems, setCommentProduct, setNbreItems, setShowBasket } from "../../Redux/Reducers";
import { ShopAPI } from "../../Api/Shop/shop";
import { TemplateDetailsProduct } from "../../Template";
import { LangContext } from "../../Lang";
import { CommonFunction } from "../../Common";
import { setItem } from "../../Redux/Reducers/CommercantReducers/CommercantReducers";

const ModalDetailsProduct = (props: IModalDetailsProduct, ref: Ref<ModalRefType | undefined>) => {
    const { commercant, product, unit } = useSelector((state: RootState) => state.commercant);
    const { showBasket, basketId, commentItem, itemId,zone, baskett } = useSelector((state: RootState) => state?.basket)
    const { lang } = useSelector((state: RootState) => state.setting)
    const { deliveryAddress, delievryMode, deliveryDate } = useSelector((state: RootState) => state.paiement)
    const { data } = useSelector((state: RootState) => state.modal);
    const [unity, setUnity] = useState<boolean>(true)
    const [myProduct, setMyProduct] = useState<IProduct | any>(product && product)
    const noteRef = useRef<HTMLInputElement>(null)
    const shopAPI = new ShopAPI()
    const userApi = new UserAPI()
    const dispatch = useDispatch();
    const langue = useContext(LangContext)
    const [quantity, setQuantity] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [iteeee, setIteeee] = useState<any>(data?.item)
    React.useEffect(() => {
        if (unity) {
            setQuantity(1)
        }
    }, [unity])
    React.useEffect(() => {
        if (data?.item !== null && data?.fromAddNote) {
            setIteeee(data?.item)
            setUnity(true)
        }
        setIsLoading(false)
    }, [data?.item])
    React.useEffect(() => {
        if (data?.item !== null && data?.fromBasket) {
            setIteeee(data?.item)
        }
        setIsLoading(false)
    }, [data?.item])
    React.useEffect(() => {
        if (data?.product !== null && data?.fromMenu) {
            dispatch(setCommentProduct({ commentItem: '', itemId: '' }))
            setMyProduct(data?.product?.[0])
            setUnity(true)
            setQuantity(1)
        }
        setIsLoading(false)
    }, [data?.product])
    React.useEffect(() => {
        if ((data?.product !== null && data?.fromAddNote) || (data?.product !== null && data?.fromClose)) {
            setMyProduct(data?.product?.[0])
            setQuantity(data?.quantity)
        }
        setIsLoading(false)
    }, [data?.product])

    const getBasket = async () => {
        const res = await shopAPI.getBasketOrder({ suid: commercant?.suid, lang: lang , zone :zone })
        if (res.status === 200) {
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
            setIsLoading(false)
            if (props?.onExit) {
                props?.onExit()
            }
        } else if (res?.status === 401) {
            if (props?.onExit) {
                props?.onExit()
            }
            // dispatch(resetToken())
        }
    }
    const onModifyItemBasket = async (orderId: number, quantity: number) => {
        setIsLoading(true)
        const response = await shopAPI.updateQuantityBasket({ suid: commercant?.suid },
            {
                order_line_id: orderId,
                new_quantity: quantity,
                remarque: commentItem
            }
        )
        if (response?.status === 200) {
            getBasket()
        } else if (response?.status === 401) {
            if (props?.onExit) {
                props?.onExit()
            }
            dispatch(resetToken())
        }
    }

    const onSetOrderQuantity = async (decrement: boolean) => {
        let formatedTotal;
        let qtyMax = 0;
        let aux = { ...iteeee }
        if (aux.product?.attribute?.availability?.quantity) {
            qtyMax = aux.product?.attribute?.availability?.quantity;
        } else {
            if (aux.product?.unity === null || aux.product?.unity?.code === 'piece') {
                qtyMax = 750;
            } else {
                if (unit === 'Gramme') {
                    qtyMax = 5000;
                } else {
                    qtyMax = 5;
                }
            }
        }
        if (decrement) {

            let newQuantity = aux?.quantity * 1000;
            let newPriceQuantity = aux?.price;
            if ((aux.product?.unity?.id === 2)) {
                if ((aux?.quantity * 1000) >= 100) {
                    newQuantity = (aux?.quantity * 1000) - 50 - ((aux?.quantity * 1000) % 50);
                    newPriceQuantity = (aux?.price * newQuantity) / 1000;
                } else {
                    newQuantity = 50
                    newPriceQuantity = (aux?.price * (newQuantity / 1000));
                }
            } else {
                if (aux?.quantity > 1) {
                    newQuantity = aux?.quantity - 1;
                    newPriceQuantity = aux?.price * newQuantity;
                }
            }
            const discountPrice = newPriceQuantity - (newPriceQuantity * aux?.product?.vat?.amount)
            if (newPriceQuantity !== undefined && CommonFunction.setFormatNumber(newPriceQuantity) !== undefined) {
                formatedTotal = CommonFunction.setFormatNumber(newPriceQuantity)?.toString()
            }
            aux = {
                ...aux,
                quantity: aux?.product?.unity?.id === 2 ? newQuantity / 1000 : newQuantity,
                quantity_with_discount: newQuantity,
                quantity_formated: newQuantity.toFixed(2) + "x",
                total_discounted: newPriceQuantity,
                total_exc_vat: discountPrice,
                total_formated: formatedTotal,
                total: newPriceQuantity
            }
        }
        if (!decrement) {
            if (aux.quantity < qtyMax) {
                let newQuantity;
                let newPriceQuantity;
                if ((aux.product?.unity?.id === 2)) {
                    newQuantity = (aux?.quantity * 1000) + 50 - ((aux?.quantity * 1000) % 50);
                    newPriceQuantity = (aux?.price * newQuantity) / 1000;
                } else {
                    newQuantity = aux?.quantity + 1;
                    newPriceQuantity = aux?.price * newQuantity;
                }
                const discountPrice = newPriceQuantity - (newPriceQuantity * aux?.product?.vat?.amount)
                if (newPriceQuantity !== undefined && CommonFunction.setFormatNumber(newPriceQuantity) !== undefined) {
                    formatedTotal = CommonFunction.setFormatNumber(newPriceQuantity)?.toString()
                }
                aux = {
                    ...aux,
                    quantity: aux?.product?.unity?.id === 2 ? newQuantity / 1000 : newQuantity,
                    quantity_with_discount: newQuantity,
                    quantity_formated: newQuantity.toFixed(2) + "x",
                    total_discounted: newPriceQuantity,
                    total_exc_vat: discountPrice,
                    total_formated: formatedTotal,
                    total: newPriceQuantity
                }
            }
        }
        setIteeee(aux)
    }

    const incrementOrDecrementQunatity = (decrement: boolean) => {
        let aux = { ...myProduct }
        if (decrement) {
            if (quantity > 1) {
                let newQuantity;
                if (unit === 'Gramme' && data?.product[0]?.unity?.id === 2) {
                    if (quantity > 50) {
                        newQuantity = quantity - 50 - (quantity % 50);
                    } else {
                        newQuantity = 1
                    }
                } else {
                    newQuantity = quantity - 1;
                }
                setQuantity(newQuantity)
            }
        }
        if (!decrement) {
            let qtyMax = 0;
            if (aux?.attribute.availability.quantity) {
                qtyMax = aux?.attribute.availability.quantity;
            } else if (aux.product?.attribute?.current_stock) {
                qtyMax = aux.product?.attribute?.current_stock
            } else {
                if (data?.product !== null) {
                    if (data?.product[0]?.unity === null || data?.product[0]?.unity?.code === 'piece') {
                        qtyMax = 750;
                    } else {
                        if (unit === 'Gramme') {
                            qtyMax = 5000;
                        } else {
                            qtyMax = 5;
                        }
                    }
                }
            }
            if ((quantity < qtyMax)) {
                let newQuantity = 0;
                newQuantity = quantity + 1;
                if (data?.item !== null) {
                    if ((unit === 'Gramme' && data?.item?.product?.unity?.id === 2)) {
                        newQuantity = data?.item?.quantity + 50 - (data?.item?.quantity % 50);
                    }
                } else if (data?.product) {
                    if (unit === 'Gramme' && data.product[0]?.unity?.id === 2) {
                        newQuantity = newQuantity + 50 - (newQuantity % 50);
                    }
                }
                setQuantity(newQuantity)
            } else {
                dispatch(openAlert({ type: 'info', message: langue.empty_disponiblity }))
            }
        }
        setMyProduct(aux)
    }
    const setOrderItems = async () => {
        setIsLoading(true)
        let discount = myProduct?.discount_rule?.available_quantity > 0
        const response = await shopAPI.setOrderItems(
            { suid: commercant?.suid, lang: lang },
            {
                delivery: { address: deliveryAddress, method: delievryMode ? 1:3  },
                product: {
                    discount: discount,
                    options: [],
                    products: [],
                    quantity: unity ? quantity : quantity / 1000,
                    timezone: "Etc/GMT-1",
                    uid: myProduct?.uniq,
                    collected_at: deliveryDate,
                    remarque: itemId === myProduct?.uniq ? commentItem : ''
                },
                // typePanier: ""
            }
        )
        if (response.status === 200) {
            if (response?.data?.code === 6000) {
                if (props.onExit) {
                    props.onExit()
                }
            } else if (response?.data?.code === 4000) {
                if (props.onExit) {
                    props.onExit()
                }
                dispatch(openAlert({ type: 'info', message: langue.pickup_time_after_hours }))
            } else if (response?.data?.code === 3000) {
                if (props.onExit) {
                    props.onExit()
                }
                dispatch(openAlert({ type: 'info', message: response.data.message }))
            } else if (response?.data?.code) {
                if (props.onExit) {
                    props.onExit()
                }
                dispatch(openAlert({ type: 'info', message: response.data.message }))
            } else {
                dispatch(setBasketItems({ items: {} }))
                dispatch(setBasket({ baskett: {}, basketId: basketId }))
                dispatch(setNbreItems({ nbreItems: null }))
                const resp = await userApi.getUserLoyalty({
                    suid: commercant?.suid,
                    lang: lang
                })
                if (resp.status === 200) {
                    const res = await shopAPI.getBasketOrder({ suid: commercant?.suid, lang: lang , zone :zone })
                    if (res.status === 200) {
                        setIsLoading(false)
                        // dispatch(setCommentProduct({ commentItem: '', itemId: '' }))
                        dispatch(setShowBasket(showBasket))
                        dispatch(setBasketItems({ items: res.data.items }))
                        dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
                        if (props.onExit) {
                            props.onExit()
                        }
                    }
                }
            }
        } else if (response?.status === 401) {
            if (props.onExit) {
                props.onExit()
            }
            // dispatch(resetToken())
        }
    }
    const onAddItemBasket = () => {
        if ((myProduct?.attribute?.priceMutliQuantity !== 0) || myProduct?.attribute?.price !== 0) {
                if (props.onExit) {
                    props?.onExit()
                }
                setOrderItems()  
        }
    }
    const onAddComment = () => {

        if (props?.onExit) {
            props?.onExit()
            if (data?.fromBasket || data?.fromAddNote || data?.fromClose) {
                dispatch(setItem({ item: iteeee }))
                dispatch(openModal({ name: 'modalAddNote', data: { fromCompose: false, itemSelected: iteeee, fromBasket: true, quantity: quantity } }))

            } 
            if (data?.fromMenu || data?.fromAddNote || data?.fromClose) {
                dispatch(setItem({ item: myProduct }))
                dispatch(openModal({ name: 'modalAddNote', data: { fromCompose: false, itemSelected: myProduct, fromMenu: true,fromCloseMenu: true, quantity: quantity } }))
            }
        }
    }

    return <Modal
        contentClassName="ds-borad-14 product-details-modal ds-pointer overflow-hidden"
        withCloseIcon
        ref={ref}
        onExit={props.onExit}
        onShow={props.onShow}
    >
        <TemplateDetailsProduct
            data={data}
            myProduct={myProduct}
            setUnity={setUnity}
            unity={unity}
            incrementOrDecrementQunatity={incrementOrDecrementQunatity}
            noteRef={noteRef}
            onAddItemBasket={onAddItemBasket}
            onAddComment={onAddComment}
            commentItem={commentItem}
            itemId={itemId}
            quantity={quantity}
            isLoading={isLoading}
            onSetOrderQuantity={onSetOrderQuantity}
            iteeee={iteeee}
            onModifyItemBasket={onModifyItemBasket}
            unit={unit}

        />
    </Modal>

}
export default forwardRef(ModalDetailsProduct)