import { IValidation } from '../interface/IValidation';
import { FormValidationInput, FormValidationOutput, FormValidationRes, ListArrayValidation, ListBooleanValidation, ListValidation, Local } from '../types';

export class Validation implements IValidation {
  isMail(email: string): boolean {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }
  IsEightCharLength(value: string): boolean {
    return value.length >= 10
  }
  hasUperCase(str: string): boolean {
    return /[A-Z]/.test(str);
  }
  hasLowerCase(str: string): boolean {
    return /[a-z]/.test(str);
  }
  hasNumber(str: string): boolean {
    return /[0-9]/.test(str);
  }
  isEmpty(str: string = ''): boolean {
    return str.toString().trim().length === 0;
  }
  isNotEmpty(str: string = ''): boolean {
    return !this.isEmpty(str)
  }
  isNumber(str: string): boolean {
    return /^\d*$/.test(str);
  }
  isTrue(bool: boolean): boolean {
    return bool === true
  }
  isDate(date: string, local: Local = 'fr'): boolean {
    let isDate = false;
    let regex: RegExp;
    switch (local) {
      case 'en':
        regex = /^(((0)[0-9])|((1)[0-2]))(\/|-|,)([0-2][0-9]|(3)[0-1])(\/|-|,)\d{4}$/;
        isDate = regex.test(date);
        break;
      case 'fr':
        regex = /^([0-2][0-9]|(3)[0-1])(\/|-|,)(((0)[0-9])|((1)[0-2]))(\/|-|,)\d{4}$/;
        isDate = regex.test(date);
        break;
      default:
        break;
    }
    return isDate;
  }
  isUrl(uri: string): boolean {
    const expression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);
    return regex.test(uri);
  }
  isDateValidation(date: string): boolean {
    const reFullYear = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    return reFullYear.test(date);
  }
  hasAlpha(value: string): boolean {
    const re = /[a-zA-Z ]+/;
    return re.test(value);
  }
  hasSpecial(value: string): boolean {
    const re = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    return re.test(value);
  }
  hasSpecialDate(value: string): boolean {
    const re = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]+/;
    return re.test(value);
  }
  checkIsDate(value: string): boolean {
    const date = new Date(value);
    if (!isNaN(date?.getTime())) {
      return true;
    }
    return false;
  }
  isArray(value: Array<any>): boolean {
    if (Array.isArray(value)) {
      if (value.length) {
        return true
      }
    }
    return false
  }
  checkLenghtNumber(value: string): boolean {
    return value.length === 19;
  }
  checkLenghtCvc(value: string): boolean {
    return value.length === 3;
  }
  checkLenghtCodeConfirmation(value: string): boolean {
    return value.length === 4;
  }
  checkLenghtPassword(value: string): boolean {
    return value.length > 7;
  }
  isPhoneNumber(str: any): boolean {
    return /^\+\d+$/.test(str);
  }
  isNumberSpace(str: string): boolean {
    return /^[\d\s]*$/.test(str);
  }
  isDateValid(str: string): boolean {
    const [monthStr, yearStr] = str.split('/');
    const monthArray = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
    const month = Number(monthStr);
    const year = Number(yearStr);
    let date = new Date()
    let verify = (monthArray.includes(monthStr)) && (year > date.getFullYear() % 100 || (year === date.getFullYear() % 100 && month >= date.getMonth() + 1));
    return verify;
  }
  checkLenghtMessage(value: string): boolean {
    return value.length < 5000;
  }
  private listValidation: ListValidation = {
    isEmpty: this.isEmpty,
    isNotEmpty: this.isNotEmpty,
    isMail: this.isMail,
    isNumber: this.isNumber,
    hasUperCase: this.hasUperCase,
    hasNumber: this.hasNumber,
    hasLowerCase: this.hasLowerCase,
    isDate: this.isDate,
    isUrl: this.isUrl,
    isDateValidation: this.isDateValidation,
    hasAlpha: this.hasAlpha,
    hasSpecial: this.hasSpecial,
    hasSpecialDate: this.hasSpecialDate,
    checkIsDate: this.checkIsDate,
    checkLenghtNumber: this.checkLenghtNumber,
    checkLenghtCvc: this.checkLenghtCvc,
    checkLenghtCodeConfirmation: this.checkLenghtCodeConfirmation,
    checkLenghtPassword: this.checkLenghtPassword,
    isPhoneNumber: this.isPhoneNumber,
    isNumberSpace: this.isNumberSpace,
    isDateValid: this.isDateValid,
    checkLenghtMessage: this.checkLenghtMessage,
  };
  private listArrayValidation: ListArrayValidation = {
    isArray: this.isArray,
  };
  private listBoolValidation: ListBooleanValidation = {
    isTrue: this.isTrue,
  };

  formValidation({ list, state }: FormValidationInput): FormValidationOutput {
    const res: FormValidationRes = {};
    let verif = true;
    for (const listElement of list) {
      for (const validationElement of listElement.validation) {
        const key = listElement.value;
        const value = state[key].value;
        if (validationElement.type !== "isArray" && validationElement.type !== "isTrue" && typeof value === 'string') {
          const fnType = this.listValidation[validationElement.type];
          if (!fnType(value)) {
            const errorMsg = validationElement.error;
            res[key] = {
              value: value,
              isValid: false,
              isInvalid: true,
              error: errorMsg ? errorMsg : '',
            };
            verif = false;
            break;
          } else {
            res[key] = {
              value: value,
              isValid: true,
              isInvalid: false,
              error: null,
            };
          }
        } else if (validationElement.type === "isArray" && Array.isArray(value)) {
          const fnType = this.listArrayValidation[validationElement.type];
          if (!fnType(value)) {
            const errorMsg = validationElement.error;
            res[key] = {
              value: value,
              isValid: false,
              isInvalid: true,
              error: errorMsg ? errorMsg : '',
            };
            verif = false;
            break;
          } else {
            res[key] = {
              value: value,
              isValid: true,
              isInvalid: false,
              error: null,
            };
          }
        } else if (validationElement.type === "isTrue" && typeof value === 'boolean') {
          const fnType = this.listBoolValidation[validationElement.type];
          if (!fnType(value)) {
            const errorMsg = validationElement.error;
            res[key] = {
              value: value,
              isValid: false,
              isInvalid: true,
              error: errorMsg ? errorMsg : '',
            };
            verif = false;
            break;
          } else {
            res[key] = {
              value: value,
              isValid: true,
              isInvalid: false,
              error: null,
            };
          }
        }
      }
    }
    return { res, verif };
  }

}
