import { Checkbox, Modal, ModalRefType, } from "../../DesignSystem";
import { IModalContactUs } from "../../Interfaces/Modal";
import { Ref, forwardRef, useContext } from "react";
import { LangContext } from "../../Lang";

const ModalMessageSent = (props: IModalContactUs, ref: Ref<ModalRefType | undefined>) => {
    const className = ['ds-borad-14 ds-flex message-send-modal overflow-hidden ds-pointer']
    const langue = useContext(LangContext)
    const renderMessageSent = () => {
        const list: JSX.Element[] = []
        list.push(<div className="ds-flex-col ds-align-center ds-p-40">
            <span className="ds-mb-40 ds-text-size-28 ds-text-weight700 ds-text-primary100 ds-text-height-33">{langue.message_sent}</span>
                <div className="ds-flex-col ds-align-center ds-justify-end">
                    <Checkbox
                        checked={true}
                    />
                    <span className="ds-mt-10 ds-text-size-16 ds-text-weight400 ds-text-success100 ds-text-height-19">{langue.message_sent_successfully}</span>
                </div>
        </div>)
        return (<>{list}</>)
    }

    return (
        <Modal
            contentClassName={className.join(' ')}
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
        >
            <div>
                {renderMessageSent()}
            </div>
        </Modal>
    )
}
export default forwardRef(ModalMessageSent)