import { useContext, useState, useEffect } from 'react'
import { IItem, IOrganismSidebar, SidebarType } from '../../Interfaces/Organisms/IOrganismSidebar/IOrganismSidebar';
import { DEFAULTSIDEBARPROPS } from '../../DefaultProps/Organisms';
import { Input } from '../../Molecules';
import { Avatar, Icon } from '../../Atoms';
import { Size } from '../../Interfaces/Atoms/IAtomAvatar/IAtomAvatar';
import { Link, useNavigate } from 'react-router-dom';
import { CommonFunction, Config, Data } from '../../../Common';
import { openModal, resetToken, setCommercant, setLang, setSideBar } from '../../../Redux/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import { Elang, IAnyKeys, RootState } from '../../../Interfaces';
import logo from '../../../Assets/images/Logo_Digi-Medium.svg'
import { LangContext } from '../../../Lang';

function OrganismSidebar(props: IOrganismSidebar): JSX.Element {
    const containerClassName: string[] = ['ds-relative ds-flex-col ds-h-100 ds-bg-white '];
    const classNameHeader: string[] = ['ds-mb-30'];
    const [selectedItem, setSelectedItem] = useState(props.selectedItem)
    const { show } = useSelector((state: RootState) => state?.sideBar)
    const { lang } = useSelector((state: RootState) => state?.setting)
    const langue = useContext(LangContext)
    const { token, user } = useSelector((state: RootState) => state?.auth)
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onOpenModal = () => {
        dispatch(openModal({ name: "modalDeleteCompteRef" }))
    }
    const config = new Config()
    if (props.type === SidebarType.type1) {
        containerClassName.push('default-sidebar')
    } else if (props.type === SidebarType.type2) {
        containerClassName.push('type2 width-300 ds-px-16')
    }
    const onGetGeneralCondition = () => {
        if (commercant?.suid) {
            dispatch(openModal({ name: 'generalConditionModal' }));
            dispatch(setSideBar());
        }
    }
    const onGetMentionsLegales = () => {
        if (commercant?.suid) {
            dispatch(openModal({ name: 'mentionsLegales' }));
            dispatch(setSideBar());
        }
    }

    useEffect(() => {
        setSelectedItem(props.selectedItem)
    }, [props.selectedItem])

    const renderSidebarHeader = () => {
        if (props.type === SidebarType.type1) {
            if (user !== null) {
                return (
                    <div className='ds-w-100 ds-pointer header-sidebar' onClick={() => { navigate('/compte'); dispatch(setSideBar()) }}>
                        <div className='ds-flex ds-w-100'>
                            <Avatar
                                user={{ firstname: user?.firstname, lastname: user?.lastname, email: user?.username }}
                                isNameText={true}
                                size={Size.large}
                            />
                        </div>
                        <div className='horizontal-line ds-mt-24 ds-mb-24'></div>
                    </div>
                )
            } else {
                return <div className='ds-w-100 ds-pb-40'>
                    <img src={logo} height={30} />
                </div>
            }
        } else {
            return null;
        }
    }
    const renderSidebarFooter = () => {
        if (props.type === SidebarType.type1) {
            return (
                <div className='ds-w-100'>
                    {token !== null && user !== null &&
                        <>
                            <div className='horizontal-line'></div>
                            <div onClick={() => { dispatch(resetToken());  dispatch(setSideBar());  }} className='ds-flex ds-align-center ds-m-10 ds-pointer'>
                                <Icon
                                    icon={props.footerIcon}
                                    size={"24px"}
                                    className={props.footerIconClassName}
                                />
                                <span className='ds-text-neutral70 ds-text-size-16 ds-text-line-19 ds-ml-12'
                                >
                                    {langue.disconnect}
                                </span>
                            </div>
                            <div className='horizontal-line'></div>
                        </>
                    }
                    <div className='ds-flex-col ds-mt-24'>
                        <span className='ds-text-neutral40 ds-text-size-14 ds-text-line-20 ds-pointer'
                            onClick={() => onGetMentionsLegales()} >
                            {langue.tnc}
                        </span>
                        <span className='ds-text-neutral40 ds-text-size-14 ds-text-line-20 ds-pointer'
                            onClick={() => { onGetGeneralCondition() }}>
                            {langue.notice}
                        </span>
                        <span className='ds-text-neutral40 ds-text-size-14 ds-text-line-20 ds-pointer'
                            onClick={() => CommonFunction.OnOpenNewBlank(config.comsumerodr_url)}
                        >
                            {langue.litigation}
                        </span>

                    </div>
                </div>
            )
        } else if (props.type === SidebarType.type2) {
            return (
                <div className='ds-w-100 ds-px-24'>
                    <div className='horizontal-line'></div>
                    <div className='ds-flex ds-align-center ds-m-5 ds-relative'>
                        <Icon
                            icon='global1'
                            size={"20px"}
                            className='ds-text-neutral70 '
                        />
                        <span
                            className='ds-text-neutral70 ds-flex-grow1 ds-text-size-16 ds-text-line-19 ds-ml-12'
                        >{langue.title_lang}
                        </span>
                        <Input
                            isSelect={true}
                            containerClassName='ds-wp-80 border-none'
                            className='border-none'
                            selectValue={lang}
                            selectOption={Data.getSelectOption()}
                            onChangeSelect={(e: Elang) => {
                                dispatch(setLang(e));

                            }
                            }
                            containerOptionClassName='lang-select-sidebar'
                        />
                    </div>
                    <div className='horizontal-line'></div>
                    <div className='ds-flex-col ds-mt-24'>
                        <span className='ds-text-neutral100 ds-text-size-14 ds-text-line-16 ds-mb-8'>
                            {langue.label_delete_profil}
                        </span>
                        <span className='ds-text-neutral40 ds-text-size-14 ds-text-line-16'>
                            {langue.delete_profile_description}

                        </span>
                        <span className='ds-pointer ds-text-error70 ds-text-size-14 ds-text-line-16 ds-flex ds-justify-end underlined-Text ds-mt-24'
                            onClick={() => {
                                onOpenModal();
                            }
                            }

                        >
                            {langue.delete_compte}
                        </span>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    const onClickSideBarElement = (index: number, item: IItem) => {
        setSelectedItem(index);
        if (item?.nameModal === 'modalContactUs') {
            dispatch(openModal({ name: item?.nameModal, data: { contactUs: true } }))
        }
        if (item?.nameModal === 'modalConnexionRef') {
            dispatch(openModal({ name: item?.nameModal }))
        }
        dispatch(setSideBar());
        // if (item?.path === "/") {
        //     dispatch(setCommercant({ commercant: {} }))
        // } else if (item?.label === "Autour de moi") {
        //     dispatch(setCommercant({ commercant: {} }))
        // } else {
        navigate(item.path || "")
        // }
    }
    const renderItems = () => {
        const list: JSX.Element[] = []
        if (props.type === SidebarType.type1) {
            props.items.forEach((item: IItem, index) => {
                let className = ['ds-pointer ds-flex ds-align-center ds-hp-48 ds-w-100 ds-text-primary70 ds-py-14']
                const selected = selectedItem === index
                if (selected) {
                    className.push('ds-bg-secondary10 ds-text-weight700 ds-text-primary100 ds-p-24')
                } else {
                    className.push('ds-bg-white ds-text-weight500 ds-px-24')
                }
                list.push(
                    <div style={{ maxHeight: 48 }}>
                        {item.path && item.label && <Link
                            to={token !== null ? item?.path : ''}
                            className='path-text ds-ml-8 ds-text-size-16 ds-text-line-19 '
                            onClick={() => { onClickSideBarElement(index, item); setSelectedItem(index); item.onClick && item.onClick() }}
                        >
                            <div className={className.join(' ')} key={index}
                            >
                                {item.icon && <Icon
                                    icon={item.icon}
                                    size={"20px"}
                                    className='ds-mb-3'
                                />}
                                <span className='path-text ds-ml-8 ds-text-size-16 ds-text-line-19 '>{item.label}</span>
                            </div>
                        </Link>}
                    </div>
                )
            })
            return <div className='ds-w-100 ds-flex-grow1'>{list}</div>
        }
        if (props.type === SidebarType.type2) {
            props.ItemsType2.forEach((item, index) => {
                let className = ['ds-pointer ds-flex ds-align-center ds-hp-48 ds-w-100 ds-text-primary70 ds-py-14']
                const selected = selectedItem === index
                if (selected) {
                    className.push('ds-bg-secondary10 border-left ds-text-weight700 ds-text-primary100 ds-p-24')
                }
                else {
                    className.push('ds-bg-white ds-text-weight500 ds-px-24')
                }
                list.push(
                    <div className={className.join(' ')} key={index} onClick={() => { setSelectedItem(index); item.onClick && item.onClick() }}>
                        {item.label && <span
                            className='ds-ml-8 ds-text-size-16 ds-text-line-19 '
                        >
                            {item.label}
                        </span>}
                        {item.path && <Link
                            to={item.path}
                            className='ds-ml-8 ds-text-size-16 ds-text-line-19 '
                        >
                            {item.path}
                        </Link>}
                    </div>
                )
            })

            return <div className='ds-w-100 ds-flex-grow1 ds-overflow-x '>{list}</div>
        }
    }

    const renderSidebar = () => {
        return (
            <div className='ds-flex  ds-relative ds-justify-between ds-flex-grow1 width-300 ds-p-16'>
                <div className='ds-flex-col  ds-w-100'>
                    {renderSidebarHeader()}
                    {renderItems()}
                    {renderSidebarFooter()}
                </div>
                {props.type === SidebarType.type1 && <Icon
                    icon='close'
                    size={"24px"}
                    className='ds-pointer ds-text-primary60'
                    onClick={() => dispatch(setSideBar(show))}
                />}
            </div>
        );
    }

    if (props.classNameHeader) { classNameHeader.push(props.classNameHeader) };
    if (props.containerClassName) { containerClassName.push(props.containerClassName) };
    const style: IAnyKeys = {}
    if (props.isMaxSize) {
        style.height = window.innerHeight
    } else {
        style.height = 'calc(100vh - 100px)'
    }
    return (
        <div className={containerClassName.join(' ')} style={style}>
            {renderSidebar()}
        </div>
    )
}


OrganismSidebar.defaultProps = {
    ...DEFAULTSIDEBARPROPS,
}

export default OrganismSidebar