import { useNavigate } from "react-router-dom"
import { Button, Tab } from "../../DesignSystem"
import { CardType, Type } from "../../DesignSystem/Interfaces"
import { Card } from "../../DesignSystem/Organisms"
import { IFilterSites, ISitesType, ITemplateMesCommercants } from "../../Interfaces"
import { Data } from "../../Common"
import { useContext } from "react"
import { LangContext } from "../../Lang"
import { useWindowSize } from "../../CustomHooks"

const TemplateMesCommercants = (props: ITemplateMesCommercants): JSX.Element => {
    const navigate = useNavigate()
    const { width } = useWindowSize()
    const langue = useContext(LangContext)
    const renderSites = () => {
        return <div className="ds-px-40 my-sites-class">
            <div className="ds-flex ds-justify-between ds-w-100">
                <span className="ds-text-size-28 ds-text-height-34 ds-text-weight700 ds-text-primary100">{langue.merchants}</span>
                <Button
                    className="search-site-btn"
                    text={langue.search_merchant}
                    type={Type.tertiary}
                    onClick={() => navigate('/')}
                />
            </div>
            <div className="ds-pt-40">
                <Tab
                    list={Data.getTabList()}
                    isTopTab={false}
                    onClick={(i: number) => props.onFilterSiteCategory(i)}
                />
                <div className="horizontal-line ds-w-100"></div>
                <div className='ds-mt-30 ds-flex ds-w-100 ds-flex-wrap '>
                    {props?.filtredSites?.map((el: ISitesType) => {
                        return (
                            el?.client?.sites?.map((item: IFilterSites, i: number) => {
                                return (
                                    <Card
                                        key={i}
                                        cardClassName='ds-mr-12 ds-ml-12 ds-mb-24 ds-center'
                                        type={CardType.shopCard}
                                        nameClient={item?.name}
                                        addressClient={`${item?.address}`}
                                        distance={`${item?.distance ? (item?.distance / 1000).toFixed(2) : ''}`}
                                        image={item?.background?.medium_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")}
                                        btnText={item.active ? langue.order : langue.open_soon}
                                        onClickCardBtn={width > 1024 ? () => props.onClickCardBtn(item.suid) : null}
                                        onClick={width < 1024 ? () => props.onClickCardBtn(item.suid) : null}
                                    />
                                )
                            })
                        )
                    })}
                </div>
            </div>
        </div>
    }

    return (
        <div className="ds-w-100">
            {renderSites()}
        </div>
    )
}
export default TemplateMesCommercants