import { ESizeInput, IMoleculeModal, IMoleculeNavbar, NavbarPosition, SizeAvatar, Type } from "../../Interfaces"
import { IMoleculePricing } from "../../Interfaces"
import { Size } from "../../Interfaces/Atoms/IAtomAvatar/IAtomAvatar"
import { IMoleculeDropdown } from "../../Interfaces/Molecule/IMoleculeDropdown/IMoleculeDropdown"
import { IInputMessage, IMolInputProps } from "../../Interfaces/Molecule/IMoleculeInput/IMoleculeInput"
import { IMoleculeStepper } from "../../Interfaces/Molecule/IMoleculeStepper/IMoleculeStepper"
import { IMoleculeTab } from "../../Interfaces/Molecule/IMoleculeTab/IMoleculeTab"
import {
    IMoleculeDatepicker
} from '../../Interfaces/Molecule/IMoleculeDatepicker/IMoleculeDatepicker';

import { DEFAULTATOMICONPROPS, DEFAULTBUTTONPROPS } from "../Atoms"
const DEFAULTINPUTMOLPROPS: IMolInputProps = {
    containerClassName: "",
    listIcons: [],
    isPassword: false,
    isSelect: false,
    selectValue: [],
    selectOption: [{ label: "kjkdf", value: "fd;jfkj∑ƒ" }],
    onChangeSelect: () => { return },
    isMulti: false,
    isClerable: false,
    onClear: () => { return },
    isSearch: false,
    messageNoOptions: "Aucun élément"
}

const DEFAULTINPUTMESSAGEPROPS: IInputMessage = {
    label: "",
    labelClassName: "",
    error: "",
    errorClassName: "",
    success: "",
    successClassName: "",
}

const DEFAULTTOPBARPROPS: IMoleculeNavbar = {
    className: "",
    numberItems:"4",
    selectedLang:"FR"
}

const DEFAULTPRICINGPROPS: IMoleculePricing = {
    className: "",
    withTitle: false,
    bsPrefix: "",
    containerClassName: "",
    iconClassName: "",
    title: "",
    description: "",
    data: []
}
const DEFAULTSTEPPERPROPS: IMoleculeStepper = {
    data: [],
    className: "",
    titleClassName: "",
    textClassName: "",
    containerClassName: "",
    bsPrefix: "",
    containerBsPrefix: "",
    isVertical: true,
    withLike: false,
    children: null,
    onChange: () => { return },
    step: null
}
const DEFAULTTABPROPS: IMoleculeTab = {
    tabClassName: "",
    onClick: () => { return },
    categoryCommercant: () => { return },
    onGetCategoriesProducts: () => { return },
    list: [],
    selectedIndex: 0,
    withArrow:false,
}
const DEFAULTDATEPICKERPROPS: IMoleculeDatepicker = {
    isRange: true,
    isYear: false,
    containerClassName: "",
    placeholder: "",
    onChange: () => { return },
    value: null,
    start: null,
    end: null,
    isInvalid: false,
    isValid: false,
    inputSize: ESizeInput.medium
}
const DEFAULTDROPDOWNPROPS: IMoleculeDropdown = {
    label: '',
    labelClassName: '',
    primaryIcon: '',
    primaryIconClassName: '',
    secondaryIcon: '',
    secondaryIconClassName: '',
    primaryIconSize: '20px',
    secondaryIconSize: '12px',
    onClick: () => { },
    dropDownClassName: '',
}
const DEFAULTMODALPROPS: IMoleculeModal = {
    children: null,
    withCloseIcon: false,
    withSubmitAction: false,
    withCloseAction: false,
    btnSubmitProps: { ...DEFAULTBUTTONPROPS, text: "Submit", type: Type.primary },
    btnResetProps: { ...DEFAULTBUTTONPROPS, text: "Close", type: Type.secondary, onClick: null },
    contentClassName: "",
    containerClassName: "",
    containerBtnClassName: "",
    onExit: () => { return },
    onShow: () => { return },
}
export {
    DEFAULTTOPBARPROPS,
    DEFAULTINPUTMOLPROPS,
    DEFAULTINPUTMESSAGEPROPS,
    DEFAULTPRICINGPROPS,
    DEFAULTSTEPPERPROPS,
    DEFAULTTABPROPS,
    DEFAULTDATEPICKERPROPS,
    DEFAULTDROPDOWNPROPS,
    DEFAULTMODALPROPS
    
}