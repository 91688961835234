import React, { useEffect, useRef } from "react"

export function useEffectOnce(callback: React.EffectCallback) {
    const firstRenderRef = useRef(true)
    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            if (firstRenderRef.current) {
                firstRenderRef.current = false
                return
            } else {
                callback()
            }
        } else {
            callback()
        }
    }, [])
}