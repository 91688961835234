import { CommonFunction, Config } from "../../Common"
import { ITemplateModalPaiment, RootState } from "../../Interfaces"
import { TypePaiement } from "../../Interfaces/Modal"
import vector from '../../Assets/images/Vector.svg';
import carte from '../../Assets/images/carte.svg';
import { Button, Checkbox, Icon, Input } from "../../DesignSystem";
import { useDispatch, useSelector } from "react-redux";
import { Validation } from "../../Common/StandardValidation";
import { setMethodePiement } from "../../Redux/Reducers";
import { useContext } from "react";
import { LangContext } from "../../Lang";

const TemplateModalPaiment = (props: ITemplateModalPaiment): JSX.Element => {
    const { totalCommande, credit, baskett, fromRecharge } = useSelector((state: RootState) => state?.basket)
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    const { delievryMode } = useSelector((state: RootState) => state?.paiement)
    const validation = new Validation()
    const dispatch = useDispatch()
    let saveCardClassName = ['ds-ml-8 ds-text-size-16 ds-text-line-19 ds-text-weight500']
    if (props?.cardSaved) {
        saveCardClassName.push('ds-text-primary100')
    } else {
        saveCardClassName.push('ds-text-primary40')
    }
    const langue = useContext(LangContext)

    const renderTypePaiment = () => {
        return (
            props?.paimentMethode?.map((el: TypePaiement, i: number) => {
                return (
                    <div key={i} className="ds-flex-col  ds-w-100">
                        {el.typePaiment !== langue.on_site ? <>
                            <div className="ds-flex-grow1 ds-pointer ds-flex ds-w-100" onClick={() => props?.onChoosePaimentMethode(i)}>

                                {el.image && <img
                                    src={el.image}
                                    className="ds-mb-10"
                                />}
                                {el.icon && <Icon
                                    icon={el.icon}
                                    className="ds-text-secondary100 ds-mb-10"
                                    size={"24px"}
                                />}
                                <div className="ds-flex-col ds-w-100 ds-ml-8">
                                    <span className="ds-mb-4  ds-text-size-14 ds-text-line-16 ds-text-weight400 ds-text-primary100">{el.typePaiment}</span>
                                    <span className="ds-text-size-14 ds-text-line-16 ds-text-weight400 ds-text-primary40">{el.typePaimentDescription}</span>
                                </div>
                                {el.typePaiment === langue.bank_card ?
                                    <Icon
                                        icon="arrow-right-11"
                                        size={"24px"}
                                        className="ds-text-primary40"
                                        onClick={() => props?.onChoosePaimentMethode(i)}
                                    /> :
                                    <Checkbox
                                        className="border-radius-50"
                                        checked={props?.selectedCheckbox === i}
                                        onChange={() => props?.onChoosePaimentMethode(i)}
                                    />
                                }
                            </div>
                            <div className="horizontal-line ds-mb-16 ds-mt-8 ds-w-100"></div>
                        </> : <>
                            {commercant.payment_upon_delivery === 2 || commercant.payment_upon_delivery === 3 &&
                                <>
                                    <div className="ds-flex-grow1 ds-pointer ds-flex ds-w-100" onClick={() => props?.onChoosePaimentMethode(i)}>

                                        {el.image && <img
                                            src={el.image}
                                            className="ds-mb-10"
                                        />}
                                        {el.icon && <Icon
                                            icon={el.icon}
                                            className="ds-text-secondary100 ds-mb-10"
                                            size={"24px"}
                                        />}
                                        <div className="ds-flex-col ds-w-100 ds-ml-8">
                                            <span className="ds-mb-4  ds-text-size-14 ds-text-line-16 ds-text-weight400 ds-text-primary100">{el.typePaiment}</span>
                                            <span className="ds-text-size-14 ds-text-line-16 ds-text-weight400 ds-text-primary40">{el.typePaimentDescription}</span>
                                        </div>
                                        <Checkbox
                                            className="border-radius-50"
                                            checked={props?.selectedCheckbox === i}
                                            onChange={() => props?.onChoosePaimentMethode(i)}
                                        />
                                    </div>
                                    <div className="horizontal-line ds-mb-16 ds-mt-8 ds-w-100"></div>
                                </>}
                        </>
                        }

                    </div>
                )
            })
        )
    }
    const getBasketPrice = () => {
        if (delievryMode && !fromRecharge) {
            return CommonFunction.setFormatNumber(commercant?.delivery_price + baskett?.total)
        } else if (!delievryMode && !fromRecharge) {
            return CommonFunction.setFormatNumber(totalCommande)
        }
        if (fromRecharge) {
            return CommonFunction.setFormatNumber(credit)
        }
    }
    const renderFormCarteBancaire = () => {
        const list: JSX.Element[] = []
        if (props?.showCardDetails) {
            list.push(
                <div className="ds-flex-col ds-h-100 ds-w-100 ds-p-24">
                    <div className="ds-flex-col ds-flex-grow1 ds-w-100">
                        <div className="ds-flex ds-align-center ds-w-100">
                            <Icon
                                icon="arrow-left"
                                size={"24px"}
                                className="ds-mt-10 ds-mr-5 ds-pointer"
                                onClick={() => {
                                    props?.setShowCardDetails(!props?.showCardDetails); dispatch(setMethodePiement({ methodePaiment: '' }))
                                }}
                            />
                            <span className="ds-text-size-20 ds-text-line-24 ds-text-weight700 ds-text-primary100 ds-mt-8">
                                {langue.add_payment_card}
                            </span>
                        </div>
                        <div className="horizontal-line ds-w-100 ds-mb-30 ds-mt-16"></div>
                        <div className="ds-flex-col ds-w-100 ds-align-center ds-mb-33">
                            <Input
                                label={langue.owner_name}
                                containerClassName="ds-mt-8 "
                                placeholder={langue.card_name}
                                {...CommonFunction.getInputProps(props?.form?.nameCard)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    props?.onChange({
                                        key: "nameCard",
                                        value: e.target.value
                                    })
                                }
                                }
                            />
                            <div className="ds-w-100 ds-relative">
                                <Input
                                    label={langue.card_number}
                                    containerClassName="ds-mt-8 "
                                    placeholder="- - - -    - - - -    - - - -    - - - -"
                                    {...CommonFunction.getInputProps(props?.form?.numberCard)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => {
                                        if (e.target.value.length < 20
                                            && validation.isNumberSpace(e.target.value)
                                        ) {
                                            let inputVal = e.target.value.replace(/\s/g, '');
                                            inputVal = inputVal.replace(/(\d{4})/g, '$1 ').trim();
                                            props?.onChange({
                                                key: "numberCard",
                                                value: inputVal
                                            })
                                        }
                                    }
                                    }
                                />
                                <img src={carte} className="ds-absolute absolute-img" height={24} />
                            </div>
                            <div className="ds-relative ds-w-100">
                                <div className="ds-flex ds-w-100 ds-mt-16 ds-relative">
                                    <Input
                                        label={langue.expired_date}
                                        containerClassName="ds-mr-8"
                                        placeholder="MM / YY"
                                        {...CommonFunction.getInputProps(props?.form?.dateExp)}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => {
                                            if (e.target.value.length <= 5) {
                                                if (e.target.value.length === 2) {
                                                    e.target.value = e.target.value + '/'
                                                    if (e.target.value.indexOf('/') !== -1 && e.nativeEvent?.inputType === "deleteContentBackward") {
                                                        e.target.value = e.target.value.slice(0, 2)
                                                    }
                                                }
                                                props?.onChange({
                                                    key: "dateExp",
                                                    value: e.target.value
                                                })
                                            }
                                        }
                                        }
                                    />
                                    <Input
                                        label="CVC"
                                        containerClassName="ds-ml-8 ds-w-100"
                                        placeholder="- - -"
                                        {...CommonFunction.getInputProps(props?.form?.cvc)}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.value.length <= 3 && validation.isNumber(e.target?.value)) {
                                                props?.onChange({
                                                    key: "cvc",
                                                    value: e.target.value
                                                })
                                            }
                                        }
                                        }
                                    />
                                </div>
                                <img src={vector} className="ds-pointer ds-absolute vector-img" height={13} />
                            </div>
                        </div>
                        <div className="ds-flex ds-w-100 ds-align-center ds-mb-33  ds-text-primary40">
                            <Checkbox
                                checked={props?.cardSaved}
                                onChange={() => props?.onSetSaveCrad(props?.cardSaved)}
                                label={langue.save_card}
                                labelClassName={saveCardClassName.join('')}
                                containerClassName="ds-text-primary40 card-saved-checkbox"
                            />
                        </div>
                    </div>
                    <div className="ds-w-100 ds-flex-col ">
                        <div className="ds-flex ds-w-100 ds-align-center ds-mb-33">
                            <Checkbox
                                checked={props?.show}
                                onChange={() => props?.onSetGeneralCondition()}
                                containerClassName="general-condition-checkbox"
                            />
                            <span
                                onClick={() => CommonFunction.OnOpenNewBlank(`${Config.getInstance().download_url + commercant?.suid}`)}
                                className="ds-ml-8 ds-text-size-16 ds-text-line-19 ds-text-weight500 underlined-Text  ds-text-primary100">
                                {langue.conditions_of_sale}
                            </span>
                        </div>
                        <Button
                            text={langue.pay + ` - ${getBasketPrice()} €`}
                            className="ds-w-100"
                            onClick={() => props?.onPayOrder()}
                            disabled={
                                (
                                    props?.form?.nameCard.isInvalid ||
                                    props?.form?.numberCard.isInvalid||
                                    props?.form?.cvc.isInvalid ||
                                    props?.form?.dateExp.isInvalid || !props?.show
                                )
                            }
                            isLoading={props.isLoading}
                        />
                    </div>
                </div>
            )
        } else {
            list.push(
                <div className="ds-flex-col ds-w-100 ds-h-100 ds-px-24">
                    <div className="ds-flex-col ds-center ds-w-100 ds-flex-grow1 ">
                        <span className="ds-text-size-28 ds-text-line-33 ds-text-weight700 ds-text-primary100">
                            {langue.payment_method}
                        </span>
                        <span className="ds-text-size-16 ds-text-line-19 ds-text-weight500 ds-text-primary40">{langue.payment_obligation}</span>
                        <div className="horizontal-line ds-w-100 ds-mb-30 ds-mt-16"></div>
                        {renderTypePaiment()}
                    </div>
                    <div className="ds-flex-col ds-w-100 ds-align-center ds-mb-30">
                        <div className="ds-flex ds-w-100 ds-center ds-mb-33">
                            <Checkbox
                                checked={props?.show}
                                onChange={() => props?.onSetGeneralCondition()}
                                containerClassName="general-condition-checkbox"
                            />
                            <span
                                onClick={() => CommonFunction.OnOpenNewBlank(`${Config.getInstance().download_url + commercant?.suid}`)}
                                className="ds-ml-8 ds-text-size-16 ds-text-line-19 ds-text-weight500 underlined-Text  ds-text-primary100">
                                {langue.conditions_of_sale}
                            </span>
                        </div>
                        <Button
                            text={langue.pay + ` - ${getBasketPrice()} €`}
                            className="ds-w-100"
                            onClick={() => props?.onPayOrder()}
                            disabled={
                                (
                                    props?.selectedCheckbox === null ||
                                    !props?.show
                                )
                            }
                            isLoading={props.isLoading}
                        />
                    </div>

                </div>
            )

        }
        return (<>{list}</>)
    }
    return (
        renderFormCarteBancaire()
    )
}
export default TemplateModalPaiment