import { createSlice } from '@reduxjs/toolkit';
import { IBasketReducer } from '../../../Interfaces/Redux';

const initiaState: IBasketReducer = {
    showBasket: false,
    showRecharge: false,
    items: {},
    nbreItems: null,
    totalCommande: null,
    baskett: { delivery_method: 1 },
    credit: null,
    fromRecharge: false,
    basketId: '',
    commentItem: '',
    itemId: '',
    pointRetrait: {},
    zone: null
};

const BasketReducer = createSlice({
    name: 'basket',
    initialState: initiaState,
    reducers: {
        setShowBasket: (state: IBasketReducer) => {
            state.showBasket = !state.showBasket
        },
        setBasketItems: (state: IBasketReducer, action) => {
            state.items = action.payload.items
        },
        setNbreItems: (state: IBasketReducer, action) => {
            state.nbreItems = action.payload.nbreItems
        },
        setPointRetrait: (state: IBasketReducer, action) => {
            state.pointRetrait = action.payload.pointRetrait
        },
        setZone: (state: IBasketReducer, action) => {
            state.zone = action.payload.zone
        },
        setTotalCommande: (state: IBasketReducer, action) => {
            state.totalCommande = action.payload.totalCommande
        },
        setEmptyBasket: (state: IBasketReducer) => {
            state.items = {}
            state.baskett = {}
            state.nbreItems = null
        },
        setShowRecharge: (state: IBasketReducer) => {
            state.showRecharge = !state.showRecharge
        },
        setBasket: (state: IBasketReducer, action) => {
            state.baskett = action.payload.baskett
            state.basketId = action.payload.basketId
        },
        setCreditPoint: (state: IBasketReducer, action) => {
            state.credit = action.payload.credit
        },
        setFromRecharge: (state: IBasketReducer, action) => {
            state.fromRecharge = action.payload.fromRecharge
        },
        setCommentProduct: (state: IBasketReducer, action) => {
            state.commentItem = action.payload.commentItem
            state.itemId = action.payload.itemId
        },
    }
});

export const { setShowBasket, setBasketItems, setPointRetrait, setEmptyBasket, setTotalCommande, setShowRecharge, setNbreItems, setBasket, setCreditPoint, setFromRecharge, setCommentProduct, setZone } = BasketReducer.actions;

export default BasketReducer.reducer;