import { type } from "os";
import { IUser } from "../../Atoms/IAtomAvatar/IAtomAvatar";
import { SubItem } from "../../Molecule/IMoleculeDropdown/IMoleculeDropdown";

export type IItem={
    label?: string,
    path?: string,
    icon?: string,
    onClick?: Function,
    nameModal?: string,
}
export type ItemsType2 = Array<{
    label?: string,
    path?: string,
   onClick?: Function,
}>
 

export type Items = Array<IItem>
export type ListItems = Array<{
    category?: string,
    items?: Items,
    recently?: string[],
}>

export enum SidebarType {
    type1 = 'type1',
    type2 = 'type2',
}
export interface IOrganismSidebar {
    type: SidebarType,
    logo?: any,
    avatar?: any,
    userName?: string,
    user?: IUser,
    items: Items;
    ItemsType2:ItemsType2;
    classNameHeader?: string;
    containerClassName?: string;
    style?: object;
    isRightBar: boolean,
    selectedItem?: number,
    children?: JSX.Element | JSX.Element[],
    footerIcon?: string,
    footerIconClassName?: string,
    footerButton?: string,
    onChangeLanguage?: React.FormEventHandler<HTMLElement> | undefined,
    onClickDeleteAccount?: React.MouseEventHandler<HTMLSpanElement> | undefined,
    isMaxSize?:boolean
}
export type FavoriteType = Array<{
    label: string,
    items: string[]
}>
export type NotifcationType = Array<{
    label: string,
    time: string,
    icon: string,
}>
export type ActivityType = Array<{
    label: string,
    time?: string,
    avatar: string,
}>
export enum ELang {
    fr = 'fr',
    en = 'en',
    nl = 'nl',
}

export type IActions = {
    label?: string,
    isDisplayed?: boolean,
    onClick?: Function

}
export type IListAction = Array<IActions>
export interface IClickableDots {
    containerClassName?: string,
    actions?: IListAction,
    rowIndex: number
}