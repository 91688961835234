import React, { useContext, useState } from 'react'
import { CardSize, CardType, IOrganismCard } from "../../Interfaces/Organisms/IOrganismCard/IOrganismCard";
import { Button } from '../../Molecules';
import { Type } from '../../Interfaces';
import { Checkbox } from '../../Atoms';
import { DEFAULTCARDPROPS } from '../../DefaultProps/Organisms';
import empty_img from '../../../Assets/images/empty_img.png'
import empty_shop from '../../../Assets/images/empty_shop.svg'
import { LangContext } from '../../../Lang';
function OrganismCard(props: IOrganismCard): JSX.Element {
    const langue = useContext(LangContext)
    const cardClassName = ['ds-bg-white ds-pointer ds-borad-8 ']
    const imageClassName = ['ds-relative']
    const nbreItemClassName = ['ds-text-neutra100 ds-text-size-12 ds-text-line-14 ds-text-weight700 ds-mt-4']
    const nameClientClassName = ['ds-text-neutral100 ds-text-size-16 ds-text-line-17 ds-text-weight700 ds-mb-8']
    const nameProductClassName = ['ds-text-neutral100 ds-text-weight700 ']
    const descriptionProductClassName = ['ds-text-neutral40 ds-text-weight400 ']
    const priceProductClassName = ['ds-text-neutral100  ds-text-weight700 ']
    const discountPriceProductClassName = ['ds-text-neutral40 ds-text-weight400 line-throught ']
    const clientCountryClassName = ['ds-text-neutral40 ds-text-weight400']
    const addressClientClassName = ['ds-text-neutral40 ds-text-weight400']
    const numeroInvoiceClassName = ['ds-text-neutral40 ds-text-weight400']
    const discountOptionClassName = ['white-space-nowrap ds-absolute ds-flex ds-center ds-bg-robinred free ds-text-white ds-text-weight900']
    const availabilityClassName = ['white-space-nowrap  ds-absolute ds-flex ds-center ds-bg-primary100 availibility ds-text-white ds-text-weight400']
    if (props.bsPrefix) {
        cardClassName.push(props.bsPrefix)
    } else if (props.type === CardType.shopCard) {
        cardClassName.push('shop-card ds-relative ds-flex-col ds-align-start ds-p-16  ')
        imageClassName.push('ds-mt-8 ds-mb-8')
        addressClientClassName.push('ds-text-neutral70 ds-text-size-14 ds-text-line-16 ')
        descriptionProductClassName.push('text-overflow-card')
    } else if (props.type === CardType.itemCard) {
        cardClassName.push('item-card  ds-flex-col ds-align-start ds-p-8 ds-borad-4')
        descriptionProductClassName.push('text-overflow-card')
    } else if (props.type === CardType.invoiceCard) {
        cardClassName.push('default-invoice-card ds-flex-col ds-align-center ds-borad-8 ds-py-24')
        numeroInvoiceClassName.push('ds-text-neutral70 ds-text-size-16 ds-text-line-19 ')
        nameProductClassName.push('ds-mt-10')
        addressClientClassName.push('text-align-center ds-text-size-12')
        descriptionProductClassName.push('ds-text-neutral70 ds-text-weight700')
        clientCountryClassName.push('ds-text-size-14')

    } else {
        cardClassName.push('default-card  ds-p-8 ds-flex-col ds-align-start  ')
        descriptionProductClassName.push('text-overflow-card')
    }
    if (props.type === CardType.productCard) {
        switch (props.size) {
            case CardSize.large:
                nameProductClassName.push('ds-text-size-24 ds-text-line-28 ');
                descriptionProductClassName.push(' ds-text-size-20 ds-text-line-24 text-overflow-card');
                priceProductClassName.push(' ds-text-size-24 ds-text-line-28  ');
                discountPriceProductClassName.push(' ds-text-size-16 ds-text-line-19 ');
                discountOptionClassName.push('ds-py-18 ds-px-8 ds-text-size-20 ds-text-line-24');
                availabilityClassName.push('ds-py-18 ds-px-8 ds-text-size-20 ds-text-line-24');
                cardClassName.push('type1')
                break;
            case CardSize.medium:
                nameProductClassName.push('ds-text-size-20 ds-text-line-24 ');
                descriptionProductClassName.push(' ds-text-size-16 ds-text-line-19 text-overflow-card');
                priceProductClassName.push(' ds-text-size-20 ds-text-line-24  ');
                discountPriceProductClassName.push(' ds-text-size-16 ds-text-line-19 ');
                discountOptionClassName.push('ds-p-8 ds-text-size-14 ds-text-line-19');
                availabilityClassName.push('ds-p-8 ds-text-size-14 ds-text-line-19');
                cardClassName.push('type2')
                break;
            case CardSize.small:
                nameProductClassName.push('ds-text-size-16 ds-text-line-19 ds-pt-12 ds-pb-4');
                descriptionProductClassName.push(' ds-text-size-14 ds-text-line-16 text-overflow-card');
                priceProductClassName.push(' ds-text-size-16 ds-text-line-19  ');
                discountPriceProductClassName.push(' ds-text-size-14 ds-text-line-16 ');
                discountOptionClassName.push(' ds-p-5 ds-text-size-12 ds-text-line-17');
                availabilityClassName.push(' ds-p-8 ds-text-size-12 ds-text-line-17');
                cardClassName.push('type3')
                break;

            default:
                break;
        }
    }

    if (props.cardClassName) cardClassName.push(props.cardClassName)
    if (props.nameProductClassName) nameProductClassName.push(props.nameProductClassName)
    if (props.descriptionProductClassName) descriptionProductClassName.push(props.descriptionProductClassName)
    if (props.priceProductClassName) priceProductClassName.push(props.priceProductClassName)
    if (props.clientCountryClassName) clientCountryClassName.push(props.clientCountryClassName)
    if (props.discountPriceProductClassName) discountPriceProductClassName.push(props.discountPriceProductClassName)
    if (props.addressClientClassName) addressClientClassName.push(props.addressClientClassName)
    if (props.numeroInvoiceClassName) numeroInvoiceClassName.push(props.numeroInvoiceClassName)
    if (props.imageClassName) imageClassName.push(props.imageClassName)
    if (props.nameClientClassName) nameClientClassName.push(props.nameClientClassName)
    if (props.nbreItemClassName) nbreItemClassName.push(props.nbreItemClassName)
    if (props.availabilityClassName) availabilityClassName.push(props.availabilityClassName)


    const renderImage = () => {
        if (props.type === CardType.shopCard) {
            return <div
                className='ds-w-100 ds-hp-147'
                style={{ backgroundImage: `url(${props.image ? props.image : empty_img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            </div>
        } else if (props.type === CardType.itemCard) {
            return (<img src={props.image ? props.image : empty_img} className={props.imageClassName} />)
        } else if (props.type === CardType.invoiceCard) {
            return (<img src={props.image ? props.image : empty_img} height={60} width={60} className={props.imageClassName} />)
        } else {
            return (
                <div className='ds-w-100 ds-hp-147 product-img' style={{ backgroundImage: `url(${props.image ? props.image : empty_img})`, backgroundSize: '100% 100%', backgroundPosition: 'center',backgroundRepeat: 'no-repeat' }}>

                </div>
            )
        }
    }
    const renderCard = () => {
        const list = []
        if (props.type === CardType.productCard) {
            list.push(
                <div className='ds-flex-col ds-w-100 ds-flex-grow1 ds-justify-between'>
                    <div className='ds-flex-col ds-w-100 ds-flex-grow1'>
                        {renderImage()}
                        {props.availability && <div className={availabilityClassName.join('')}>
                            {props.availability}
                        </div>}
                        {props.discountOption && <div className={discountOptionClassName.join('')}>
                            {props.discountOption}
                        </div>}
                        <span className={nameProductClassName.join(' ')}>
                            {props.nameProduct}
                        </span>
                        <span className={descriptionProductClassName.join(' ')}>
                            {props.descriptionProduct}
                        </span>
                    </div>
                    <div className='ds-w-100 ds-flex-col ds-align-end ds-justify-end  product-price'>
                        {props.discountPriceProduct !== null &&
                            // <>
                            //     <div className='dot ds-mr-4'>
                            //     </div>
                            <span className={discountPriceProductClassName.join(' ')}>
                                {props.discountPriceProduct}
                            </span>
                            // </>
                        }
                        {<span className={priceProductClassName.join(' ')}>
                            {props.priceProduct}
                        </span>}
                    </div>
                </div>
            )
        } else if (props.type === CardType.shopCard) {
            list.push(
                <div className='ds-relative ds-flex-col ds-justify-between ds-w-100'>
                    <span className={nameClientClassName.join(' ') + ' overflow-elipsis ds-hp-18'}>{props.nameClient}</span>
                    {renderImage()}
                    <div className='ds-flex ds-w-100 ds-justify-between ds-mt-8 '>
                        <span className={addressClientClassName.join(' ') + " overflow-elipsis width-addresse"}>
                            {props.addressClient}
                        </span>
                        <span className={`ds-text-neutral100 text-nowrap ds-text-size-14 ds-text-line-16 ds-text-weight400 ds-wp-77 ${props.distanceClassName}`}>
                            {props.distance}
                        </span>
                    </div>
                </div>
            )
        } else if (props.type === CardType.itemCard) {
            list.push(
                <>
                    {<div className='ds-relative'>
                        {renderImage()}
                        <Checkbox
                            checked={props.isChecked}
                            onClick={props.onClickCardBtn}
                            onChange={props.onChange}
                            containerClassName='border-secondary30'
                            disabled={props?.disabled}
                        />
                    </div>
                    }
                    <span className={nameProductClassName.join(' ')}>{props.nameProduct}</span>
                </>
            )
        } else if (props.type === CardType.invoiceCard) {
            list.push(
                <>
                    {renderImage()}
                    <span className={nameProductClassName.join(' ')}>{props.nameClient}
                        <span className={clientCountryClassName.join(' ')}>{props.clientCountry}</span>
                    </span>
                    <span className={addressClientClassName.join(' ')}>{props.addressClient}</span>
                    <div className='ds-bg-secondary10 ds-hp-32 ds-w-100 ds-mt-24 ds-mb-8 ds-flex ds-center'>
                        <span className={descriptionProductClassName.join(' ')}>{langue.invoice_history + ' '}
                            <span className={numeroInvoiceClassName.join(' ')}>{props.numeroInvoice}</span>
                        </span>
                    </div>
                    <span className={clientCountryClassName.join(' ')}>{props.dateInvoice}</span>
                    <span className={nbreItemClassName.join(' ')}>{`${props.nbreItem + ' ' + langue.articles}`}</span>
                </>
            )
        }
        return <>{list}</>
    }
    return (
        <div className={cardClassName.join(' ')} onClick={props.onClick}>
            {renderCard()}
            {props.type === CardType.invoiceCard && <div className={'hover-btn  ds-absolute  ds-flex ds-center'}>
                <Button
                    icon={props.type === CardType.invoiceCard ? 'eye' : ''}
                    type={props.type === CardType.invoiceCard ? Type.secondary : Type.primary}
                    text={props.btnText}
                    onClick={props.onClickCardBtn}
                />
            </div>}
            {props.type === CardType.shopCard && <div className={'hover-btn  ds-absolute align-self-center ds-flex ds-center'}>
                <Button
                    text={props.btnText}
                    onClick={props.onClickCardBtn}
                />
            </div>}
        </div>
    )


}
OrganismCard.defaultProps = {
    ...DEFAULTCARDPROPS
}
export default OrganismCard