import React, { Ref, forwardRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { CommercantAPI } from "../../Api/Commercants/Commercants"
import TemplateModalGeneralCondition from "../../Template/TemplateModalGeneralCondition/TemplateModalGeneralCondition"
import { RootState } from "../../Interfaces"
import { Modal, ModalRefType } from '../../DesignSystem'
import { IModalGeneralCondition } from '../../Interfaces/Modal'
import { openModal } from '../../Redux/Reducers'

const ModalGeneralCondition = (props: IModalGeneralCondition, ref: Ref<ModalRefType | undefined>): JSX.Element => {
    const commercantApi = new CommercantAPI()
    const dispatch = useDispatch()
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    const { lang } = useSelector((state: RootState) => state?.setting)
    const { data } = useSelector((state: RootState) => state?.modal)
    const [modalContent, setModalContent] = useState<string | TrustedHTML>('')
    const getGeneralCondition = async () => {
        if (commercant?.suid) {
            const response = await commercantApi.getGeneraleCondition({ suid: commercant?.suid, lang: lang })
            setModalContent(response?.data.replace('body { zoom: 0.75; }', ''))
        }
    }
    React.useEffect(() => {
        getGeneralCondition()
    }, [])

    const onClose = () => {
        props.onExit && props.onExit();
        data?.fromSupscription && dispatch(openModal({ name: 'modalInscriptionFinalisationRef' }));
    }

    return (
        <Modal
            contentClassName="ds-pointer ds-borad-14 modal-general-conditions ds-overflow-x ds-flex"
            withCloseIcon={true}
            ref={ref}
            onExit={onClose}
            onShow={props.onShow}
        >

            <TemplateModalGeneralCondition
                modalContent={modalContent}
            />
        </Modal>
    )

}
export default forwardRef(ModalGeneralCondition)