import { CommercantAPI } from "../../Api/Commercants/Commercants"
import { EListFunction, IUseFormResult, useForm } from "../../CustomHooks"
import { TemplateDevenirCommercant } from "../../Template"

const PageDevenirCommercant = () => {
    const {
        state: form,
        onChange,
        onUpdateState
    }: IUseFormResult | any = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "nom",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "prenom",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "phone",
                value: "",
                rules: [
                    {
                        function: EListFunction.IsEightCharLength,
                        messageError: "",
                        priority: 1
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "email",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    },
                    {
                        function: EListFunction.isMail,
                        messageError: "",
                        priority: 1
                    },
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "secteur",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "message",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
        ]
    })
    const onUpdateData = () => {
        onUpdateState({
            ...form,
            nom: {
                value: "",
                isValid: false,
                isInvalid: false,

            },
            prenom: {
                value: "",
                isValid: false,
                isInvalid: false,
            }
            ,
            phone: {
                value: "",
                isValid: false,
                isInvalid: false,
            }
            ,
            email: {
                value: "",
                isValid: false,
                isInvalid: false,
            },
            secteur: {
                value: "",
                isValid: false,
                isInvalid: false,
            },
            message: {
                value: "",
                isValid: false,
                isInvalid: false,
            }
        })
    }
    const commercantApi = new CommercantAPI()
    const addCommercant = async () => {
        if (form?.nom?.value !== '' && form?.prenom?.value !== '' && form?.phone?.value !== '' && form?.email?.value !== '' && form?.secteur?.value !== '' && form?.message?.value !== '') {
            const response = await commercantApi.addCommercant(
                {
                    name: form?.nom?.value,
                    lastName: form?.prenom?.value,
                    phone: form?.phone?.value,
                    Email: form?.email?.value,
                    activity: form?.secteur?.value,
                    message: form?.message?.value

                }
            )
            if (response.status === 200) {
                onUpdateData();
            }
        }
        else if (form.nom.value === '') {
            onUpdateState({
                ...form,
                nom: {
                    ...form.nom,
                    isValid: false,
                    isInvalid: true,
                }
            })
        }
        else if (form.prenom.value === '') {
            onUpdateState({
                ...form,
                prenom: {
                    ...form.prenom,
                    isValid: false,
                    isInvalid: true,
                }
            })
        }
        else if (form.phone.value === '') {
            onUpdateState({
                ...form,
                phone: {
                    ...form.phone,
                    isValid: false,
                    isInvalid: true,
                }
            })
        }
        else if (form.email.value === '') {
            onUpdateState({
                ...form,
                email: {
                    ...form.email,
                    isValid: false,
                    isInvalid: true,
                }
            })
        }
        else if (form.secteur.value === '') {
            onUpdateState({
                ...form,
                secteur: {
                    ...form.secteur,
                    isValid: false,
                    isInvalid: true,
                }
            })
        }
        else if (form.message.value === '') {
            onUpdateState({
                ...form,
                message: {
                    ...form.message,
                    isValid: false,
                    isInvalid: true,
                }
            })
        }
    }
    return (
        <TemplateDevenirCommercant
            form={form}
            onChange={onChange}
            addCommercant={addCommercant}
            onUpdateData={onUpdateData}
            onUpdateState={onUpdateState}
        />
    )
}
export default PageDevenirCommercant 