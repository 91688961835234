import React from 'react'
import { Icon } from '..';
import { DEFAULTPAGINATIONPROPS } from '../../DefaultProps/Atoms';
import { IAtomPagination, IRenderItem } from '../../Interfaces/Atoms/IAtomPagination/IAtomPagination';

const AtomPagination = (props: IAtomPagination) => {
    const containerClassName = ['ds-flex', 'ds-align-center'];
    if (props.containerClassName) {
        containerClassName.push(props.containerClassName);
    }
    const renderItem = ({ withIcon, data, onClick, hover = true, disabled }: IRenderItem) => {
        const itemClassName = ['ds-px-7 ds-py-3 ds-text-neutral900 ds-text-size-12'];
        if (hover) {
            itemClassName.push('ds-pointer');
        }
        if (props.page === data) {
            itemClassName.push('ds-text-primary ds-bg-primary50 ds-border-radius-8 ');
        }
        if (props.iconNavigation && disabled) {
            itemClassName.push('ds-disabled');
        }
        return (
            <div key={data} className={itemClassName.join(' ')} onClick={onClick}>
                {withIcon ? <Icon color='#3F5165' icon={data as string} size='12px' /> : data}
            </div>
        );
    }

    function renderItems() {
        const numberOfBlock = Math.ceil(props.numberOfPage / props.maxElementInPagination);
        const pageBlock = Math.ceil(props.page / props.maxElementInPagination);
        const data = [];
        if (pageBlock > 1) {
            data.push(renderItem({ withIcon: false, data: 1, onClick: () => props.onClick && props.onClick(1) }));
            data.push(renderItem({ withIcon: false, data: '...', onClick: () => { }, disabled: false }));
        }

        let index = 0;
        for (
            index = (pageBlock - 1) * props.maxElementInPagination;
            index < (pageBlock - 1) * props.maxElementInPagination + props.maxElementInPagination;
            index++
        ) {
            if (index >= 0 && index < props.numberOfPage) {
                const value = index + 1;
                data.push(renderItem({ withIcon: false, data: value, onClick: () => props.onClick && props.onClick(value) }));
            }
        }
        if (pageBlock < numberOfBlock) {
            data.push(renderItem({ withIcon: false, data: '...', onClick: () => { }, disabled: false }));
            data.push(
                renderItem({
                    withIcon: false,
                    data: props.numberOfPage,
                    onClick: () => props.onClick && props.onClick(props.numberOfPage)
                })
            );
        }
        return data;
    }
    return (
        <div className={containerClassName.join(' ')}>
            {renderItem(
                {
                    withIcon: props.iconNavigation,
                    data: props.iconNavigation ? 'chevron-left1' : 'prev',
                    onClick: () => props.onClick && props.page > 1 && props.onClick(props.page - 1),
                    disabled: props.page == 1
                }
            )}
            {renderItems()}
            {renderItem(
                {
                    withIcon: props.iconNavigation,
                    data: props.iconNavigation ? 'chevron-right1' : 'next',
                    onClick: () => props.onClick && props.page < props.numberOfPage && props.onClick(props.page + 1),
                    disabled: props.page == props.numberOfPage
                }
            )}
        </div>
    )
}

AtomPagination.defaultProps = {
    ...DEFAULTPAGINATIONPROPS
};

export default AtomPagination