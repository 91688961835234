import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../Interfaces"
import { TemplateCompte } from "../../Template"
import { useContext, useState, useRef } from "react"
import { EListFunction, IUseFormResult } from "../../CustomHooks/interfaces"
import { useForm } from "../../CustomHooks/hooks/useForm/useForm"
import moment from "moment"
import { UserAPI } from "../../Api/User/user"
import { LangContext } from "../../Lang"
import { openModal, resetToken, setCustomerMember, setLang, setUserRegistration } from "../../Redux/Reducers"
import { useNavigate } from "react-router-dom"
import { useUpdateEffect } from "../../CustomHooks"

const PageCompte = (): JSX.Element => {
    const codeValue = useRef('')
    const callCodeApi = useRef(false)
    const codeRef = useRef<HTMLElement | null | undefined>() as React.MutableRefObject<HTMLInputElement>;
    const [typingInterval, setTypingInterval] = useState<any>(null);
    const { user } = useSelector((state: RootState) => state?.auth)
    const [showEditField, setShowEditField] = useState<boolean>(false)
    const [showInputCodePromo, setShowInputCodePromo] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<number>(0)
    const { commercant, customerMember } = useSelector((state: RootState) => state.commercant)
    const { width } = useSelector((state: RootState) => state?.screen)
    const { lang } = useSelector((state: RootState) => state.setting)
    const langue = useContext(LangContext)
    const navigate = useNavigate()
    const userApi = new UserAPI()
    const dispatch = useDispatch()
    const { state: form,
        onChange, onUpdateState, isFormValid
    }: IUseFormResult = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "company",
                value: user?.company ? user?.company : "",
            },
            {
                key: "username",
                value: user?.username ? user?.username : "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.email_invalid,
                    priority: 1,

                },
                {
                    function: EListFunction.isMail,
                    messageError: langue.email_invalid,
                    priority: 2,
                }],

            },

            {
                key: "phone",
                value: user?.phone ? `${"+"}${user?.phone}` : "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.phone_required,
                    priority: 1,
                },
                {
                    function: EListFunction.isPhoneNumber,
                    messageError: langue.phone_invalid,
                    priority: 2,
                },
                ],
            },
            {
                key: "lastname",
                value: user?.lastname ? user?.lastname : "",
                isRealTimeValidation: true,
                rules: [{

                    function: EListFunction.isNotEmpty,
                    messageError: langue.lastname_required,
                    priority: 1,
                }
                ],
            },
            {
                key: "firstname",
                value: user?.firstname ? user?.firstname : "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.firstname_required,
                    priority: 1,
                }
                ],
            },
            {
                key: "dob",
                value: user?.dob ? moment(user?.dob).format('DD/MM/YYYY') : "",
                isRealTimeValidation: true,
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: langue.dob_invalid,
                        priority: 1,
                    },
                    {
                        function: EListFunction.isDate,
                        messageError: langue.dob_invalid,
                        priority: 2,
                    }
                ],
            },

            {
                key: "adrFact",
                value: user?.address ? user?.address : "",

            },
            {
                key: "delivery_address",
                value: user?.delivery_address ? user?.delivery_address : "",

            },
            {
                key: "partnership",
                value: "",

            },
        ]
    })
    const changeLang = (l: string[]) => {
        dispatch(setLang(l));

    }
    const onOpenModal = () => {
        dispatch(openModal({ name: "modalDeleteCompteRef" }))
    }
    const logout = () => {
        dispatch(resetToken());
    }

   
    const onUpdateData = () => {
        onUpdateState({
            ...form,
            company: {
                value: user?.company ? user?.company : "",

            },
            username: {
                value: user?.username ? user?.username : "",

            }
            ,
            phone: {
                value: user?.phone ? `${"+"}${user?.phone}` : "",

            }
            ,
            lastname: {
                value: user?.lastname ? user?.lastname : "",

            },
            firstname: {
                value: user?.firstname ? user?.firstname : "",

            },
            dob: {
                value: user?.dob ? moment(user?.dob).format('DD/MM/YYYY') : "",

            },
            adrFact: {
                value: user?.address ? user?.address : "",

            },
            delivery_address: {
                value: user?.delivery_address ? user?.delivery_address : "",

            },

        })
    }
    const onChangeProfile = async () => {
        const response = await userApi.putCustomerProfile(
            { suid: commercant?.suid, lang: lang },
            {
                user:
                {
                    address: String(form?.adrFact?.value),
                    city: user?.city,
                    company: form?.company?.value,
                    countryCode: user?.country_code,
                    delivery_address: form?.delivery_address.value,
                    delivery_city: user?.delivery_city,
                    delivery_postcode: user?.delivery_postcode,
                    dob: moment(String(form?.dob?.value)).format('YYYY-DD-MM'),
                    firstname: form?.firstname?.value,
                    lastname: form?.lastname?.value,
                    membershipCard: user?.membership_card,
                    optin: user?.optin,
                    optout: user?.optout,
                    phone: String(form?.phone?.value).substring(1),
                    postcode: user?.postcode,
                    student: user?.student
                }
            }
        )
        if (response.status === 200) {
            dispatch(setUserRegistration({ user: response?.data }));
            onUpdateData();
        }
    }


    const addCodePromo = async (code: string) => {
        callCodeApi.current = true
        const response = await userApi.addCodePromo(
            { suid: commercant?.suid, lang: lang },
            { partnership: { code: code } }
        )
        if (typingInterval) {
            clearInterval(typingInterval);
            setTypingInterval(null)
        }
        if (response.status === 204) {
            onUpdateState({
                ...form,
                partnership: {
                    ...form.partnership,
                    value: code,
                    errorMessage: langue.partnershipCode_invalid,
                    isInvalid: true
                }
            })

        }
        if (response.status === 200) {
            if (response.data !== '"same code"') { dispatch(setCustomerMember({ customerMember: JSON.parse(response.data) })) }
            onUpdateState({
                ...form,
                partnership: {
                    ...form.partnership,
                    value: "",
                    errorMessage: "",
                    isInvalid: false,
                    isValid: true
                }
            })
            setShowInputCodePromo(false);
        }
    }
    const onScroll = (scrolltop: number) => {
        if (scrolltop >= 0 && scrolltop <=358) {
            setSelectedItem(0)
        }
        else if (scrolltop >= 359 && scrolltop <= 593) {
            setSelectedItem(1)
        }
        else if (scrolltop >= 594 && scrolltop <= 753)  {
            setSelectedItem(2)
        }
        else if (scrolltop >=754){
            setSelectedItem(3)
 
        }
    }
    const onChangeCode = (value: string) => {

        onChange({ key: "partnership", value: value })
        codeValue.current = value
        callCodeApi.current = false
        if (typingInterval) {
            clearInterval(typingInterval);
            setTypingInterval(null)
        }
        const newInterval = setInterval(() => {
            if (callCodeApi.current === false) {
                addCodePromo(codeValue.current)
                clearInterval(newInterval)
            }
        }, 2000)
        setTypingInterval(newInterval);
    }

    useUpdateEffect(() => {
        return () => {
            if (typingInterval) {
                clearInterval(typingInterval);
            }
        };
    }, [typingInterval]);
    return (
        <TemplateCompte
            onChangeCode={onChangeCode}
            form={form}
            onChange={onChange}
            user={user}
            setShowEditField={setShowEditField}
            showEditField={showEditField}
            onChangeProfile={onChangeProfile}
            onUpdateData={onUpdateData}
            isFormValid={isFormValid}
            logout={logout}
            setShowInputCodePromo={setShowInputCodePromo}
            showInputCodePromo={showInputCodePromo}
            customerMember={customerMember}
            langue={langue}
            codeRef={codeRef}
            width={width}
            lang={lang}
            changeLang={changeLang}
            onOpenModal={onOpenModal}
            onScroll={onScroll}
            selectedItem={selectedItem}
           
        />
    )
}
export default PageCompte