import { CardSize, CardType, IOrganismBasket, IOrganismCard } from '../../Interfaces';
import { IOrganismSidebar, SidebarType } from '../../Interfaces/Organisms/IOrganismSidebar/IOrganismSidebar';
import { IOrganismStepper } from '../../Interfaces/Organisms/IOrganismStepper/IOrganismStepper';

const DEFAULTSIDEBARPROPS: IOrganismSidebar = {
  isMaxSize:true,
  type: SidebarType.type1,
  logo: null,
  avatar: 'http://i.stack.imgur.com/Dj7eP.jpg',
  userName: "Jane Cooper",
  user: {
    firstname: 'Jane',
    lastname: 'Cooper',
    email: 'Mon Compte',
  },
  selectedItem: 0,
  footerButton: 'Se déconnecter',
  footerIcon: 'logout',
  footerIconClassName: 'ds-text-neutral70 ds-mb-3',
  isRightBar: true,
  classNameHeader: "",
  containerClassName: "",
  items: [
    {
      "icon": "home-21",
      "label": "Snack BLA",

    },
    {
      "icon": "document2",
      "label": "Mes commerçants",

    },
    {
      "icon": "receipt-item",
      "label": "Mes commandes",

    },
    {
      "icon": "empty-wallet1",
      "label": "Mes recharges",

    },
    {
      "icon": "sms1",
      "label": "Contactez-nous",
    },
    {
      "icon": "shop1",
      "label": "Devenir commerçant",

    },
    {
      "icon": "location1",
      "label": "Autour de moi",
    },

  ],
  ItemsType2: [
    {
      "label": "Informations personnelles",

    },
    {
      "label": "Coordonnées",

    },
    {
      "label": "Réduction entreprise",

    },
    {
      "label": "Moyens de paiement",

    }]
  

}
const DEFAULTCARDPROPS: IOrganismCard = {
  nameProduct: "Lorem ipsum dolor sit amet",
  descriptionProduct: "Hendrerit nulla nisl ipsum egestas quam neque pretium duis.",
  priceProduct: "9,50 €",
  discountPriceProduct: "10,20 €",
  discountOption: "2+1 gratuit",
  nameClient: "Snack BLA",
  addressClient: "Restaurant rapide, waterloo",
  distance: "4,23 km",
  size: CardSize.medium,
  distanceClassName:''
}
const DEFAULTSTEPPERPROPS: IOrganismStepper = {
  selectedItem:0,
}
const DEFAULTBASKETPROPS: IOrganismBasket = {
  footerItem: [
    {
      title: "Livraison",
      deliveryAddress: "Chau. de Bruxelles 1190 Bruxelles, Belgique",
      deliveryDate: "Aujourd’hui 1 Avril - 13h00",
      textBtn: 'Modifier'
    },
    {
      title: "Remarques",
      iconItem: "edit",
      description: "Insérer vos remarques en cliquant sur le bouton “Ajouter” ",
      textBtn: 'Ajouter'
    },
    {
      title: "Coupon de réduction",
      iconItem: "tag1",
      description: "Saisir votre code promo en cliquant sur le bouton “Valider” ",
      textBtn: 'Valider'
    },
  ],
  RechargeItem:[
    {label:"5,00", value:"5.00", checked:false},
    {label:"10,00", value:"10.00", checked:false},
    {label:"15,00", value:"15.00", checked:false},
    {label:"20,00", value:"20.00", checked:false},
    {label:"25,00", value:"25.00", checked:false},
    {label:"30,00", value:"30.00", checked:false},
    {label:"35,00", value:"35.00", checked:false},
    {label:"40,00", value:"40.00", checked:false},
    {label:"45,00", value:"45.00", checked:false},
    {label:"50,00", value:"50.00", checked:false},
    {label:"60,00", value:"60.00", checked:false},
    {label:"70,00", value:"70.00", checked:false},
    {label:"80,00", value:"80.00", checked:false},
    {label:"90,00", value:"90.00", checked:false},
    {label:"100,00", value:"100.00", checked:false}
  ],
}
export {
  DEFAULTSIDEBARPROPS,
  DEFAULTCARDPROPS,
  DEFAULTBASKETPROPS,
  DEFAULTSTEPPERPROPS
}