export enum Type {
    switch = "switch",
    checkbox = "checkbox",
}

interface IAtomCheckbox {
    disabled?: boolean | undefined,
    className?: string | undefined,
    checked?: boolean | undefined,
    labelClassName?: string | undefined,
    label?: string | undefined,
    type?: Type | undefined,
    bsPrefix?: string | undefined
    labelBsPrefix?: string,
    onClick?: React.MouseEventHandler<HTMLInputElement> | undefined,
    isValid?: boolean,
    isInvalid?: boolean,
    containerClassName?: string,
    isSwitch?:boolean,
    deliveryTime?:string,
    takeawayTime?:string,
    onChange?:React.ChangeEventHandler<HTMLInputElement> | undefined,
    labelBefore?:string,
    labelAfter?:string,
    afterLabelClassName?:string,
    beforeLabelClassName?:string,
}

export type {
    IAtomCheckbox
}