import { Button, Checkbox, Input } from "../../DesignSystem";
import { Size, Type } from "../../DesignSystem/Interfaces";
import { ITemplateModalDeleteCompte } from "../../Interfaces";

const templateModalDeleteCompte = (props: ITemplateModalDeleteCompte): JSX.Element => {
    return (<div className="ds-p-40 ds-flex-col ds-center">
        <span className="ds-mb-48 ds-text-line-34 ds-text-size-28 ds-text-weight700"
        >{props.langue.delete_compte}</span>
        <span className="ds-text-line-19 ds-text-size-16 ds-text-weight400"
        >{props.langue.my_total_credit}</span>
        <span className="ds-mt-28 ds-mb-48 ds-text-line-34 ds-text-size-28 ds-text-weight700 ds-text-primary50"
        >{props.myCredit} € </span>
        <div className="ds-w-100 ds-pl-24 ds-flex ds-align-center">
            <Checkbox
                className="ds-mr-8"
                checked={props.isChecked}
                onClick={() => props.setIsChecked(!props.isChecked)}


            />
            <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400">
                {props.langue.delete_profile}</span>

        </div>
        <span className="ds-mt-12 ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-primary50 text-align-center">
            {props.langue.delete_profile_description}
        </span>

        <div className="ds-w-100 ds-flex">


            <Button
                type={Type.tertiary}
                size={Size.large}
                className="primary-10-btn ds-w-100 ds-mt-16 ds-mr-16"
                text={props.langue.cancel}
                onClick={() => {
                    props.onClose();
                }}
            />
            <Button
                size={Size.large}
                className="primary-20-btn ds-w-100 ds-mt-16"
                text={props.langue.validate}
                disabled={!props.isChecked}
                onClick={() => { props.deleteUser(); }}
            />
        </div>
    </div>)
}
export default templateModalDeleteCompte
