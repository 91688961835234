import { useDispatch } from "react-redux";
import { Button, Checkbox, Input } from "../../DesignSystem";
import { Type } from "../../DesignSystem/Interfaces";
import { ITemplateDetailsProduct } from "../../Interfaces";
import { setUnityProduct } from "../../Redux/Reducers";
import { CommonFunction } from "../../Common";
import { useContext } from "react";
import { LangContext } from "../../Lang";

const TemplateDetailsProduct = (props: ITemplateDetailsProduct): JSX.Element => {
    const priceProductClassName = ['modal-compose-price-text ds-text-primary100 ds-mr-10']
    const discountPriceProductClassName = ['modal-compose-discount-price-text ds-text-neutral40 line-throught']
    const productNameClassName = ['modal-compose-title-text ds-text-primary100 text-overflow-hidden name-class ds-mt-10 ds-mb-6']
    const productDescriptionClassName = ['modal-compose-description-text description-mt-8 ds-text-primary70 text-overflow-hidden']
    const className = ['ds-flex-col ds-flex-grow1 ']
    const inputClassName = ["quatity-input product-quantity height-48"]
    const dispatch = useDispatch()
    const langue = useContext(LangContext);
    if (props?.unit == 'kilo') {
        inputClassName.push('input-kg')
    }
    if (props?.unit == '') {
        inputClassName.push('')
    }
    const renderProductPrice = () => {
        if (!props.unity && props?.data.product[0]?.unity?.id === 2) {
            if (props?.quantity > 1) {
                if (props?.myProduct?.attribute?.promo !== null) {
                    return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.promo) / 1000) * props?.quantity);
                } else {
                    return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.price) / 1000) * props?.quantity);
                }
            } else {
                if (props?.myProduct?.attribute?.promo !== null) {
                    return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.promo) / 1000) * props?.quantity);
                } else {
                    return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.price) / 1000) * props?.quantity);
                }
            }
        } else {
            if (props?.quantity > 1) {
                if (props?.quantity > 1) {
                    if (props?.myProduct?.attribute?.promo !== null) {
                        return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.promo)) * props?.quantity);
                    } else {
                        return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.price)) * props?.quantity);
                    }
                } else {
                    if (props?.myProduct?.attribute?.promo !== null) {
                        return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.promo)) * props?.quantity);
                    } else {
                        return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.price)) * props?.quantity);
                    }
                }
            } else {
                if (props?.myProduct?.attribute?.promo !== null) {
                    return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.promo)) * props?.quantity);
                } else {
                    return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.price)) * props?.quantity);
                }
            }
        }
    }
    const renderModifyPrice = () => {
        if (!props.unity && props?.data.item.product?.unity?.id === 2) {
            if (props?.iteeee?.quantity >= 0.05) {
                if (props?.myProduct?.attribute?.promo !== null) {
                    return CommonFunction.setFormatNumber(props?.iteeee?.product?.attribute?.promo * props?.iteeee?.quantity);
                } else {
                    return CommonFunction.setFormatNumber(props?.iteeee?.product?.attribute?.price * props?.iteeee?.quantity);
                }
            } else {
                if (props?.myProduct?.attribute?.promo !== null) {
                    return CommonFunction.setFormatNumber(props?.iteeee?.product?.attribute?.promo);
                } else {
                    return CommonFunction.setFormatNumber(props?.iteeee?.product?.attribute?.price);
                }
            }
        } else {
            if (props?.iteeee?.quantity > 1) {
                if (props?.myProduct?.attribute?.promo !== null) {
                    return CommonFunction.setFormatNumber(props?.iteeee?.product?.attribute?.promo * props?.iteeee?.quantity);
                } else {
                    return CommonFunction.setFormatNumber(props?.iteeee?.product?.attribute?.price * props?.iteeee?.quantity);
                }
            } else {
                if (props?.myProduct?.attribute?.promo !== null) {
                    return CommonFunction.setFormatNumber(props?.iteeee?.product?.attribute?.promo);
                } else {
                    return CommonFunction.setFormatNumber(props?.iteeee?.product?.attribute?.price);
                }
            }
        }
    }
    const renderDetailsProductModal = () => {
        const list = []
        if (props?.data?.product !== null && props?.data?.item === null) {
            list.push(
                <div className="details-product ">
                    <div className="image-product" style={{
                        backgroundImage: `url(${props?.data.product[0]?.media?.small_picture.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center'
                    }}>
                    </div>
                    <div className={className.join(' ')}>
                        <div className="ds-flex-col ds-flex-grow1 ">
                            <span className={productNameClassName.join(' ')}>{props?.data.product[0]?.name}</span>
                            <div className='ds-flex ds-align-center'>
                                <span className={priceProductClassName.join(' ')}>
                                    {props?.data.product[0]?.attribute?.promo !== null ? `${CommonFunction.setFormatNumber(props?.data.product[0]?.attribute?.promo)
                                        } €` : `${CommonFunction.setFormatNumber(props?.data.product[0]?.attribute?.price)
                                        } €`}
                                    {props?.data.product[0]?.weight && <span>{'/kg'}</span>}
                                </span>
                                {props?.data.product[0]?.attribute?.promo !== null &&
                                    <>
                                        <div className='dot ds-mr-4'>
                                        </div>
                                        <span className={discountPriceProductClassName.join(' ')}>
                                            {`${CommonFunction.setFormatNumber(props?.data.product[0]?.attribute?.price)} €`}
                                        </span>
                                    </>
                                }
                            </div>
                            <span className={productDescriptionClassName.join(' ')}>{props?.data.product[0]?.description}</span>
                            {props?.data.product[0]?.unity?.id === 2 ? <>
                                <span className="nbr-pt-24 modal-compose-subtitle-text ds-mb-10">{langue.weight + ' :'}</span>
                                <div className="ds-flex ds-align-start ds-w-100 gap-7">
                                    <>
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Button
                                                type={Type.tertiary}
                                                icon="minus"
                                                className="ds-text-primary40 quantity-btn"
                                                onClick={(e: any) => { e.stopPropagation(); props?.incrementOrDecrementQunatity(true) }}
                                            />
                                        </div>
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Input
                                                className={inputClassName.join(' ')}
                                                containerClassName={"quatity-input product-quantity ds-ml-10 ds-mr-10"}
                                                value={props?.quantity}
                                                disabled={true}
                                                onClick={(e: any) => { e.stopPropagation() }}
                                            />
                                        </div>
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Button
                                                type={Type.tertiary}
                                                icon="add"
                                                className="ds-text-primary40 quantity-btn ds-mr-50"
                                                onClick={(e: any) => { e.stopPropagation(); props?.incrementOrDecrementQunatity(false) }}
                                            />
                                        </div>
                                    </>
                                    <Checkbox
                                        isSwitch
                                        checked={props?.unity}
                                        onChange={() => { props?.setUnity(!props?.unity); dispatch(setUnityProduct({ unit: !props?.unity ? 'kilo' : 'Gramme' })) }}
                                    />
                                </div>
                            </>
                                :
                                <>
                                    <span className="nbr-pt-24 modal-compose-subtitle-text ds-mb-10">{langue.no_of_pieces}</span>
                                    <div className="ds-flex ds-align-start ds-w-100 gap-100 ">
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Button
                                                type={Type.tertiary}
                                                icon="minus"
                                                className="ds-text-primary40 quantity-btn "
                                                onClick={(e: any) => { e.stopPropagation(); props?.incrementOrDecrementQunatity(true) }}
                                            />
                                        </div>
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Input
                                                value={props?.quantity}
                                                className={inputClassName.join(' ')}
                                                containerClassName="quatity-input  product-quantity "
                                                disabled={true}
                                                onClick={(e: any) => { e.stopPropagation() }}
                                            />
                                        </div>
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Button
                                                type={Type.tertiary}
                                                icon="add"
                                                className="ds-text-primary40 quantity-btn "
                                                onClick={(e: any) => { e.stopPropagation(); props?.incrementOrDecrementQunatity(false) }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            <div onClick={(e: any) => { e.stopPropagation() }}>
                                <Input
                                    label={langue.comments + " :"}
                                    className="ds-w-100 ds-hp-48 comment-input-mt-6"
                                    containerClassName="comment-mt-24"
                                    labelClassName="modal-compose-subtitle-text"
                                    ref={props?.noteRef}
                                    value={(props?.myProduct?.uniq === props?.itemId) ? props?.commentItem : props?.data?.comment}
                                    onClick={(e: any) => { e.stopPropagation(); props?.onAddComment() }}
                                />
                            </div>
                        </div>
                        <div className="ds-pt-34" onClick={(e: any) => { e.stopPropagation() }}>
                            <Button
                                className="modal-compose-button-text ds-w-100  ds-hp-48"
                                text={langue.add_to_basket + ` - ${renderProductPrice()} €`}
                                onClick={(e: any) => { e.stopPropagation(); props?.onAddItemBasket() }}
                                isLoading={props.isLoading}
                            />
                        </div>
                    </div>
                </div>
            )
        } else {
            list.push(
                <div className="details-product ds-h-100 ds-flex-grow1 ds-flex-col ds-justify-between">
                    <div className="image-product" style={{
                        backgroundImage: `url(${props?.data.item.product?.media?.small_picture.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center'
                    }}>
                    </div>
                    <div className={className.join(' ')}>
                        <div className="ds-flex-col ds-flex-grow1 ">
                            <span className={productNameClassName.join(' ')}>{props?.data.item.product?.name}</span>
                            <div className='ds-flex ds-align-center'>
                                <span className={priceProductClassName.join(' ')}>
                                    {props?.data.item.product?.attribute?.promo !== null ? `${CommonFunction.setFormatNumber(
                                        props?.data.item.product?.attribute?.promo)
                                        } €` : `${CommonFunction.setFormatNumber(
                                            props?.data.item.product?.attribute?.price)
                                        } €`}
                                    {props?.data.item.product?.weight && <span>{'/kg'}</span>}
                                </span>
                                {props?.data.item.product?.attribute?.promo !== null &&
                                    <>
                                        <div className='dot ds-mr-4'>
                                        </div>
                                        <span className={discountPriceProductClassName.join(' ')}>
                                            {`${CommonFunction.setFormatNumber(props?.data.item.product?.attribute?.price)} €`}
                                        </span>
                                    </>
                                }
                            </div>
                            <span className={productDescriptionClassName.join(' ')}>{props?.data.item.product?.description}</span>
                            {props?.data.item.product?.unity?.id === 2 ? <>
                                <span className="modal-compose-subtitle-text ds-mb-10">{langue.weight + ' :'}</span>
                                <div className="ds-flex ds-align-start ds-w-100 gap-7">
                                    <>
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Button
                                                type={Type.tertiary}
                                                icon="minus"
                                                className="ds-text-primary40 quantity-btn"
                                                onClick={(e: any) => { e.stopPropagation(); props?.onSetOrderQuantity(true) }}
                                            />
                                        </div>
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Input
                                                className={inputClassName.join(' ')}
                                                containerClassName="quatity-input product-quantity "
                                                value={props?.iteeee?.quantity}
                                                disabled={true}
                                                onClick={(e: any) => { e.stopPropagation() }}
                                            />
                                        </div>
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Button
                                                type={Type.tertiary}
                                                icon="add"
                                                className="ds-text-primary40 quantity-btn ds-mr-50"
                                                onClick={(e: any) => { e.stopPropagation(); props?.onSetOrderQuantity(false) }}
                                            />
                                        </div>
                                    </>
                                    <Checkbox
                                        isSwitch
                                        checked={props?.unity}
                                        onChange={() => { props?.setUnity(!props?.unity); dispatch(setUnityProduct({ unit: !props?.unity ? 'kilo' : 'Gramme' })) }}
                                    />
                                </div>
                            </>
                                :
                                <>
                                    <span className="nbr-pt-24 modal-compose-subtitle-text ds-mb-10">{langue.no_of_pieces}</span>
                                    <div className="ds-flex ds-align-start ds-w-100 gap-100 ">
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Button
                                                type={Type.tertiary}
                                                icon="minus"
                                                className="ds-text-primary40 quantity-btn"
                                                onClick={(e: any) => { e.stopPropagation(); props?.onSetOrderQuantity(true) }}
                                            />
                                        </div>
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Input
                                                value={props?.iteeee?.quantity}
                                                className={inputClassName.join(' ')}
                                                containerClassName="quatity-input product-quantity ds-ml-10 ds-mr-10"
                                                disabled={true}
                                                onClick={(e: any) => { e.stopPropagation() }}
                                            />
                                        </div>
                                        <div onClick={(e: any) => { e.stopPropagation() }}>
                                            <Button
                                                type={Type.tertiary}
                                                icon="add"
                                                className="ds-text-primary40 quantity-btn"
                                                onClick={(e: any) => { e.stopPropagation(); props?.onSetOrderQuantity(false) }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            <div onClick={(e: any) => { e.stopPropagation() }}>
                                <Input
                                    label={langue.comments + " :"}
                                    className="ds-w-100 ds-hp-48  ds-mb-8  comment-input-mt-6"
                                    labelClassName="modal-compose-subtitle-text"
                                    containerClassName="comment-mt-24"
                                    ref={props?.noteRef}
                                    value={(props?.iteeee?.product?.uniq === props?.itemId && (props?.data?.fromAddNote || props?.data?.fromClose)) ? props?.commentItem : props?.iteeee?.remarque}
                                    onClick={(e: any) => { e.stopPropagation(); props?.onAddComment() }}
                                />
                            </div>
                        </div>
                        <div className="ds-pt-34" onClick={(e: any) => { e.stopPropagation() }}>
                            <Button
                                className="modal-compose-button-text ds-w-100  ds-hp-48"
                                text={langue.modify + ` - ${renderModifyPrice()} €`}
                                onClick={(e: any) => { e.stopPropagation(); props?.onModifyItemBasket(props?.iteeee?.id, props?.iteeee?.quantity) }}
                                isLoading={props.isLoading}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return <>{list}</>
    }
    return (renderDetailsProductModal())
}
export default TemplateDetailsProduct