
import { Outlet } from "react-router-dom";

const ShopPublicRoute = (): JSX.Element => {

    return (
        <div className="ds-flex ds-w-100 ds-h-100" >
            <Outlet />
        </div>
    )
}

export default ShopPublicRoute