import { IAtomIcon } from "../../Atoms/IAtomIcon/IAtomIcon";
import { IMolInputProps } from "../IMoleculeInput/IMoleculeInput";

enum NavbarPosition {
    left = "left",
    right = "right",
    center = "center",
}
interface IIconNavbar extends IAtomIcon{
    isAvatar?: boolean
    img?: string
}
interface IMoleculeNavbar {
    className?: string,
    logoClassName?:string,
    onClickMenu?:Function
    onClickWallet?:Function
    onClickBag?:Function
    numberItems?:string
    searchValue?:string
    location?:string
    onChangeLocation?:React.FormEventHandler<HTMLElement> | undefined
    onChangeSearch?:React.FormEventHandler<HTMLElement> | undefined
    onClickCommercantBtn?:Function
    selectedLang?:string
    onChangeSelect?:Function
}

export type {
    IMoleculeNavbar,
    IIconNavbar
}
export {
    NavbarPosition
}