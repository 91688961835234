import { Elang,  IPageProps } from "../../Interfaces"
import { LangContext, EN, NL, FR } from "../../Lang"
import { useSelector } from "react-redux"
import { RootState } from "../../Interfaces/Redux"
export default function LangProvider(props: IPageProps): JSX.Element {
  const {lang} = useSelector((state: RootState) => state.setting)
  let type = FR
  if (lang[0] === Elang.en) {
    type = EN
  } else if (Elang.nl === lang[0]) {
    type = NL
  }
  return <LangContext.Provider value={type}>{props.children}</LangContext.Provider>
}
