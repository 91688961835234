import { InputAttributes } from "react-number-format"
import { ESizeInput } from "../IAtomInput/IAtomInput"

enum EthousandsGroupStyle {
    thousand = 'thousand',
    lakh = 'lakh',
    wan = 'wan',
    none = 'none'
}

enum EDisplayType {
    input = 'input',
    text = 'text'
}

interface IAtomNumber {
    thousandsGroupStyle?: EthousandsGroupStyle,
    decimalSeparator?: string,
    thousandSeparator?: string,
    prefix?: string,
    suffix?: string,
    disabled?: boolean,
    readOnly?: boolean,
    value?: number | string | null,
    placeholder?: string,
    id?: string,
    displayType?: EDisplayType,
    customInput?: React.ComponentType<InputAttributes>,
    valueIsNumericString?: boolean,
    allowLeadingZeros?: boolean,
    allowNegative?: boolean,
    className?: string,
    onChange?: Function,
    bsPrefix?: string,
    inputSize?: ESizeInput,
    isInvalid?: boolean,
    isValid?: boolean,
}

export {
    EthousandsGroupStyle,
    EDisplayType,
}
export type{
    IAtomNumber
}