import { Api, CommonFunction, Config } from "../../Common"
import { IAddCommercant, ICatalogueSitePage, ICommercant, ICommon, IGeocoding, IPublicSites, ISearchSite } from "../../Interfaces"
class CommercantAPI {
    _api: Api
    _apiv3: Api
    constructor() {
        this._api = new Api(Config.getInstance().API_URL)
        this._apiv3 = new Api(Config.getInstance().url_v3_rest)
    }
    async getPublicCommercants(body: IPublicSites) {
        return await this._api.get(`public/sites?lat=${body.lat}&lng=${body.lng}?lang=${body.lang}`, CommonFunction.createHeaders({ withToken: false }))
    }
    async getPublicCommercantsV2(body: IPublicSites) {
        return await this._api.get(`public/sitesWithDistance?lat=${body.lat}&lng=${body.lng}?lang=${body.lang}&maxDistance=${"3000000"}`, CommonFunction.createHeaders({ withToken: false }))
    }
    async getSitesSearch(body: ISearchSite) {
        return await this._api.get(`public/getSitesSearch?lat=${body.lat}&lng=${body.lng}?lang=${body.lang}&keySearch=${body.keySearch}`, CommonFunction.createHeaders({ withToken: false }))
    }
    async getPublicCommercantsItems(body: any) {
        return await this._api.post(`public/sitesOfIdsList`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async getCommercant(body: ICommercant) {
        return await this._api.get(`public/site/${body.suid}?suid=${body.suid}&lang=${body.lang}`, CommonFunction.createHeaders({ withToken: false }))
    }
    async getCommercantCatalogue(body: ICatalogueSitePage) {
        return await this._api.get(`public/catalog?suid=${body.suid}&lang=${body.lang}&collected_at=${body.collected_at}&delivery_method=${body.delivery_method}`, CommonFunction.createHeaders({ withToken: false }))
    }
    async getAutoCompletePlaces(params:ICommon,body: any) {
        return await this._apiv3.post(`v3/delivery/places-autocomplete?suid=${params.suid}&lang=${params.lang}`,JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async getSites(params: ICommon) {
        return await this._api.get(`customer/members/user?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }))
    }
    async verifyDistance(params: ICommon) {
        return await this._api.get(`v3/delivery/distance-matrix?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }))
    } 
    async getGeneraleCondition(params: ICommon) {
        return await this._api.getNotJson(`public/notice?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }))
    } 
    async getMentionsLegales(params: ICommon) {
        return await this._api.getNotJson(`public/terms?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }))
    } 
    async addCommercant(body: IAddCommercant) {
        return await this._api.post(`public/addCommercant`,JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    } 
    async getGeocoding(params:ICommon,body: IGeocoding) {
        return await this._apiv3.post(`v3/delivery/geocoding?suid=${params.suid}&lang=${params.lang}`,JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async setDistanceMatrix(params:ICommon,body: IGeocoding) {
        return await this._apiv3.post(`v3/delivery/distance-matrix?suid=${params.suid}&lang=${params.lang}`,JSON.stringify(body), CommonFunction.createHeaders({ withToken: false }))
    }
    async getCommercantOrNot(body: any) {
        return await this._api.post(`public/who-am-i?url=${body.url}`,JSON.stringify({}),  CommonFunction.createHeaders({ withToken: false }))
    }
} 
export { CommercantAPI }