import React, { useContext, useEffect, useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input } from '../../../DesignSystem';
import { IMoleculeAutoComplete } from '../../Interfaces';
import { CommercantAPI } from '../../../Api/Commercants/Commercants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Interfaces';
import { ISuggestions } from '../../Interfaces/Molecule/IMoleculeAutoComplete/IMoleculeAutoComplete';

const MoleculeAutoComplete = (props: IMoleculeAutoComplete) => {
    const { commercant } = useSelector((state: RootState) => state.commercant);
    const { deliveryAddress } = useSelector((state: RootState) => state.paiement);

    const { lang } = useSelector((state: RootState) => state.setting);
    const [address, setAddress] = useState<any>()
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
    const [sugg, setSugg] = useState<ISuggestions>([])
    const commercantAi = new CommercantAPI()

    const checkDeliveryOptions = (distance: any) => {
        let isDistanceAreaValide = false;
        if (commercant?.delivery_options?.by_meters) {
            let distanceToCheck = 0;
            if (distance.value < distanceToCheck) {
                distanceToCheck = distance.value;
            } else {
                distanceToCheck = distance.value;
            }
            if (distanceToCheck <= commercant?.delivery_options?.meters) {
                isDistanceAreaValide = true;
            }
        }
        return isDistanceAreaValide;
    }

    const setDistanceMatrix = async (address: string) => {
        let isDistanceAreaValide = false;
        const response = await commercantAi.setDistanceMatrix({ suid: commercant?.suid, lang: lang }, { address: address, origin: commercant?.address })
        if (response?.status === 200) {
            if (response?.data?.code === 'OK' && response?.data?.elements && response?.data?.elements.length) {
                isDistanceAreaValide = checkDeliveryOptions(response?.data?.elements[0]?.distance);
            }
        }
    }
    const setGeocoding = async (address: string) => {

        const response = await commercantAi.getGeocoding({ suid: commercant?.suid, lang: lang }, { address: address })
        if (response?.status === 200) {
            setDistanceMatrix(response?.data?.address)           
        }
    }

    const getPlacesAutocomplete = async (add: any) => {
        const res = await commercantAi.getAutoCompletePlaces({
            suid: commercant.suid,
            lang: lang
        }, {
            address: add,
            addressNotEnougthPrecise: false,
            addressToFar: false,
            city: "",
            country: "Belgique",
            establishment: false,
            iso_country: "be",
            postcode: "",
            street: "",
            streetNumber: "",
            toLarge: false,
            validAddress: false
        })
        if (res.status === 200) {
            setSugg(res?.data?.result)
        }
    }
  
    const handleSelect = async (value: any) => {        
        props.onChange && props.onChange(value)
        setAddress(value)
        setShowSuggestions(true)
    };
    React.useEffect(() => {
        if (props.value) {
            setAddress(props.value)
            setGeocoding(props?.value)
        }
    }, [props.value])
React.useEffect(()=>{
    if(deliveryAddress == "")
    {setAddress('')}
},[deliveryAddress])
    React.useEffect(() => {
        if (address) {
            getPlacesAutocomplete(address)
        }else if(address === ''){            
            props.onChange && props.onChange(address)
        }
    }, [address])
    React.useEffect(() => {
        setShowSuggestions(true)
    }, [])

    const className = ['']
    if (props.className) { className.push(props.className) }
    return (
        <div className='ds-relative ds-w-100'  >
            {props.label && <div className="ds-mb-6 input-label ds-text-primary100 ds-text-size-12 ds-text-line-14 ds-text-weight400">
                {props.label}
            </div>}
            
            <PlacesAutocomplete
            
                value={address}
                onChange={(e) => {setAddress(e);e !== '' ? setShowSuggestions(false) : setShowSuggestions(true)}}
                onSelect={handleSelect}
            
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <>
                        <Input
                            listIcons={props?.listIcons}
                            placeholder={props.placeholder}
                            disabled={props?.disabled}
                            className={className.join(' ')}
                            {...getInputProps(props.value)} />
                        {!showSuggestions && <div className='ds-w-100 ds-absolute ds-text-primary100 box-shadow-8 ds-overflow-x container-suggestion'>
                            {loading ? <div>...loading</div> : null}
                            {sugg?.map((suggestion: any, i: number) => {
                                return (
                                     <div
                                        {...getSuggestionItemProps(suggestion)}
                                        key={i}
                                        className='ds-w-100 ds-pointer'>
                                        {suggestion?.description}
                                    </div>
                                );
                            })}
                        </div>}
                    </>
                )}
            </PlacesAutocomplete>
        </div>
    )

}
export default MoleculeAutoComplete