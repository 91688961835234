import { useState } from "react"
import { IUseForm, IElementUseForm, IState, IElementState, IOnChnage, IUseFormResult, typeState, IListRules, ISuccessReturn, IRulesTreatment, IRulesTreatmentReturn, IValidForm, IErrorStatus, ISuccessStatus, IonValidateValue, IGetState } from "../../interfaces"
import { Validation } from "../../../Common/StandardValidation"
const validation = new Validation()
const useForm = (props: IUseForm): IUseFormResult => {
    const { isRealTimeValidation, data } = props
    const getStateObject = (value: typeState): IElementState => {
        return {
            value: value,
            isValid: false,
            successMessage: "",
            isInvalid: false,
            errorMessage: "",
        }
    }
    const resetData = (value: typeState): IElementState => {
        switch (typeof value) {
            case 'number':
                return getStateObject(0)
            case 'bigint':
                return getStateObject(0)
            case 'boolean':
                return getStateObject(false)
            case 'string':
                return getStateObject('')
            case 'object':
                if (Array.isArray(value)) {
                    return getStateObject([])
                }
                return getStateObject('')
            default:
                return getStateObject('')
        }
    }
    const onSortRules = (rules: IListRules) => {
        return rules?.sort((a, b) => {
            if (a.priority > b.priority)
                return 1
            if (a.priority < b.priority)
                return 1
            return 0
        })
    }
    const getState = ({ isEmpty = false, currentValue = false }: IGetState) => {
        const object: IState = {}
        data.forEach((el: IElementUseForm) => {
            const { value, key } = el
            if (!isEmpty) {
                object[key] = getStateObject(value)
                if (currentValue) {
                    const { obj } = rulesTreaments({ rules: el.rules as IListRules, element: el, value: state[key].value as typeState })
                    object[key].value = state[key].value
                    object[key].isValid = state[key].isValid
                    object[key].successMessage = el.successMessage as string
                    object[key].isInvalid = state[key].isInvalid
                    object[key].errorMessage = obj.errorMessage
                }
            } else {
                switch (typeof value) {
                    case 'number':
                        object[key] = getStateObject(0)
                        break;
                    case 'bigint':
                        object[key] = getStateObject(0)
                        break;
                    case 'boolean':
                        object[key] = getStateObject(false)
                        break;
                    case 'string':
                        object[key] = getStateObject('')
                        break;
                    case 'object':
                        if (Array.isArray(value)) {
                            object[key] = getStateObject([])
                            break;
                        }
                        object[key] = getStateObject('')
                        break;
                    default:
                        object[key] = getStateObject('')
                        break;
                }
            }
        })
        return object
    }
    const onValidateValue = ({ rule, value }: IonValidateValue) => {
        if (rule.function === "isArray") {
            if (Array.isArray(value)) {
                return validation[rule.function](value)
            } else {
                return false
            }
        } else if (rule.function === "isTrue") {
            if (typeof value === "boolean") {
                return validation[rule.function](value)
            } else {
                return false
            }
        } else if (rule.function === "IsEightCharLength") {
            return validation[rule.function](value)
        }
        else {
            return validation[rule.function](value.toString())
        }
    }
    const onReturnSuccessStatus = ({ res, element }: ISuccessStatus): ISuccessReturn => {
        return {
            isValid: res,
            successMessage: element.successMessage ? element.successMessage : ""
        }
    }

    const onReturnErrorStatus = ({ res, rule, update = false }: IErrorStatus) => {
        const obj: IElementState = {}
        obj.isInvalid = !res
        if (!res) {
            if (update) {
                obj.errorMessage = rule.messageError
            }
        }
        return obj
    }

    const rulesTreaments = ({ rules, element, update = true, value }: IRulesTreatment): IRulesTreatmentReturn => {
        let obj: IElementState = {}
        let verif: boolean = true
        for (let index = 0; index < rules.length; index++) {
            const rule = rules[index];
            if (validation[rule.function]) {
                const res = onValidateValue({ rule, value })
                obj = {
                    value,
                    ...onReturnSuccessStatus({ res, element }),
                    ...onReturnErrorStatus({ res, rule, update })
                }
                if (!res) {
                    verif = false
                    break
                }
            }
        }
        return { obj, verif }
    }

    const onValidForm = ({ update = true, form = {} }: IValidForm) => {
        const newState = Object.keys(form).length ? { ...form } : { ...state }
        let verif = true
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const key = element.key
            const value = newState?.[key]?.value
            const rules = element?.rules ? onSortRules(element?.rules) : []
            let object: IElementState = {}
            if (rules?.length) {
                if (value === undefined) {
                    const { obj, verif: verifOfRules } = rulesTreaments({ rules, element, update, value: '' })
                    if (verif) {
                        verif = verifOfRules
                    }
                    object = obj
                } else {
                    const { obj, verif: verifOfRules } = rulesTreaments({ rules, element, update, value })
                    if (verif) {
                        verif = verifOfRules
                    }
                    object = obj
                }


                newState[key] = { ...object }
            }
        }
        if (update) {
            setIsFormValid(verif)
            setState(newState)
        }
        return verif

    }
    const onChange = (params: IOnChnage) => {
        let verif = true
        const { value, key } = params
        const indexElement = data.findIndex(el => el.key === key)
        if (indexElement !== -1) {
            const newState = { ...state }
            newState[key] = { ...newState[key], value: value }
            if (isRealTimeValidation) {
                const element = data[indexElement]
                if (element.isRealTimeValidation) {
                    const rules = element?.rules ? onSortRules(element?.rules) : []
                    let object: IElementState = {}
                    if (rules?.length) {
                        if (value === undefined) {
                            const { obj, verif: verifOfRules } = rulesTreaments({ rules, element, value: '' })
                            verif = verifOfRules
                            object = obj
                        } else {
                            const { obj, verif: verifOfRules } = rulesTreaments({ rules, element, value })
                            verif = verifOfRules
                            object = obj
                        }
                        newState[key] = { ...object }
                    }
                }
            }
            verif = onValidForm({ update: false, form: newState })
            setIsFormValid(verif)
            setState(newState)
        }
    }

    const onReset = (isEmpty: boolean = true) => {
        setState(getState({ isEmpty }))
    }

    const onUpdateData = () => {
        const newState = getState({ isEmpty: false, currentValue: true })
        setState(newState)
    }
    const onUpdateState = (data: IState) => {
        setState({ ...data })
    }

    const [state, setState] = useState(getState({}))
    const [isFormValid, setIsFormValid] = useState(false)
    return { state, onChange, onValidForm, isFormValid, onReset, onUpdateData, onUpdateState }
}
export { useForm }