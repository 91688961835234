import { Provider } from 'react-redux'
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import AppRoute from './Route/AppRoute'
import ShopRoute from './Route/ShopRoute'
import { store, persistor } from './Redux/Store/Store'
import { useEffectOnce } from './CustomHooks'
import { CommercantAPI } from './Api/Commercants/Commercants'
import { useEffect, useState } from 'react'

const App = (): JSX.Element => {
  useEffectOnce(() => {
    localStorage.removeItem("persist:DigiStore")
    localStorage.removeItem("persist:digi")
    localStorage.removeItem("persist:root")
  })

  const [isShop, setIsShop] = useState<boolean>(false)
  const [shop, setShop] = useState<any>()

  const commercantApi = new CommercantAPI()
  const getCommercant = async () => {
    // const response = await commercantApi.getCommercantOrNot({ url: "amigos-express.be" })
    const response = await commercantApi.getCommercantOrNot({ url: window.location.href.split('//')[1].split('/')[0] })
    if (response.status == 200) {
      setIsShop(true)
      setShop(response.data?.sites)
    }
  }

  useEffect(() => {
    getCommercant()
  }, [])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {isShop ? <ShopRoute sites={shop} /> : <AppRoute />}
      </PersistGate>
    </Provider>
  )
}

export default App
