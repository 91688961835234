import React, { useRef, useState } from 'react'
import { AtomInput, Checkbox, Icon } from '../../Atoms';
import { InputLabel } from '..';
import { DEFAULTINPUTATOMPROPS } from '../../DefaultProps/Atoms'
import { DEFAULTINPUTMESSAGEPROPS, DEFAULTINPUTMOLPROPS } from '../../DefaultProps/Molecules';
import { IDivWidth, IMolInputProps } from '../../Interfaces/Molecule/IMoleculeInput/IMoleculeInput';
import { ETypesInput } from '../../Interfaces/Atoms/IAtomInput/IAtomInput';
import { ETextInput } from '../../Interfaces/Molecule/ITextInput/ITextInput';
import { usePopper } from 'react-popper';
import { useClickOutside, useUpdateEffect } from "../../../CustomHooks"
const POSITION: number = 16
const ICONCONTAINERPADDIING = 4
const SIZE: string = "16px"
const MoleculeInput = React.forwardRef((props: IMolInputProps, ref: React.Ref<HTMLInputElement>): JSX.Element => {
    const inputId = useRef(new Date().getTime() + Math.random() * 1000000000)
    const selectId = useRef(new Date().getTime() + Math.random() * 1000000000)
    const optionsScrollRef = useRef(0)
    const [search, setSearch] = useState('')
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [isSelectFocus, setIsSelectFocus] = useState(false)
    const focusSelect = useRef(false)
    const inputRef = useRef<HTMLElement | null | undefined>() as React.MutableRefObject<HTMLInputElement>;
    const selectRef = useRef<HTMLElement | null | undefined>() as React.MutableRefObject<HTMLInputElement>;
    const contentSelectRef = useRef<HTMLElement | null | undefined>() as React.MutableRefObject<HTMLInputElement>;
    const refOptions = useRef<HTMLElement | null | undefined>() as React.MutableRefObject<HTMLInputElement>;
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const { attributes } = usePopper(referenceElement, popperElement);
    const [contentSelectHeight, setContentSelectHeight] = useState(0)
    const [isShowOption, setIsShowOption] = useState(true)
    setTimeout(() => {
        if (contentSelectRef?.current?.offsetHeight && contentSelectRef?.current?.offsetHeight !== contentSelectHeight) {
            setContentSelectHeight(contentSelectRef.current.offsetHeight)
        }
    }, 100)
    useClickOutside(refOptions, () => {
        if (isSelectFocus) {

            if (focusSelect.current) {
                const activeElement: any = document.activeElement
                if (activeElement?.id !== inputId.current.toString()) {
                    setIsSelectFocus(false)
                    focusSelect.current = false
                    !!props.setClearData && props.setClearData(true)
                }
            } else {
                focusSelect.current = true
            }
        }

    })

    useUpdateEffect(() => {
        if (isShowOption && optionsScrollRef.current) {
            setTimeout(() => {
                if (refOptions?.current?.scrollTo) {
                    refOptions?.current?.scrollTo({ top: optionsScrollRef.current })
                    optionsScrollRef.current = 0
                }
            }, 1000)
        }
    }, [isShowOption])

    useUpdateEffect(() => {
        if (isSelectFocus) {
            if (props.isSearch) {
                inputRef.current.focus()
            }
        } else {
            setSearch('')
            setContentSelectHeight(0)
            setIsShowOption(true)
        }
    }, [isSelectFocus])

    const getDivWidth = (params: IDivWidth) => {
        const { text, font = '400 14px Lato' } = params
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (context) {
            context.font = font;
            const { width } = context.measureText(text);
            return { width };
        }
        return { width: 0 }
    };

    const renderIcon = ({ icon = "Setting", IconClassName = "ds-text-primary70", color = '', containerStyle = {}, style = {}, size = SIZE, onClick = () => { return } }) => {
        return <div className={"ds-absolute ds-pl-2 ds-pb-4 ds-top-7 ds-hp-20 ds-wp-20 search-icon ds-pointer"} style={containerStyle}>
            <Icon icon={icon} className={IconClassName} size={size} style={style} color={color} onClick={onClick} />
        </div>
    }

    const getRigthValidationPosition = () => {
        return POSITION
    }

    const returnSize = (size = SIZE) => {
        if (size) {
            if (typeof size === "string") {
                const x = Number(size.replace('px', ''))
                return x ?? Number(SIZE.replace('px', ''))
            }
        }
        return Number(SIZE.replace('px', ''))
    }

    const onClear = () => {
        if (props.isSelect) {
            props?.onClear?.([])

        } else {
            props?.onClear?.("")
        }
    }

    const renderIcons = (type = true) => {
        const list = []
        const classNames = []
        const validationPosition = getRigthValidationPosition()
        let validationWidth = 0
        if (props.isInvalid && !props.isValid) {
            list.push(renderIcon({
                icon: "exclamation-circle",
                IconClassName: "ds-text-error100",
                containerStyle: { right: validationPosition }
            }))
            validationWidth = 20
        }
        if (props.isValid) {
            list.push(renderIcon({
                icon: "check-circle",
                IconClassName: "ds-text-success100",
                containerStyle: { right: validationPosition }
            }))
            validationWidth = 20
        }
        let marginLeft = POSITION
        let marginRight = POSITION + validationWidth
        if (props.isPassword && !props.isSelect) {
            list.push(renderIcon({
                icon: isShowPassword ? "eye" : "eye-slash",
                IconClassName: "ds-text-primary70 ds-pointer slash-icon",
                containerStyle: { right: marginRight },
                onClick: () => setIsShowPassword(!isShowPassword)
            }))
            marginRight = marginRight + ICONCONTAINERPADDIING + returnSize(SIZE)
        }
        if (props.isSelect && !props.isPassword && !props?.renderCustomOption) {
            list.push(renderIcon({
                icon: "arrow-down-1",
                IconClassName: "ds-text-primary70 ds-pointer ",
                containerStyle: { right: marginRight },
                onClick: () => {
                    setIsSelectFocus(!isSelectFocus)
                    focusSelect.current = false
                }
            }))
            marginRight = marginRight + ICONCONTAINERPADDIING + returnSize(SIZE)
        }
        if (props.isSelect && !props.isPassword && props?.renderCustomOption && !props?.isMenu) {
            list.push(renderIcon({
                icon: "document3",
                size: window.innerWidth > 320 ? "24px" : "16px",
                IconClassName: "ds-text-primary70",
                containerStyle: { right: marginRight },
            }))
            list.push(renderIcon({
                icon: "search-normal2",
                size: window.innerWidth > 320 ? "24px" : "16px",
                IconClassName: "ds-text-primary70 ",
                containerStyle: { left: window.innerWidth > 1024 ? marginRight : '8px' },
            }))
            marginLeft = marginLeft + ICONCONTAINERPADDIING + returnSize(SIZE)
            marginRight = marginRight + ICONCONTAINERPADDIING + returnSize(SIZE)
        }
        if (props.isSelect && !props.isPassword && props?.renderCustomOption && props?.isMenu) {
            list.push(renderIcon({
                icon: "search-normal2",
                size: window.innerWidth > 320 ? "24px" : "16px",
                IconClassName: "ds-text-primary70 ",
                containerStyle: { left: window.innerWidth > 1024 ? marginRight : '8px' },
            }))
            marginLeft = marginLeft + ICONCONTAINERPADDIING + returnSize(SIZE)
            marginRight = marginRight + ICONCONTAINERPADDIING + returnSize(SIZE)
        }
        if (props.isClerable) {
            list.push(renderIcon({
                icon: "x",
                IconClassName: "ds-text-primary70 ds-pointer ",
                containerStyle: { right: marginRight },
                onClick: () => onClear(),
            }))
            marginRight = marginRight + ICONCONTAINERPADDIING + returnSize(SIZE)

        }
        if (!props.isPassword && !props.isSelect && props?.listIcons?.length) {
            props.listIcons.forEach(element => {
                list.push(renderIcon({
                    icon: element.icon,
                    IconClassName: element.className,
                    style: element.style,
                    color: element.color,
                    size: element.size ? element.size?.toString() : SIZE,
                    containerStyle: { [element.isLeft ? "left" : "right"]: element.isLeft ? marginLeft : marginRight },
                    onClick: element.onClick ? element.onClick : () => { return },
                }))
                if (element.isLeft) {
                    marginLeft = marginLeft + ICONCONTAINERPADDIING + returnSize(element.size ? element.size.toString() : SIZE)
                } else {
                    marginRight = marginRight + ICONCONTAINERPADDIING + returnSize(element.size ? element.size.toString() : SIZE)
                }
            })
        }
        if (props.leftIcon) {
            list.push(renderIcon({
                icon: props.leftIcon,
                IconClassName: props.leftIconClassName,
                containerStyle: { left: marginLeft, right: marginRight },
            }))
            marginLeft = marginLeft + ICONCONTAINERPADDIING + returnSize(SIZE)
            marginRight = marginRight + ICONCONTAINERPADDIING + returnSize(SIZE)
        }
        if (props.isEditable) {
            list.push(renderIcon({
                icon: "pencil-alt",
                IconClassName: "ds-text-neutral40 ds-pointer",
                containerStyle: { right: marginRight },
                onClick: props.onClickIconEdit,
            }))
            marginRight = marginRight + ICONCONTAINERPADDIING + returnSize(SIZE)

        }
        classNames.push("ds-pl-" + marginLeft)
        classNames.push("ds-pr-" + marginRight)
        if (type) {
            return list
        } else {
            return classNames.join(" ")
        }
    }

    const renderInputType = () => {
        if (props.isPassword) {
            if (isShowPassword)
                return ETypesInput.text
            return ETypesInput.password
        }
        return props.type
    }

    const renderInput = () => {
        return (
            <div className='ds-w-100 ds-relative ds-flex  ds-align-center ds-content-center'>
                <AtomInput
                    ref={ref}
                    className={className.join(' ')}
                    id={props.id}
                    type={renderInputType()}
                    placeholder={props.placeholder}
                    value={props.value?.toString()}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    inputSize={props.inputSize}
                    isInvalid={props.isInvalid}
                    isValid={props.isValid}
                    bsPrefix={props.bsPrefix}
                    onKeyPress={props.onKeyPress}
                    onClick={props.onClick}
                />
                {renderIcons(true)}
            </div>
        )
    }

    const onChangeSelect = (value: string) => {
        if (props.onChangeSelect) {
            let list = []
            if (props.isMulti) {
                if (Array.isArray(props.selectValue)) {
                    list = [...props.selectValue]
                    if (value) {
                        const index = list.indexOf(value)
                        if (index === -1) {
                            list.push(value)
                        } else {
                            list = props.selectValue.filter(el => el !== value)
                        }
                    }
                } else {
                    if (value) {
                        list.push(value)
                    }
                }
            } else {
                list = [value]
            }
            if (!props.isMulti) {
                setIsSelectFocus(false)
            }
            props.onChangeSelect(list)
        }

    }

    const onRemoveElement = (value: string) => {
        if (props.onChangeSelect) {
            const list = props.selectValue?.filter(el => el !== value) || []
            props.onChangeSelect(list)
        }
    }

    const getCheckedValue = (value: string) => {
        if (props.selectValue?.length) {
            const list = props.selectValue?.filter(el => el === value) || []
            if (list.length) {
                return true
            }
        }
        return false
    }

    const renderSelectValue = () => {
        const list: any = []
        const defaultClass = "ds-mr-8 ds-px-8 ds-my-2 ds-mr-8 ds-hp-24 ds-flex ds-align-center ds-text-primary70"
        let contentWidth = 0
        if (!props?.selectValue?.length) {
            if (props.isSearch && isSelectFocus) {
                list.push(renderInputSearch(contentWidth))
            } else {
                if (props.placeholder) {
                    list.push(<div className={"ds-mr-8 ds-px-8 ds-my-2 ds-mr-8 ds-hp-24 ds-flex ds-align-center ds-text-primary20 placeholder-class"}>{props.placeholder}</div>)
                }
            }
        } else {
            if (props.isMulti) {
                if (Array.isArray(props.selectValue)) {
                    props.selectValue.forEach((el, index) => {
                        const filtred = props?.selectOption?.filter(element => element.value === el) || []
                        if (filtred.length) {
                            const label: string = filtred[0].label
                            list.push(<div
                                id={selectId.current.toString() + index + "az"}
                                className='ds-select-value ds-bg-neutral200 ds-px-8 ds-my-2 ds-mr-8 ds-borad-4 ds-flex ds-text-size-12 ds-align-center'>
                                {label}
                                <Icon
                                    id={selectId.current.toString() + index + "ay"}
                                    icon={"x"}
                                    className={"ds-pointer ds-ml-8"}
                                    size="12"
                                    onClick={() => { setTimeout(() => setIsSelectFocus(true), 50); onRemoveElement(el) }}
                                />
                            </div>)
                            const { width } = getDivWidth({ text: label })
                            contentWidth += width + 8 + 16 + 8 + 13
                        }
                    })
                }
            } else {
                if (props.selectValue[0]) {
                    const filtred = props?.selectOption?.filter(element => element.value === props?.selectValue?.[0]) || []
                    if (filtred.length) {
                        list.push(<div className={defaultClass}>{filtred[0].label}</div>)
                        const { width } = getDivWidth({ text: filtred[0].label })
                        contentWidth += width + 8
                    }
                }
            }
            if (props.isSearch && isSelectFocus) {
                list.push(renderInputSearch(contentWidth))
            }
        }
        return list
    }

    const renderInputSearch = (contentWidth: number): JSX.Element => {
        const containerWidth = Number(contentSelectRef.current?.offsetWidth)
        if (containerWidth) {
            const style = { width: "auto" }
            return <AtomInput
                id={inputId.current.toString()}
                ref={inputRef}
                bsPrefix='ds-input-select'
                value={search}
                disabled={props.disabled}
                className="ds-text-primary100 ds-w-100 ds-my-2 ds-hp-22"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setSearch(e.target.value); props.onChange && props.onChange(e) }}
                style={style}
            />
        }
        return (<></>)
    }

    const onScroll = (e: any) => {
        optionsScrollRef.current = e?.target?.scrollTop
    }

    const renderSelectOptions = () => {
        const list = [];
        const options = !search ? props.selectOption : props.isMenu ? props?.selectOption?.map(option => ({
            ...option,
            data: option?.data?.filter((item: any) => item?.name.toLowerCase().includes(search.toLowerCase()))
        })).filter(option => option?.data?.length > 0) :
            !props?.renderCustomOption ?
                props?.selectOption?.filter(el => el.label.indexOf(search) !== -1) :
                props?.selectOption?.map(option => ({
                    ...option,
                    data: option?.data?.filter((item: any) =>
                    (item?.name.toLowerCase().includes(search.toLowerCase())
                        || (item?.city.toLowerCase().includes(search.toLowerCase()))
                        || (item?.vocation?.slug.toLowerCase().includes(search.toLowerCase())))
                    )
                })).filter(option => option?.data?.length > 0);
        if (options?.length) {
            for (let index = 0; index < options.length; index++) {
                const element = options[index];
                if (props.renderCustomOption) {
                    list.push(<div className='ds-w-100' onClick={() => onChangeSelect(element.value)}>
                        {props.renderCustomOption(element, index)}
                    </div>
                    )
                } else {
                    list.push(<div className='ds-py-6 padding-multi-select ds-text-size-14 ds-text-line-16 ds-flex ds-align-center select-options' onClick={() => onChangeSelect(element.value)}>
                        {props.isMulti && <Checkbox checked={getCheckedValue(element.value)} />}
                        {element.label}
                    </div>)
                }
            }
        } else {
            list.push(<div className='ds-py-16 ds-text-size-14 ds-text-line-16 ds-flex ds-align-center'>{props.messageNoOptions}</div>)
        }

        const containerOptionClassName: any = ["ds-w-100 ds-absolute container-option-drop ds-borad-4 ds-bg-white ds-z-index-100000 ds-text-primary70"]
        if (props.containerOptionClassName) {
            containerOptionClassName.push(props.containerOptionClassName)
        }
        return <div
            ref={setPopperElement}
            {...attributes.popper}
            style={props?.containerOptionStyle}
            onScroll={onScroll}
            className={containerOptionClassName.join(' ')}
        >
            <div className=' ds-pointer' ref={refOptions}>
                {list}
            </div>
        </div>
    }

    const renderSelect = () => {
        className.push('default-select ds-flex ds-align-center')
        return (
            <div className='ds-w-100 ds-relative' ref={selectRef}>
                <div className='ds-w-100 ds-relative' ref={setReferenceElement}>
                    <AtomInput
                        ref={ref}
                        className={className.join(' ')}
                        id={props.id}
                        disabled={props.disabled}
                        inputSize={props.inputSize}
                        isInvalid={props.isInvalid}
                        isValid={props.isValid}
                        bsPrefix={props.bsPrefix}
                        isSelect
                        onClick={() => setIsSelectFocus(true)}
                    >
                        <div className='ds-flex ds-align-center ds-w-100 ds-flex-wrap ds-py-4 selected-value ds-pointer' style={{ height: "max-content" }} ref={contentSelectRef}>
                            {renderSelectValue()}
                        </div>
                    </AtomInput>
                    {isSelectFocus && isShowOption && !props.disabled && renderSelectOptions()}
                    {renderIcons(true)}
                </div>
            </div>
        )
    }

    const containerClassName = ["ds-w-100"]
    const className = [""]
    if (props.className) {
        className.push(props.className)
    }
    if (props.containerClassName) {
        containerClassName.push(props.containerClassName)
    }
    let paddingClass = renderIcons(false)
    if (typeof paddingClass !== "string") {
        paddingClass = paddingClass.join(' ')
    }
    className.push(paddingClass)
    if (props.inputClassName)
        className.push(props.inputClassName)
    return <div className={containerClassName.join(' ')}>
        <InputLabel text={props.label} type={ETextInput.label} className={props.labelClassName} />
        {!props.isSelect ? renderInput() : renderSelect()}
        {props.isValid && <InputLabel text={props.success} type={ETextInput.success} className={props.successClassName} />}
        {!props.isValid && props.isInvalid && <InputLabel text={props.error} type={ETextInput.error} className={props.errorClassName} />}
        {props.helperText && !props.isInvalid && !props.isValid && <InputLabel text={props.helperText} type={ETextInput.helper} className={props.helperTextClassName} />}
    </div>

});

MoleculeInput.defaultProps = {
    ...DEFAULTINPUTATOMPROPS,
    ...DEFAULTINPUTMOLPROPS,
    ...DEFAULTINPUTMESSAGEPROPS
}

export default MoleculeInput