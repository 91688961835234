import { IModalPaiment, TypePaimentCard } from "../../Interfaces/Modal";
import React, { Ref, forwardRef, useContext, useState } from "react";
import masterCard from '../../Assets/images/master_card.svg';
import bancontact from '../../Assets/images/Bancontact.svg';
import { EListFunction, IUseFormResult, useForm } from "../../CustomHooks";
import { CommonFunction, Config } from "../../Common";
import { useDispatch, useSelector } from "react-redux";
import {
    openAlert,
    openModal,
    setBasket,
    setBasketItems, setCardSaved, setCustomerMember, setDetailsCommande,
    setMethodePiement, setNbreItems, setPreparePayment
} from "../../Redux/Reducers";
import { RootState } from "../../Interfaces";
import { PaiementApi } from "../../Api/Paiement/Paiement";
import { TemplateModalPaiment } from "../../Template";
import { Modal, ModalRefType } from "../../DesignSystem";
import { useNavigate } from 'react-router-dom';
import { ShopAPI } from "../../Api/Shop/shop";
import { LangContext } from "../../Lang";
import { IBasketItem } from "../../DesignSystem/Interfaces";
import { UserAPI } from "../../Api/User/user";
import Cookies from "js-cookie"


const ModalPaiment = (props: IModalPaiment, ref: Ref<ModalRefType | undefined>) => {
    const dispatch = useDispatch()
    const { deliveryAddress, delievryMode } = useSelector((state: RootState) => state.paiement)
    const { baskett, credit, fromRecharge, basketId, zone } = useSelector((state: RootState) => state?.basket)
    const myCredit = useSelector((state: RootState) => state?.commercant?.customerMember?.credit)
    const { user } = useSelector((state: RootState) => state?.auth)
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    const { lang } = useSelector((state: RootState) => state?.setting)
    const { data } = useSelector((state: RootState) => state?.modal)
    const { methodePaiment, paymentMethodes, cardSaved, } = useSelector((state: RootState) => state?.paiement)
    const [paimentMethode, setPaimentMethode] = useState<TypePaimentCard | undefined>(
        []
    )
    const [showCardDetails, setShowCardDetails] = useState<boolean>(false)
    const [selectedCheckbox, setSelectedCheckbox] = useState<number | null>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    const paiementApi = new PaiementApi()
    const shopApi = new ShopAPI()
    const userApi = new UserAPI()
    const [show, setShow] = useState<boolean>(false)
    const langue = useContext(LangContext)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    React.useEffect(() => {
        if (!props.isOpen && isOpen) {
            setSelectedCheckbox(null)
            setShowCardDetails(false)
            setShow(false)
            setIsOpen(false)

        }
        else { setIsOpen(true) }
    }, [props.isOpen])
    React.useEffect(() => {
        if (data?.showCradDetails) {
            setShowCardDetails(false)
        }
    }, [data?.showCradDetails])
    React.useEffect(() => {
        if (methodePaiment === '') {
            setSelectedCheckbox(null)
            setShow(false)
            setShowCardDetails(false)
        }
    }, [methodePaiment])

    React.useEffect(() => {
        if (fromRecharge) {
            setPaimentMethode([
                { image: masterCard, typePaiment: langue.bank_card, typePaimentDescription: langue.add_payment_card },
                { image: bancontact, typePaiment: "Bancontact", typePaimentDescription: langue.pay_boncontact, checked: true },
            ])
        } else {
            setPaimentMethode(
                [
                    { image: masterCard, typePaiment: langue.bank_card, typePaimentDescription: langue.add_payment_card },
                    { image: bancontact, typePaiment: "Bancontact", typePaimentDescription: langue.pay_boncontact, checked: true },
                    { icon: "wallet-money", typePaiment: langue.credit_account, typePaimentDescription: `${CommonFunction.setFormatNumber(myCredit)} € ${langue.credit_desc}`, checked: false },
                    { icon: "directbox-receive1", typePaiment: langue.on_site, typePaimentDescription: langue.pay_your_merchant, checked: false }
                ]
            )
        }
        setShowCardDetails(false)
    }, [fromRecharge, myCredit])
    const {
        state: form,
        onChange,
        onUpdateState,
        isFormValid
    }: IUseFormResult = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "nameCard",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "numberCard",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNumberSpace,
                        messageError: "",
                        priority: 1
                    },
                    {
                        function: EListFunction.checkLenghtNumber,
                        messageError: "",
                        priority: 2
                    },
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "dateExp",
                value: "",
                rules: [
                    {
                        function: EListFunction.isDateValid,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "cvc",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNumber,
                        messageError: "",
                        priority: 0
                    },
                    {
                        function: EListFunction.checkLenghtCvc,
                        messageError: "",
                        priority: 1
                    },
                ],
                successMessage: "",
                isRealTimeValidation: true
            }
        ]
    })
    React.useEffect(() => {
        if (showCardDetails) {
            onUpdateState({
                ...form,
                nameCard: {
                    ...form.nameCard,
                    isInvalid: false,
                    isValid: false,
                    value: ""
                },
                numberCard: {
                    ...form.numberCard,
                    isInvalid: false,
                    isValid: false,
                    value: ""
                },
                dateExp: {
                    ...form.dateExp,
                    isInvalid: false,
                    isValid: false,
                    value: ""
                },
                cvc: {
                    ...form.cvc,
                    isInvalid: false,
                    isValid: false,
                    value: ""
                },
            })
        }
    }, [showCardDetails])
    const getBasket = async () => {
        const res = await shopApi.getBasketOrder({ suid: commercant?.suid, lang: lang, zone: zone })
        if (res.status === 200) {
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
            // } else if (res?.status === 401) {
            //     dispatch(resetToken())
        } else if (res?.status === 404) {
            dispatch(setNbreItems({ nbreItems: null }))
            dispatch(setBasketItems({ items: {} }))
            dispatch(setBasket({ baskett: {}, basketId: basketId }))
        }
    }
    const onSetSaveCrad = () => {
        dispatch(setCardSaved(cardSaved))
    }
    const onSetGeneralCondition = () => {
        setShow(!show)
    }
    const onChoosePaimentMethode = (index: number) => {
        let aux: TypePaimentCard | undefined = []
        if (paimentMethode) {
            aux = [...paimentMethode]
            if (delievryMode) {
                if (aux[index].typePaiment === langue.bank_card) {
                    if (commercant?.basket_min_amount >= baskett?.total - (baskett?.fees + baskett?.delivery_fees)) {
                        dispatch(openAlert({ type: 'info', message: langue.minimum_basket + ` ${commercant?.basket_min_amount} € ` + langue.minimum_basket }))
                    }
                    else {
                        setShowCardDetails(true)
                        aux[index] = { ...aux[index], checked: !aux[index].checked }
                        setPaimentMethode(aux)
                        setSelectedCheckbox(index)
                        dispatch(setMethodePiement({ methodePaiment: aux[index].typePaiment }))
                    }
                }
                else if (aux[index].typePaiment === langue.bancontact_title) {
                    if (commercant?.basket_min_amount >= baskett?.total - (baskett?.fees + baskett?.delivery_fees)) {
                        dispatch(openAlert({ type: 'info', message: langue.minimum_basket + ` ${commercant?.basket_min_amount} € ` + langue.minimum_basket }))
                    }
                    else {
                        aux[index] = { ...aux[index], checked: !aux[index].checked }
                        setPaimentMethode(aux)
                        setSelectedCheckbox(index)
                        dispatch(setMethodePiement({ methodePaiment: aux[index].typePaiment }))
                    }
                }
                else {
                    aux[index] = { ...aux[index], checked: !aux[index].checked }
                    setPaimentMethode(aux)
                    setSelectedCheckbox(index)
                    dispatch(setMethodePiement({ methodePaiment: aux[index].typePaiment }))
                }
            }
            else {
                if (aux[index].typePaiment === langue.bank_card) {
                    setShowCardDetails(true)
                }
                aux[index] = { ...aux[index], checked: !aux[index].checked }
                setPaimentMethode(aux)
                setSelectedCheckbox(index)
                dispatch(setMethodePiement({ methodePaiment: aux[index].typePaiment }))
            }

        }
    }
    const onCloseModal = () => {
        if (props.onExit) {
            props.onExit()
        }
        onUpdateState({
            ...form,
            nameCard: {
                ...form.nameCard,
                isInvalid: false,
                isValid: false,
                value: ""
            },
            numberCard: {
                ...form.numberCard,
                isInvalid: false,
                isValid: false,
                value: ""
            },
            dateExp: {
                ...form.dateExp,
                isInvalid: false,
                isValid: false,
                value: ""
            },
            cvc: {
                ...form.cvc,
                isInvalid: false,
                isValid: false,
                value: ""
            },
        })
        setShowCardDetails(false)
    }
    const onPayOrder = () => {
        if (show && methodePaiment === langue.bank_card) {
            onPreparePaiement()
        } else if (methodePaiment === langue.credit_account) {
            onPayeWithCreditAccount()
        } else if (methodePaiment === langue.on_site) {
            onPayOnSite()
        } else if (methodePaiment === "Bancontact") {
            onPrepareBancontactPaiement()
        }
    }
    const renderPreparePaimentPayload = (basket: IBasketItem, uniq: string) => {
        if (fromRecharge) {
            return ({
                params: {
                    suid: commercant?.suid, lang: lang
                },
                body: {
                    basket: "",
                    card_id: paymentMethodes?.id,
                    credit_amount: credit,
                    save_card: cardSaved,
                    zone: zone,
                    fromPayement: true

                }
            })
        } else {
            return ({
                params: {
                    suid: commercant?.suid, lang: lang
                },
                body: {
                    order_uniq: uniq,
                    credit_amount: 0,
                    save_card: cardSaved,
                    basket: basket,
                    card_id: paymentMethodes?.id,
                    zone: zone,
                    fromPayement: true

                }
            })
        }
    }
    const onHandleApiResponse = () => {
        if (props?.onExit) {
            props?.onExit()
        }
        setIsLoading(false)
    }
    const onPreparePaiement = async () => {
        setIsLoading(true)
        let basket = baskett ?? ""
        let uniq = baskett ? baskett?.uniq : null
        const response = await paiementApi.setPreparePaiement(renderPreparePaimentPayload(basket, uniq).params, renderPreparePaimentPayload(basket, uniq).body)
        if (response.status === 200) {
            dispatch(setDetailsCommande({ detailsCommande: [] }))
            dispatch(setPreparePayment({ paymentDetails: response.data }))
            const resp = await paiementApi.confirmPayment(response.data.id, {
                save_payment_method: cardSaved,
                setup_future_usage: 'off_session',
                payment_method: response?.data?.payment_method,
                expected_payment_method_type: response?.data?.payment_method_types[0],
                use_stripe_sdk: true,
                key: Config.getInstance().stripeKeyBETest,
                client_secret: response?.data?.client_secret
            })
            if (resp) {
                setShowCardDetails(false)
                setShow(false)
                onUpdateState({
                    ...form,
                    nameCard: {
                        ...form.nameCard,
                        isValid: true,
                        isInvalid: false,
                        value: ""
                    },
                    numberCard: {
                        ...form.numberCard,
                        isValid: true,
                        isInvalid: false,
                        value: ""
                    },
                    dateExp: {
                        ...form.dateExp,
                        isValid: true,
                        isInvalid: false,
                        value: ""
                    },
                    cvc: {
                        ...form.cvc,
                        isValid: true,
                        isInvalid: false,
                        value: ""
                    },
                })
                setIsLoading(false)
                if (fromRecharge) {
                    onCloseModal()
                    if (props.onExit) {
                        props.onExit()
                    }
                    if (location.pathname !== '/mes-recharges') {
                        navigate('mes-recharges')
                    }
                } else {
                    dispatch(setNbreItems({ nbreItems: null }))
                    dispatch(setBasketItems({ items: {} }))
                    dispatch(setBasket({ baskett: {} }))
                    // getBasket()
                    // await getCommandeDetails()
                    await getItemCommandeDetails()
                    onCloseModal()
                    if (props.onExit) {
                        props.onExit()
                    }
                    navigate('mes-commandes')
                }
                if (props.onExit) {
                    props.onExit()
                }
            }
        }
        if (response.status === 400) {
            dispatch(openAlert({ type: 'info', message: langue.time_expired }))
            onHandleApiResponse()
            dispatch(openModal({ name: 'modalDelivery' }))
        }
        if (response.status === 500) {
            onHandleApiResponse()
        }
    }
    const getItemCommandeDetails = async () => {
        if (basketId) {
            const response = await shopApi.getItemCommande({ uniq: basketId, suid: commercant?.suid, lang: lang })
            if (response.status === 200) {
                await getCommandeDetails()
                //getBasket()
            }
        }
    }
    const getCommandeDetails = async () => {
        const response = await shopApi.getCommande({ suid: commercant?.suid, lang: lang })
        if (response.status === 200) {
            dispatch(setDetailsCommande({ detailsCommande: response?.data }))
            await getBasket()
        }
    }
    const getCustomerMemeber = async () => {
        const response = await userApi.getCustomerMember({
            userId: user?.uniq,
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            dispatch(setCustomerMember({ customerMember: response.data }))
        }
    }
    const onPrepareBancontactPaiement = async () => {
        setIsLoading(true)
        let basket = baskett ?? ""
        let uniq = baskett ? baskett?.uniq : null
        let credit_recharge = credit === null ? 0 : credit
        const response = await paiementApi.setPrepareBancontactPaiement(
            {
                suid: commercant?.suid, lang: lang
            },
            {
                order_uniq: uniq,
                credit_amount: fromRecharge ? credit_recharge : 0,
                return_host: Config.getInstance().host_url,
                fromPayement: true,
                basket: basket,
                zone: zone
            }
        )
        if (response.status === 200) {
            dispatch(setDetailsCommande({ detailsCommande: [] }))
            dispatch(setPreparePayment({ paymentDetails: response.data }))
            const resp = await paiementApi.confirmBancontactPayment(response.data.id,
                {
                    payment_method_data: {
                        type: 'bancontact',
                        billing_details: {
                            name: user?.fullname,
                            email: user?.username,
                        },
                        muid: Cookies.get("__stripe_mid"),
                        sid: Cookies.get("__stripe_sid"),
                        payment_user_agent: Config.getInstance().payment_user_agent,
                        time_on_page: Config.getInstance().time_on_page,
                    },
                    expected_payment_method_type: 'bancontact',
                    mandate_data: {
                        customer_acceptance: {
                            type: 'online',
                            online: {
                                infer_from_client: true,
                            },
                        },
                    },
                    key: Config.getInstance().stripeKeyBETest,
                    client_secret: response?.data?.client_secret,
                    return_url: response?.data?.returnUrl,
                });
            if (resp) {
                setShowCardDetails(false)
                setShow(false)
                setIsLoading(false)
                if (fromRecharge) {
                    await getCustomerMemeber()
                    onCloseModal()
                    if (props.onExit) {
                        props.onExit()
                    }
                    // if (location.pathname !== '/mes-recharges') {
                    //     navigate('mes-recharges')
                    // }
                } else {
                    dispatch(setNbreItems({ nbreItems: null }))
                    dispatch(setBasketItems({ items: {} }))
                    dispatch(setBasket({ baskett: {} }))
                    getCommandeDetails()
                    onCloseModal()
                    if (props.onExit) {
                        props.onExit()
                    }
                    //navigate('mes-commandes')
                }
                if (resp?.next_action?.redirect_to_url?.url) {
                    CommonFunction.OnOpenNewSelf(resp?.next_action?.redirect_to_url?.url)
                }
                if (props.onExit) {
                    props.onExit()
                }
            }
        }
        if (response.status === 400) {
            dispatch(openAlert({ type: 'info', message: langue.time_expired }))
            setIsLoading(false)
            dispatch(openModal({ name: 'modalDelivery' }))

        }
        if (response.status === 500) {
            onHandleApiResponse()
        }
    }
    const onSaveCard = () => {
        if (isFormValid) {
        }
    }
    const onPayeWithCreditAccount = async () => {
        setIsLoading(true)
        const response = await paiementApi.PaymentWithCreditAccount(
            { suid: commercant?.suid, lang: lang },
            {
                basket: {
                    delivery_address: deliveryAddress,
                    comment: baskett?.comment,
                    delivery_method: baskett.delivery_method,
                    platform: "web",
                    zone: zone
                }
            })
        if (response.status === 200) {
            setShowCardDetails(false)
            setShow(false)
            setIsLoading(false)
            if (fromRecharge) {
                onCloseModal()
                if (props.onExit) {
                    props.onExit()
                }
                if (location.pathname !== '/mes-recharges') {
                    navigate('mes-recharges')
                }
            } else {
                getCustomerMemeber()
                dispatch(setNbreItems({ nbreItems: null }))
                dispatch(setBasketItems({ items: {} }))
                dispatch(setBasket({ baskett: {}, basketId: basketId }))
                getCommandeDetails()
                onCloseModal()
                if (props.onExit) {
                    props.onExit()
                }
                navigate('mes-commandes')
            }

            if (props.onExit) {
                props.onExit()
            }
        }
        if (response.status === 400) {
            dispatch(openAlert({ type: 'info', message: langue.time_expired }))
            onHandleApiResponse()
            dispatch(openModal({ name: 'modalDelivery' }))
        }
        if (response.status === 500) {
            onHandleApiResponse()
        }
    }
    const onPayOnSite = async () => {
        setIsLoading(true)
        const response = await paiementApi.onPayOnSite(
            { suid: commercant?.suid, lang: lang },
            {
                basket: {
                    delivery_address: deliveryAddress,
                    comment: baskett?.comment,
                    delivery_method: baskett.delivery_method,
                    platform: "web",
                    zone: zone
                }
            })
        if (response.status === 200) {
            setShowCardDetails(false)
            setShow(false)
            setIsLoading(false)
            if (fromRecharge) {
                onCloseModal()
                if (props.onExit) {
                    props.onExit()
                }
                if (location.pathname !== '/mes-recharges') {
                    navigate('mes-recharges')
                }
            } else {
                dispatch(setNbreItems({ nbreItems: null }))
                dispatch(setBasketItems({ items: {} }))
                dispatch(setBasket({ baskett: {}, basketId: basketId }))
                getCommandeDetails()
                onCloseModal()
                if (props.onExit) {
                    props.onExit()
                }
                navigate('mes-commandes')
            }

        }
        if (response.status === 400) {
            dispatch(openAlert({ type: 'info', message: langue.time_expired }))
            onHandleApiResponse()
            dispatch(openModal({ name: 'modalDelivery' }))
        }
        if (response.status === 500) {
            onHandleApiResponse()
        }
    }
    return (
        <Modal
            contentClassName="ds-pointer ds-borad-14 modal-paiment overflow-hidden ds-flex"
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
        >
            <TemplateModalPaiment
                onChange={onChange}
                form={form}
                showCardDetails={showCardDetails}
                onCloseModal={onCloseModal}
                onPayOrder={onPayOrder}
                onSaveCard={onSaveCard}
                paimentMethode={paimentMethode}
                selectedCheckbox={selectedCheckbox}
                onChoosePaimentMethode={onChoosePaimentMethode}
                setShowCardDetails={setShowCardDetails}
                cardSaved={cardSaved}
                onSetSaveCrad={onSetSaveCrad}
                show={show}
                onSetGeneralCondition={onSetGeneralCondition}
                isLoading={isLoading}
            />
        </Modal>
    )
}
export default forwardRef(ModalPaiment)