import React from 'react'
import { useDispatch } from 'react-redux'
import { setCommercant } from '../../Redux/Reducers'
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../CustomHooks';

function PageSites(props: any) {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { width } = useWindowSize()

    const goToSite = (site: any) => {
        dispatch(setCommercant({ commercant: site }))
        navigate(`/menuCommercant/${site?.suid}`)
    }

    const renderSites = () => {
        return props.sites?.map((site: any) => {
            return (<div className="ds-w-50 ds-mx-10 ds-my-5 site-card ds-pointer" onClick={() => goToSite(site)}>
                <div className="ds-w-100 ds-hp-180" style={{
                    backgroundImage: `url(${site?.background?.large_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderTopRightRadius: 8,
                    borderTopLeftRadius: 8
                }}></div>
                <div className="ds-flex-col ds-my-12 ds-text-center">
                    <span className="ds-text-size-18 ds-text-line-21 ds-text-weight600 ds-text-primary100">
                        {`${site?.company} `}
                    </span>
                    <span className="ds-text-size-16 ds-text-line-17 ds-text-weight500 ds-text-primary100 ds-mw-500">
                        {site?.city}
                    </span>
                </div>
            </div>
            )
        }
        )
    }

    return (
        <div className="ds-w-100 ds-flex-col ds-justify-center" style={{ backgroundColor: 'rgba(0,0,0,.05)' }}>
            <div className="ds-flex-col ds-align-center ds-pt-40">
                <img src={props.sites[0]?.logo?.xsmall_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")} className="ds-radius-50 small-picture ds-wp-100 ds-hp-100" />
                <div className="ds-flex-col ds-mt-24 ds-text-center" style={{padding: '0 20px'}}>
                    <span className="ds-text-size-36 ds-text-line-43 ds-text-weight600 ds-text-primary100">
                        {`${props.sites[0]?.company} `}
                    </span>
                    <span className="ds-text-size-16 ds-text-line-21 ds-text-weight500 ds-text-primary100 ds-mw-500 ds-my-24">
                        {props.sites[0]?.description}
                    </span>
                </div>
            </div >
            <div className={`${width > 1024 ? 'ds-justify-center' : ''} ds-flex ds-w-100 overflow-auto`} style={{padding: '0 15px'}}>
                {renderSites()}
            </div>
        </div>
    )
}

export default PageSites