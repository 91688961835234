import { useState } from "react"
import { typeState } from "../../interfaces"

const useCustomState = (value: typeState) => {
    const [state, setState] = useState({
        value: value,
        isValid: false,
        successMessage: "",
        isInvalid: false,
        errorMessage: "",
    })
    return [state, setState]
}
export { useCustomState }