import { IKeys } from "../../Interfaces";

const lang: IKeys = {
  connexion: "Inloggen",
  register: "register",
  info_register: "Om online te bestellen!",
  login: "Inloggen",
  haveAccount: "U heeft geen account",
  haventAccount: "Heb je geen account?",
  haventCode: "Code niet ontvangen?",
  continue_with: "OF VERDER MET",
  info_login: "Eén account, al mijn handelaars",
  intro: "Registreren of inloggen om ten volle te profiteren van de online besteldienst",
  i_order: "Ik bestel",
  baseline: "U heeft maar één account nodig om toegang te krijgen tot alle winkels",
  welcome: "Welkom",
  shops: "Mijn handelaren",
  shops2: "De winkels",
  orders: "Mijn aankopen",
  profile: "Mijn account",
  map: "Om mij heen",
  map_search_around: "Zoek naar een stad, een handelaar",
  about: "Over",
  tnc: "Wettelijke kennisgeving",
  notice: "Algemene verkoopsvoorwaarden",
  litigation: "Geschillenbeslechting",
  copyright: "Digicommerce © {{year}} _ Alle rechten voorbehouden",
  contact: "Contacteer Ons",
  i_search: "Ik ben op zoek naar",
  i_search_text: "een handelaar om mij heen",
  i_recommend: "Doe een aanbeveling",
  i_recommend_text: "van mijn favoriete handelaar",
  for: "Voor",
  number_mobile: "Mobiel nummer",
  email_address: "E_mailadres",
  phone_code_info: "Een code wordt per sms verzonden om uw telefoonnummer te verifiëren",
  email_code_info: "Een e_mail zal een code worden verzonden om uw e_mailadres te verifiëren",
  is_account: "al een account?",
  button_continue: "Doorgaan",
  button_ignorer: "Negeren",
  button_continue_partner: "Mijn code valideren",
  button_ignorer_partner: "Doorgaan zonder code",
  code_label1: "Code van",
  code_label2: "Bevestiging",
  code_entry_phone_label: "Voer de code in die u heeft ontvangen ",
  code_entry_email_label: "Voer de code in die u heeft ontvangen ",
  code_resend: "RETOUR EEN CODE",
  use_email: "GEBRUIK EEN POSTADRES",
  use_phone: "GEBRUIK EEN MOBIEL TELEFOONNUMMER",
  finalize_label: "Af te ronden",
  finalize_label2: "Mijn registratie",
  form_firstname: "Voornaam",
  firstName: "Voornaam",
  form_lastname: "Naam",
  form_email: "Email",
  email: "naam@email.com",
  form_mobile: "Mobiel num",
  form_password: "Wachtwoord",
  optin_infos: "Om goede deals en promoties van verkopers te ontvangen",
  optout_infos: "Om aanbiedingen van partners te ontvangen",
  cgv_label1: "Door dit formulier te valideren, verklaar ik dat ik het heb gelezen en aanvaard ",
  cgv_label2: "algemene voorwaarden",
  button_end: "Eindigen",
  code_required: "De code is verplicht",
  code_invalid: "De code bestaat uit 4 cijfers",
  code_wrong: "De code is niet geldig",
  firstname_required: "De voornaam is verplicht.",
  lastname_required: "De naam is verplicht.",
  password_required: "Het wachtwoord is verplicht",
  password_minlength: "Het wachtwoord moet minimaal 8 tekens bevatten.",
  godfatherCodeUsed_label: "Voordeel",
  godfatherCodeUsed_label2: "Sponsoring",
  godfatherCodeUsed_placeholder: "Uw sponsorcode",
  godfatherCodeUsed_info: "Voer de sponsorcode in en bied uw sponsor punten aan voor uw eerste online bestelling.",
  continueWith_fb: "Ga verder met Gmail",
  continueWith_gmail: "Ga verder met facebook",
  or: "Goud",
  validate: "Bevestigen",
  cancel: "Annuleren",
  next: "Volgende",
  previous: "Terug",
  add: "Toevoegen",
  send: "Verzenden",
  connect: "Inloggen",
  connect2: "Inloggen",
  disconnect: "Ontkoppelen",
  modify: "wijzigen",
  delete: "Verwijder",
  menu: "menu",
  read_more: "meer weten",
  reduce: "verminderen",
  passed: "Pass",
  empty: "Nog niets",
  save: "Opnemen",
  title: "Allergenen",
  text1: "Hieronder vindt u de correspondentie van de kleuren van de 14 belangrijkste allergene ingrediënten die zijn opgesomd in decreet nr. 2008_1153 van 7.11.2008. Wij nodigen u uit om regelmatig onze informatie over allergenen te raadplegen, aangezien de samenstelling van onze producten kan worden gewijzigd. We kunnen de toevallige aanwezigheid van andere allergene ingrediënten die zich tijdens de productie van onze producten kunnen hebben voorgedaan, niet uitsluiten",
  text2: "Voor glutenhoudende granen: tarwe, rogge, gerst, haver, spelt, haver, spelt, kamut,..... Voor noten: amandelen, hazelnoten, walnoten, pecannoten, paranoten, paranoten, pistaches, macadamianoten en moeren van Queensland Voor sulfieten: alleen als de hoeveelheid zwaveldioxide en sulfieten groter is dan 10 mg / kg eindproduct",
  buy: "Kopen in punten",
  cancel_point: "Annuleer de aankoop in punten",
  deals_title1: "Goede loyaliteitsplannen",
  deals_title2: "De goede deals",
  deals_title3: "Voordeel aanbiedingen",
  deals_title4: "Te ontdekken",
  myaccount_title: "Mijn klantenaccount",
  mycredit: "Mijn tegoed en punten",
  pos_link_title: "Une carte de fidélité ?",
  pos_link_desc: "Renseigner votre numéro de carte pour cumuler votre fidélité boutique",
  pos_link_btn: "Ajouter",
  myedenred: "Mijn Edenred",
  restoflash: "Resto Flash",
  restaurant_card_not_enough: "Het beschikbare bedrag op uw restaurantkaart is onvoldoende.",
  reload: "Oplaadapparaat",
  return: "Bekijk de catalogus",
  not_enough_credit: "Je hebt niet genoeg krediet.",
  invalid: "De geselecteerde ophaaltijd is ongeldig.",
  free: "gratis",
  free_plural: "gratis",
  minor_prohibited: "Product uitsluitend bestemd voor volwassenen",
  composed: "Stel uw",
  promo: "Promotie",
  discount_rule: "Voor {{repetition}} {{name}} gekocht, {{quantity}} aangeboden, afhankelijk van beschikbaarheid.",
  warning: "Houd er rekening mee dat de aangeboden producten kunnen verschillen van de gekochte producten.",
  composition: "Samenstelling",
  for_100gr: "Voor 100gr",
  by_united: "Per eenheid",
  defrost_text: "Ontdooid product. Vries een ontdooid product nooit opnieuw in!",
  title_time: "Afhalingstijd",
  today: "Vandaag",
  mandatory_choice: "De keuze van de optie is verplicht",
  basket_amount: "Hoeveelheid van uw mandje",
  title_basket: "Bestelmandje",
  comment_placeholder: "Commentaar",
  empty_basket: "Uw winkelwagen is leeg.",
  min: "minimumbestelmandje.",
  voucher_placeholder: "Kortingsbon",
  delivery_method_mandatory: "U moet een opnamemethode kiezen.",
  delivery_method_title: "Kies een opnamemethode",
  delivery_method_subtitle: "levering van {{amount}}",
  delivery_method_collect: "Afhalen",
  delivery_method_eatin: "Ter plaatse",
  delivery_method_delivery: "In levering",
  delivery_method_delivery_point: "Punt van terugtrekking",
  delivery_method_picking: "En plukken",
  delivery_method_none: "Terugtrekkingsmodus",
  delivery_price: "Verzendkosten",
  delivery_order_in_advance: "Remise commande anticipée",
  delivery_free: "Levering franco",
  discounts_first_order: "Voordelen van eerste bestellingen",
  discount_first_order: "Eerste order voordeel",
  order_method_title: "Kies een betaalmethode",
  order_method_title2: "Bestelling met verplichte betaling",
  order_method_credit: "In kredietrekening",
  order_method_bankcard: "Met creditcard",
  order_method_upon_receipt: "Aan de balie",
  order_method_upon_delivery: "Bij ontvangst",
  order_method_restaurant_card: "Met een restaurantkaart",
  order_method_restaurant_restoflash: "Restoflash",
  order_method_restoflash: "Restoflash",
  order_method_free: "Zonder te betalen",
  order: "Bevestigen",
  time_placeholder: "Kies een schema",
  partnership_title: "Sociaal voordeel",
  partnership_text: "toegepaste verlaging",
  discount_title: "Privilege voordeel",
  discount_text: "toegepaste verlaging",
  add_basket: "Toevoegen",
  add_panier: "Toevoegen",
  amount_not_enough: "Bedrag van de ontoereikende korf.",
  min_amount: "Uw winkelmandje moet ten minste de volgende {{amount}} zijn ",
  //voucher
  not_for_you: "Deze coupon is niet voor u bestemd.",
  already_used: "Reeds gebruikte kortingscoupon.",
  success: "Er is rekening gehouden met uw coupon.",
  invalid_voucher: "Uw coupon is niet geldig.",
  validate_voucher: "Ik valideer mijn code",
  add_succes: "met succes toegevoegd:",
  error_delivery: "Probleem met de levering!",
  info_quantity: "U heeft {{number}} producten in uw winkelwagentje",
  label_number_product: "TOTALE PRODUCTEN",
  label_price_delivery: "VERZENDKOSTEN",
  label_total: "TOTAAL incl.BTW",
  free_delivery: "Nog {{price}} en profiteert van gratis levering!",
  label_free_delivery: "Gratis levering!",
  info_price_variation: "De verzendkosten variëren afhankelijk van de afstand.",
  info_distance_delivery: "Het aangegeven adres ligt tussen {{a}} en {{b}} km",
  info_max_distance_delivery: "Het aangegeven adres ligt op meer dan {{number}} km afstand.",
  info_remove_product: "Verwijder artikelen uit het winkelmandje of wijzig de opnamemodus.",
  continue_shopping: "Ik ga door met mijn aankopen",
  pay_items: "Ik betaal voor mijn artikelen",
  fees: "Servicegebühr",
  description_fees: "De servicekosten worden herverdeeld naar het Digicommerce platform en maken het onderhoud en de ontwikkeling van deze dienst mogelijk.",
  title_rda: "Dagelijkse inname",
  title_composition: "Samenstelling",
  title_recipe: "Voorstellen",
  title_availability: "Beschikbaarheid",
  text1_availability: "Voor een hoeveelheid groter dan  ",
  text2_availability: "producten, staat u alstublieft een vertraging van meer dan ",
  text2_single_availability: "product, laat meer dan toe ",
  text1_contact: "U kunt contact met ons opnemen als u een verduidelijking, een klacht of een suggestie heeft.",
  text2_contact: "Wij verbinden ons ertoe u zo snel mogelijk te antwoorden.",
  label_mail: "Uw e_mailadres",
  required_mail: "Het e_mailadres is verplicht.",
  invalid_mail: "Het e_mailadres is niet correct.",
  label_firstname: "Uw voornaam",
  required_firstname: "De voornaam is verplicht.",
  label_lastname: "Uw naam",
  required_lastname: "De naam is verplicht.",
  label_message: "Bericht",
  required_message: "Voer een bericht in.",
  success_msg: "Uw bericht is succesvol verzonden",
  maxSize: "Uw bestand is groter dan 5 MB",
  h1_send: "Opnieuw in",
  pwd_send: "Vernieuw uw wachtwoord",
  code_validation: "Mijn account valideren",
  new_code_validation: "Mijn code terugsturen",
  new_link_validation: "Email opnieuw verzenden",
  lost_password: "Wachtwoord vergeten?",
  invalid_mail_password: "Onjuiste e_mail of wachtwoord",
  email_send: "Er is een e_mail naar u verzonden.",
  email_invalid: "Onjuist e_mail adres.",
  password_change: "Uw wachtwoord is gewijzigd.",
  test_used: "Uw 3 tests worden gebruikt. Probeer het opnieuw.",
  rest: "Uw bevestigingscode is onjuist.",
  //map
  search: "Zoeken naar",
  list: "Een handelaar zoeken",
  open: "Dienstregelingen",
  open_soon: "Binnenkort open",
  bluebox_title: "Mijn verkopers met één druk op de knop",
  bluebox_subtitle1: "Wachten, wat een vreemd idee !",
  bluebox_text1: "Profiteer van een in_store queue cutter service. Geen wachtrijen meer. Verdubbel op iedereen, dit is het principe hier",
  bluebox_subtitle2: "Reserveer uw producten vooraf",
  bluebox_text2: "Geen onaangename verrassingen meer, bestel uw favoriete producten van tevoren of.... voor nu. Bij elke gelegenheid.",
  bluebox_subtitle3: "Profiteer van de goede deals",
  bluebox_text3: "Profiteer nu van alle speciale aanbiedingen. U wordt als eerste op de hoogte gebracht van de goede plannen en loyaliteitsvoordelen.",
  bluebox_subtitle4: "Geen behoefte meer aan verandering",
  bluebox_text4: "Betaal uw aankopen online of in de winkel met uw klantenaccount. Oplaadbaar met uw contant geld, restauranttickets of CB.",
  bluebox_button: "Vind mijn dealer",
  comming_soon: "Binnenkort te openen",
  comming_soon_text: "Onze winkelier zal binnenkort open zijn. Bedankt voor uw geduld.",
  to_collect: "Gepland in",
  collect: "klaar",
  ready_on: "De",
  amount: "hoeveelheid",
  article: "artikel",
  article_plural: "artikels",
  order_passed: "bestelling geplaatst op",
  item_delivery: "Verzendkosten",
  error_collected_at: "De tijd van uw bestelling is niet meer geldig, wijzig de tijd a.u.b. ...",
  error_products_collected_at: "U kunt uw winkelmandje niet voor {{date}} bestellen. Wijzig het tijdstip van de bestelling of verwijder niet_beschikbare producten ...",
  error_product_collected_at: "U kunt uw winkelmandje niet voor {{date}} bestellen. Wijzig het tijdstip van de bestelling of verwijder het niet beschikbare product ...",
  reco_title: "Aanbevelen",
  placeholder: "Zoek hier naar uw dealer",
  reco_text1: "Adviseer en verdien 10€ krediet bij uw handelaar als hij/zij lid is van de Food & Collect Digicommerce.",
  reco_text2: "Zoek het adres van uw winkelier en vul uw e_mailadres in, zodat wij weer contact met u kunnen opnemen.",
  success_recommandation: "Dank u wel, uw aanbeveling is met succes verzonden.",
  title_find_shop: "Een handelaar zoeken",
  p_find_shop: "Vind een dealer bij u in de buurt, bestel en het zal in uw favorieten staan.",
  title_recommendation: "Een handelaar aanbevelen",
  p_recommendation: "En verdien €10 aan krediet van uw winkelier, als ze lid worden van de Food &amp; Collect Digicommerce.",
  title_profile: "Mijn account",
  title_register: "Maak mijn account aan",
  title_validation: "Account bevestiging",
  email_label: "Email",
  email_placeholder: "Uw e_mailadres",
  email_required: "Het e_mailadres is verplicht.",
  email_valid_error: "het e_mailadres is niet beschikbaar",
  password_label: "Wachtwoord",
  password_new_label: "Nieuw wachtwoord",
  password2_label: "Wachtwoord verificatie",
  password2_placeholder: "Wachtwoord verificatie",
  password2_invalid: "Wachtwoordverificatie is onjuist.",
  company_label: "Onderneming",
  company_placeholder: "Uw bedrijf (optioneel)",
  phone_label: "Telefoonnummer",
  phone_placeholder: "06123456789",
  phone_required: "Het telefoonnummer is verplicht.",
  phone_invalid: "Het telefoonnummer is niet correct.",
  phone_not_mobile: "Voer een mobiel telefoonnummer in.",
  phone_not_available: "Er bestaat al een account voor dit telefoonnummer.",
  mail_not_available: "Er bestaat al een account voor dit email.",
  dob_label: "Geboortedatum",
  dob_placeholder: "dd/mm/jjjj",
  dob_invalid: "Ongeldige geboortedatum",
  countryCode_label: "Land",
  countryCode_placeholder: "Uw land",
  membershipCard_label: "Badge nummer of klantenkaart",
  membershipCard_placeholder: "Uw badgenummer of klantenkaartnummer",
  membershipCard_label2: "Badge nummer of klantenkaart",
  membershipCard_placeholder2: "Uw badgenummer of klantenkaartnummer",
  membershipCard_valid: "Uw nummer is geldig",
  membershipCard_invalid: "Uw nummer bestaat niet",
  label_godfatherCodeUsed: "Sponsoring Voordeel",
  placeholder_godfatherCodeUsed: "Uw sponsorcode",
  godfatherCodeUsed_valid: "Uw code is geldig",
  godfatherCodeUsed_invalid: "Uw code is niet geldig",
  partnershipCode_label: "Enterprise reductie",
  partnershipCode_placeholder: "Uw bedrijfskortingscode",
  partnershipCode_placeholder2: "Voer uw code in",
  partnershipCode_add: "Een code toevoegen",
  partnershipCode_valid: "Uw code is geldig",
  partnershipCode_invalid: "Uw code is niet geldig",
  partnershipCode_help: "Voeg hier uw bedrijfscode toe om te profiteren van uitzonderlijke voordelen, kortingen of promoties op uw bestellingen",
  student_label: "Ik ben een student (uw kaart wordt bij elke opname opgevraagd)",
  optin_label: "Ik wil graag promotionele informatie ontvangen",
  optout_label: "Ik wil graag aanbiedingen van partners ontvangen",
  accept_label: "Ik heb de algemene verkoopsvoorwaarden van de site gelezen en accepteer deze.",
  accept_required: "U moet de algemene verkoopsvoorwaarden aanvaarden.",
  button_register: "Inschrijven",
  facturation_address: "Factuuradres",
  delivery_address: "Leveringsadres",
  saved_delivery_address: "Opgeslagen afleveradres",
  address: "Adres",
  postcode: "Postcode",
  city: "Stad",
  cellphone: "telefoneren",
  born: "geboren op",
  credit_card: "Kredietkaart",
  credit_card_helper: "U kunt de creditcard die u tijdens een vorige aankoop heeft geregistreerd verwijderen",
  credit_card_helper2: "Registratie zal worden aangeboden op het moment van uw aankoop",
  edenred_card: "Restaurant Ticket®",
  edenred_card_helper: "Login om uw aankopen te betalen met uw Ticket Restaurant®_kaart bij uw handelaar",
  restoflash_card: "De onbeperkte restaurantbon",
  restoflash_card_helper: "Betaal uw aankopen met Resto Flash bij uw winkelier, kies bij het afronden van uw winkelmandje voor de optie \"Resto Flash\"",
  godfather_text: "Deel uw verwijzingscode en verdien {{site.credit_godfather}} punten voor de eerste online bestelling van uw petekind",
  godfather_subject: "Sponsoring aanbod",
  godfather_message: "{{profile.firstname}} biedt u X punten op uw volgende online bestelling met de code {{profile.godfather_code}}",
  smscode_label: "Bevestigingscode",
  smscode_placeholder: "bijvoorbeeld: XXXX",
  smscode_text: "U ontvangt een bevestigingscode per SMS om de aanmaak van uw account te bevestigen",
  smscode_required: "De code is verplicht",
  smscode_invalid: "De code bestaat uit 4 cijfers",
  link_validation_label: "Bevestigingslink",
  send_activate_link: "Er is een activatielink naar uw mailbox gestuurd.",
  button_scan: "Scan mijn kaart",
  and_connected: "en verbonden",
  profile_success: "Uw profiel is opgeslagen.",
  profile_error: "Kan niet worden bijgewerkt. Probeer het opnieuw.",
  scan_error: "Kan uw kaart niet scannen, probeer het opnieuw.",
  register_success: "Uw account is aangemaakt. U ontvangt een activeringscode op uw mobiele telefoon of mailbox",
  title_payment: "Betaalmiddelen",
  title_address: "Coördinaten",
  title_perso: "Persoonlijke informatie",
  title_lang: "Taal",
  title_god_father: "Mijn sponsorcode",
  voucher_success: "Er is rekening gehouden met uw bedrijfsvoordeelcode.",
  share: "{{firstname}} biedt u 10 punten op uw volgende online bestelling met de verwijzingscode {{godfather_code}}. Ga naar {{{url}}",
  share_title: "Sponsoring aanbod",
  send_activate_code: "Er is een activeringscode naar u verzonden op uw mobiele telefoon of in uw mailbox.",
  not_validate_code: "Uw inschrijving is niet bevestigd.",
  account_validate: "Uw account is gevalideerd.",
  new_email_account_validate: "Er is rekening gehouden met uw nieuwe e_mail.",
  can_connect: "U kunt nu inloggen.",
  code_error: "Uw code is onjuist. Probeer het opnieuw.",
  membership_card_lastname: "Vul uw achternaam in.",
  already_leave: "Ga je al weg ?",
  modify_email: "Ik bevestig de wijziging van mijn e_mailadres.",
  modify_email_description: "Na validatie van het formulier, nodigen wij u uit om uit te loggen. Ga naar het gevalideerde gedeelte van mijn account met uw nieuwe validatiecode om de wijziging van het e_mailadres effectief te maken. ",
  delete_profile: "Ik bevestig de verwijdering van mijn account.",
  label_delete_profil: "Account verwijderen",
  delete_profile_description: "Wij herinneren u eraan dat het verwijderen van een account een onomkeerbare actie is.",
  username_not_valid: "Dit e_mailadres is niet beschikbaar. Voer een ander e_mailadres in.",
  new_email_label: "Vul uw nieuwe e_mailadres in",
  info_code_label: "Voer de ontvangen code in",
  info_send: "Probeer het over {{minute}} minuten opnieuw.",
  got: "U heeft de beschikking over ",
  points: "punten",
  credits: "krediet",
  title_prepaye: "Mijn prepaid account",
  my_credit: "Mijn krediet",
  charger: "Laden",
  my_total_credit: "De stand van uw rekening",
  my_points: "Mijn loyaliteitspunten",
  points_text: "Gebruik het in uw winkelwagen om te profiteren van kortingen",
  credit_offers: "Herlaadaanbieding",
  credit_other: "Oplader mon compte",
  credit_added: "van het gekochte krediet",
  credit_given: "aangeboden",
  partnership: "Sociaal voordeel",
  partnership_discount: " korting",
  partnership_text1: "dankzij {{value.name}}, op uw online bestellingen",
  discount: "Privilege voordeel",
  discount_title1: " korting",
  discount_text1: "voor uw loyaliteit, op uw online bestellingen",
  not_benefite: "U heeft op dit moment geen voordeel.",
  only_shop: "alleen in de winkel",
  refill_confirm: "Je staat op het punt te herladen",
  bank_confirm: "U staat op het punt te betalen",
  refill_thanks: "Op uw kredietrekening en wij danken u.",
  bank_thanks: "Met creditcard en we danken u.",
  bank_confirm_title: "Betalingsbevestiging",
  refill_bankcard_carrefully: "Waarschuwing!",
  refill_bankcard_saved: "Een creditcard is geregistreerd.",
  refill_paiement_send: "De betaling wordt direct verzonden.",
  refill_confirm_button: "Ik bevestig",
  refill_no_card_confirm: "Door te bevestigen,",
  refill_no_card_redirection: "U wordt doorgestuurd naar de betaalpagina",

  //payment
  generalConditions: "ik aanvaard de algemene voorwaarden",
  done: "Betaling gedaan.",
  error: "Betalingsfout.",
  success_pay: "Uw betaling is gedaan.",
  loading: "Betaling in behandeling.",
  wrong: "Oeps! Er is iets fout gegaan",
  verify_your_account: "Verifieer uw account en probeer het opnieuw.",
  home_page: "Ga naar homepagina",
  validate_pay: "Uw bestelling is gevalideerd",
  title_pay: "Betaalmiddelen",
  subtitle: "bestelling met betalingsverplichting",
  cards_title: "Geregistreerde kaarten",
  add_title: "Voeg een betaalkaart toe",
  add_desc: "bank_ of restaurantvoucher",
  empty_title: "Geen kaart opgeslagen",
  empty_desc: "Voeg een kaart toe om uw volgende aankoop te vereenvoudigen",
  loading_title: "Herstel",
  loading_desc: "van uw geregistreerde betaalmiddel",
  add_card: "Nieuwe betalingsmethode?",
  exp_on: "Vervalt op",
  card_selected: "selected",
  card_choose: "choose",
  card_save: "Bewaar mijn kaart",
  with_card_save: "De opgeslagen kaart wordt gebruikt",
  pay: "Betalen",
  confirm: "Bevestigen",
  bank_title: "Met bankpas",
  bank_desc: "online betalen",
  bancontact_title: "Bancontact",
  bancontact_desc: "Betaal met bancontact",
  credit_title: "Kredietrekening",
  credit_desc: "beschikbaar op uw account",
  free_title: "Zonder te betalen",
  free_desc: "dankzij mijn loyaliteit",
  uponDelivery_title: "Bij ontvangst",
  uponDelivery_desc: "betalen bij aflevering",
  uponReceipt_title: "Ter plaatse",
  uponReceipt_desc: "betalen in de winkel",
  edenred_title: "MyEdenred",
  edenred_desc: "met uw Edenred_account",
  restoflash_title: "Restoflash",
  restoflash_desc: "De restauranttitel zonder limiet",
  error_delivery_pay: "Let op, het leveringsbedrag is gewijzigd! Bevestig de betaling nogmaals om de nieuwe prijs te bevestigen.",
  error_generic: "Er is een probleem opgetreden, probeer het later opnieuw.",
  error_prepare_intent: "Er is een fout opgetreden bij het valideren van uw betaling, probeer het later opnieuw.",
  error_pm_unknown: "Selecteer een betalingsmethode",
  error_already_charged: "Uw bestelling is al betaald.",
  error_insufficient_amount: "Het minimum bedrag is {{ min_amount }}€ om met een creditcard te kunnen betalen!",
  //weekdays
  Monday: "maandag",
  Tuesday: "dinsdag",
  Wednesday: "woensdag",
  Thursday: "donderdag",
  Friday: "vrijdag",
  Saturday: "zaterdag",
  Sunday: "zondag",
  title_timetable: "Openingstijden winkel",
  text_timetable: "van {{open1}}{{close1}} _ {{open2}}{{close2}}",
  title2: "Openingstijden van de dienst",
  close: "Gesloten",
  //delivery_modal
  delivery_modal_title: "Voor levering aan",
  not_add: "geen extra kosten",
  distance_danger: "De afstand is te lang ({{distance}} km)",
  distance_to_far: "De levering wordt niet verzorgd op het gekozen adres",
  distance_delivery: "De leveringsafstand mag niet groter zijn",
  and_buy: "en de bijkomende kost",
  delivery_is: "Vanaf een bestelling",
  delivery_modal_free: "",
  at: "van",
  of_purchase: "is de levering zonder meerkost.",
  delivery_modal_text1: "Voor elke bestelling geplaatst voor 00u00 profiteert u van ",
  delivery_modal_text2: "tussen 00:00 en",
  text3: "en tussen {{start}} en {{end}}",
  incomplete_address: "Het geselecteerde adres is onvolledig voor de bezorger!",
  invalid_address: "Adres niet gevonden, controleer het adres.",
  address_to_far: "Het geselecteerde adres is te ver van uw handelaar!",
  to_large: "Het volume van de bestelling is te groot voor de bezorger !",
  number: "Nombre:",
  street: "Straat:",
  postal_code: "Postcode:",
  delivery_modal_city: "Stad:",
  back: "Terug",
  confirm_address: "Ik bevestig mijn adres",
  save_address: "Bewaar het adres",
  confirm_phone: "Bevestiging van het telefoonnummer van de bezorger",
  confirm_informations: "Ik valideer mijn gegevens",
  infos_text: "Gegevens:",
  but: " maar ",
  possible_cities_1: "Het is ook mogelijk in de steden van:",
  possible_cities_2: "Het is mogelijk in de steden van:",
  possible_cities_3: "Het is alleen mogelijk in de steden van:",
  possible_postal_codes_1: "Het is ook mogelijk in steden met de volgende postcodes:",
  possible_postal_codes_2: "Het is mogelijk in steden met de volgende postcodes:",
  possible_postal_codes_3: "Het is alleen mogelijk in steden met de volgende postcodes:",
  //banner
  banner_title: "Onze goede tips !",
  banner_to_find: "te ontdekken",
  banner_promotions: "acties",

  incoming: "Wordt uitgevoerd",
  not_enough_points: "Je hebt niet genoeg punten.",
  incoming_text: "Uw bestelling is in voorbereiding (fin {{dateFormat}})",
  order_ready: "Uw bestelling is klaar",
  waiting_job: "Wachten op een bezorger",
  job_canceled: "De bezorger is er niet in geslaagd contact met u op te nemen en kon u niet bezorgen.",
  job_voided: "Levering probleem, uw bestelling wordt terugbetaald.",
  job_expired: "Geen levering beschikbaar voor uw bestelling, uw bestelling wordt terugbetaald.",
  waiting_processing: "Wachten op validatie van uw bank.",
  delivery_follow: "Volg mijn bezorger",
  ordering_at: "Besteld op",
  for_to: "Voor de",
  deliveree_support: "U kunt contact opnemen met de klantendienst op (+33) 09 75 18 21 01",

  title_history: "Mijn bestellingen",
  invoice_history: "FACTUUR",
  order_again_history: "Bestel opnieuw",

  mes_recharge: "Mijn navullingen",
  createdAt: "Herladen op",
  allLoaded: "Alle gegevens zijn geladen",

  Re_commander: "Herbestelling",
  warning_dispo: "Sommige producten zijn mogelijk niet beschikbaar.",
  not_delivery_time: "Levertijden zijn niet geconfigureerd.",
  add_item_basket: "Item toegevoegd aan het winkelwagentje.",
  item_not_available: "Er zijn geen producten beschikbaar.",

  cgv: "Algemene verkoopsvoorwaarden",
  ml: "Wettelijke informatie",
  to: "om",
  other_at: "om",
  between: "tussen",
  and: "en",
  deliveree_week_between: "Verwachte levering {{text}} tussen {{start}} en {{end}}",
  deliveree_far_between: "Verwachte levering tussen {{start}} en {{end}}",
  today2: "vandaag",
  total: "totaal",
  ours: "Onze",
  delete_compte: "Mijn account verwijderen",
  cookies: "Ce site utilise des cookies pour que vous puissiez passer des commandes, en l'utilisant, vous acceptez notre politique de cookies disponibles dans les mentions légales."

  ,
  //msg alert
  empty_disponiblity: `Er zijn niet genoeg producten beschikbaar.`,
  opening_soon: 'Onze winkel zal binnenkort open zijn. Bedankt voor uw geduld.',
  merchants: 'Mijn winkeliers',
  search_merchant: 'Een handelaar zoeken',
  //mes commercant categories
  all: 'Alle',
  snacking: 'snacken',
  restaurant: 'Restaurant',
  bakery: 'Bakkerij',
  butcher: 'Slagerij',
  // recharges
  refills: 'Mijn opwaarderingen',
  recharged: 'Opgeladen',
  // basket
  element_added: 'Een artikel is toegevoegd aan de winkelwagen.',
  minimum_basket: 'Uw winkelwagen moet minimaal zijn',
  // status commercant
  open_now: 'Nu geopend',
  closed_now: 'Nu gesloten',
  last_order: 'Laatste bestelling bij',
  plan: 'Plan',
  avis: 'beoordelingen',
  search_address: 'Zoeken op plaats, categorie of verkoper',
  location_search: 'Voer uw locatie in',
  menu_search: 'Gerechten en producten zoeken',
  become_retailer: 'Een detailhandelaar worden',
  // commande en cours 
  minutes: 'minuten',
  hours: 'Uren',
  in: 'In',
  delivered: 'Geleverd',
  invoice: 'Overzicht',
  my_orders: 'Mijn bestellingen',
  add_comments: 'Opmerkingen toevoegen',
  complaint_comments: 'Wil je specifieke informatie over onze producten of wil je gewoon contact met ons opnemen? Vul dan onderstaand formulier in.',
  committed_serving: 'We doen er alles aan om je onder de best mogelijke omstandigheden van dienst te zijn.',
  insert_comments: 'Geef uw commentaar',
  loyalty: 'Loyaliteit',
  good_deals: 'Goede deals',
  advantage_offers: 'Voordeelaanbiedingen',
  contact_us: 'Neem contact met ons op',
  numbre_characters: 'Aantal resterende tekens : ',
  attachment: 'Bijlage',
  select_file: 'Kies een bestand',
  amount_insufficient: 'het bedrag in uw winkelmandje is onvoldoende voor deze promotiecode ',
  u_have: 'Je hebt',
  discount_percent: '% korting op winkelmandje ',
  promo_code_label: 'Actiecode invoeren',
  apply: 'Toepassen',
  no_promotion: `Je hebt momenteel geen promotie`,
  articles: ` artikelen `,
  message_sent: `Bericht verstuurd`,
  message_sent_successfully: `Uw bericht is succesvol verzonden.`,
  bank_card: `Visa Debetkaart`,
  add_payment_card: `Een betaalkaart toevoegen`,
  pay_boncontact: `Betalen met Bancontact`,
  credit_account: `Kredietrekening`,
  on_site: `Op locatie`,
  pay_your_merchant: 'Betalen bij uw handelaar',
  time_expired: 'Uw ophaaltijd is verstreken.',
  owner_name: `Naam van de eigenaar`,
  card_name: 'Naam op de kaart',
  card_number: 'Kaartnummer',
  expired_date: `Vervaldatum`,
  save_card: `Mijn kaart opslaan`,
  conditions_of_sale: `Ik aanvaard de algemene verkoopsvoorwaarden`,
  payment_method: `Betalingsmethode`,
  payment_obligation: `Bestelling met betaalverplichting`,
  opennow: 'Geopend',
  closenow: 'Gesloten',
  accepts_orders: `Accepteert bestellingen tot`,
  fair_prices: `Eerlijke prijzen • Gezond`,
  call: `Bellen`,
  opening_hours: `Openingstijden`,
  pickup_hours: `Afhaaltijden`,
  account_credit: 'Mijn krediet',
  point: 'Punt(en)',
  basket: 'Mijn mandje',
  empty_your_basket: 'De mand legen',
  compose_formula: 'Stel je pakket samen',
  without_additional_sauce: 'Geen extra saus',
  selected_sauces: 'Geselecteerde saus(sen)',
  add_to_basket: 'In winkelmand',
  comments: 'Opmerkingen',
  pickup_time_after_hours: 'Kies een ophaaltijd van meer dan 2 uur(en)',
  weight: 'Gewicht',
  no_of_pieces: 'Aantal onderdelen :',
  no_file_chosen: 'Geen bestanden geselecteerd',
  planning_order: 'Een bestelling plannen',
  delivery: 'Levering',
  takeaway: 'wegnemen',
  insert_comment: 'Voer uw opmerkingen in door op de knop "Toevoegen" te klikken ',
  insert_promo_code: 'Voer je promotiecode in door op de knop "Valideren" te klikken ',
  search_retailer: 'Dealer zoeken',
  cmd_incomming: 'Bestellingen in uitvoering',
  invoices: 'Mijn facturen',
  discount_received: `U profiteert van een korting van `,
  purchases_percent: '% korting op uw aankopen',
  January: 'Januari',
  February: 'februari',
  March: 'maart',
  April: 'April',
  May: 'mei',
  June: 'juni',
  July: 'juli',
  August: 'augustus',
  September: 'september',
  October: 'oktober',
  November: 'november',
  December: 'december',
  Order_ready: 'Klaar om te bestellen',
  conatct_help: `Als je een van onze aangesloten winkeliers wilt worden en meer informatie wilt, neem dan contact met ons op door dit formulier in te vullen.`,
  message: 'Bericht',
  business_sector: 'Uw activiteitensector',
  activity_sector: 'Sector van activiteit',
  phone_no: 'Telefoon nr.',
  including: '',
  selected_items: 'Geselecteerde item(s)',
  delivery_not_allowed: 'Ongeoorloofde levering',
  Mon: "Maandag",
  Tue: "Dinsdag",
  Wed: "Woensdag",
  Thu: "Donderdag",
  Fri: "Vrijdag",
  Sat: "Zaterdag",
  Sun: "Zondag",
  Lun: "Maandag",
  Mar: "Dinsdag",
  Mer: "Woensdag",
  Jeu: "Donderdag",
  Ven: "Vrijdag",
  Sam: "Zaterdag",
  Dim: "Zondag",
  le: "",
  delivery_on: "Levering op",
  des: "van de",
  achat_livraison: "van aankoop > (levering",
  offert_des: "/ aangeboden vanaf",
  point_retrait: "Verzamelpunten",
  adresses: "adressen van",
  saved_address: "Adres opslaan",
  minimum: "voor minimaal",
  hors_service: "(exclusief servicekosten)"

}
export default lang;