import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthAPI } from "../../Api/Auth"
import { Spinner } from "../../DesignSystem"
import { Type } from "../../DesignSystem/Interfaces/Atoms/IAtomButton/IAtomButton"
import { RootState } from "../../Interfaces";
import { setToken, setUserRegistration } from "../../Redux/Reducers/AuthReducer/AuthReducer";
import { setBasket, setBasketItems, setNbreItems } from "../../Redux/Reducers";
import { ShopAPI } from "../../Api/Shop/shop";
import { UserAPI } from "../../Api/User/user";

const PageRedirectAfterLogin = () => {
    const authApi = new AuthAPI()
    const shopAPI = new ShopAPI()
    const userApi = new UserAPI()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { commercant } = useSelector((state: RootState) => state.commercant)
    const { user } = useSelector((state: RootState) => state.auth)
    const { lang } = useSelector((state: RootState) => state.setting)
    const { zone } = useSelector((state: RootState) => state.basket)
    const queryParams = new URLSearchParams(window.location.search);
    const ref = useRef(false)
    const token = queryParams.get('token')

    useEffect(() => {
        if (ref.current === false) {
            if (token) {
                ref.current = true
                dispatch(setToken({ token: token }))
                setLoggedInUser()
            }
        }
    }, [token])

    const setLoggedInUser = async () => {
        getUserProfile()
        getCustomerProfileMajor()
        getBasket()
        if (user) {
            navigate(`/menuCommercant/${commercant?.suid}`)
        }
    }
    const getUserProfile = async () => {
        const response = await authApi.getUserProfile({ suid: commercant?.suid });
        if (response.status === 200) {
            dispatch(setUserRegistration({ user: response.data }))
        }
    }
    const getBasket = async () => {
        const res = await shopAPI.getBasketOrder({ suid: commercant?.suid, lang: lang, zone: zone })
        if (res.status === 200) {
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
        }
    }
    const getCustomerProfileMajor = async () => {
        const res = await userApi.getCustomerProfile()
        if (res.status === 200) {
            return res
        }
    }

    return (
        <div className="s-m-aduto ds-flex ds-center ds-justify-center" style={{ height: '80vh' }}>
            <Spinner className="ds-hp-50 ds-wp-50" type={Type.secondary} />
        </div>
    )
}

export default PageRedirectAfterLogin