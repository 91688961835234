import React from 'react'
import { Icon, Spinner, AtomButton } from '../../Atoms';
import { DEFAULTBUTTONPROPS, DEFAULTIMPORTATOMICONPROPS, DEFAULTMOLECULEBUTTONPROPS } from '../../DefaultProps/Atoms';
import { Size } from '../../Interfaces/Atoms/IAtomButton/IAtomButton';
import { IMoleculeButton } from '../../Interfaces/Molecule/IMoleculeButton/IMoleculeButton';
function MoleculeButton(props: IMoleculeButton): JSX.Element {
    let iconSize: string | number = "24px"
    let iconMargin = "ds-mr-6"
    if (props.size === Size.small) {
        iconSize = "15px"
    }
    if (props.size === Size.large) {
        iconSize = "36px"
    }
    if (props.isRightIcon) {
        iconMargin = "ds-ml-6"
    }
    const renderIcon = () => {        
        if (props.icon) {
            const iconClassName = ['']
            if (props.text) {
                iconClassName.push(iconMargin)

            }
            return <Icon className={iconClassName.join(' ')} size={iconSize} icon={props.icon} />
        }
        return null
    }

    const renderSpinner = () => {
        if (props.isLoading) {
            const spinnerClassName = [];
            if (props.text) {
                spinnerClassName.push(iconMargin)
            }
            return <Spinner className={spinnerClassName.join(' ')} type={props.type} />
        }
        return null
    }

    const renderBtnContent = () => {
        const list = []
        if (!props.isRightIcon && !props.isLoading) {
            list.push(renderIcon())
        }
        if (!props.isRightIcon && props.isLoading) {
            list.push(renderSpinner())
        }
        list.push(props.text)
        if (props.isRightIcon && !props.isLoading) {
            list.push(renderIcon())
        }
        if (props.isRightIcon && props.isLoading) {
            list.push(renderSpinner())
        }
        return <>{list}</>
    }
    return (
        <AtomButton
            className={props.className}
            style={props.style}
            disabled={props.disabled}
            type={props.type}
            size={props.size}
            onClick={props.onClick}
            isRadius={props.isRadius}
        >
            {renderBtnContent()}
        </AtomButton>
    )
}

MoleculeButton.defaultProps = {
    ...DEFAULTBUTTONPROPS,
    ...DEFAULTMOLECULEBUTTONPROPS,
    ...DEFAULTIMPORTATOMICONPROPS,
}
export default MoleculeButton