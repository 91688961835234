enum TypeLabel {
    "Progress" = "In Progress",
    "Completed" = "Completed",
    "Pending" = "Pending",
    "Approved" = "Approved",
    "Rejected" = "Rejected",
}

interface IAtomLabel {
    className?: string 
    typeLabel?: TypeLabel
    bsPrefix?: string 
    primary?: boolean
    size?:LabelSize
    icon?:string
    iconClassName?:string
    labelText?:string
}
enum LabelSize {
    normal = "normal",
    small = "small"
}

export type{
    IAtomLabel
}
export {
    TypeLabel,
    LabelSize
}