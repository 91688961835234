import React, { useContext } from "react"
import { IRecharge, IRechargeType, ITemplateMesRecharges, RootState } from "../../Interfaces"
import { CommonFunction } from "../../Common"
import { Icon, Label, Spinner, TypeLabel } from "../../DesignSystem"
import { LabelSize } from "../../DesignSystem/Interfaces"
import { LangContext } from "../../Lang"
import { useSelector } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component";

const TemplateMesRecharges = (props: ITemplateMesRecharges): JSX.Element => {
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    const langue = useContext(LangContext)
    const renderPiamentType = (type: string) => {
        switch (type) {
            case 'CB':
                return langue.credit_card;
            default:
                break;
        }
    }
    const renderRechargeElement = (recharge: IRecharge, i: number) => {
        return (
            <div className="ds-flex-col ds-w-100 ds-overflow-x">
                <div className="ds-flex ds-align-center ds-w-100 ds-mt-16">
                    <span className="ds-text-secondary100 ds-text-size-12 ds-text-weight700 ds-text-height-14">{renderPiamentType(recharge?.method?.name)}</span>
                    <Icon
                        icon="clock"
                        className="ds-text-primary70 ds-ml-10 ds-mr-5 ds-mb-5"
                        size={"16px"}
                    />
                    <span className="ds-text-primary70 ds-text-size-12 ds-text-weight400 ds-text-height-14">{langue.le + `${CommonFunction.formatDateFr(recharge?.created_at)}`}</span>
                </div>
                <div className="ds-flex ds-w-100 ds-justify-between">
                    {recharge?.status?.id === 20 ? <span className=" ds-text-error100 ds-text-size-12 ds-text-weight400 ds-text-height-14">{langue.waiting_processing}</span>
                        :
                        <Label
                            typeLabel={TypeLabel.Completed}
                            primary={false}
                            size={LabelSize.small}
                            labelText={langue.recharged}
                        />
                    }
                    <span className="ds-text-primary100 ds-text-size-16 ds-text-weight400 ds-text-height-19 white-space-nowrap">{`${CommonFunction?.setFormatNumber(recharge?.amount)} €`}</span>
                </div>
                {i < props?.recahrgesNotCompleted.length - 1 && <div className="border-dashed ds-w-100 ds-mt-5 ds-mb-5"></div>}
            </div>
        )
    }

    const renderRecharge = (recharges: IRechargeType) => {
        const listImg: JSX.Element[] = []
        const list1: JSX.Element[] = []
        const list: JSX.Element[] = []
        listImg.push(
            <div className='ds-wp-136 ds-hp-94 image-recharge-class' style={{
                backgroundImage: `url(${recharges[0]?.site_details?.background?.medium_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center'
            }}></div>
        )
        list1.push(<div className="ds-flex ds-w-100 ">
        <span className="ds-text-size-16 ds-text-height-19 ds-text-weight700 ds-text-primary100">
            {`${commercant?.company} - `}
            <span className="ds-text-size-16 ds-text-height-19 ds-text-weight700 ds-text-primary100">
                {`${commercant?.city}`}
            </span>
        </span> 
    </div>)
        recharges?.map((el: IRecharge, i: number) => {
                list.push(

                    <div className="ds-flex-col ds-w-100 ">
                        {renderRechargeElement(el, i)}
                    </div>
                )
            
        })
        return (
            <div className="ds-flex ds-w-100 ds-pr-40 recharge-flex-col">
                <div className="image-recharge-class">{listImg}</div>
                <div className="ds-flex-col ds-w-100 ds-ml-16 site-recharge">
                    <div className="site-class">{list1}</div>
                    <div className="ds-flex-col ds-w-100">{list}</div>
                </div>
            </div>
        )
    }
    const renderMesRecharges = () => {
        return (
            <div className="ds-px-40 ds-w-100 ds-h-100 ds-flex-col refill-class">
                <span className="ds-text-size-28 ds-text-height-34 ds-text-weight700 ds-text-primary100">
                    {langue.refills}
                </span>
                {props.recahrgesNotCompleted && props.recahrgesNotCompleted.length !== 0 &&
                    <InfiniteScroll
                        dataLength={props.recahrgesNotCompleted.length}
                        next={props.fetchMoreData}
                        hasMore={true}
                        loader={!props?.stopBottom && <Spinner className="ds-hp-30 ds-wp-30 color-spinner"/>}
                    >
                        <div className="ds-pt-40 ds-flex-col ds-w-100 ds-h-cal-100percent-34px ds-overflow-x">
                            {renderRecharge(props.recahrgesNotCompleted)}
                        </div>
                        <div className="horizontal-line  ds-w-100 ds-mt-16 ds-mb-16"></div>
                    </InfiniteScroll>

                }
            </div>
        )
    }
    return <div className="ds-overflow-x ds-w-100 ds-h-100" >
        {renderMesRecharges()}
    </div >
}
export default TemplateMesRecharges