import { ITemplateModalInscriptionFinalisation } from "../../Interfaces"
import { Button, Input } from "../../DesignSystem";
import { Checkbox, Text } from "../../DesignSystem/Atoms";
import { ESizeInput, Size } from "../../DesignSystem/Interfaces";
import { CommonFunction } from "../../Common";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const TemplateModalInscriptionFinalisation = (props: ITemplateModalInscriptionFinalisation) => {
    return (
        <div className="ds-p-24">
            <div className="ds-w-100  ds-flex-col ds-center">
                <Text
                    className="ds-mb-20 ds-text-line-34 ds-text-size-28 ds-text-weight700"
                    text={`${props.langue.finalize_label} ${props.langue.finalize_label2}`}
                />

                <Input
                    {...CommonFunction.getInputProps(props.form?.prenom)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "prenom", value: e.target.value })
                    }
                    inputSize={ESizeInput.large}
                    placeholder={props.langue.label_firstname}
                    label={props.langue.form_firstname}
                    containerClassName="ds-mb-16"
                    inputClassName="px-2"
                />
                <Input
                    {...CommonFunction.getInputProps(props.form?.nom)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "nom", value: e.target.value })
                    }
                    inputSize={ESizeInput.large}
                    placeholder={props.langue.label_lastname}
                    label={props.langue.form_lastname}
                    containerClassName="ds-mb-16"
                    inputClassName="px-2"
                />
                {props.show && <Input
                    {...CommonFunction.getInputProps(props.form?.email)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "email", value: e.target.value })
                    }
                    inputSize={ESizeInput.large}
                    placeholder={props.langue.email}
                    label={props.langue.email_address}
                    inputClassName="px-2"
                />}
                {props.showErrorEmail && <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-error100 ds-mt-12">{props.show ? props.langue.phone_not_available : props.langue.mail_not_available}</span>}
                {!props.show && <>
                    <div className="ds-w-100 ds-flex ds-align-center">
                        <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-mb-6 ds-flex ds-align-center">{props.langue.form_mobile}</span>
                    </div>
                    <div className={`ds-w-100 ${CommonFunction.getInputProps(props.form?.phone).isInvalid ? 'not-valid' : ''}`}>
                        <PhoneInput
                            onChange={(a, b, c, phone_number: string) => {
                                props.onChange({ key: "phone", value: phone_number })
                                if (b && 'countryCode' in b) {
                                    props.setCountryCode(b.countryCode);
                                }
                            }}
                            placeholder={props.langue.phone_placeholder}
                            country="be"
                            searchPlaceholder='Search'
                            enableSearch={true}
                            disableSearchIcon={true}
                            inputClass={`phone-input`}

                        />
                        {CommonFunction.getInputProps(props.form?.phone).isInvalid && !CommonFunction.getInputProps(props.form?.phone).isValid && <Text text={props.langue.phone_invalid} isSpan className={"ds-mt-4 ds-text-error100"} />}
                    </div>
                </>
                }
                <Input
                    {...CommonFunction.getInputProps(props.form?.password)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "password", value: e.target.value })
                    }
                    placeholder={props.langue.h1_send}
                    label={props.langue.mdp}
                    containerClassName="ds-mb-16 ds-mt-16"
                    isPassword
                    inputSize={ESizeInput.large}
                    inputClassName="px-2"
                />
            </div>

            <div className="ds-w-100 ds-pt-14 ds-pb-12 ds-flex ds-align-center">
                <Checkbox
                    className="ds-mr-8"
                    checked={props.isCheckedReceiveDeals}
                    onClick={() => props.setIsCheckedReceiveDeals(!props.isCheckedReceiveDeals)}
                />
                <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-primary50">
                    {props.langue.optin_infos}</span>

            </div>
            <div className="ds-w-100 ds-flex ds-align-center">
                <Checkbox
                    className="ds-mr-8"
                    checked={props.isCheckedReceiveOffers}
                    onClick={() => props.setIsCheckedReceiveOffers(!props.isCheckedReceiveOffers)}


                />
                <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-primary50">
                    {props.langue.optout_infos}</span>

            </div>
            <div className="ds-w-100 ds-flex-col ds-center ds-p-8 ds-pt-20">
                <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-primary50 text-align-center">
                    {props.langue.cgv_label1}
                    <a className="ds-text-secondary100 ds-pointer ds-text-weight600 text-decoration-none"
                        onClick={() => { props.onOpenConditions() }}>{props.langue.cgv_label2}</a>
                </span>
                <Button
                    size={Size.large}
                    className="ds-w-100 ds-mt-16"
                    text={props.langue.button_end}
                    onClick={() => { props.onFinalizeClick() }}
                />
            </div>
        </div>
    )


}
export default TemplateModalInscriptionFinalisation