import { IElementState } from "../../CustomHooks"
import { store } from "../../Redux/Store/Store";
import { Config } from "../Config/Config";

class CommonFunction {
    private static instance: CommonFunction;
    constructor() { }
    public static getInstance(): CommonFunction {
        if (!CommonFunction.instance) {
            CommonFunction.instance = new CommonFunction();
        }
        return CommonFunction.instance;
    }
    static getInputProps(state: IElementState) {
        return {
            value: state.value ? state.value.toString() : "",
            isInvalid: state.isInvalid,
            isValid: state.isValid,
            success: state.successMessage,
            error: state.errorMessage,
        }
    }
    static createHeaders = ({ withToken = true, contentType = 'application/json' }) => {
        const header = new Headers()
        header.set('Content-Type', contentType)
        if (contentType === 'form-data') {
            header.set('accept', 'form-data')
        } else {
            header.set('accept', 'application/json')
        }

        if (withToken) {
            const token = store.getState().auth.token
            header.set("Authorization", 'Bearer ' + token)
        }
        return header
    }
    static getSelectProps(state: IElementState) {
        let value: Array<string> = []
        if (state.value) {
            if (!Array.isArray(state.value)) {
                value = []
            } else {
                value = state.value
            }
        } else {
            value = []
        }
        return {
            selectValue: value ? value : [],
            isInvalid: state.isInvalid,
            isValid: state.isValid,
            success: state.successMessage,
            error: state.errorMessage,
        }
    }
    static formatDate(dateString: string, inovoice = true) {
        if (inovoice) {
            const options: any = { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' };
            const date = new Date(dateString);
            const formattedDate = date.toLocaleString('fr-FR', options);
            return formattedDate;
        } else {
            const options: any = { weekday: 'long', day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' };
            const date = new Date(dateString);
            const formattedDate = date.toLocaleString('fr-FR', options).replace('à', ' - ').replace(':', 'h');
            return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        }

    }
    static formatDateFr(dateString: string) {
        const months = [
            "janvier", "février", "mars", "avril", "mai", "juin",
            "juillet", "août", "septembre", "octobre", "novembre", "décembre"
        ];
        if (dateString) {
            const inputDate = new Date(dateString);
            const day = inputDate.getDate();
            const month = months[inputDate.getMonth()];
            const hours = inputDate.getHours().toString().padStart(2, '0');
            const minutes = inputDate.getMinutes().toString().padStart(2, '0');

            return `${day} ${month} - ${hours}h${minutes}`;
        }

    }
    static setFormatNumber(nbr: any) {
        const formattedNumber = nbr?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        if (typeof formattedNumber === 'string') {
            const [integerPart, decimalPart] = formattedNumber?.split('.');
            const modifiedDecimalPart = decimalPart?.length === 1 ? `${decimalPart}0` : decimalPart;
            const modifiedFormattedNumber = `${integerPart}.${modifiedDecimalPart}`;
            return modifiedFormattedNumber.replace('.', ',');
        }
    }
    static setFormatDateTime(date: Date) {
        const formattedDate = date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
        });
        const formattedTime = date.toLocaleTimeString('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
        });
        let formated = `Le ${formattedDate} - ${formattedTime}`
        return formated
    }
    static OnOpenNewBlank = (url: string) => {
        const newlink = document.createElement('a');
        newlink.setAttribute('href', url);
        newlink.setAttribute('target', "_blank");
        newlink.click()

    }
    static OnOpenNewSelf = (url: string) => {
        const newlink = document.createElement('a');
        newlink.target = "_self"
        newlink.href = url
        newlink.click()
    }
    static dateFormat = (dateString: string) => {
        const date = new Date(dateString);
        const options: any = { weekday: 'long', day: 'numeric', month: 'long' };
        const formattedDate = date.toLocaleDateString('fr-FR', options);
        return formattedDate
    }
    static timestampToDate = (timestamp: any, langue: any) => {
        const date = new Date(timestamp * 1000);
        const joursEnLettres: any = [langue.Sunday, langue.Monday, langue.Tuesday, langue.Wednesday, langue.Thursday, langue.Friday, langue.Saturday];
        let moisEnLettres: any = [langue.January, langue.February, langue.March, langue.April, langue.May, langue.June, langue.July, langue.August, langue.September, langue.October, langue.November, langue.December];
        const jourEnLettres = joursEnLettres[date.getDay()];
        const jourEnChiffres = date.getDate();
        moisEnLettres = moisEnLettres[date.getMonth()];
        const heures = date.getHours();
        const minutes = date.getMinutes();
        const dateFormatee = `${jourEnLettres} ${jourEnChiffres} ${moisEnLettres} - ${heures}h${minutes < 10 ? '0' : ''}${minutes}`;
        return dateFormatee
    }
    static renderDeliveryTitle = (langue: any, basket: any) => {
        if (basket.delivery_method == 3) {
            if (basket.delivery_method_name === 'LIVRAISON') {
                return (langue.delivery)
            }
            if (basket.delivery_method_name === 'RETRAIT') {
                return (langue.delivery_method_delivery_point)
            }
        }
        return (langue.takeaway)
    }
    static renderFooterItem = (langue: any, basket: any) => {
        return ([
            {
                title: this.renderDeliveryTitle(langue, basket),
                textBtn: langue.modify
            },
            {
                title: langue.comments,
                iconItem: "edit",
                description: basket?.comment && basket?.comment !== '' ? basket?.comment : langue.insert_comment,
                textBtn: basket?.comment && basket?.comment !== '' ? langue.modify : langue.add
            },
            {
                title: langue.voucher_placeholder,
                iconItem: "tag1",
                description: basket?.discount && basket?.discount ? langue.discount_received + basket?.discount + langue.purchases_percent : langue.insert_promo_code,
                textBtn: basket?.discount && basket?.discount ? langue.modify : langue.validate
            },
        ])
    }
    static parseJwt = (token: string) => {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const decodedData = window.atob(base64);
            return JSON.parse(decodedData);
        } catch (e) {
            return null;
        }
    }
    static convertTimeFormat(originalTime: any) {
        const [hours, minutes] = originalTime.split(':');
        const formattedTime = hours + 'h' + minutes;
        return formattedTime;
    }

}

export { CommonFunction }