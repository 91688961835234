import { IKeys, ISite } from "../../Interfaces"

class Data {
    private static instance: Data
    constructor() { }

    public static getInstance(): Data {
        if (!Data.instance) {
            Data.instance = new Data()
        }
        return Data.instance
    }
    static getSelectOption() {
        return [
            { label: "Fr", value: "Fr" },
            { label: "En", value: "En" },
            { label: "Nl", value: "Nl" },
        ]
    }

    static getItems(lang: IKeys) {
        return [
            {
                isLeftSecondaryIcon: true,
                icon: "home",
                label: lang.default,
                subItems: [
                    {
                        label: lang.overview
                    },
                    {
                        label: lang.project
                    }
                ]
            },
            {
                isLeftSecondaryIcon: true,
                icon: "shopping-bag",
                label: lang.eCommerce,
                subItems: [
                    {
                        label: lang.overview
                    },
                    {
                        label: lang.project
                    }
                ]
            }
        ]
    }
    static getTabList() {
        return [
            { index: 0, label: "Tous" },
            { index: 1, label: "Snacking" },
            { index: 2, label: "Restaurant" },
            { index: 3, label: "Boulangerie" },
            { index: 4, label: "Boucherie" },
        ];
    }

    static getMapStyle() {
        return [
            {
                elementType: "geometry",
                stylers: [{ color: "#EBEBEB" }],
            },
            {
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
            },
            {
                elementType: "labels.text.fill",
                stylers: [{ color: "#616161" }],
            },
            {
                elementType: "labels.text.stroke",
                stylers: [{ color: "#f5f5f5" }],
            },
            {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [{ color: "#bdbdbd" }],
            },
            {
                featureType: "poi",
                elementType: "geometry",
                stylers: [{ color: "#eeeeee" }],
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }],
            },
            {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#e5e5e5" }],
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
            },
            {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#ffffff" }],
            },
            {
                featureType: "road.arterial",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }],
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#dadada" }],
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#616161" }],
            },
            {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
            },
            {
                featureType: "transit.line",
                elementType: "geometry",
                stylers: [{ color: "#e5e5e5" }],
            },
            {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [{ color: "#eeeeee" }],
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#c9c9c9" }],
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
            },
        ]
    }

    static getResponsive() {
        return {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 1024 },
                items: 4,
                slidesToSlide: 4
            },
            desktop: {
                breakpoint: { max: 1024, min: 800 },
                items: 2,
                slidesToSlide: 1
            },
            tablet: {
                breakpoint: { max: 800, min: 464 },
                items: 2,
                slidesToSlide: 1
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2,
                slidesToSlide: 1
            },
        };
    }
    static getSideBarItems(lang: any, user: any, token: string, commercant: ISite, path: string = "") {
        if (token !== null && user !== null && Object.keys(commercant).length !== 0) {
            return [
                {
                    icon: "home-21",
                    label: commercant?.company,
                    path: `menuCommercant/${commercant?.suid}`
                },
                {
                    icon: "document2",
                    label: lang.shops,
                    path: 'mes-commercants'
                },
                {
                    icon: "receipt-item",
                    label: lang.my_orders,
                    path: 'mes-commandes',
                },
                {
                    icon: "empty-wallet1",
                    label: lang.refills,
                    path: "mes-recharges",
                },
                {
                    icon: "sms1",
                    label: lang.contact_us,
                    nameModal: "modalContactUs",
                    path: `menuCommercant/${commercant?.suid}`,
                },
                {
                    icon: "shop1",
                    label: lang.become_retailer,
                    path: "devenir-commercant"
                },
                {
                    icon: "location1",
                    label: lang.map,
                    path: "/",
                },

            ]
        } else {
            if (path == "") {
                return [
                    {
                        icon: "home-21",
                        label: commercant?.company,
                        path: `menuCommercant/${commercant?.suid}`
                    },
                    {
                        icon: "user",
                        label: lang.connexion,
                        nameModal: "modalConnexionRef",
                        path: `menuCommercant/${commercant?.suid}`
                    },
                    {
                        icon: "shop1",
                        label: lang.become_retailer,
                        path: "devenir-commercant"
                    },
                    {
                        icon: "location1",
                        label: lang.map,
                        path: "/",
                    },


                ]
            } else {
                return [
                    {
                        icon: "search-normal-1",
                        label: lang.search_retailer,
                        path: "/",
                    },
                    {
                        icon: "shop1",
                        label: lang.become_retailer,
                        path: "devenir-commercant"
                    },
                    {
                        icon: "location1",
                        label: lang.map,
                        path: "/",
                    },


                ]
            }
        }
    }
    static getShopSideBarItems(lang: any, user: any, token: string, commercant: ISite, path: string = "") {
        if (token !== null && user !== null && Object.keys(commercant).length !== 0) {
            return [
                {
                    icon: "home-21",
                    label: commercant?.company,
                    path: `menuCommercant/${commercant?.suid}`
                },
                {
                    icon: "receipt-item",
                    label: lang.my_orders,
                    path: 'mes-commandes',
                },
                {
                    icon: "empty-wallet1",
                    label: lang.refills,
                    path: "mes-recharges",
                },
                {
                    icon: "sms1",
                    label: lang.contact_us,
                    nameModal: "modalContactUs",
                    path: `menuCommercant/${commercant?.suid}`,
                }
            ]
        } else {
            if (path == "") {
                return [
                    {
                        icon: "home-21",
                        label: commercant?.company,
                        path: `menuCommercant/${commercant?.suid}`
                    },
                    {
                        icon: "user",
                        label: lang.connexion,
                        nameModal: "modalConnexionRef",
                        path: `menuCommercant/${commercant?.suid}`
                    }
                ]
            } else {
                return [
                    {
                        icon: "shop1",
                        label: lang.become_retailer,
                        path: "devenir-commercant"
                    },
                    {
                        icon: "location1",
                        label: lang.map,
                        path: "/",
                    },


                ]
            }
        }
    }
    static getCommandeTabList(langue: any) {
        return [
            { index: 0, label: `${window.innerWidth > 1024 ? langue.cmd_incomming : langue.incoming}` },
            { index: 1, label: langue.order_again_history },
            { index: 2, label: langue.invoices }
        ];
    }

}
export { Data }