import { DEFAULTCHECKBOXPROPS } from '../../DefaultProps/Atoms';
import { IAtomCheckbox, Type } from '../../Interfaces/Atoms/IAtomCheckbox/IAtomCheckbox';

function AtomCheckbox(props: IAtomCheckbox): JSX.Element {
    const containerClassName = ["ds-flex ds-align-center"]
    const className = [];
    const labelClassName = [];
    const beforeLabelClassName = [' ds-text-size-14 ds-text-height-22 ds-text-weight700 ds-pointer']
    const afterLabelClassName = [' ds-text-size-14 ds-text-height-22 ds-text-weight700 ds-pointer']
    if (props.bsPrefix) {
        className.push(props.bsPrefix)
    } else if (props.isSwitch) {
        className.push("ds-switch ds-pointer")
        containerClassName.push("ds-switch ds-pointer")
    } else {
        className.push("ds-checkbox ds-hp-21 ")
    }
    if (props.labelBsPrefix) {
        labelClassName.push(props.labelBsPrefix)
    } else {
        labelClassName.push("ds-mx-4 ds-text-line-19 ds-text-weight400 ds-text-size-16")
    }
    if (props.checked) {
        labelClassName.push("ds-text-primary100")
    } else if (props.disabled) {
        labelClassName.push("ds-text-neutral10")
    } else {
        labelClassName.push("ds-text-primary40")
    }

    if (props.type === Type.switch) className.push(props.type + " ds-wp-38 ds-borad-11")
    if (props.type === Type.checkbox && !props.isSwitch) className.push("ds-wp-21 ds-borad-2")
    if (props.disabled) labelClassName.push("ds-text-neutral10")
    if (props.isInvalid) { className.push('invalid') }
    if (props.isValid) { className.push('valid') }
    if (props.className) className.push(props.className)
    if (props.labelClassName) labelClassName.push(props.labelClassName)
    if (props.containerClassName) {
        containerClassName.push(props.containerClassName)
    }
    if (props.afterLabelClassName) {
        afterLabelClassName.push(props.afterLabelClassName)
    }
    if (props.beforeLabelClassName) {
        beforeLabelClassName.push(props.beforeLabelClassName)
    }
    if (props.checked) {
        beforeLabelClassName.push('ds-text-primary100')
        afterLabelClassName.push('ds-text-primary50')
    } else {
        afterLabelClassName.push('ds-text-primary100')
        beforeLabelClassName.push('ds-text-primary50')
    }
    const renderCheckbox = () => {
        const list = []
        if (props.isSwitch) {
            list.push(
                <span className='ds-switch'>
                    <input
                        type="checkbox"
                        disabled={props.disabled}
                        checked={props.checked}
                        onClick={props.onClick}
                        onChange={props.onChange}
                        className='ds-pointer'
                    />
                    <label>
                    </label>
                    <div className='ds-flex-col ds-center label-before checked-class' onClick={props.onClick}>
                        {props.labelBefore && <span onClick={props.onClick}
                            className={beforeLabelClassName.join(' ')}
                        >
                            {props.labelBefore}
                        </span>}
                        {props.takeawayTime && <span
                            className=" ds-text-size-12 ds-text-height-14 ds-text-primary50 ds-pointer"
                        >
                            {props.takeawayTime}
                        </span>}
                    </div>
                    <div className='ds-flex-col ds-center label-after checked-class' onClick={props.onClick}>
                        {props.labelAfter && <span onClick={props.onClick}
                            className={afterLabelClassName.join(' ')}
                        >
                            {props.labelAfter}
                        </span>}
                        {props.deliveryTime && <span
                            className=" ds-text-size-12 ds-text-height-14 ds-text-primary50 ds-pointer"
                        >
                            {props.deliveryTime}
                        </span>}

                    </div>
                </span>
            )
        }
        return <>{list}</>
    }

    return (
        props.isSwitch ? <div className='ds-flex ds-justify-between'>{renderCheckbox()}</div> :
            <div className={containerClassName.join(' ')}>
                <input
                    type="checkbox"
                    disabled={props.disabled}
                    checked={props.checked}
                    onClick={props.onClick}
                    onChange={props.onChange}
                    className={className.join(' ')}
                />
                {props.label && <label
                    className={labelClassName.join(' ')}
                >
                    {props.label}
                </label>}
            </div>
    )
}

AtomCheckbox.defaultProps = {
    ...DEFAULTCHECKBOXPROPS
}
export default AtomCheckbox
