import { ITemplateDevenirCommercant, RootState } from "../../Interfaces";
import devenir_commercant_bg from '../../Assets/images/bg.png'
import devenir_commercant_content from '../../Assets/images/contenu.png'
import { Button, Input, TextArea } from "../../DesignSystem";
import { CommonFunction } from "../../Common";
import { ESizeInput, Type } from "../../DesignSystem/Interfaces";
import PhoneInput from "react-phone-input-2";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../Lang";
import { useWindowSize } from "../../CustomHooks";

const TemplateDevenirCommercant = (props: ITemplateDevenirCommercant): JSX.Element => {
    const langue = useContext(LangContext)
    const { width } = useWindowSize()
    const renderTemplateDevenirCommmercant = () => {
        return <div className="devenir-commercant ds-flex-col ds-w-100 overflow-auto">
            <span className="ds-text-size-28 ds-text-primary100 ds-text-line-33 ds-text-weight700">{langue.become_retailer}</span>
            <div className="ds-flex ds-w-100 ds-pt-24 ds-mb-50">
                {width > 992 && <div className="ds-relative" style={{ width: "calc(100% - 600px)" }}>
                    <img src={devenir_commercant_bg} className="become-retailer-bg ds-absolute" />
                    <img src={devenir_commercant_content} className="become-retailer-content ds-absolute" />
                </div>}
                <div className="ds-flex-col fixed-witdh ds-w-100 ds-ml-80 retailer">
                    <span className="ds-mb-24 ds-text-size-16 ds-text-primary100 ds-text-line-19 ds-text-weight400">{langue.conatct_help}</span>
                    <Input
                        placeholder={langue.label_lastname}
                        containerClassName="ds-mb-16"
                        label={langue.lastName}
                        inputSize={ESizeInput.large}
                        {...CommonFunction.getInputProps(props.form?.nom)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            props?.onChange({
                                key: "nom",
                                value: e.target.value
                            })
                        }
                    />
                    <Input
                        placeholder={langue.label_firstname}
                        containerClassName="ds-mb-16"
                        label={langue.firstName}
                        inputSize={ESizeInput.large}
                        {...CommonFunction.getInputProps(props.form?.prenom)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            props?.onChange({
                                key: "prenom",
                                value: e.target.value
                            })
                        }
                    />
                    <span className="ds-mb-4 ds-text-size-14 ds-text-primary100 ds-text-line-17 ds-text-weight400">{langue.phone_no}</span>
                    <div className={`ds-w-100 ${CommonFunction.getInputProps(props.form?.phone).isInvalid ? 'not-valid' : ''}`}>
                        <PhoneInput
                            onChange={(phone_number: string) => props.onChange({ key: "phone", value: phone_number })}
                            placeholder={'00 00 00 00'}
                            country="be"
                            searchPlaceholder='Search'
                            enableSearch={true}
                            disableSearchIcon={true}
                            inputClass={"phone-input ds-mb-20"}
                            containerClass="ds-mb-16"
                        />
                    </div>
                    <Input
                        placeholder="name@email.com"
                        containerClassName="ds-mb-16"
                        label={langue.email_label}
                        inputSize={ESizeInput.large}
                        {...CommonFunction.getInputProps(props.form?.email)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            props?.onChange({
                                key: "email",
                                value: e.target.value
                            })
                        }
                    />
                    <Input
                        placeholder={langue.business_sector}
                        containerClassName="ds-mb-16"
                        label={langue.activity_sector}
                        inputSize={ESizeInput.large}
                        {...CommonFunction.getInputProps(props.form?.secteur)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            props?.onChange({
                                key: "secteur",
                                value: e.target.value
                            })
                        }
                    />
                    <TextArea
                        containerClassName="ds-mb-20 ds-hp-111 ds-mb-16 text-area-class"
                        className="ds-hp-111 ds-mb-5 text-area-class"
                        label={langue.message}
                        {...CommonFunction.getInputProps(props.form?.message)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length <= 5000) {
                                props?.onChange({
                                    key: "message",
                                    value: e.target.value
                                })
                            }
                        }
                        }
                    />
                    <span className="ds-mt-10 ds-mb-50 ds-text-size-12 ds-text-primary40 ds-text-line-14 ds-text-weight400">
                        {langue.numbre_characters + ` ${props?.form?.message?.value.toString().length ? 5000 - props?.form?.message?.value.toString().length : "5000"}`}
                    </span>
                    <div className="ds-flex ds-w-100 btn-devenir-commercant ds-mb-10">
                        <Button
                            text={langue.cancel}
                            type={Type.quaternary}
                            className="btn-primary10 ds-mr-10"
                            onClick={() => { props.onUpdateData(); }}
                        />
                        <Button
                            text={langue.send}
                            type={Type.quaternary}
                            className="ds-bg-primary40 btn-primary20 ds-borad-8"
                            onClick={() => {
                                props.addCommercant();
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
    const [className, setClassName] = useState<string>("ds-px-40 ds-overflow-y height-100");
    useEffect(() => {
        const menuElement = document.querySelector('.sticky-nav');
        if (menuElement) {
            setClassName((prevClassName) => `${prevClassName} ds-pt-94`);
        }
    }, []);
    return (
        <div className={className}>
            {renderTemplateDevenirCommmercant()}
        </div>
    )
}
export default TemplateDevenirCommercant