import React from 'react'
import { DEFAULTTEXTAREAPROPS } from '../../DefaultProps/Atoms';
import { ITextAreaProps } from '../../Interfaces/Atoms/IAtomTextArea/IAtomTextArea'

const AtomTextArea = React.forwardRef((props: ITextAreaProps, ref: React.Ref<HTMLTextAreaElement>): JSX.Element => {
    const className: string[] = [];
    if (props.bsPrefix) {
        className.push(props.bsPrefix)
    } else {
        className.push('default-input ds-text-dark ds-px-12 ds-bg-white ds-text-weight400 ds-w-100 ds-borad-8')
    }

    if (props.isInvalid) className.push('invalid');
    if (props.isValid) className.push('valid');
    if (props.disabled) className.push('ds-op-7');
    if (props.className) className.push(props.className)
    return (
        <textarea
            ref={ref}
            className={className.join(' ')}
            id={props.id}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            style={props.style}
            rows={props.rows}
        />
    )
})

AtomTextArea.defaultProps = {
    ...DEFAULTTEXTAREAPROPS
}

export default AtomTextArea