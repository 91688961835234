import React from "react"
import { IATomCol } from "../../Interfaces/Atoms/IAtomCol/IAtomCol"

function AtomCol(props: IATomCol): JSX.Element {
    const className = ["ds-col", "ds-px-15"]
    if (props.col) {
        if (typeof props.col === "number") {
            if (props.col < 13 && props.col > 0) {
                className.push('ds-col-' + props.col)
            }
        } else if (typeof props.col === "string") {
            const num = Number(props.col)
            if (num < 13 && num > 0) {
                className.push('ds-col-' + num)
            }
        }
    }
    if (props.xs) {
        if (typeof props.xs === "number") {
            if (props.xs < 13 && props.xs > 0) {
                className.push('ds-col-xs-' + props.xs)
            }
        } else if (typeof props.xs === "string") {
            const num = Number(props.xs)
            if (num < 13 && num > 0) {
                className.push('ds-col-xs-' + num)
            }
        }
    }
    if (props.sm) {
        if (typeof props.sm === "number") {
            if (props.sm < 13 && props.sm > 0) {
                className.push('ds-col-sm-' + props.sm)
            }
        } else if (typeof props.sm === "string") {
            const num = Number(props.sm)
            if (num < 13 && num > 0) {
                className.push('ds-col-sm-' + num)
            }
        }
    }
    if (props.md) {
        if (typeof props.md === "number") {
            if (props.md < 13 && props.md > 0) {
                className.push('ds-col-md-' + props.md)
            }
        } else if (typeof props.md === "string") {
            const num = Number(props.md)
            if (num < 13 && num > 0) {
                className.push('ds-col-md-' + num)
            }
        }
    }
    if (props.lg) {
        if (typeof props.lg === "number") {
            if (props.lg < 13 && props.lg > 0) {
                className.push('ds-col-lg-' + props.lg)
            }
        } else if (typeof props.lg === "string") {
            const num = Number(props.lg)
            if (num < 13 && num > 0) {
                className.push('ds-col-lg-' + num)
            }
        }
    }
    if (props.xl) {
        if (typeof props.xl === "number") {
            if (props.xl < 13 && props.xl > 0) {
                className.push('ds-col-xl-' + props.xl)
            }
        } else if (typeof props.xl === "string") {
            const num = Number(props.xl)
            if (num < 13 && num > 0) {
                className.push('ds-col-xl-' + num)
            }
        }
    }
    if (props.xxl) {
        if (typeof props.xxl === "number") {
            if (props.xxl < 13 && props.xxl > 0) {
                className.push('ds-col-xxl-' + props.xxl)
            }
        } else if (typeof props.xxl === "string") {
            const num = Number(props.xxl)
            if (num < 13 && num > 0) {
                className.push('ds-col-xxl-' + num)
            }
        }
    }
    if (props.className) {
        className.push(props.className)
    }
    return <div className={className.join(' ')} style={props.style}>
        {props.children}
    </div>
}
export default AtomCol