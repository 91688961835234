import { createSlice } from '@reduxjs/toolkit';
import { ICommercantReducers } from '../../../Interfaces';

const initiaState: ICommercantReducers = {
    publicCommercants: [],
    commercant: {
        suid: '',
        city: '',
        name: '',
        vocation: {},
        background: '',
        distance: 0,
        active: false,
        company: ''
    },
    menuCommercant: {},
    categoryCommercant: [],
    productCommercant: [],
    filtredMenu: [],
    times: [],
    product: {},
    listOptions: [],
    customerMember: {},
    goodDeals: {},
    sites: [],
    recharges: [],
    generalCondition: {},
    unit: '',
    CategryObject: [],
    item: {},
    options: [],
    lng: 0,
    lat: 0,
    isSticky:false
};

const CommercantReducers = createSlice({
    name: 'commercant',
    initialState: initiaState,
    reducers: {
        setCoords: (state: ICommercantReducers, action) => {
            state.lat = action.payload.lat
            state.lng = action.payload.lng
        },
        setGoodDeals: (state: ICommercantReducers, action) => {
            state.goodDeals = action.payload.goodDeals
        },
        setPublicCommercants: (state: ICommercantReducers, action) => {
            state.publicCommercants = action.payload.publicCommercants
        },
        setMenuCommercant: (state: ICommercantReducers, action) => {
            state.menuCommercant = action.payload.menuCommercant
        },
        setCommercant: (state: ICommercantReducers, action) => {
            state.commercant = action.payload.commercant
        },
        setCategoryCommercant: (state: ICommercantReducers, action) => {
            state.categoryCommercant = action.payload.categoryCommercant
        },
        setProductCommercant: (state: ICommercantReducers, action) => {
            state.productCommercant = action.payload.productCommercant
        },
        setFiltredMenu: (state: ICommercantReducers, action) => {
            state.filtredMenu = action.payload.filtredMenu
        },
        setShopTimes: (state: ICommercantReducers, action) => {
            state.times = action.payload.times
        },
        setProduct: (state: ICommercantReducers, action) => {
            state.product = action.payload.product
        },
        setTabOptions: (state: ICommercantReducers, action) => {
            state.listOptions = action.payload.listOptions
        },
        setCustomerMember: (state: ICommercantReducers, action) => {
            state.customerMember = action.payload.customerMember
        },
        setSites: (state: ICommercantReducers, action) => {
            state.sites = action.payload.sites
        },
        setSitesRecharges: (state: ICommercantReducers, action) => {
            state.recharges = action.payload.recharges
        },
        setGeneralCondition: (state: ICommercantReducers, action) => {
            state.generalCondition = action.payload.generalCondition
        },
        setUnityProduct: (state: ICommercantReducers, action) => {
            state.unit = action.payload.unit
        },
        setObejctCategory: (state: ICommercantReducers, action) => {
            state.CategryObject = action.payload.CategryObject
        },
        setItem: (state: ICommercantReducers, action) => {
            state.item = action.payload.item
        },
        setOptionsList: (state: ICommercantReducers, action) => {
            state.options = action.payload.options
            state.nameCategory = action.payload.nameCategory
        },
    }
});

export const { setOptionsList, setItem, setGoodDeals, setPublicCommercants, setMenuCommercant, setCommercant, setCategoryCommercant, setProductCommercant, setFiltredMenu, setShopTimes, setProduct, setTabOptions, setCustomerMember, setSites, setSitesRecharges, setGeneralCondition, setUnityProduct, setObejctCategory, setCoords, } = CommercantReducers.actions;

export default CommercantReducers.reducer;
