import React from 'react'
import { IAtomPlaceHolder } from '../../Interfaces/Atoms/IAtomPlaceHolder/IAtomPlaceHolder';
function AtomPlaceHolder(props: IAtomPlaceHolder): JSX.Element {
    const className = ['loading-placeholder']
    if (props.className) {
        className.push(props.className)
    }
    return (
        <div className={className.join(' ')} style={props.style} />
    );
}
export default AtomPlaceHolder