import { useContext } from "react";
import { Button, Icon, Tab } from "../../DesignSystem";
import { Type } from "../../DesignSystem/Interfaces";
import { ITemplateSiteDetailsModal } from '../../Interfaces';
import { LangContext } from "../../Lang";
import { ITimeTable } from "../../Interfaces/Modal";
import { CommonFunction } from "../../Common";


const TemplateSiteDetailsModal = (props: ITemplateSiteDetailsModal): JSX.Element => {
    const langue = useContext(LangContext)
    
    const renderSiteDetails = () => {
        const list: JSX.Element[] = []
        list.push(
            <div className="ds-flex-col ds-w-100">
                <div
                    onClick={() => props?.onCloseModal()}
                    className='ds-absolute ds-z-index-100000 absolute-close-modale ds-hp-40 ds-wp-40 ds-flex ds-center ds-m-16 ds-box-shadow1 ds-bg-white ds-radius-50'>
                    <Icon
                        icon='close'
                        size={"24px"}
                    />
                </div>
                <img src={props?.commercant?.background?.medium_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")} />
                <div className='ds-hp-6 ds-bg-secondary100 ds-w-100'></div>
                <div className='ds-w-100 ds-flex-col ds-px-40 ds-pt-20'>
                    <span className="ds-text-size-36 ds-text-line-43 ds-text-weight700 ds-text-primary100">
                        {`${props?.commercant?.company} `} <span className="ds-text-size-24 ds-text-line-28 ds-text-weight600 ds-text-primary100">{props?.commercant?.city}</span>
                    </span>
                    <span className='ds-mt-7 ds-text-size-16 ds-text-line-19 ds-text-weight400 ds-text-primary70'>{props?.commercant?.description}</span>
                    <div className='horizontal-line ds-w-100 ds-mt-12 ds-mb-12'></div>
                    <div className="ds-flex ds-w-100 ds-align-center ds-justify-between">
                        <div className="ds-flex ds-flex-grow1 ds-align-center">
                            <Icon
                                icon={'map'}
                                size={"20px"}
                                className="ds-text-primary100 ds-mr-8 ds-mb-13"
                            />
                            <div className="ds-flex-col">
                                <span className="ds-text-size-16 ds-text-line-19 ds-flex ds-text-primary100 ds-text-weight700">{props?.commercant?.address}</span>
                                <span className="ds-flex ds-align-center ds-text-size-14 ds-text-line-16 ds-text-primary70">{props?.commercant?.city}</span>
                            </div>
                        </div>
                        <Icon
                            icon={'diagonalarrow'}
                            size={"24px"}
                            className=" ds-text-primary40"
                            onClick={() => { props?.onRedirectToGoogleMaps() }}
                        />
                    </div>
                    <div className='horizontal-line ds-w-100 ds-mt-12 ds-mb-12'></div>
                    <div className="ds-flex ds-w-100 ds-align-center ds-justify-between">
                        <div className="ds-flex ds-flex-grow1 ds-align-center">
                            <Icon
                                icon={'clock'}
                                size={"20px"}
                                className="ds-text-primary100 ds-mr-8 ds-mb-13"
                            />
                            <div className="ds-flex-col">
                                <span className={`ds-text-size-16 ds-text-line-19 ds-flex  ds-text-weight700 ${props?.commercant?.active ? 'ds-text-success100' : 'ds-text-error100'}`}>{`${props?.commercant?.active ? langue.opennow : langue.closenow} `}</span>
                                <span className="ds-flex ds-align-center ds-text-size-14 ds-text-line-16 ds-text-primary70">{props?.commercant?.company + ' ' + langue.accepts_orders + ' ' + props?.commercant?.delivery_end}</span>
                            </div>
                        </div>
                        <Icon
                            icon={props?.isShowHoraire ? 'arrow-up-1' : 'arrow-down-1'}
                            size={"24px"}
                            className=" ds-text-primary40"
                            onClick={() => props?.setIsShowHoraire(!props?.isShowHoraire)}
                        />
                    </div>
                    {
                        props?.isShowHoraire &&
                        <div className='ds-flex-col ds-center ds-w-100 ds-p-20'>
                            <Tab
                                isTopTab={false}
                                list={[{ index: 0, label: langue.opening_hours }, { index: 1, label: langue.pickup_hours }]}
                                onClick={(i: number) => props?.setSelctedTab(i)}
                                tabClassName=" site-info-tab"
                            />
                            <div className='site-info-line ds-w-100 ds-mb-16'></div>
                            {props?.commercant?.timetables.map((element: ITimeTable, index: number) => {
                                return (
                                    <div key={index} className='ds-flex-col ds-w-100'>
                                        <div className='ds-flex ds-align-center ds-justify-between ds-flex-grow1'>
                                            <div className='dot-primary100 ds-mr-8'></div>
                                            <span className=' ds-text-size-16 ds-text-line-19 ds-flex ds-text-primary100 ds-text-weight700 ds-flex ds-flex-grow1'>{langue?.[element?.weekname]}</span>
                                            {props?.selctedTab === 0 && element.shop_open &&
                                                <span className='ds-text-size-14 ds-text-line-16 ds-flex ds-text-primary100 ds-text-weight400'>{`${element?.open1 && CommonFunction.convertTimeFormat(element?.open1) || element?.open2 && CommonFunction.convertTimeFormat(element?.open2)} - ${element?.close1 && CommonFunction.convertTimeFormat(element?.close1) || element?.close2 && CommonFunction.convertTimeFormat(element?.close2)}`}</span> 
                                            }
                                            {((props?.selctedTab === 0 && element.shop_open === null) || (props?.selctedTab === 1 && element.service_open === null)) &&
                                                <span>{langue.closenow}</span> 
                                            }
                                            {props?.selctedTab === 1 && element.service_open &&
                                                <span className='ds-text-size-14 ds-text-line-16 ds-flex ds-text-primary100 ds-text-weight400'>{`${ element?.start2 && CommonFunction.convertTimeFormat(element?.start2) || element.start1 && CommonFunction.convertTimeFormat(element.start1)} - ${element?.end2 && CommonFunction.convertTimeFormat(element?.end2) || element?.end1 && CommonFunction.convertTimeFormat(element?.end1)}`}</span>
                                            }
                                        </div>
                                        {index < props?.commercant?.timetables.length - 1 && <div className='border-dashed ds-w-100 ds-mt-8 ds-mb-8'></div>}
                                    </div>
                                )
                            })}
                        </div>
                    }
                    <div className='horizontal-line ds-w-100 ds-mt-12 ds-mb-12'></div>
                    <div className="ds-flex ds-w-100 ds-align-center ds-justify-between">
                        <div className="ds-flex ds-flex-grow1 ds-align-center">
                            <Icon
                                icon={'star'}
                                size={"20px"}
                                className="ds-text-primary100 ds-mr-8 ds-mb-13"
                            />
                            <div className="ds-flex-col">
                                <span className="ds-text-size-16 ds-text-line-19 ds-flex ds-text-primary100 ds-text-weight700">{'4.0 (87 ' + langue.avis + ')'}</span>
                                <span className="ds-flex ds-align-center ds-text-size-14 ds-text-line-16 ds-text-primary70">{langue.fair_prices}</span>
                            </div>
                        </div>
                    </div>
                    <div className='horizontal-line ds-w-100 ds-mt-12 ds-mb-12'></div>
                    <div className="ds-flex ds-w-100 ds-align-center ds-justify-between ds-mb-24 ds-mt-4">
                        <div className="ds-flex ds-flex-grow1 ds-align-center">
                            <Icon
                                icon={'call1'}
                                size={"20px"}
                                className="ds-text-primary100 ds-mr-8 ds-mb-13"
                            />
                            <div className="ds-flex-col ds-align-center ds-mb-10">
                                <span className="ds-text-size-16 ds-text-line-19 ds-flex ds-text-primary100 ds-text-weight700">{props?.commercant?.phone}</span>
                            </div>
                        </div>
                        <Button
                            text={langue.call}
                            type={Type.tertiary}
                            className="border-primary10 ds-text-primary70 ds-text-size-12 ds-text-line-14 ds-mb-12"
                            onClick={() => props?.OnClickAppeler()}
                        />
                    </div>
                </div>
            </div>
        )
        return (<>{list}</>)
    }
    return (
        renderSiteDetails()
    )
}
export default TemplateSiteDetailsModal