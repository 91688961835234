import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { ShopAPI } from "../../Api/Shop/shop"
import { ICommande, ISites, RootState } from "../../Interfaces"
import { TemplateCommandeEnCour } from "../../Template"
import { Config } from '../../Common'
import { openAlert, resetToken, setBasket, setBasketItems, setDetailsCommande, setHistoryCommande, setNbreItems } from '../../Redux/Reducers'
import { useNavigate } from 'react-router-dom'
import { LangContext } from '../../Lang'

const PageCommandeEnCour = () => {
    const { commercant } = useSelector((state: RootState) => state.commercant)
    const { lang } = useSelector((state: RootState) => state.setting)
    const { detailsCommande, historyCommandes, deliveryDate } = useSelector((state: RootState) => state.paiement)
    const { basketId,zone } = useSelector((state: RootState) => state?.basket)
    const shopApi = new ShopAPI()
    const [commandeDetails, setCommandeDetails] = useState<ICommande[] | []>(detailsCommande && detailsCommande || [])
    const [historyCommandesDetails, setHistoryCommandesDetails] = useState<ICommande[] | []>(historyCommandes && historyCommandes)
    const [cmd, setCmd] = useState<ICommande[] | []>(historyCommandes && historyCommandes)
    const [itemDetails, setItemDetails] = useState<ISites>()
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const langue = useContext(LangContext);
    const [stopBottom, setStopBottom] = useState<boolean>(false);
    const getCommandeDetails = async () => {
        const response = await shopApi.getCommande({ suid: commercant?.suid, lang: lang })
        if (response.status === 200) {
            dispatch(setDetailsCommande({ detailsCommande: response?.data }))
        }
    }
    const getBasket = async () => {
        const res = await shopApi.getBasketOrder({ suid: commercant?.suid, lang: lang, zone :zone })
        if (res.status === 200) {
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
        } else if (res?.status === 404) {
            dispatch(setNbreItems({ nbreItems: null }))
            dispatch(setBasketItems({ items: {} }))
            dispatch(setBasket({ baskett: {}, basketId: basketId }))
        }
        // } else if (res?.status === 401) {
        //     dispatch(resetToken())
        
    }
    const getHistoryCommande = async () => {
        setHistoryCommandesDetails([])
        setCmd([])
        const response = await shopApi.getHistory({ suid: commercant?.suid, lang: lang }, { offset: cmd.length })
        if (response.status === 200) {
            dispatch(setHistoryCommande({ historyCommandes: response?.data }))
            setHistoryCommandesDetails(historyCommandesDetails.concat(response?.data))
            setCmd(cmd.concat(response?.data))
        }
        if (response.status === 401) {
            dispatch(resetToken())
        }
        if (!response.data.length) {
            setStopBottom(true);
            dispatch(openAlert({ type: 'info', message: langue.allLoaded }))
        }
    }
    const getItemCommandeDetails = async () => {
        if (basketId) {
            const response = await shopApi.getItemCommande({ uniq: basketId, suid: commercant?.suid, lang: lang })
            if (response.status === 200) {
                setItemDetails(response?.data?.items)
                if (
                    response?.data?.status.code === 'PAYED' ||
                    response?.data?.status.code === 'INPROGRESS' ||
                    response?.data?.status.code === 'PREPARED'
                ) {
                    getCommandeDetails();
                }

            }
        }
    }
    const getCommandeExpress = async (uniq: string) => {
        const response = await shopApi.setCommandeExpress(
            { uniq: uniq, suid: commercant?.suid, lang: lang }, {
            collected_at: deliveryDate,
            delivery_method: { method: 1, address: "" }
        }
        )
        if (response.status === 200) {
            if (response?.data) {
                navigate(`/menuCommercant/${commercant?.suid}`, { state: { fromHistory: true } })
            }
        }
    }
    React.useEffect(() => {
        getItemCommandeDetails()
        getCommandeDetails()
        getHistoryCommande()
        getBasket()
    }, [])
    React.useEffect(() => {
        if (detailsCommande) {
            setCommandeDetails(detailsCommande)
        }
    }, [detailsCommande])
    React.useEffect(() => {
        if (historyCommandes) {
            setHistoryCommandesDetails(historyCommandes)
        }
    }, [historyCommandes])

    const OnDowloadInvoice = (uniq: string) => {
        let url = `${Config.getInstance().API_URL}/public/invoice/pdf/${uniq}`
        const newlink = document.createElement('a');
        newlink.setAttribute('href', url);
        newlink.setAttribute('target', "_blank");
        newlink.click()
    }
    const fetchMoreData = () => {
        setTimeout(() => {
            doInfinite()
            setCmd(cmd.concat(Array.from({ length: 0 })))
        }, 1500);
    };
    const doInfinite = async () => {
        await getHistoryCommande();
    }
    return <TemplateCommandeEnCour
        commandeDetails={commandeDetails}
        itemDetails={itemDetails}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        OnDowloadInvoice={OnDowloadInvoice}
        historyCommandesDetails={historyCommandesDetails}
        getCommandeExpress={getCommandeExpress}
        stopBottom={stopBottom}
        fetchMoreData={fetchMoreData}
        cmd={cmd}
    />
}
export default PageCommandeEnCour