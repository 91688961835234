import React from 'react'
import { InputLabel } from '..'
import { AtomTextArea, Icon } from '../../Atoms'
import { ETextInput } from '../../Interfaces'
import { IMoleculeTextArea } from '../../Interfaces/Molecule/IMoleculeTextArea/IMoleculeTextArea'

const MoleculeTextArea = React.forwardRef((props: IMoleculeTextArea, ref: React.Ref<HTMLTextAreaElement>): JSX.Element => {
    const SIZE: number = 16
    const containerClassName = ["ds-w-100 ds-relative"]
    if (props.containerClassName) {
        containerClassName.push(props.containerClassName)
    }

    const renderIcon = ({ icon = "Setting", IconClassName = "ds-text-primary70", color = '', containerStyle = {}, style = {}, size = SIZE, onClick = () => { return } }) => {
        return <div className={`ds-hp-${size} ds-wp-${size} ds-flex ds-center ds-absolute ds-top-27 ds-right-15 ds-px-2`} style={containerStyle}>
            <Icon icon={icon} className={IconClassName} size={size ? size : SIZE} style={style} color={color} onClick={onClick} />
        </div>
    }

    const renderValidityIcon = () => {
        if (props.isValid) {
            return renderIcon({
                icon: "exclamation-circle",
                IconClassName: "ds-text-success100"
            })
        } else if (props.isInvalid) {
            return renderIcon({
                icon: "exclamation-circle",
                IconClassName: "ds-text-error100",
            })
        } else {
            return <></>
        }
    }
    return (
        <div className={containerClassName.join(' ')}>
            <InputLabel text={props.label} type={ETextInput.label} className={props.labelClassName} />
            <AtomTextArea
                ref={ref}
                className={props.className}
                id={props.id}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                style={props.style}
                rows={props.rows}
                isInvalid={props.isInvalid}
                isValid={props.isValid}
            />
            {renderValidityIcon()}
            {props.isValid && <InputLabel text={props.success} type={ETextInput.success} className={props.successClassName} />}
            {!props.isValid && props.isInvalid && <InputLabel text={props.error} type={ETextInput.error} className={props.errorClassName} />}
        </div>

    )
})

export default MoleculeTextArea