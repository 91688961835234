import { ITemplateModalInscriptionConfirmation } from "../../Interfaces";
import { Button, Input } from "../../DesignSystem";
import { Text } from "../../DesignSystem/Atoms";
import { Type } from "../../DesignSystem/Interfaces";

const TemplateModalInscriptionConfirmation = (props: ITemplateModalInscriptionConfirmation): JSX.Element => {
    const inputClassName = ['ds-hp-72 ds-wp-65 text-align-center']
    const inputContainerClassName = ['ds-mr-8']
    return (
        <div className="ds-w-100 ds-p-24 ds-flex-col ds-center" >
            <span></span>
            <Text
                className="ds-mb-50 ds-text-line-34 ds-text-size-28 ds-text-weight700"
                text={props.langue.smscode_label}
            >
            </Text>
            <div className="ds-flex">
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { props.onChange(1, e.target.value) }}
                    containerClassName={inputContainerClassName.join('')}
                    className={inputClassName.join('')}
                    ref={props?.form?.code1?.ref}
                    value={props?.form?.code1?.value}
                />
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(2, e.target.value)
                    }
                    containerClassName={inputContainerClassName.join('')}
                    className={inputClassName.join('')}
                    ref={props?.form?.code2?.ref}
                    value={props?.form?.code2?.value}

                />
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(3, e.target.value)
                    }
                    containerClassName={inputContainerClassName.join('')}
                    className={inputClassName.join('')}
                    ref={props?.form?.code3?.ref}
                    value={props?.form?.code3?.value}

                />
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(4, e.target.value)
                    }
                    containerClassName={inputContainerClassName.join('')}
                    className={inputClassName.join('')}
                    ref={props?.form?.code4?.ref}
                    value={props?.form?.code4?.value}

                />
            </div>
            <div className="ds-mb-24 ds-flex-col ds-center">
                <span className="ds-text-line-19 ds-text-size-16 ds-text-weight400 ds-text-primary50 ds-pt-16 text-align-center">
                    {props.show ? `${props.langue.code_entry_phone_label} ${props.user?.phone}` : `${props.langue.code_entry_email_label} ${props.user?.email}`}
                </span>
                {props.showErrorSpan && <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-error100 ds-mt-12">{props.langue.code_wrong}</span>}
            </div>

            <Button
                className="ds-w-100"
                text={props.langue.button_continue}
                onClick={() =>
                    props.onSubmit()
                }
            >
            </Button>
            <Button
                type={Type.tertiary}
                className="ds-w-100 ds-mt-14"
                text={props.show ? props.langue.use_email : props.langue.use_phone}
                onClick={() => {
                    props.switchPhoneEmailInput();
                    props.onClose();
                    props.onOpenModal();
                }
                }
            >
            </Button>
            <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-primary50 ds-pt-16">
                {props.langue.haventCode}
                <span className="ds-text-line-17 ds-text-size-14 ds-text-weight700 ds-text-primary ds-pointer ds-ml-6"
                    onClick={() =>
                        props.onSubmitSendNewCode()
                    }
                >
                    {props.langue.code_resend}
                </span>
            </span>
        </div>
    )


}
export default TemplateModalInscriptionConfirmation