
enum ESizeProgressBar {
    small = "small",
    medium = "medium",
    larg = "lang",
}
enum ETypeProgressBar {
    success = "success",
    warning = "warning",
    error = "error",
}
interface IAtomProgressbar {
    className?: string;
    height?: number;
    width?: number;
    size?: ESizeProgressBar,
    type?: ETypeProgressBar,
    percent: number
    withDot?: boolean
}

export {
    ESizeProgressBar,
    ETypeProgressBar
}
export type {
    IAtomProgressbar
}