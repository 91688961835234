import { useState, useContext, useEffect } from "react"
import { CommercantAPI } from "../../Api/Commercants/Commercants"
import { useUpdateEffect } from "../../CustomHooks"
import { TemplateMenuCommercant } from "../../Template"
import { useDispatch, useSelector } from "react-redux"
import { openAlert, openModal, resetToken, setBasket, setBasketItems, setCategoryCommercant, setCommentProduct, setCommercant, setCustomerMember, setDelievryMode, setDeliveryAddress, setFiltredMenu, setGoodDeals, setNbreItems, setProduct, setProductCommercant, setPublicCommercants, setShopTimes, setTabOptions } from "../../Redux/Reducers"
import { IFiltredProductType, IMenuSitePage, RootState } from "../../Interfaces"
import { useLocation, useParams } from "react-router-dom"
import { ShopAPI } from "../../Api/Shop/shop"
import { UserAPI } from "../../Api/User/user"
import { List } from "../../DesignSystem"
import { Config } from "../../Common"
import { LangContext } from "../../Lang"
import { PaiementApi } from "../../Api/Paiement/Paiement"

const PageMenuCommercant = (props: IMenuSitePage): JSX.Element => {
    const dispatch = useDispatch();
    const params = useParams()
    const { lang } = useSelector((state: RootState) => state.setting)
    const { deliveryDate, delievryMode } = useSelector((state: RootState) => state?.paiement);
    const { goodDeals, commercant, categoryCommercant, productCommercant, } = useSelector((state: RootState) => state.commercant);
    const { user, token } = useSelector((state: RootState) => state?.auth);
    const { showBasket, nbreItems, baskett, zone } = useSelector((state: RootState) => state?.basket)
    const [tabListCategories, setTabListCategories] = useState<List>(categoryCommercant)
    const [listProducts, setListProducts] = useState<[]>(productCommercant)
    const [category, setCategory] = useState<string>()
    const [filtredProducts, setFiltredProducts] = useState<IFiltredProductType>([])
    const [selectedCategory, setSelectedCategory] = useState<number>(0)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [deliveryMethode, setDeliveryMethode] = useState<boolean>(true)
    const shopApi = new ShopAPI()
    const userApi = new UserAPI()
    const shopAPI = new ShopAPI()
    const commercantApi = new CommercantAPI()
    const location = useLocation()
    const langue = useContext(LangContext)
    const paimentApi = new PaiementApi()
    const { suid } = useParams()


    const onInit = async () => {
        if (props?.site?.suid !== suid || commercant?.suid !== suid) {
            getCommercant()
        }
        setShowMenu(true)
        getModePaymentStripe()
    }

    useEffect(() => {
        if (suid && suid !== commercant?.suid) {
            dispatch(setPublicCommercants({ publicCommercants: [] }))
            dispatch(setCategoryCommercant({ categoryCommercant: [] }))
            dispatch(setProductCommercant({ productCommercant: [] }))
            dispatch(setFiltredMenu({ filtredMenu: [] }))
            setFiltredProducts([])
        }
        onInit()
    }, [])

    useEffect(() => {
        if (!showBasket) {
            getBasket()
        }
    }, [showBasket])

    useEffect(() => {
        if (baskett?.delivery_method) {
            setDeliveryMethode(baskett?.delivery_method === 1)
        }
    }, [baskett])

    useEffect(() => {
        if (location?.state?.fromHistory) {
            dispatch(openAlert({ type: 'info', message: langue.element_added }))
        }
    }, [location?.state?.fromHistory])

    useEffect(() => {
        if (categoryCommercant.length && params?.suid === commercant.suid) {
            setTabListCategories(categoryCommercant)
        }
    }, [categoryCommercant])

    useEffect(() => {
        if (listProducts.length) {
            setFiltredProducts(listProducts[selectedCategory])
            onGetCategoriesProducts(selectedCategory)
        }
    }, [listProducts, selectedCategory])

    useEffect(() => {
        if (location.pathname === `/menuCommercant/${commercant?.suid}`) {
            onGetCategoriesProducts(0)
        }
    }, [location.pathname])

    useUpdateEffect(() => {
        if (token !== '' && user !== null) {
            onGetTimesShop()
            getCustomerMemeber()
            getBasket()
        }
    }, [token, user])

    const onGetTimesShop = async () => {
        const response = await shopApi.getShopTimes({
            index: baskett?.delivery_method,
            suid: params?.suid,
            lang: lang,
            zone: zone
        })
        if (response.status === 200) {
            dispatch(setShopTimes({ times: response?.data }))
        }
    }
    const getFiltredProducts = (indexSelected: number) => {
        let mergedArray: any = [];
        for (const key in listProducts) {
            if (listProducts.hasOwnProperty(key)) {
                const filteredArray = listProducts[indexSelected]
                mergedArray = filteredArray;
            }
        }
        setFiltredProducts(mergedArray)
        dispatch(setFiltredMenu({ filtredMenu: mergedArray }))
    }
    const getDeliveryDetails = async () => {
        const response = await shopApi.setCollectedAt({
            suid: commercant?.suid,
            lang: lang,
            zone: zone
        },
            {
                collected_at: deliveryDate,
                delivery: 1,
                delivery_address: ''
            }
        )
        if (response.status === 200) {
            await getBasket()
            dispatch(setDeliveryAddress({ deliveryAddress: '' }))
        } else if (response.status === 401) {
            dispatch(resetToken())
        }
    }
    const onSetModeDelivery = () => {
        if (nbreItems === null) {
            dispatch(openAlert({ type: 'info', message: langue.empty_basket }))
        }
        else
            if (commercant?.delivery_allowed) {
                if (delievryMode) {
                    if (baskett?.total - (baskett?.fees + baskett?.delivery_fees) >= commercant?.delivery_options?.delivery_min_basket) {
                        dispatch(openModal({ name: 'modalDelivery', data: { switch: true } }))
                    } else {
                        dispatch(openAlert({ type: 'info', message: langue.minimum_basket + ` ${commercant?.delivery_options?.delivery_min_basket} € ` + langue.minimum_basket }))
                    }
                } else {
                    getDeliveryDetails()
                    setDeliveryMethode(true)
                }
            } else {
                dispatch(openAlert({ type: 'info', message: langue.delivery_not_allowed }))
            }
    }
    const onClickAccueilSite = () => {
        dispatch(openModal({ name: 'modalBonPlan', data: goodDeals }))
    }
    const onShowSiteDetails = () => {
        dispatch(openModal({ name: 'modalSiteDetails', data: { siteInfo: true } }))
    }
    const onContactUs = () => {
        if (token !== null && user !== null) {
            dispatch(openModal({ name: 'modalContactUs', data: { contactUs: true } }))
        } else {
            dispatch(openModal({ name: 'modalConnexionRef' }))
        }
    }
    const onClickPlanifier = () => {
        if (token && user !== null) {
            if (nbreItems === null) {
                dispatch(openAlert({ type: 'info', message: langue.empty_basket }))

            }
            else {
                dispatch(openModal({ name: 'modalDelivery' }))
            }
        } else {
            dispatch(openModal({ name: 'modalConnexionRef' }))
        }
    }
    const onGetOptionCategories = (data: any) => {
        let arr: string[] = []
        if (data) {
            Object.values(data).map((el: any) => {
                arr.push(el?.name)
            })
            const formattedData: { index: number, label: string }[] = arr.map((label: string, index: number) => ({ index, label }));
            return formattedData;
        }
    }
    const onAddProductToBasket = (uniq: string) => {
        dispatch(setProduct({ product: {} }))
        const product: any = Object.values(filtredProducts).filter((el: any, i: number) => {
            return el.uniq === uniq
        })
        if (product[0]?.attribute?.availability?.quantity === 0 && product[0]?.attribute?.availability?.error) {
            dispatch(openAlert({ type: 'info', message: langue.empty_disponiblity }))
        } else {
            dispatch(setProduct({ product: product[0] }))
            if (token !== null && user !== null) {
                if (product[0]?.menu_categories.length !== 0) {
                    dispatch(setTabOptions({ listOptions: onGetOptionCategories(product[0]?.menu_categories) }))
                    dispatch(setCommentProduct({ commentItem: '', itemId: '' }))
                    dispatch(openModal({ name: 'modalComposeFormule', data: { product: product, item: null, fromMenu: true } }))
                } else {
                    dispatch(setCommentProduct({ commentItem: '', itemId: '' }))
                    dispatch(openModal({ name: 'modalProductDetails', data: { product: product, item: null, fromMenu: true } }))
                }
            } else {
                dispatch(openModal({ name: 'modalConnexionRef' }))
            }
        }
    }
    const onClickAppeler = () => {
        window.location.href = `tel: ${commercant?.phone}`;
    }
    const onRedirectToGoogleMaps = () => {
        const url = `${Config.getInstance().map_uri + encodeURIComponent(commercant?.address)}`;
        window.open(url, "_blank");
    };

    const onGetCategoriesProducts = (index: number) => {
        setCategory(categoryCommercant[index]?.label);
        setSelectedCategory(index)
        getFiltredProducts(index);
    }

    const onShareFacebook = () => {
        const encodedUrl = encodeURIComponent(commercant?.service_url);
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        window.open(facebookShareUrl, '_blank', 'noopener noreferrer');
    }
    const getCustomerMemeber = async () => {
        const response = await userApi.getCustomerMember({
            userId: user?.uniq,
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            dispatch(setCustomerMember({ customerMember: response.data }))
        }
    }
    const getBasket = async () => {
        const res = await shopAPI.getBasketOrder({ suid: commercant?.suid, lang: lang, zone: zone })
        if (res.status === 200) {
            dispatch(setDelievryMode({ delievryMode: res.data?.delivery_method === 1 }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
        }
        else if (res?.status === 404 && res.data.error.message === "Not Found") {
            dispatch(setNbreItems({ nbreItems: null }))
            dispatch(setBasket({ baskett: { delivery_method: 1 } }))
            dispatch(setBasketItems({ items: {} }))
        }
    }

    const getSUID = () => {
        if (suid) return suid
        if (props.site) return props.site?.suid
            return commercant.suid
    }

    const getCommercant = async () => {
        const response = await commercantApi.getCommercant({
            suid: getSUID(),
            lang: lang
        })
        if (response.status === 200) {
            dispatch(setCommercant({ commercant: response.data }))
            await getCatalogueCommercant()
        }
    }
    const getCatalogueCommercant = async () => {
        setFiltredMenu([])
        const response = await commercantApi.getCommercantCatalogue({
            suid: params?.suid,
            lang: lang,
            collected_at: deliveryDate,
            delivery_method: baskett?.delivery_method
        })
        if (response.status === 200) {
            dispatch(setGoodDeals({ goodDeals: response.data }))
            if (response?.data?.categories) {
                let arrCategories: string[] = []
                const arrProducts: any = []
                Object.values(response?.data?.categories).map((el: any) => {
                    arrCategories.push(el?.name)
                    arrProducts.push(el?.products)
                })
                const formattedData: { index: number, label: string }[] = arrCategories.map((label: string, index: number) => ({ index, label }));
                setTabListCategories(formattedData)
                setListProducts(arrProducts)
                setCategory(response?.data?.categories[0]?.name)
            }
        }
    }
    const getModePaymentStripe = async () => {
        const query = `query {
      site(id: "${commercant?.suid}") {
        id
        mode_payment {
          id,
          client_id,
          site_id,
          country,
          amex,
          lunchr,
          moneoresto,
          appetiz,
          digibon
        }
      }
    }`;
        const response = await paimentApi.gql_query({}, {
            operationName: '',
            query: query,
            variables: {}
        })
    }
    return (
        <TemplateMenuCommercant
            site={commercant}
            tabListCategories={tabListCategories}
            onClickAppeler={onClickAppeler}
            onRedirectToGoogleMaps={onRedirectToGoogleMaps}
            onAddProductToBasket={onAddProductToBasket}
            onClickPlanifier={onClickPlanifier}
            onContactUs={onContactUs}
            onShowSiteDetails={onShowSiteDetails}
            onClickAccueilSite={onClickAccueilSite}
            onSetModeDelivery={onSetModeDelivery}
            deliveryMethode={deliveryMethode}
            getFiltredProducts={getFiltredProducts}
            onGetCategoriesProducts={onGetCategoriesProducts}
            category={category}
            filtredProducts={filtredProducts}
            commercant={commercant}
            onShareFacebook={onShareFacebook}
            selectedCategory={selectedCategory}
            showMenu={showMenu}
        />
    )
}
export default PageMenuCommercant