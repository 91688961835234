import { Button, Input, InputFile, TextArea } from "../../DesignSystem";
import { CommonFunction } from "../../Common";
import infoCircle from '../../Assets/images/info-circle.svg';
import { Type } from "../../DesignSystem/Interfaces";
import { ITemplateContactUsModal } from "../../Interfaces";
import { useContext } from "react";
import { LangContext } from "../../Lang";

const TemplateContactUsModal = (props: ITemplateContactUsModal): JSX.Element => {
    const langue = useContext(LangContext)
    const renderContactUsForm = () => {
        const list: JSX.Element[] = []
        list.push(
            <div className="ds-px-40 ds-flex-col ds-align-center ds-overflow-x padding-contact">
                <span className="ds-text-size-28 ds-text-line-33 ds-text-weight700 ds-text-primary100 ds-mt-8 ds-mb-8">
                    {langue.contact_us}
                </span>
                <span className="ds-text-size-16 ds-text-line-19 ds-text-weight400 ds-text-primary100 ds-flex ds-align-center">{langue.complaint_comments}</span>
                <div className="ds-flex ds-align-center ds-mt-8">
                    <img src={infoCircle} height={13} width={13} className="ds-mb-20 ds-mr-4" />
                    <span className="ds-text-size-14 ds-text-line-16 ds-text-weight400 ds-text-secondary100 ds-flex ds-align-center">{langue.committed_serving}</span>
                </div>
                <Input
                    placeholder="janecooper@gmail.com"
                    containerClassName="ds-mb-16"
                    label={langue.form_email}
                    {...CommonFunction.getInputProps(props.form?.email)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        props?.onChange({
                            key: "email",
                            value: e.target.value
                        })
                    }
                />
                <Input
                    placeholder="Cooper"
                    containerClassName="ds-mb-16"
                    label={langue.lastName}
                    {...CommonFunction.getInputProps(props?.form?.lastname)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        props?.onChange({
                            key: "lastname",
                            value: e.target.value
                        })
                    }
                />
                <Input
                    placeholder="Jane"
                    containerClassName="ds-mb-16"
                    label={langue.firstName}
                    {...CommonFunction.getInputProps(props?.form?.firstname)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        props?.onChange({
                            key: "firstname",
                            value: e.target.value
                        })
                    }
                />
                <TextArea
                    placeholder={langue.insert_comments}
                    label={langue.label_message}
                    className=" ds-hp-90 text-area-class"
                    containerClassName="text-area-class"
                    {...CommonFunction.getInputProps(props?.form?.message)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length <= 5000) {
                            props?.onChange({
                                key: "message",
                                value: e.target.value
                            })
                        }
                    }
                    }
                />
                <span className="ds-text-primary40 ds-text-size-12 ds-text-line-14 ds-text-weight400">{langue.numbre_characters + ` ${props?.form?.message?.value ? 5000 - props?.form?.message?.value?.toString().length : 5000}`}</span>
                <InputFile
                    label={langue.attachment}
                    containerClassName="ds-mb-16"
                    text={langue.select_file}
                    className={'btn-upload ds-flex ds-center'}
                    {...CommonFunction.getInputProps(props?.form?.file)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                        props?.onChange({
                            key: "file",
                            value: e[0]?.name
                        })
                    }
                    textClassName={props?.form?.file?.isInvalid ? 'ds-text-error100' : 'ds-text-primary30'}
                />
                <Button
                    text={langue.send}
                    className="ds-w-100"
                    onClick={() => props?.onContactUs()}
                />
                <Button
                    text={langue.cancel}
                    type={Type.quaternary}
                    className="ds-w-100 background-primary10 ds-mt-8"
                    onClick={() => props?.onCloseModal()}
                />
            </div>
        )
        return (<>{list}</>)
    }
    return (renderContactUsForm())
}
export default TemplateContactUsModal