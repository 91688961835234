import { useContext, useMemo, useRef, useState } from "react"
import * as _ from "lodash"
import { useUpdateEffect } from "../../../CustomHooks"
import { Icon } from "../../../DesignSystem";
import { LangContext } from "../../../Lang";

export default function AtomElementDatePicker(props: any) {
    const { height, data, type } = props
    const ref = useRef<HTMLDivElement>(null)
    const scrollRef = useRef(false)
    const [selected, setSelected] = useState(1)
    const langue = useContext(LangContext)

    const onScroll = useMemo(
        () =>
            _.debounce(() => {
                let res = 0
                if (ref.current) {
                    const ligneTop = props.topLigne.current.getBoundingClientRect().top
                    const children = Array.from(ref.current.children)
                    for (let index = 0; index < children.length; index++) {
                        const element = children[index]
                        const { top } = element.getBoundingClientRect()
                        const position = top - ligneTop
                        if ((position > (height * -0.5) + 2) && (position < (height * 1.5) - 2)) {
                            res = index
                            break
                        }
                    }
                    let top = (res - 1) * height
                    ref.current.scrollTo({ top: top })
                    scrollRef.current = ref.current.scrollTop !== top
                    setSelected(res)
                }
            }, 100),
        []
    );

    const renderDateTranslation = (date: any) => {
        if (date !== -1) {
            const parts: any = date.split(" ");
            if (parts.length !== 3) {
                return langue.today
            }         
            return date.replace(String(parts[0]), String(langue[parts[0].replace(',', '')]))
        }
        return ""
    }

    const onDownIconClick = () => {
        if (ref.current) {

            let top = selected * height
            ref.current.scrollTo({ top: top })
            if (selected + 1 < data.length && data[selected + 1] != -1) {
                setSelected(selected + 1)
            }
        }
    }

    const onUpIconClick = () => {
        if (ref.current) {

            let top = (selected - 2) * height
            ref.current.scrollTo({ top: top })
            if (selected - 1 > 0 && data[selected - 1] != -1) {
                setSelected(selected - 1)
            }
        }
    }

    useUpdateEffect(() => {
        props.onChange && props.onChange(selected, type)
    }, [selected])
    useUpdateEffect(() => {
        if (ref.current) {
            // ref.current.scrollTo({ top: 0 })
        }
    }, [props.data])

    const renderDownIcon = () => {
        const list = []
        list.push(
            <div className="ds-wp-44 ds-hp-24 ds-bg-white ds-flex ds-center shadow ds-borad-10 ds-absolute arrow-btn"
                onClick={onUpIconClick}
            >
                <Icon
                    icon="arrow-up-1"
                    className="ds-text-primary40 ds-pointer ds-mb-5"
                    style={{ transform: "rotate(180deg)" }}
                    size={"20px"}
                />
            </div>
        )
        return list
    }

    const renderUpIcon = () => {
        const list = []
        list.push(
            <div className="ds-wp-44 ds-hp-24 ds-bg-white ds-flex ds-center shadow ds-borad-10 ds-absolute arrow-btn"
                onClick={onDownIconClick}
                style={{ top: -10 }}
            >
                <Icon
                    icon="arrow-up-1"
                    className="ds-text-primary40 ds-pointer ds-mb-5"
                    size={"20px"}
                />
            </div>
        )
        return list
    }

    return <div className="ds-relative arrow-time">
        {window.innerWidth > 992 && renderUpIcon()}
        <div
            className="ds-flex-col ds-overflow-x height-300"
            onScrollCapture={onScroll}
            ref={ref}
        >
            {
                data.map((el: string | any, index: number) =>
                    <div
                        key={index}
                        className={`ds-p-16 ds-flex ds-w-100 ds-hp-52 ds-mhp-52 text-align-center 
                        ${selected === index ? 'ds-text-primary100 ds-text-weight600' : 'ds-text-primary40'}`}
                        onClick={() => {
                            let top = (index - 1) * height;
                            ref?.current?.scrollTo({ top: top });
                            if (el !== -1) {
                                setSelected(index);
                            }
                        }}
                    >
                        <span className={`ds-w-100 white-space-nowrap ${el === -1 ? "text-transparent" : ""}`}>
                            {props.withTranslation ? renderDateTranslation(el) : props.hours ? el + ' h' : el}
                        </span>

                    </div>
                )
            }
        </ div >
        {window.innerWidth > 992 && renderDownIcon()}
    </div>
}