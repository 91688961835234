import React, { useRef, useState } from 'react'
import { Icon, Portal, Text } from '../../Atoms'
import { DEFAULTDATEPICKERPROPS, DEFAULTINPUTMESSAGEPROPS } from '../../DefaultProps/Molecules'
import { ETypesInput, IMoleculeDatepicker } from '../../Interfaces'
import MoleculeInput from '../MoleculeInput/MoleculeInput'
import moment from 'moment';
import { listDays, month } from '../../Constant'
import { useClickOutside, useUpdateEffect } from '../../../CustomHooks'
import { usePopper } from 'react-popper'
import { IDate } from '../../Interfaces/Molecule/IMoleculeDatepicker/IMoleculeDatepicker'

const ROWSTYLE = 'ds-flex ds-justify-between ds-w-100'
function MoleculeDatepicker(props: IMoleculeDatepicker): JSX.Element {
    const clickIcon=useRef(false)
    const iconId = useRef(new Date().getTime() + Math.random() * 1000000000)
    const now = useRef(new Date())
    const containerClassName = ['ds-w-100']
    const ref = React.useRef<HTMLInputElement>(null);
    const [yearState, setYearState] = useState({
        year: now.current,
        yearStart: now.current.getFullYear() - 7
    })
    const [dateState, setDateState] = useState<IDate>({
        date: new Date(now.current.getFullYear(), now.current.getMonth(), now.current.getDate()),
        selectedDate: null,
        start: null,
        end: null,
        hover: null,
    })
    const [isOpenDate, setIsOpenDate] = useState(false);
    const refinput = React.useRef<HTMLInputElement>(null);
    const refDatepicker = React.useRef<HTMLInputElement>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(refinput.current, popperElement);
    useClickOutside(refDatepicker, () => {
        if(isOpenDate){
            if(!clickIcon.current){
                setIsOpenDate(false)
            }else{
                clickIcon.current=false
            }
        }
    })
    const getListInputIcons = () => {
        return [{
            icon: 'plus-square',
            size: '32px',
            className: 'ds-flex ds-align-end ds-bg-primary ds-borad-4 ds-mt-2 ds-text-white ds-pointer',
            onClick: () => {
                clickIcon.current=true
             setIsOpenDate(!isOpenDate) },
            id: iconId.current
        }]
    }
    const onChangeDate = (date: Date) => {
        const aux = { ...dateState }
        if (props.isRange) {
            if (!aux.start) {
                aux.start = date
                aux.hover = null
            } else {
                if (!aux.end) {
                    aux.hover = null
                    aux.end = date
                }
            }
        } else {
            aux.selectedDate = date
        }
        setDateState(aux)
    }
    const onMouseOver = (date: Date) => {
        if (props.isRange) {
            const aux = { ...dateState }
            if (aux.start) {
                aux.hover = date
            }
            // setDateState(aux)
        }
    }
    const createCalendar = () => {
        const date = dateState.date
        const list = [];
        let row = [];
        let firstDay = 1;
        let lastDay = 0;
        const preMonthLastDay = new Date(date.getFullYear(), date.getMonth(), 0)
        const currentMonthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        const daysInMonth = currentMonthLastDay.getDate();
        const nextMonthFirstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1)
        switch (preMonthLastDay.getDay()) {
            case 6:
                firstDay = -5
                break;
            case 5:
                firstDay = -4
                break;
            case 4:
                firstDay = -3
                break;
            case 3:
                firstDay = -2
                break;
            case 2:
                firstDay = -1
                break;
            default:
                firstDay = 1
                break;
        }
        switch (nextMonthFirstDay.getDay()) {
            case 6:
                lastDay = 1
                break;
            case 5:
                lastDay = 2
                break;
            case 4:
                lastDay = 3
                break;
            case 3:
                lastDay = 4
                break;
            case 2:
                lastDay = 5
                break;
            case 1:
                lastDay = 6
                break;
            default:
                lastDay = 0
                break;
        }
        let j = 0
        for (let index = firstDay; index <= daysInMonth + lastDay + 1; index++) {
            j = j + 1

            const d = new Date(date.getFullYear(), date.getMonth(), index);
            const formatDate = moment(d).format("DD");
            const className = ["ds-pointer ds-text-size-10 ds-text-line-12 ds-hp-24 col-date-picker ds-flex ds-center  ds-text-weight600 ds-borad-4 background-grey"]
            if (!props.isRange) {
                if (dateState.selectedDate) {
                    if (new Date(dateState.selectedDate).getTime() === d.getTime()) {
                        className.push("ds-text-white ds-bg-primary")
                    }
                }
            } else {
                if (dateState.start) {
                    let second = dateState.end
                    if (dateState.hover) {
                        second = dateState.hover
                    }
                    if (second) {
                        if (new Date(dateState.start).getTime() <= d.getTime() && new Date(second).getTime() >= d.getTime()) {
                            className.push("ds-text-white ds-bg-primary")
                        }
                    } else if (new Date(dateState.start).getTime() === d.getTime()) {
                        className.push("ds-text-white ds-bg-primary")
                    }
                }

            }
            if (index <= 0 || index > daysInMonth) {
                className.push('ds-text-grey')
            } else {
                className.push('ds-text-neutral900')
            }

            row.push(
                <div className={className.join(' ')}
                    onClick={() => onChangeDate(d)}
                    onMouseOver={() => onMouseOver(d)}
                >
                    {formatDate}
                </div>
            )
            if (row.length === 7 || index === daysInMonth + lastDay + 1) {
                list.push(
                    <div key={index} className={ROWSTYLE}>
                        {row}
                    </div>
                );
                row = [];
            }
        }
        return list;
    };

    const createYearCalendar = () => {
        const auxdata: any = [];
        let j = 1
        let aux = []
        for (let index = yearState.yearStart; index < yearState.yearStart + 16; index++) {
            if (j === 5) {
                j = 1
                auxdata.push(
                    <div
                        key={index}
                        className="ds-flex ds-justify-between"
                    >
                        {aux}
                    </div>)
                aux = []
            }
            const yearClassName = ["ds-pointer ds-borad-4 ds-text-size-14 ds-text-line-22 ds-hp-40 ds-wp-40 ds-flex ds-center ds-text-weight400"]
            if (yearState.year.getFullYear() === index) {
                yearClassName.push("ds-text-white ds-bg-primary ")
            } else {
                yearClassName.push('background-grey')

            }
            aux.push(
                <span
                    className={yearClassName.join(' ')}
                    onClick={() => onChangeYear(index)}
                >
                    {index.toString()}
                </span>
            )
            j = j + 1
        }
        return auxdata;
    };

    const onChangeYear = (year: number) => {
        setYearState({
            ...yearState,
            year: new Date(year, 0, 1,)
        })
    }

    const renderValue = () => {
        if (props.isYear) {
            if (yearState.year)
                return '--/--/' + yearState.year.getFullYear()
        } else {
            if (props.isRange) {
                let value = ""
                if (dateState.start) {
                    value = moment(dateState.start).format('YYYY/MM/DD') + " -> "
                    if (dateState.end) {
                        value = value + moment(dateState.end).format('YYYY/MM/DD')
                    }
                }
                return value
            } else {
                if (dateState.selectedDate) {
                    return moment(dateState.selectedDate).format('YYYY/MM/DD')
                }
            }
        }
        return ""
    }

    const renderMonth = () => {
        if (props.isYear) {
            return month[new Date().getMonth()]
        } else {
            return month[dateState.date.getMonth()]
        }
    }

    const renderYear = () => {
        if (props.isYear) {
            return yearState.year.getFullYear().toString()
        } else {
            return dateState.date.getFullYear().toString()
        }
    }

    if (props.containerClassName) {
        containerClassName.push(props.containerClassName)
    }

    const onChangePage = (increment = true) => {
        if (props.isYear) {
            setYearState({
                ...yearState,
                yearStart: increment ? yearState.yearStart + 16 : yearState.yearStart - 16
            })
        } else {
            let month = dateState.date.getMonth()
            if (increment) {
                month = month + 1
            } else {
                month = month - 1

            }
            const date = new Date(dateState.date.getFullYear(), month, dateState.date.getDate())
            setDateState({
                ...dateState,
                date: date
            })
        }
    }

    const renderArrows = () => {
        return <div className='ds-wp-48 ds-hp-32 ds-flex ds-center ds-justify-end'>
            <Icon
                icon='arrow-sm-left'
                size={'20px'}
                className='ds-pointer ds-text-neutral900'
                onClick={() => onChangePage(false)}
            />
            <div className='vertical-line'></div>
            <Icon
                icon='arrow-sm-right'
                size={'20px'}
                className='ds-pointer ds-text-neutral900'
                onClick={() => onChangePage(true)}
            />
        </div>
    }

    const renderListDays = () => {
        return <div className={ROWSTYLE + ' ds-mt-20'}>
            {listDays.map((el,index:number) => {
                return (
                    <Text key={index} className={'ds-flex ds-center ds-text-size-10 ds-text-line-14 ds-text-neutral400 ds-hp-24 col-date-picker'} isSpan text={el.day} />
                )
            })}
        </div>
    }

    useUpdateEffect(() => {
        if (props.onChange) {
            props.onChange(yearState.year)
        }
    }, [yearState.year])

    useUpdateEffect(() => {
        if (props.onChange && !props.isRange) {
            props.onChange(dateState.selectedDate)
        }
    }, [dateState.selectedDate])

    useUpdateEffect(() => {
        if (props.onChange && props.isRange && dateState.end && dateState.start) {
            props.onChange({ end: dateState.end, start: dateState.start })
        }
    }, [dateState.end, dateState.start])

    useUpdateEffect(() => {
        if (props.value) {
            if (props.value instanceof Date) {
                if (props.isYear) {
                    setYearState({
                        ...yearState,
                        year: props.value

                    })
                } else {
                    setDateState({
                        ...dateState,
                        selectedDate: props.value

                    })
                }
            }
        }
    }, [props.value])

    useUpdateEffect(() => {
        const aux = { ...dateState }
        let update = false
        if (props.isRange) {
            if (props.end) {
                if (props.end instanceof Date) {
                    if (props.end.getTime() !== dateState.end?.getTime()) {
                        aux.end = props.end
                        update = true

                    }
                }
            }
            if (props.start) {
                if (props.start instanceof Date) {
                    if (props.start.getTime() !== dateState.start?.getTime()) {
                        aux.start = props.start
                        update = true
                    }
                }
            }
        }
        if (update) {
            setDateState(aux)
        }
    }, [props.end, props.start])


    const renderDatepicker = () => {
        return (
            <div className={containerClassName.join(' ')} ref={refinput}>
                <MoleculeInput
                    type={ETypesInput.text}
                    value={renderValue()}
                    className={'box-sixing ds-text-neutral900 ds-text-size-16 ds-text-line-16 ds-text-weight400 ds-pointer'}
                    listIcons={getListInputIcons()}
                    ref={ref}
                    placeholder={props.placeholder}
                    id={iconId.current.toString()}
                    isValid={props.isValid}
                    isInvalid={props.isInvalid}
                    error={props.error}
                    errorClassName={props.errorClassName}
                    success={props.success}
                    successClassName={props.successClassName}
                    label={props.label}
                    labelClassName={props.labelClassName}
                    inputSize={props.inputSize}
                />
                {isOpenDate &&
                    <Portal >
                        <div ref={setPopperElement}
                            {...attributes.popper}
                            style={{
                                ...styles.popper,
                                width: "max-content",
                                minWidth: "300px"
                            }}
                            className='ds-relative'>
                            <div className=' border-11 ds-mt-7 ds-borad-4 ds-p-16 ds-bg-white
                             calendar-box ' ref={refDatepicker}>
                                <div className='ds-flex ds-justify-between ds-w-100'>
                                    <div className='ds-w-100 ds-hp-32 ds-flex ds-align-center'>
                                        <Text isSpan
                                            className={`'ds-flex ds-align-start ds-justify-start ds-text-size-20 ds-text-line-32 ds-mr-5 '${props.isYear ? 'ds-cursor-not-allowed ds-text-grey' : 'ds-text-neutral900'} `}
                                            text={renderMonth()}
                                        />
                                        <Text isSpan
                                            className={'ds-flex ds-align-start ds-justify-start ds-text-neutral900 ds-text-size-20 ds-text-line-32'}
                                            text={renderYear()}
                                        />
                                    </div>
                                    {renderArrows()}
                                </div>
                                {!props.isYear && renderListDays()}
                                <div className='ds-w-100 ds-mt-20'>{props.isYear ? createYearCalendar() : createCalendar()} </div>
                            </div>
                        </div>
                    </Portal>
                }
            </div>
        )
    }
    return (
        renderDatepicker()
    )
}

MoleculeDatepicker.defaultProps = {
    ...DEFAULTDATEPICKERPROPS,
    ...DEFAULTINPUTMESSAGEPROPS
}

export default MoleculeDatepicker