import React, { useContext, useState } from 'react'
import { Ref, forwardRef } from "react";
import { List, Modal, ModalRefType } from "../../DesignSystem";
import { IFilterMenu, IMenuCategories, IModalComposeFormuleOrSandwich, IProduct, IProductComposeType, IProducts, ISuplementListType, ISupplementCategory, ISupplementCategoryType, ISupplementList } from "../../Interfaces/Modal";
import { TemplateModalComposeFormuleOrSandwich } from "../../Template";
import { useDispatch, useSelector } from "react-redux";
import { IAttribute, IAvailibility, RootState } from "../../Interfaces";
import { ShopAPI } from '../../Api/Shop/shop';
import { UserAPI } from '../../Api/User/user';
import { useUpdateEffect } from '../../CustomHooks';
import { openAlert, openModal, resetToken, setBasket, setBasketItems, setCommentProduct, setNbreItems, setShowBasket } from '../../Redux/Reducers';
import { LangContext } from '../../Lang';
import { CommonFunction } from '../../Common';
import { setItem, setObejctCategory, setOptionsList, setTabOptions } from '../../Redux/Reducers/CommercantReducers/CommercantReducers';

const ModalComposeFormuleOrSandwich = (props: IModalComposeFormuleOrSandwich, ref: Ref<ModalRefType | undefined>) => {
    const { listOptions, commercant, CategryObject, nameCategory } = useSelector((state: RootState) => state.commercant);
    const { showBasket, commentItem, itemId,zone } = useSelector((state: RootState) => state?.basket)
    const { lang } = useSelector((state: RootState) => state.setting);
    const { data } = useSelector((state: RootState) => state.modal);
    const { deliveryAddress, deliveryDate, delievryMode } = useSelector((state: RootState) => state.paiement)
    const [myProduct, setMyProduct] = useState<IProductComposeType>(data && data[0])
    const [tabListOptions, setTabListOptions] = useState<List | any>(listOptions)
    const [supplement, setSupplement] = useState<string>('Steak Frites')
    const [supplementList, setSupplementList] = useState<ISuplementListType>([])
    const [selectedElement, setSelectedElementt] = useState<number>(0)
    const [noSupplement, setNoSupplement] = useState<boolean>(false)
    const [supplementProducts, setSupplementProducts] = useState<IProducts | undefined>()
    const [objectCategry, setObjectCategry] = useState<ISupplementCategoryType>([])
    const [showOptions, setShowOptions] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const shopAPI = new ShopAPI()
    const userApi = new UserAPI()
    const dispatch = useDispatch();
    const langue = useContext(LangContext)
    const [quantity, setQuantity] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [iteeee, setIteeee] = useState<any>(data?.item)
    const [isAddingCommande, setIsAddingCommande] = useState<boolean>(false)
    const [isOptionList, setIsOptionList] = useState<boolean>(false)
    const [selectedOptions, setSelectedOptions] = useState<any>([])
    const [supplementPrice, setSupplementPrice] = useState<number>(0)
    const [selectedSupplement, setSelectedSupplement] = useState<number>(0)
    const composeClassName = [' ds-borad-14 ds-pointer overflow-hidden ds-flex-col']


    React?.useEffect(() => {
        if (isOptionList) {
            setSelectedSupplement(0)
            setSupplementPrice(0)
            setSelectedOptions([])
        }
    }, [isOptionList])
    React?.useEffect(() => {
        if (data?.item?.fromAddNote) {
            setIsAddingCommande(true)
            setShowOptions(true)
        }
    }, [data?.item?.fromAddNote])
    React.useEffect(() => {
        if (data?.item !== null && data?.fromAddNote) {
            setIsOptionList(false)
            setMyProduct(data?.item)
            setIteeee(data?.item)
            dispatch(setTabOptions({ listOptions: onGetOptionCategories(data?.item?.product?.menu_categories) }))
            setShow(false)
            setShowOptions(true)
            setSelectedElementt(0)
            onSelectOptionTab(0)
            setSelectedElementt(0)
            setObjectCategry(CategryObject)
            setIteeee(data?.item)
            if (commentItem === "" && itemId === "") {
                setObjectCategry(CategryObject)
                const aux: any = [...CategryObject]
                data?.item?.product?.menu_categories?.map((el: IFilterMenu) => {
                    aux.push({ isChecked: false, index: 0, category: [], category_name: el?.name })
                })
                setObjectCategry(aux)
            }
            if (data?.item?.remarque === "") {
                const aux: any = [...CategryObject]
                data?.item?.product?.menu_categories?.map((el: IFilterMenu) => {
                    aux.push({ isChecked: false, index: 0, category: [], category_name: el?.name })
                })
                setObjectCategry(aux)
            }
        }
    }, [data?.fromAddNote])
    const onGetOptionCategories = (data: any) => {
        let arr: string[] = []
        if (data) {
            Object.values(data).map((el: any) => {
                arr.push(el?.name)
            })
            const formattedData: { index: number, label: string }[] = arr.map((label: string, index: number) => ({ index, label }));
            return formattedData;
        }
    }
    React.useEffect(() => {
        if (data?.item !== null && data?.fromBasket) {
            setIsOptionList(false)
            dispatch(setTabOptions({ listOptions: onGetOptionCategories(data?.item?.product?.menu_categories) }))
            setShow(false)
            setShowOptions(false)
            setIsAddingCommande(false)
            setSelectedElementt(0)
            onSelectOptionTab(0)
            setSelectedElementt(0)
            setObjectCategry(CategryObject)
            setIteeee(data?.item)
            if (commentItem === "" && itemId === "") {
                setObjectCategry(CategryObject)
                const aux: any = [...CategryObject]
                data?.item?.product?.menu_categories?.map((el: IFilterMenu) => {
                    aux.push({ isChecked: false, index: 0, category: [], category_name: el?.name })
                })
                setObjectCategry(aux)
            }
            if (data?.item?.remarque === "") {
                const aux: any = [...CategryObject]
                data?.item?.product?.menu_categories?.map((el: IFilterMenu) => {
                    aux.push({ isChecked: false, index: 0, category: [], category_name: el?.name })
                })
                setObjectCategry(aux)
            }
        }
    }, [data?.item])
    React.useEffect(() => {
        if (data?.product !== null && data?.fromMenu) {
            setSelectedSupplement(0)
            setIsOptionList(false)
            setSupplementPrice(0)
            dispatch(setCommentProduct({ commentItem: '', itemId: '' }))
            if (data?.product !== null && data?.product?.[0]) {
                dispatch(setTabOptions({ listOptions: onGetOptionCategories(data?.product?.[0]?.menu_categories) }))
            }
            if (objectCategry.length) {
                setObejctCategory(CategryObject)
            } else {
                setObjectCategry(CategryObject)
            }
            setShow(false)
            setShowOptions(false)
            setIsAddingCommande(false)
            setSelectedElementt(0)
            onSelectOptionTab(0)
            setSelectedElementt(0)
            if (data?.product !== null && data?.item === null && commentItem === "" && itemId === "") {
                setObjectCategry(CategryObject)
                setSupplementList([])
                setMyProduct(data?.product[0])
                setQuantity(1)
                setIsLoading(false)
                setObjectCategry(CategryObject)
                if (commentItem === "" && itemId === "") {
                    setObjectCategry([])
                    const aux: any = []
                    data?.product[0]?.menu_categories?.map((el: IFilterMenu) => {
                        aux.push({ isChecked: false, index: 0, category: [], category_name: el?.name })
                    })
                    setObjectCategry(aux)
                }
            } else if (data?.product === null && data?.item !== null && commentItem !== "" && itemId !== "") {
                setObjectCategry(CategryObject)
                setMyProduct(data?.item?.product)
                setQuantity(data?.item?.quantity)
                setIsLoading(false)
                setObjectCategry(CategryObject)
                if (commentItem === "" && itemId === "") {
                    const aux: any = [...objectCategry]
                    data?.item?.product?.menu_categories?.map((el: IFilterMenu) => {
                        aux.push({ isChecked: false, index: 0, category: [], category_name: el?.name })
                    })
                    setObjectCategry(aux)
                }
            }
        }
    }, [data?.product])

    useUpdateEffect(() => {
        if (listOptions) {
            setTabListOptions(listOptions)
        }
    }, [listOptions])

    const onSetOrderQuantity = async (decrement: boolean) => {
        let formatedTotal;
        let aux = { ...iteeee }
        if (decrement) {
            if (aux?.quantity > 1) {
                const newQuantity = aux?.quantity - 1;
                const newPriceQuantity = aux?.price * newQuantity;
                const discountPrice = newPriceQuantity - (newPriceQuantity * aux?.product?.vat?.amount)
                if (newPriceQuantity !== undefined && CommonFunction.setFormatNumber(newPriceQuantity) !== undefined) {
                    formatedTotal = CommonFunction.setFormatNumber(newPriceQuantity)?.toString()
                }
                aux = {
                    ...aux,
                    quantity: newQuantity,
                    quantity_with_discount: newQuantity,
                    quantity_formated: newQuantity.toFixed(2) + "x",
                    total_discounted: newPriceQuantity,
                    total_exc_vat: discountPrice,
                    total_formated: formatedTotal,
                    total: newPriceQuantity
                }
            }
        }
        if (!decrement) {
            let qtyMax = 0;
            if (aux.product?.attribute?.availability?.quantity) {
                qtyMax = aux.product?.attribute?.availability?.quantity;
            } else if (aux.product?.attribute?.current_stock) {
                qtyMax = aux.product?.attribute?.current_stock
            } else {
                qtyMax = 250
            }
            if (aux.quantity < qtyMax) {
                const newQuantity = aux?.quantity + 1;
                setQuantity(newQuantity)
                const newPriceQuantity = aux?.price * newQuantity;
                const discountPrice = newPriceQuantity - (newPriceQuantity * aux?.product?.vat?.amount)
                if (newPriceQuantity !== undefined && CommonFunction.setFormatNumber(newPriceQuantity) !== undefined) {
                    formatedTotal = CommonFunction.setFormatNumber(newPriceQuantity)?.toString()
                }
                aux = {
                    ...aux,
                    quantity: newQuantity,
                    quantity_with_discount: newQuantity,
                    quantity_formated: newQuantity.toFixed(2) + "x",
                    total_discounted: newPriceQuantity,
                    total_exc_vat: discountPrice,
                    total_formated: formatedTotal,
                    total: newPriceQuantity
                }
            }
        }
        setIteeee(aux)
    }
    const incrementOrDecrementQunatity = (decrement: boolean) => {
        let aux = { ...myProduct }
        let attribute: IAttribute = aux?.attribute
        let availability: IAvailibility = attribute?.availability ?? {}
        if (decrement) {
            if (quantity > 1) {
                const newQuantity = quantity - 1;
                setQuantity(newQuantity)
            }
            setMyProduct(aux)
        }
        if (!decrement) {
            let qtyMax = 0;
            if (availability.quantity) {
                qtyMax = availability.quantity;
            } else {
                qtyMax = 250;
            }
            if ((quantity < qtyMax)) {
                const newQuantity = quantity + 1;
                setQuantity(newQuantity)
            } else {
                dispatch(openAlert({ type: 'info', message: langue.empty_disponiblity }))
            }
            setMyProduct(aux)
        }
    }

    const onChooseSupplement = (category: IFilterMenu, product: IProduct, i: number, indice: number) => {
        if (product?.uniq) {
            const uniq = product?.uniq as string
            if (product?.option_lists?.length) {
                dispatch(setOptionsList({ options: product?.option_lists, nameCategory: category?.name }))
                setIsOptionList(true)
            }
            let aux: any = [...objectCategry]
            const targetIndex = objectCategry.findIndex((item: any) => { item?.category[0] === product?.uniq && category?.name === tabListOptions[selectedElement].label });
            if (targetIndex === -1) {
                aux.push({ isChecked: false, index: indice + 1, category: [uniq], category_name: category?.name, options: [] })
                if (i < tabListOptions.length - 1) {
                    onSelectOptionTab(i + 1)
                } else if (i === tabListOptions.length - 1) {
                    onSelectOptionTab(i)
                }
            } else {
                aux.splice(targetIndex, 1)
            }
            const categoryLastIndex: any = {};
            for (let i = 0; i < aux.length; i++) {
                categoryLastIndex[aux[i].category_name] = i;
            }
            const result = Object.values(categoryLastIndex).map((index: any) => aux[index]);
            setObjectCategry(result)
            let list = result.map(item => ({
                uid: item.category[0],
                options: item?.options,
            }));
            setSupplementList(list)
        }
    }
    React.useEffect(() => {
        const data = [...objectCategry]
        if (selectedOptions.length) {
            let ccc = objectCategry.findIndex((category: any) => category?.category_name === nameCategory)
            if (ccc !== -1) {
                data[ccc]!.options = selectedOptions
            }
        }
        setObjectCategry(data)
        let list: any = data.map((item: any) => ({
            uid: item.category[0],
            options: item?.options,
        }));
        setSupplementList(list)
    }, [selectedOptions])
    const onChangeWithoutSauce = (i: number) => {
        const aux = [...objectCategry]
        aux[i].isChecked = !aux[i].isChecked;
        aux[i].category = []
        aux[i].index = 0
        setObjectCategry(aux)
        if (i < tabListOptions.length - 1) {
            onSelectOptionTab(i + 1)
        } else if (i === tabListOptions.length - 1) {
            onSelectOptionTab(i)
        }
        const list = [...supplementList]
        list.splice(i, 1)
        setSupplementList(list)
    }
    const setOrderItems = async () => {
        setIsLoading(true)
        let discount = data?.item?.product?.discount_rule?.available_quantity > 0;
        const response = await shopAPI.setOrderItems(
            { suid: commercant?.suid, lang: lang },
            {
                delivery: { address: deliveryAddress, method: delievryMode ? 1 : 3 },
                product: {
                    discount: discount,
                    options: [],
                    products: supplementList.filter(item => { return item.uid !== undefined }),
                    quantity: myProduct?.unity?.id == 2 ? quantity / 1000 : quantity,
                    timezone: "Etc/GMT-1",
                    uid: myProduct?.uniq,
                    collected_at: deliveryDate,
                    remarque: itemId === myProduct?.uniq ? commentItem : ''
                },
            }
        )
        if (response.status === 200) {
            if (response.data.code) {
                if (props.onExit) {
                    props.onExit()
                }
                dispatch(openAlert({ type: 'info', message: response.data.message }))
            } else {
                const resp = await userApi.getUserLoyalty({
                    suid: commercant?.suid,
                    lang: lang
                })
                if (resp.status === 200) {
                    const res = await shopAPI.getBasketOrder({ suid: commercant?.suid, lang: lang, zone :zone })
                    if (res.status === 200) {
                        setIsLoading(false)
                        dispatch(setCommentProduct({ commentItem: '', itemId: '' }))
                        dispatch(setShowBasket(showBasket))
                        dispatch(setBasketItems({ items: res.data.items }))
                        dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
                        if (props.onExit) {
                            props.onExit()
                            setShow(true)
                        }
                    }
                }
            }

        } else if (response?.status === 401) {
            setIsLoading(false)
            if (props.onExit) {
                props.onExit()
            }
            dispatch(resetToken())
        }

    }
    const onSelectOptionTab = (index: number) => {
        setSupplement(tabListOptions[index]?.label)
        setSelectedElementt(index)
        if (myProduct) {
            myProduct?.menu_categories?.filter((el: IMenuCategories, i: number) => {
                if (i === index) {
                    setSupplementProducts(el?.products)
                    if (noSupplement) {
                        setSupplementProducts(el?.products)

                    } else {
                        if (el?.name === supplement) {
                            if (el?.products) {
                                setSupplementProducts(el?.products)
                            }
                            return el?.products
                        }
                    }
                }
            })
        }

    }
    const onAddItemBasket = () => {
        let attribute: IAttribute = myProduct?.attribute
        if ((attribute?.price ?? 0) !== 0) {
            if (props.onExit) {
                props?.onExit()
            }
            setOrderItems()  
        }
    }
    const getBasket = async () => {
        const res = await shopAPI.getBasketOrder({ suid: commercant?.suid, lang: lang , zone :zone})
        if (res.status === 200) {
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
            setIsLoading(false)
            if (props?.onExit) {
                props?.onExit()
            }
        } else if (res?.status === 401) {
            if (props?.onExit) {
                props?.onExit()
            }
            // dispatch(resetToken())
        }
    }
    const onModifyItemBasket = async (orderId: number, quantity: number) => {
        setIsLoading(true)
        const response = await shopAPI.updateQuantityBasket({ suid: commercant?.suid },
            {
                order_line_id: orderId,
                new_quantity: quantity,
                remarque: commentItem

            }
        )
        if (response?.status === 200) {
            getBasket()
        } else if (response?.status === 401) {
            if (props?.onExit) {
                props?.onExit()
            }
            dispatch(resetToken())
        }
    }

    if ((!showOptions && window.innerWidth <= 1024 && (!isAddingCommande && (data?.fromMenu || data?.fromBasket)))) {
        composeClassName.push('compose-min-modal')
    } else {
        if (isOptionList) {
            composeClassName.push('modal-compose-option-formule')
        }
        composeClassName.push('modal-compose-formule')
    }

    const onAddComment = () => {
        if (props?.onExit) {
            props?.onExit()
            if (data?.fromBasket) {
                dispatch(setItem({ item: iteeee }))
                dispatch(openModal({ name: 'modalAddNote', data: { fromCompose: true, itemSelected: iteeee, fromBasket: true } }))

            } if (data?.fromMenu) {
                dispatch(setItem({ item: myProduct }))
                dispatch(openModal({ name: 'modalAddNote', data: { fromCompose: true, itemSelected: myProduct, fromMenu: true } }))
            }
            dispatch(setObejctCategory({ CategryObject: objectCategry }))
        }
    }
    const onChooseSupplementOptions = (index: number, option: any) => {
        let som = 0
        let aux = [...selectedOptions]
        const indexOf = selectedOptions.findIndex((item: any) => { return item.id === option?.id });
        if (indexOf === -1) {
            aux.push(option?.id)
            setSelectedSupplement(index + 1)
        } else {
            aux.splice(indexOf, 1)
        }
        for (let index = 0; index < aux.length; index++) {
            som = som + option?.price
        }
        setSupplementPrice(som)
        setSelectedOptions(aux)
    }

    return (
        <Modal
            contentClassName={composeClassName.join(' ')}
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
            childrenContainerClassName={"modal-plan-container"}
        >
            <TemplateModalComposeFormuleOrSandwich
                onAddItemBasket={onAddItemBasket}
                onChooseSupplement={onChooseSupplement}
                incrementOrDecrementQunatity={incrementOrDecrementQunatity}
                myProduct={myProduct}
                tabListOptions={tabListOptions}
                supplementList={supplementList}
                supplement={supplement}
                onSelectOptionTab={onSelectOptionTab}
                selectedElement={selectedElement}
                noSupplement={noSupplement}
                setNoSupplement={setNoSupplement}
                supplementProducts={supplementProducts}
                setSelectedElementt={setSelectedElementt}
                objectCategry={objectCategry}
                onChangeWithoutSauce={onChangeWithoutSauce}
                showOptions={showOptions}
                setShowOptions={setShowOptions}
                show={show}
                onAddComment={onAddComment}
                commentItem={commentItem}
                itemId={itemId}
                quantity={quantity}
                isLoading={isLoading}
                iteeee={iteeee}
                onSetOrderQuantity={onSetOrderQuantity}
                onModifyItemBasket={onModifyItemBasket}
                isAddingCommande={isAddingCommande}
                setIsOptionList={setIsOptionList}
                isOptionList={isOptionList}
                onChooseSupplementOptions={onChooseSupplementOptions}
                selectedOptions={selectedOptions}
                supplementPrice={supplementPrice}
                selectedSupplement={selectedSupplement}
            />

        </Modal>
    )
}
export default forwardRef(ModalComposeFormuleOrSandwich)