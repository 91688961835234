enum ETextInput {
    label = 'label',
    error = 'error',
    success = 'success',
    helper = 'helper',
}
interface ITextInput {
    text?: string
    className?: string
    type?: ETextInput
}
export type{
    ITextInput
}
export {
    ETextInput
}