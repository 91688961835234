import { IKeys } from "../../Interfaces";

const lang: IKeys = {
    fr: "Français",
    en: "Anglais",
    ar: "Arabe",
    //menu
    connexion: "Se connecter",
    connexionTitle: "Connexion",
    inscriptionTitle: "Inscription",
    forgetPass: "Mot de passe oublié?",
    register: "S'inscrire maintenant",
    continue_with: "OU CONTINUER AVEC",
    haveAccount: " Vous avez déja un compte ?",
    haventAccount: "Vous n’avez pas de compte ?",
    haventCode: "Vous n'avez pas reçu le code?",
    identifiant: "Identifiant",
    mdp: "Mot de passe",
    rememberMe: "Se souvenir de moi",
    firstName: "Prénom",
    lastName: "Nom",
    confirmPassword: "Confirmer mot de passe",
    acceptCondition: "J'accepte les conditions d'utilisation",
    validationMessage: "Oui",
    emptyMessage: "Champ obligatoire",
    invalidMailMessage: "C'est pas un mail",
    forgetPassBtn: "Envoyer un e-mail",
    resetPassTitle: "Changer mot de passe",
    ForgetPassTitle: "Trouvez votre compte",
    resetPassBtn: "Changer",
    reliabilityMessage: "Fiabilité de mot de passe : faible",
    default: "par défaut",
    overview: "aperçu",
    project: "Projets",
    eCommerce: "e_Commerce",
    logOut: "déconnexion ",
    info_register: "Pour commander en ligne !",
    info_login: "Un compte, tous mes commerçants",
    intro: "Inscrivez-vous ou connectez-vous pour profiter pleinement sur du service de commande en ligne",
    i_order: "Je commande",
    baseline: "Vous n'avez besoin que d'un compte pour acceder à toutes les boutiques",
    welcome: "Bienvenue",
    shops: "Mes commerçants",
    shops2: "Les boutiques",
    orders: "Mes achats",
    profile: "Mon compte",
    map: "Autour de moi",
    map_search_around: "Rechercher une ville, un commerçant",
    about: "A propos",
    tnc: "Mentions légales",
    notice: "Conditions générales de vente",
    litigation: "Règlement des litiges",
    copyright: "Digicommerce © {{year}} _ Tous droits résérvés",
    contact: "Contactez_nous",
    i_search: "Je recherche",
    i_search_text: "un commerçant autour de moi",
    i_recommend: "Faire une recommandation",
    i_recommend_text: "de mon commerçant préféré",
    for: "Pour",
    //register

    number_mobile: "Numéro de mobile",
    email_address: "Email",
    email: "nom@email.com",
    phone_code_info: "Un code sera envoyé par SMS pour vérifier votre numéro",
    email_code_info: "Un code sera envoyé par email pour vérifier votre adresse",
    is_account: "déjà un compte ?",
    button_continue: "Continuer",
    button_ignorer: "Ignorer",
    button_continue_partner: "Valider mon code",
    button_ignorer_partner: "Continuer sans code",
    code_label1: "Code de",
    code_label2: "Confirmation",
    code_entry_phone_label: "Entrez le code que vous avez reçu au ",
    code_entry_email_label: "Entrez le code que vous avez reçu sur ",
    code_resend: "Renvoyer un code",
    use_email: "Reçevoir le code par email",
    use_phone: "Reçevoir le code par SMS",
    finalize_label: "Finaliser",
    finalize_label2: "mon inscription",
    form_firstname: "Prénom",
    form_lastname: "Nom",
    form_email: "Email",
    form_mobile: "N° mobile",
    form_password: "Mot de passe",
    optin_infos: "Pour reçevoir les bons plans et promotions des commerçants",
    optout_infos: "Pour reçevoir les offres des partenaires",
    cgv_label1: "En validant ce formulaire, je déclare avoir lu et accepté les ",
    cgv_label2: "conditions générales",
    button_end: "Terminer",
    code_required: "Le code est obligatoire",
    code_invalid: "Le code est composé de 4 chiffres",
    code_wrong: "Le code n'est pas valide",
    firstname_required: "Le prénom est obligatoire.",
    lastname_required: "Le nom est obligatoire.",
    password_required: "Le mot de passe est obligatoire.",
    password_minlength: "Le mot de passe doit contenir au moins 8 caractères.",
    godfatherCodeUsed_label: "Avantage",
    godfatherCodeUsed_label2: "Parrainage",
    godfatherCodeUsed_placeholder: "Votre code de parrainage",
    godfatherCodeUsed_info: "Entrer le code de parrainage et offrez des points à vos parrains pour vos premières commandes en ligne.",
    continueWith_fb: "Continuer avec Facebook",
    continueWith_gmail: "Continuer avec Gmail",
    or: "ou",
    //button
    validate: "Valider",
    cancel: "Annuler",
    next: "Suivant",
    previous: "Précédent",
    add: "Ajouter",
    send: "Envoyer",
    connect: "Se connecter",
    connect2: "Connexion",
    disconnect: "Se déconnecter",
    modify: "Modifier",
    delete: "Supprimer",
    menu: "menu",
    read_more: "en savoir plus",
    reduce: "réduire",
    passed: "Passer",
    empty: "Rien pour l'insant",
    save: "Enregistrer",
    //allergerns
    title: "Les Allergènes",
    text1: "Vous trouverez ci dessous la correspondance des couleurs des 14 principaux ingrédients allergènes recensés par le Décret n°2008_1153 du 7.11.2008. Nous vous invitons à consulter régulièrement nos informations sur les allergènes, car la composition de nos produits peut être modifiée. Nous ne pouvons pas exclure une présence accidentelle d'autres ingrédients allergènes qui serait intervenue lors de la fabrication de nos produits.",
    text2: "Pour le Glutten: Céréales contenant du gluten : Blé, Seigle, Orge, Avoine, Epeautre, Kamut,... Pour les Fruits à coques :Amandes, Noisettes, Noix, Noix de Pécan, Noix du Brésil, Pistaches, Noix de Macadamia et Noix du Queensland Pour les Sulfites: Uniquement si la quantité d’anhydride sulfureux et sulfites est supérieur à 10 mg / kg de produit finis",
    //catalog 
    //points
    buy: "Acheter en points",
    cancel_point: "Annuler l'achat en points",
    deals_title1: "Les bons plans fidélité",
    deals_title2: "Les bonnes affaires",
    deals_title3: "Les offres avantages",
    deals_title4: "A découvrir",
    myaccount_title: "Mon compte client",
    mycredit: "Mon crédit et mes points",
    pos_link_title: "Une carte de fidélité ?",
    pos_link_desc: "Renseigner votre numéro de carte pour cumuler votre fidélité boutique",
    pos_link_btn: "Ajouter",
    myedenred: "My Edenred",
    restoflash: "Resto Flash",
    restaurant_card_not_enough: "Le montant disponible sur votre carte restaurant est insufisant.",
    reload: "Recharger",
    return: "Afficher le catalogue",
    not_enough_credit: "Vous avez pas assez de crédit.",
    invalid: "L'heure de retrait choisi n'est pas valide.",
    //product
    free: "gratuit",
    free_plural: "gratuits",
    minor_prohibited: "Produit réservés aux personnes majeures",
    composed: "Composez votre",
    promo: "Promotion",
    discount_rule: "Pour {{repetition}} {{name}} achetés, {{quantity}} offert(s), sous réserve de disponibilité.",
    warning: "Attention, les produits offerts peuvent différés de celui acheté.",
    composition: "Composition",
    for_100gr: "Pour 100gr",
    by_united: "Par unité",
    defrost_text: "Produit décongelé. Ne jamais recongeler un produit décongelé !",
    title_time: "Heure de retrait",
    today: "Aujourd'hui",
    mandatory_choice: "Le choix de l'option est obligatoire",
    basket_amount: "Montant de votre panier",
    title_basket: "Panier",
    comment_placeholder: "Commentaire",
    empty_basket: "Votre panier est vide.",
    min: "panier min.",
    voucher_placeholder: "Coupon de réduction",
    delivery_method_mandatory: "Vous devez choisir un mode de retrait.",
    delivery_method_title: "Choisir un mode de retrait",
    delivery_method_subtitle: "livraison à partir de {{amount}}",
    delivery_method_collect: "À emporter",
    delivery_method_eatin: "Sur place",
    delivery_method_delivery: "En livraison",
    delivery_method_delivery_point: "Point de retrait",
    delivery_method_picking: "En drive",
    delivery_method_none: "Mode de retrait",
    delivery_price: "Frais de livraison",
    delivery_order_in_advance: "Remise commande anticipée",
    delivery_free: "Offert",
    discounts_first_order: "Avantages premières commandes",
    discount_first_order: "Avantage première commande",
    order_method_title: "Choisir un mode de règlement",
    order_method_title2: "commande avec obligation de paiement",
    order_method_credit: "En compte crédit",
    order_method_bankcard: "Carte bancaire",
    order_method_upon_receipt: "Sur place",
    order_method_upon_delivery: "A réception",
    order_method_restaurant_card: "En carte restaurant",
    order_method_restaurant_restoflash: "Restoflash",
    order_method_restoflash: "Restoflash",
    order_method_free: "Sans payer",
    order: "Commander",
    time_placeholder: "Choisir un horaire",
    partnership_title: "Avantage société",
    partnership_text: "réduction appliquée",
    discount_title: "Avantage privilège",
    discount_text: "réduction appliquée",
    add_basket: "Ajout panier",
    add_panier: "Ajouter",
    amount_not_enough: "Montant du panier insuffisant.",
    min_amount: "Votre panier doit être au minimum de {{amount}}",
    //voucher
    not_for_you: "Ce coupon ne vous est pas déstiné.",
    already_used: "Coupon de réduction déjà utilisé.",
    success: "Votre coupon a été pris en compte.",
    invalid_voucher: "Votre coupon n'est pas valide.",
    validate_voucher: "Je valide mon code",
    add_succes: "ajouté avec succès:",
    error_delivery: "Problème de livraison!",
    info_quantity: "Vous avez {{number}} produit(s) dans votre panier",
    label_number_product: "TOTAL PRODUITS",
    label_price_delivery: "PRIX LIVRAISON",
    label_total: "TOTAL TTC",
    free_delivery: "Encore {{price}} afin de profiter de la livraison gratuite !",
    label_free_delivery: "Frais de livraison offerts!",
    info_price_variation: "Les frais de livraison varie en fonction de la distance.",
    info_distance_delivery: "L'adresse indiquée se trouve entre {{a}} et {{b}} km",
    info_max_distance_delivery: "L'adresse indiquée se trouve au_delà de {{number}} km",
    info_remove_product: "Veuillez enlever des articles du panier ou changer de mode de retrait.",
    continue_shopping: "Je continue mes achats",
    pay_items: "Je paye mes articles",
    fees: "Frais de service",
    description_fees: "Les frais de services sont redistribués à la plateforme Digicommerce et permettent d'assurer la maintenance et le développement de ce service.",
    title_rda: "Apports journaliers",
    title_composition: "Composition",
    title_recipe: "Suggestions",
    title_availability: "Disponibilité",
    text1_availability: "Pour une quantité supérieure à ",
    text2_availability: "produits, merci de prévoir un délai supérieur à ",
    text2_single_availability: "produit, merci de prévoir un délai supérieur à ",
    text1_contact: "Vous pouvez nous contacter si vous avez une précision, une réclamation ou une suggestion à faire.",
    text2_contact: "Nous nous engagons à vous répondre dans les meilleurs délais.",
    label_mail: "Votre mail",
    required_mail: "L'adresse e-mail est obligatoire.",
    invalid_mail: "L'adresse e-mail n'est pas correcte.",
    label_firstname: "Votre prénom",
    required_firstname: "Le prénom est obligatoire.",
    label_lastname: "Votre nom",
    required_lastname: "Le nom est obligatoire.",
    label_message: "Message",
    required_message: "Veuillez saisir un message.",
    success_msg: "Votre message a bien été envoyé",
    maxSize: "Votre fichier dépasse les 5 Mo",
    h1_send: "Votre mot de passe",
    pwd_send: "Renouveler votre mot de passe",
    code_validation: "Valider mon compte",
    new_code_validation: "Renvoyer mon code",
    new_link_validation: "Renvoyer un email",
    lost_password: "Mot de passe oublié",
    invalid_mail_password: "E-mail ou mot de passe incorrect",
    email_send: "Un email vous a été envoyé.",
    email_invalid: "Email incorrect.",
    password_change: "Votre mot de passe a bien été changé.",
    test_used: "Vos 3 essais sont utilisé. Veuillez réessayer.",
    rest: "Votre code de confirmation est incorrect.",
    //map
    search: "Rechercher",
    list: "Rechercher un commerçant",
    open: "Horaires",
    open_soon: "Bientôt Ouvert",
    bluebox_title: "Mes commerçants à portée de clic",
    bluebox_subtitle1: "Poireauter, quelle drôle d'idée !",
    bluebox_text1: "Bénéficiez d’un service coupe_file en boutique. Fini la file d’attente. Doublez tout le monde, ici c’est le principe",
    bluebox_subtitle2: "Réservez vos produits à l’avance",
    bluebox_text2: "Plus de mauvaises surprises, commandez vos produits préférés à l’avance ou… pour tout de suite. En toute occasion.",
    bluebox_subtitle3: "Profitez des bons plans",
    bluebox_text3: "Bénéficiez dès maintenant de toutes les offres promos. Vous êtes informés en premier des bons plans et avantages fidélités.",
    bluebox_subtitle4: "Plus besoin de monnaie",
    bluebox_text4: "Réglez vos achats en ligne ou en boutique avec votre compte client. Rechargeable avec vos espèces, tickets restos ou CB.",
    bluebox_button: "Trouver mon commerçant",
    comming_soon: "Bientôt ouvert",
    comming_soon_text: "Notre commerçant sera bientôt ouvert\nMerci de votre patience.",
    to_collect: "Prévue dans",
    collect: "Prêt",
    ready_on: "Le",
    amount: "montant",
    article: "article",
    article_plural: "articles",
    order_passed: "commande passée le",
    item_delivery: "Frais de livraison",
    error_collected_at: "L'heure de votre commande n'est plus valable, merci de changer l'heure ...",
    error_products_collected_at: "Vous ne pouvez pas commander votre panier avant le {{date}}. Veuillez changer l'heure de la commande ou enlever les produits indisponibles ...",
    error_product_collected_at: "Vous ne pouvez pas commander votre panier avant le {{date}}. Veuillez changer l'heure de la commande ou enlever le produit indisponible ...",
    reco_title: "Recommander",
    placeholder: "Recherchez ici votre commerçant",
    reco_text1: "Recommandez et gagnez 10€ de crédit chez votre commerçant, si il adhère au Food & Collect Digicommerce.",
    reco_text2: "Recherchez l'adresse de votre commerçant et renseignez votre adresse email pour que nous puissions vous recontacter.",
    success_recommandation: "Merci, votre recommandation a bien été envoyée.",
    title_find_shop: "Recherchez un commerçant",
    p_find_shop: "Trouvez un commerçant proche de chez vous, commandez et il sera dans vos favoris.",
    title_recommendation: "Recommander un commerçant",
    p_recommendation: "Et gagnez 10€ de crédit chez votre commerçant, si il adhère au Food &amp; Collect Digicommerce.",
    title_profile: "Mon compte",
    title_register: "Créer mon compte",
    title_validation: "Validation du compte",
    email_label: "Email",
    email_placeholder: "Votre email",
    email_required: "L'adresse e-mail est obligatoire.",
    email_valid_error: "l'adresse e-mail est déjà utilisée.",
    password_new_label: "Nouveau mot de passe",
    password2_label: "Vérification du mot de passe",
    password2_invalid: "La vérification du mot de passe est incorrecte.",
    company_label: "Société",
    company_placeholder: "Votre société (facultatif)",
    phone_label: "Téléphone",
    phone_placeholder: "06123456789",
    phone_required: "Le numéro de téléphone est obligatoire.",
    phone_invalid: "Le numéro de téléphone n'est pas correcte.",
    phone_not_mobile: "Veuillez renseigner un numero de téléphone mobile.",
    phone_not_available: "Un compte existe déjà pour ce numéro de téléphone.",
    mail_not_available: "Un compte existe déjà pour cet email.",
    dob_label: "Date de naissance",
    dob_placeholder: "jj/mm/aaaa",
    dob_invalid: "Date de naissance non valide",
    countryCode_label: "Pays",
    countryCode_placeholder: "Votre pays",
    membershipCard_label: "N° de badge ou carte de fidélité",
    membershipCard_placeholder: "Votre numéro de badge ou carte de fidélité",
    membershipCard_label2: "N° de badge ou carte de fidélité",
    membershipCard_placeholder2: "Votre numéro de badge ou carte de fidélité",
    membershipCard_valid: "Votre numéro est valide",
    membershipCard_invalid: "Votre numéro n'existe pas ou est déjà utilisé.",
    label_godfatherCodeUsed: "Avantage Parrainage",
    placeholder_godfatherCodeUsed: "Votre code de parrainage",
    godfatherCodeUsed_valid: "Votre code est valide",
    godfatherCodeUsed_invalid: "Votre code n'est pas valide",
    partnershipCode_label: "Réduction entreprise",
    partnershipCode_placeholder: "Votre code de réduction société",
    partnershipCode_placeholder2: "Tapez votre code",
    partnershipCode_add: "Ajouter un code",
    partnershipCode_valid: "Votre code est valide",
    partnershipCode_invalid: "Votre code n'est pas valide",
    partnershipCode_help: "Ajoutez ici votre code société pour profiter d'avantages, de réductions ou de promotions exceptionnels sur vos commandes",
    student_label: "Je suis étudiant (votre carte sera demandée à chaque retrait)",
    optin_label: "Je souhaite recevoir des informations promotionnelles",
    optout_label: "Je souhaite recevoir les offres partenaires",
    accept_label: "J'ai lu et j'accepte les conditions générales de vente du site.",
    accept_required: "Vous devez accepter les conditions générales de vente.",
    button_register: "S'inscrire",
    facturation_address: "Adresse de facturation",
    delivery_address: "Adresse de livraison",
    saved_delivery_address: "Adresse de livraison sauvegardé",
    postcode: "Code Postal",
    city: "Ville",
    cellphone: "téléphone",
    born: "né(e) le",
    credit_card: "Carte bancaire",
    credit_card_helper: "Vous pouvez supprimer la carte bancaire que vous avez enregistrée lors d'un précedent achat",
    credit_card_helper2: "L'enregistrement sera proposé lors de votre achat",
    edenred_card: "Ticket Restaurant®",
    edenred_card_helper: "Connectez_vous pour régler vos achats avec votre carte Ticket Restaurant® chez votre commerçant",
    restoflash_card: "Le titre restaurant sans limite",
    restoflash_card_helper: "Réglez vos achats avec Resto Flash chez votre commerçant, au moment de finaliser votre panier choisissez l'option \"Resto Flash\"",
    godfather_text: "Partagez votre code de parrainage et gagnez {{site.credit_godfather}} points pour la première commande en ligne de votre filleul(e)",
    godfather_subject: "Offre parrainage",
    godfather_message: "{{profile.firstname}} vous offre X points sur votre prochaine commande en ligne avec le code {{profile.godfather_code}}",
    smscode_label: "Code de confirmation",
    smscode_placeholder: "Ex: XXXX",
    smscode_text: "Vous allez recevoir un code de confirmation par SMS pour valider la création de votre compte",
    smscode_required: "Le code est obligatoire",
    smscode_invalid: "Le code est composé de 4 chiffres",
    link_validation_label: "Lien de validation",
    send_activate_link: "Un lien d'activation vous a été envoyé dans votre boite mail.",
    button_scan: "Scanner ma carte",
    and_connected: "et connecté",
    profile_success: "Votre profil a été enregistré.",
    profile_error: "Mise à jour impossible. Veuillez réessayer.",
    scan_error: "Impossible de scanner votre carte, merci de rééssayer.",
    register_success: "Votre compte a été créé. vous allez recevoir un code d'activation de compte sur votre mobile ou votre boite mail",
    title_payment: "Moyens de paiement",
    title_address: "Coordonnées",
    title_perso: "Informations personnelles",
    title_lang: "Langue",
    title_god_father: "Mon code parrainage",
    voucher_success: "Votre code avantage société a bien été pris en compte.",
    share: "{{firstname}} vous offre {{credit_godson}} points sur votre prochaine commande en ligne avec le code de parrainage {{godfather_code}}. Rendez vous sur {{url}}",
    share_title: "Offre parrainage",
    send_activate_code: "Un code d'activation vous a été envoyé sur votre mobile ou dans votre boite mail.",
    not_validate_code: "Votre inscription n'a pas été confirmée.",
    account_validate: "Votre compte a été validé.",
    new_email_account_validate: "Votre nouvel e-mail a été pris en compte.",
    can_connect: "Vous pouvez maintenant vous connecter.",
    code_error: "Votre code est incorrect. Veuillez réessayer.",
    membership_card_lastname: "Pour une meilleur sécurité, veuillez renseigner votre nom.",
    already_leave: "Vous nous quittez déjà ?",
    modify_email: "Je confirme la modification de mon adresse mail.",
    modify_email_description: "Après validation du formulaire, nous vous invitons à vous déconnecter. Rendez_vous dans la partie validé mon compte munis de votre nouveau code de validation afin de rendre le changement d'adresse mail effectif. ",
    delete_profile: "Je confirme la suppression de mon compte.",
    label_delete_profil: "Suppression du compte",
    delete_profile_description: "Nous vous rappelons que tout suppression de compte est une action irréversible.",
    username_not_valid: "Cette adresse mail n'est pas disponible. Veuillez en saisir une autre.",
    new_email_label: "Saisir votre nouvel e-mail",
    info_code_label: "Veuillez saisir le nouveau code reçu",
    info_send: "Veuillez réessayer dans {{minute}} minutes",
    got: "Vous disposez de ",
    points: "points",
    credit: "€",
    title_prepaye: "Mon compte prépayé",
    my_credit: "Mon crédit",
    charger: "Charger",
    my_total_credit: "Votre crédit total restant",
    my_points: "Mes points de fidélités",
    points_text: "A utiliser pour profiter de réductions",
    credit_offers: "Offre de rechargement",
    credit_other: "Recharger mon compte",
    credit_added: "de crédit acheté",
    credit_given: "offert",
    partnership: "Avantage société",
    partnership_discount: "de réduction",
    partnership_text1: "grâce à {{value.name}}, sur vos commandes en ligne",
    discount: "Avantage privilège",
    discount_title1: "-{{value}}% de réduction",
    discount_text1: "pour votre fidélité, sur vos commandes en ligne",
    not_benefite: "Vous n'avez pas de d'avantage pour le moment.",
    only_shop: "seulement en boutique",
    refill_confirm: "Vous êtes sur le point de recharger",
    bank_confirm: "Vous êtes sur le point de payer",
    refill_thanks: "Sur votre compte crédit et nous vous en remercions.",
    bank_thanks: "Par carte bancaire et nous vous en remercions.",
    bank_confirm_title: "Confirmation de paiement",
    refill_bankcard_carrefully: "Attention !",
    refill_bankcard_saved: "Une carte bancaire est enregistrée.",
    refill_paiement_send: "Le paiement sera directement soumis.",
    refill_confirm_button: "Je confirme",
    refill_no_card_confirm: "En confirmant,",
    refill_no_card_redirection: "Vous serez redirigé sur la page de paiement",
    //payment
    generalConditions: "J'accepte les conditions générales de vente",
    done: "Paiement effectué.",
    error: "Erreur de paiement.",
    loading: "Paiement en cours.",
    wrong: "Opps ! un problème est survenu",
    verify_your_account: "Veuillez vérifier votre compte et réessayer.",
    home_page: "Aller à la page d'accueil",
    success_pay: "Votre paiement a bien été éffectué.",
    validate_pay: "Votre commande est validée",
    title_pay: "Règlement",
    subtitle: "commande avec obligation de paiement",
    cards_title: "Cartes enregistrés",
    add_title: "Ajouter une carte bancaire",
    add_desc: "bancaire ou titre restaurant",
    empty_title: "Aucune carte enregistrée",
    empty_desc: "Ajoutez une carte pour simplifier votre prochain achat",
    loading_title: "Récuperation",
    loading_desc: "de vos moyens de paiement enregistrés",
    add_card: "Nouveau moyen de paiement ?",
    exp_on: "Expire le",
    card_selected: "sélectionné",
    card_choose: "choisir",
    card_save: "Enregistrer ma carte",
    with_card_save: "La carte enregistrée sera utilisée",
    pay: "Payer",
    confirm: "Confirmer",
    bank_title: "Carte bancaire",
    bank_desc: "payer en ligne",
    bancontact_title: "Bancontact",
    bancontact_desc: "Payer à l'aide de bancontact",
    credit_title: "Compte crédit",
    credit_desc: " disponibles sur votre compte",
    free_title: "Sans payer",
    free_desc: "grace à ma fidélité",
    uponDelivery_title: "A réception",
    uponDelivery_desc: "payer lors de la livraison",
    uponReceipt_title: "Sur place",
    uponReceipt_desc: "payer chez votre commerçant",
    edenred_title: "MyEdenred",
    edenred_desc: "avec votre compte Edenred",
    restoflash_title: "Restoflash",
    restoflash_desc: "Le titre restaurant sans limite",
    error_delivery_pay: "Attention, le montant de la livraison a changé ! Merci de valider de nouveau le paiement afin de confirmer le nouveau prix.",
    error_generic: "Un probleme est survenu, merci de réessayer ultérieurement.",
    error_prepare_intent: "Une erreur est survenue pour la validation de votre paiement, merci de réessayer ultérieurement.",
    error_pm_unknown: "Veuillez selectionnner un méthode de règlement",
    error_already_charged: "Votre commande a déjà été payée.",
    error_insufficient_amount: "Le montant minimum est de {{ min_amount }}€ afin de pouvoir payer par carte bancaire!",
    //weekdays
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    Sunday: "Dimanche",
    title_timetable: "Horaires d'ouverture du magasin",
    text_timetable: "de {{open1}}{{close1}} _ {{open2}}{{close2}}",
    title2: "Horaires d'ouverture du service",
    close: "Fermé",
    //delivery_modal

    delivery_modal_title: "Pour une livraison au",
    not_add: "pas de supplément",
    distance_danger: "La distance est trop élevée ({{distance}} km)",
    distance_to_far: "La livraison n'est pas prise en charge à l'adresse sélectionnée",
    distance_delivery: "La distance de livraison ne peut dépasser",
    and_buy: "et coûte",
    delivery_is: "La livraison est",
    delivery_modal_free: "offerte",
    at: "à partir de",
    of_purchase: "d'achat.",
    delivery_modal_text1: "Pour toute commande passée avant 00h00 vous bénéficiez de ",
    delivery_modal_text2: "entre 00h00 et",
    text3: "et entre {{start}} et {{end}}",
    incomplete_address: "L'adresse sélectionnée, n'est pas assez complète pour le livreur !",
    invalid_address: "Adresse introuvable, veuillez verifier l'adresse.",
    address_to_far: "L'adresse sélectionnée, est trop éloigné de votre commerçant !",
    to_large: "Le volume de la commande est trop importante pour le livreur !",
    number: "Numéro:",
    street: "Rue:",
    postal_code: "Code Postal:",
    delivery_modal_city: "Ville:",
    back: "Retour",
    confirm_address: "Je confirme mon adresse",
    save_address: "Sauvegarder l'adresse",
    confirm_phone: "Confirmation du numéro de téléphone pour le livreur",
    confirm_informations: "Je valide mes informations",
    infos_text: "Informations:",
    but: " mais ",
    possible_cities_1: "Elle est également possible dans les communes de:",
    possible_cities_2: "Elle est possible dans les communes de:",
    possible_cities_3: "Elle est uniquement possible dans les communes de:",
    possible_postal_codes_1: "Elle est également possible dans les communes ayant les codes postaux suivant:",
    possible_postal_codes_2: "Elle est possible dans les communes ayant les codes postaux suivant:",
    possible_postal_codes_3: "Elle est uniquement possible dans les communes ayant les codes postaux suivant:",


    //banner
    banner_title: "Nos Bons Plans !",
    banner_to_find: "à découvrir",
    banner_promotions: "les promotions",


    incoming: "En cours",
    not_enough_points: "Vous n'avez pas assez de points.",
    incoming_text: "Votre commande est en préparation (fin {{dateFormat}})",
    order_ready: "Votre commande est prête",
    waiting_job: "En attente d'un livreur",
    job_canceled: "Le livreur n'a pas réussi a vous contacter et n'a pas pu vous livrer.",
    job_voided: "Problème de livraison, votre commande vous sera rembourser.",
    job_expired: "Aucun livreur disponible pour votre commande, votre commande vous sera rembourser.",
    waiting_processing: "Transaction en attente, votre banque n'a pas encore validée le paiement.",
    delivery_follow: "Suivre mon livreur",
    ordering_at: "Commandé le",
    for_to: "Pour le",
    deliveree_support: "Vous pouvez contacter le service client au (+33) 09 75 18 21 01",


    title_history: "Historique",
    invoice_history: " Facture",
    order_again_history: "Commander de nouveau",


    mes_recharge: "Mes recharges",
    createdAt: "Rechargé le",
    allLoaded: "Toutes les données ont été chargées",


    //order_modal
    Re_commander: "Re_commander",
    warning_dispo: "Il est possible que certain produit ne soit pas disponible.",
    not_delivery_time: "Les heures de livraison ne sont pas configurées.",
    add_item_basket: "Article ajouté au panier.",
    item_not_available: "Aucun produit n'est disponible.",


    //other
    cgv: "Conditions générales de vente",
    ml: "Mentions légales",
    to: "à",
    other_at: "à",
    between: "entre",
    and: "et",
    deliveree_week_between: "Livraison prévue {{text}} entre {{start}} et {{end}}",
    deliveree_far_between: "Livraison prévue entre {{start}} et {{end}}",
    today2: "aujourd'hui",
    total: "Total",
    ours: "Notre",
    delete_compte: "Supprimer mon compte",
    cookies: "Ce site utilise des cookies pour que vous puissiez passer des commandes, en l'utilisant, vous acceptez notre politique de cookies disponibles dans les mentions légales."
    ,
    //msg alert
    empty_disponiblity: `il n'ya pas assez de produits disponibles.`,
    opening_soon: 'Notre commerçant sera bientot ouvert Merci de votre patience',
    merchants: 'Mes commerçants',
    search_merchant: 'Rechercher un commerçant',

    //mes commercant categories
    all: 'Tous',
    snacking: 'Snacking',
    restaurant: 'Restaurant',
    bakery: 'Boulangerie',
    butcher: 'Boucherie',

    // recharges 
    refills: 'Mes recharges',
    recharged: 'Rechargé',
    // basket
    element_added: 'un element a ete ajouté au panier.',
    minimum_basket: 'Votre panier doit etre au minimum',
    // status commercant
    open_now: 'Ouvert maintenant',
    closed_now: 'Fermé maintenant',
    last_order: 'Dernière commande à',
    plan: 'Planifier',
    avis: 'avis',
    search_address: 'Rechercher par ville, par catégorie, par commerçant',
    location_search: 'Entrez votre adresse de localisation',
    menu_search: 'Rechercher des plats, des produits',
    become_retailer: 'Devenir commerçant',
    // commande en cours 
    minutes: 'minutes',
    hours: 'Heures',
    in: 'Dans',
    delivered: 'Livrée',
    invoice: 'Aperçu',
    my_orders: 'Mes commandes',
    add_comments: 'Ajouter des remarques',
    complaint_comments: 'Vous souhaitez avoir des renseignements particuliers sur nos produits ou souhaitez prendre simplement contact avec nous ? Veuillez compléter le formulaire ci-après.',
    committed_serving: 'Nous nous engageons à vous servir dans les meilleurs conditions recommandées .',
    insert_comments: 'Insérer vos remarques',
    loyalty: 'Fidélité',
    good_deals: 'Bonnes Affaires',
    advantage_offers: 'Offres Avantages',
    contact_us: 'Contactez-nous',
    numbre_characters: 'Nombre de caractères restants : ',
    attachment: 'Pièce jointe',
    select_file: 'Choisir un fichier',
    amount_insufficient: 'le montant de votre panier est insuffisant pour ce code promo ',
    u_have: 'Vous avez',
    discount_percent: '% de réduction sur le panier ',
    promo_code_label: 'Saisir le code promo',
    apply: 'Appliquer',
    no_promotion: `Vous n'avez actuellement aucune promotion`,
    articles: `article(s) `,
    message_sent: `Message envoyé`,
    message_sent_successfully: `Votre message a été envoyé avec succès.`,
    bank_card: `Visa Carte bancaire`,
    add_payment_card: `Ajouter une carte de paiement`,
    pay_boncontact: `Payer à l’aide de bancontact`,
    credit_account: `Compte crédit`,
    on_site: `Sur place`,
    pay_your_merchant: 'Payer chez votre commerçant',
    time_expired: 'Votre heure de retrait est dépassé.',
    owner_name: 'Nom du propriétaire',
    card_name: 'Nom sur la carte',
    card_number: 'Numéro de carte',
    expired_date: `Date d'expiration`,
    save_card: `Enregister ma carte`,
    conditions_of_sale: `J’accepte les conditions générales de vente`,
    payment_method: `Moyen de paiement`,
    payment_obligation: `Commande avec obligation de paiement`,
    opennow: 'Ouvert',
    closenow: 'Fermé',
    accepts_orders: `accepte les commandes jusqu’a`,
    fair_prices: `Des prix équitables • Sain`,
    call: `Appeler`,
    opening_hours: `Horaire d’ouverture`,
    pickup_hours: `Horaire de retrait`,
    account_credit: 'Mon crédit',
    point: 'point(s)',
    basket: 'Mon panier',
    empty_your_basket: 'Vider le panier',
    compose_formula: 'Composez votre formule',
    without_additional_sauce: 'Sans sauce supplémentaire',
    selected_sauces: 'Sauce(s) sélectionnée(s)',
    add_to_basket: 'Ajouter au panier',
    comments: 'Remarques',
    pickup_time_after_hours: 'Merci de choisir un retrait au dela de 2 heure(s)',
    weight: 'Poids',
    no_of_pieces: 'Nbr. de pièces :',
    no_file_chosen: 'Aucun fichier choisi',
    planning_order: 'Planifier une commande',
    delivery: 'Livraison',
    takeaway: 'À emporter',
    insert_comment: 'Insérer vos remarques en cliquant sur le bouton “Ajouter” ',
    insert_promo_code: 'Saisir votre code promo en cliquant sur le bouton “Valider” ',
    search_retailer: 'Chercher Commerçant',
    cmd_incomming: 'Commandes en cours',
    invoices: 'Mes factures',
    discount_received: `Vous bénéficiez d'une réduction de `,
    purchases_percent: '% sur vos achats',
    January: 'Janvier',
    February: 'Février',
    March: 'Mars',
    April: 'Avril',
    May: 'Mai',
    June: 'Juin',
    July: 'Juillet',
    August: 'Août',
    September: 'Septembre',
    October: 'Octobre',
    November: 'Novembre',
    December: 'Décembre',
    Order_ready: 'Commande prête',
    conatct_help: `Si vous avez envie faire partie de nos commerçants connectés et souhaitez obtenir plus d'informations, n'hésitez pas à nous contacter en complétant ce formulaire.`,
    message: 'Message',
    business_sector: 'Votre secteur d’activité',
    activity_sector: 'Secteur d’activité',
    phone_no: 'N°. de téléphone',
    including: 'dont ',
    selected_items: 'Élément(s) sélectionnée(s)',
    delivery_not_allowed: 'Livraison non autorisée',
    Mon: "Lundi ",
    Tue: "Mardi",
    Wed: "Mercredi",
    Thu: "Jeudi",
    Fri: "Vendredi",
    Sat: "Samedi",
    Sun: "Dimanche",
    Lun: "Lundi",
    Mar: "Mardi",
    Mer: "Mercredi",
    Jeu: "Jeudi",
    Ven: "Vendredi",
    Sam: "Samedi",
    Dim: "Dimanche",
    le: "Le ",
    delivery_on: "Livraison sur",
    des: "dés",
    achat_livraison: "d'achat > (livraison",
    offert_des: "/ offerte dés ",
    point_retrait: "Points de retrait",
    adresses: "Adresses de ",
    saved_address: "Sauvegarder l'adresse",
    minimum: "pour minimum",
    hors_service: "(hors frais de service)"

}
export default lang;