import { Modal, ModalRefType } from "../../DesignSystem";
import { IModalAddNote } from "../../Interfaces/Modal";
import React, { Ref, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EListFunction, IUseFormResult, useForm } from "../../CustomHooks";
import { ShopAPI } from "../../Api/Shop/shop";
import { RootState } from "../../Interfaces";
import { TemplateAddNoteModal } from "../../Template";
import { openModal, setBasket, setCommentProduct } from "../../Redux/Reducers";

const ModalAddNote = (props: IModalAddNote, ref: Ref<ModalRefType | undefined>) => {
    const { lang } = useSelector((state: RootState) => state.setting)
    const { commercant, product, item } = useSelector((state: RootState) => state.commercant)
    const { data } = useSelector((state: RootState) => state.modal)
    const { commentItem, itemId, baskett } = useSelector((state: RootState) => state.basket)
    const dispatch = useDispatch()
    const shoApi = new ShopAPI()
    const {
        state: form,
        onChange,
        onUpdateState,
    }: IUseFormResult = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "remarque",
                value: '',
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            }
        ]
    })
    const setOrderComment = async () => {
        if (product?.uniq && form?.remarque?.value !== '' && !data?.isTrue) {
            const commentItem = form.remarque?.value;
            const itemId = product.uniq;
            if (commentItem && itemId) {
                dispatch(setCommentProduct({ commentItem: commentItem, itemId: itemId }))
            }
            if (commentItem !== '' && itemId !== '') {
                if (props.onExit) {
                    props.onExit()
                }
                if (data?.fromCompose) {
                    if (data?.fromBasket) {
                        dispatch(openModal({ name: 'modalComposeFormule', data: { product: null, item: data?.itemSelected, fromAddNote: true, showOptions: true } }))
                    }
                    if (data?.fromMenu) {
                        dispatch(openModal({ name: 'modalComposeFormule', data: { product: [product], item: null, fromAddNote: true, showOptions: true } }))
                    }
                } else {
                    if (data?.fromBasket) {
                        dispatch(openModal({ name: 'modalProductDetails', data: { product: null, item: data?.itemSelected, fromAddNote: true } }))
                    }
                    if (data?.fromMenu) {
                        dispatch(openModal({ name: 'modalProductDetails', data: { product: [product], item: null, fromAddNote: true, quantity: data?.quantity } }))
                    }
                }
            }
        } else {
            const response = await shoApi.setOrderComment({
                suid: commercant?.suid,
                lang: lang
            },
                { comment: form?.remarque?.value }
            )
            if (response.status === 200) {
                if (props.onExit) {
                    props.onExit()
                    dispatch(setBasket({ baskett: { ...data?.basket, comment: form?.remarque?.value }, basketId: data?.basket?.uniq }))
                }
            }
        }
    }

    const onAddNote = () => {
        if (form?.remarque?.value !== '') {
            setOrderComment()
        }else{
            onUpdateState({
                ...form,
                remarque: {
                    ...form.remarque,
                    isValid: false,
                    isInvalid: true
                }
            })
        }
    }
    const onCloseAddNote = () => {
        if (data?.fromMenu && data?.itemSelected) {
            dispatch(openModal({ name: 'modalProductDetails', data: { product: [data?.itemSelected], item: null, fromClose: true, quantity: data?.quantity, comment: form?.remarque?.value,itemId:product?.uniq } }))
        }
    }

    React.useEffect(() => {
        if (data?.isTrue && data?.basket?.comment) {
            onUpdateState({
                ...form,
                remarque: {
                    ...form.remarque,
                    value: data?.basket?.comment,
                    isValid: false,
                    isInvalid: false
                }
            })
        }
    }, [data?.isTrue, data?.basket?.comment])
    React.useEffect(() => {
        if (data?.fromMenu) {
            onUpdateState({
                ...form,
                remarque: {
                    ...form.remarque,
                    value: '',
                    isValid: false,
                    isInvalid: false
                }
            })
        }
    }, [data?.fromMenu])

    React.useEffect(() => {
        if (data && !data?.isTrue) {
            if (itemId === product?.uniq) {
                onUpdateState({
                    ...form,
                    remarque: {
                        ...form.remarque,
                        value: commentItem,
                        isValid: true,
                        isInvalid: false
                    }
                })
            }
        }
    }, [data])
    React.useEffect(() => {
        if (product) {
            if (itemId === product?.uniq) {
                onUpdateState({
                    ...form,
                    remarque: {
                        ...form.remarque,
                        value: commentItem,
                        isValid: true,
                        isInvalid: false
                    }
                })
            } else {
                onUpdateState({
                    ...form,
                    remarque: {
                        ...form.remarque,
                        value: '',
                        isValid: true,
                        isInvalid: false
                    }
                })
            }
        }
    }, [product])

    return (
        <Modal
            contentClassName="ds-borad-14 modal-add-note overflow-hidden ds-flex ds-pointer"
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
            onClickClose={() => onCloseAddNote()}
        >

            <TemplateAddNoteModal
                form={form}
                onChange={onChange}
                onAddNote={onAddNote}
            />
        </Modal>
    )
}
export default forwardRef(ModalAddNote)