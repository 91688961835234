import { IModalMentionLegales } from "../../Interfaces/Modal"
import { TemplateMentionLegales } from "../../Template"
import { useSelector } from "react-redux"
import { RootState } from "../../Interfaces"
import React, { Ref, forwardRef, useState } from 'react'
import { Modal, ModalRefType } from '../../DesignSystem'
import { CommercantAPI } from "../../Api/Commercants/Commercants"

const ModalMentionLegales = (props: IModalMentionLegales, ref: Ref<ModalRefType | undefined>): JSX.Element => {
    const [mentionLegales, setMentionLegales] = useState<string | TrustedHTML>('')
    const commercantApi = new CommercantAPI()
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    const { lang } = useSelector((state: RootState) => state?.setting)
    const getMentionsLegales = async () => {
        if (commercant?.suid) {
            const response = await commercantApi.getMentionsLegales({ suid: commercant?.suid, lang: lang })
            setMentionLegales(response?.data)
        }
    }
    React.useEffect(() => {
        getMentionsLegales()
    }, [])

    return (
        <Modal
            contentClassName="ds-pointer ds-borad-14 modal-general-conditions ds-overflow-x ds-flex"
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
        >

            <TemplateMentionLegales
                mentionLegales={mentionLegales}
            />
        </Modal>
    )
}
export default forwardRef(ModalMentionLegales)