import { createSlice } from '@reduxjs/toolkit';
import { IAlertReducer } from '../../../Interfaces';

const initiaState: IAlertReducer = {
  message:"",
  type:""
};

const AlertReducer = createSlice({
  name: 'alert',
  initialState: initiaState,
  reducers: {
    openAlert:(state: IAlertReducer, action)=>{
      state.type = action.payload.type
      state.message = action.payload.message
    }

  }
});

export const {openAlert} = AlertReducer.actions;

export default AlertReducer.reducer;
