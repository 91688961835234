
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { RootState } from "../../Interfaces/Redux";
import { Navbar, Sidebar } from "../../DesignSystem";
import Drawer from 'react-modern-drawer'
import { Data } from "../../Common";
import { setSideBar } from "../../Redux/Reducers";
import { useContext } from 'react'
import { LangContext } from "../../Lang";

const PublicRoute = (): JSX.Element => {
    const { show } = useSelector((state: RootState) => state?.sideBar);
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    const { user, token } = useSelector((state: RootState) => state?.auth)
    const className = ["ds-w-100"]
    const langue = useContext(LangContext)
    const dispatch = useDispatch()
    const handleCloseDrawer = () => {
        dispatch(setSideBar(show));
    };
    
    return (
        <>
            <div
                className="ds-flex sidebar-container ds-w-100 ds-h-100" >
                <div className={className.join(' ')} style={{ height: '100%' }}>
                    <Navbar />
                    <div className="ds-w-100 ds-h-100 ds-flex-col">
                        <Outlet />
                    </div>
                </div>

            </div>
            {show && <Drawer
                open={show}
                direction='left'
                size={300}
                onClose={() => { handleCloseDrawer() }}
                enableOverlay={show}
            >
                <Sidebar
                    style={{ width: window.innerWidth }}
                    items={Data.getSideBarItems(langue, user, token, commercant, "/")}
                />
            </Drawer>}
        </>
    )
}

export default PublicRoute