import { Api, CommonFunction, Config } from "../../Common"
import { ICollectedOrder, IComment, ICommon, IContactUs, IDeliveryOption, IExpressCommande, IFormData, IOrderItem, IOrderQuantity, IVoucher, Ioffset } from "../../Interfaces"

class ShopAPI {
    _api: Api
    constructor() {
        this._api = new Api(Config.getInstance().API_URL)
    }
    getDeliveryMethodName = (index: number | undefined) => {
        switch (index) {
            case 2:
                return 'eatin';
            case 3:
                return 'delivery';
            case 4:
                return 'picking';
            default:
                return 'collect';
        }
    }
    async getShopTimes(params: ICommon) {
        return await this._api.get(`shop/times/${this.getDeliveryMethodName(params?.index)}?suid=${params.suid}&lang=${params.lang}&zone=${params.zone}`, CommonFunction.createHeaders({ withToken: true }))
    }
    async setOrderItems(params: ICommon, body: IOrderItem) {
        return await this._api.post(`order/basket/item?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async getBasketOrder(params: ICommon) {
        return await this._api.get(`order/basket?suid=${params.suid}&lang=${params.lang}&zone=${params.zone}`, CommonFunction.createHeaders({ withToken: true }))
    }
    async setOrderComment(params: ICommon, body: IComment) {
        return await this._api.post(`order/basket/comment?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async setCodeCoupon(params: ICommon, body: IVoucher) {
        return await this._api.post(`loyalty/voucher/use?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async deleteItem(params: ICommon) {
        return await this._api.delete(`order/basket/item/${params?.uid}?suid=${params.suid}&lang=${params.lang}&zone=${params.zone}`, CommonFunction.createHeaders({ withToken: true }))
    }
    async getVoucherCheck(params: ICommon) {
        return await this._api.get(`loyalty/check/voucher/use?suid=${params.suid}&lang=${params.lang}&zone=${params.zone}`, CommonFunction.createHeaders({ withToken: true }))
    }
    async setCollectedAtOrder(params: ICommon, body: ICollectedOrder) {
        return await this._api.post(`order/basket/${body.collected_at}?${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async onContactUs(params: IContactUs, body: IFormData) {
        return await this._api.post(`shop/contact?suid=${params?.suid}&contact=${JSON.stringify(params.contact)}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async getCommande(params: ICommon) {
        return await this._api.get(`order/user/incoming/0/100?suid=${params.suid}&lang=${params.lang}&isCordova=false`, CommonFunction.createHeaders({ withToken: true }))
    }
    async getHistory(params: ICommon,body:Ioffset) {
        return await this._api.get(`order/user/history/${body.offset}/8?suid=${params.suid}&lang=${params.lang}&isCordova=false`, CommonFunction.createHeaders({ withToken: true }))
    }
    async getItemCommande(params: ICommon) {
        return await this._api.get(`order/order/${params.uniq}?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }))
    }
    async getUserTransactions(params: ICommon,body:Ioffset) {
        return await this._api.get(`order/user/transactions/${body.offset}/8?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }))
    }
    async setCollectedAt(params: ICommon, body: IDeliveryOption) {
        return await this._api.post(`order/basket/collected_at?suid=${params?.suid}&lang=${params.lang}&zone=${params.zone}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async setCommandeExpress(params: ICommon, body: IExpressCommande) {
        return await this._api.post(`order/basket/express/${params?.uniq}?suid=${params?.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async getUserTransactionsnotCompleted(params: ICommon) {
        return await this._api.get(`order/user/transactions/0/8/true?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }))
    }
    async emptyBasket(params: ICommon) {
        return await this._api.delete(`order/basket/item?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }))
    }
    async updateQuantityBasket(params: ICommon, body: IOrderQuantity) {
        return await this._api.post(`order/basket/update-item-quantity-and-remarque?suid=${params?.suid}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
}
export { ShopAPI }