import { ModalRefType } from "../../DesignSystem";
import { ReactNode, Ref } from "react";

enum Elang {
    fr = "Fr",
    en = "En",
    nl = "Nl",
}

interface IPageProps {
    children: JSX.Element | null | undefined | ReactNode
}
interface ITypePayload {
    type?: string;
    payload?: string;
}
interface IKeys {
    [key: string]: string;
}
interface IAnyKeys {
    [key: string]: any;
}
interface IKeysModal {
    [key: string]: Ref<ModalRefType | undefined | null>
}
export type {
    IPageProps,
    ITypePayload,
    IKeys,
    IKeysModal,
    IAnyKeys
}

export {
    Elang,
}