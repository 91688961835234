import React from "react"
import { Text } from "../../Atoms";
import { ITextInput } from "../../Interfaces/Molecule/ITextInput/ITextInput";
const MoleculeInputLabel = (props: ITextInput): JSX.Element => {
    const { text, type, className } = props
    const labelClassName = ["ds-text-line-17 ds-text-size-14"]
    switch (type) {
        case "label":
            labelClassName.push('ds-mb-4 ds-text-primary100')
            break;
        case "error":
            labelClassName.push('ds-mt-4 ds-text-error100')
            break;
        case "success":
            labelClassName.push('ds-mt-4 ds-text-success100')
            break;
        case "helper":
            labelClassName.push('ds-mt-4 ds-text-neutral40')
            break;

        default:
            break;
    }
    if (className) {
        labelClassName.push(className)
    }
    if (text) {
        return <Text text={text} isSpan className={labelClassName.join(' ')} />
    }
    return <></>
}
MoleculeInputLabel.defaultProps = {
    text: "",
    type: "label",
    className: ''
}
export default MoleculeInputLabel