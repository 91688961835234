import { Button, Input } from "../../DesignSystem";
import slash from '../../Assets/images/slash.svg';
import { Type } from "../../DesignSystem/Interfaces";
import { CommonFunction } from "../../Common";
import { ITemplateCouponReductionModal, RootState } from "../../Interfaces";
import { useContext } from "react";
import { LangContext } from "../../Lang";
import { useSelector } from "react-redux";

const TemplateCouponReductionModal = (props: ITemplateCouponReductionModal): JSX.Element => {
    const { baskett } = useSelector((state: RootState) => state.basket)
    const langue = useContext(LangContext)
    const renderAddCoupon = () => {
        const list: JSX.Element[] = []
        list.push(
            <div className="ds-flex-col ds-center ds-w-100 ds-p-0-24">
                <span className="ds-text-size-28 ds-text-line-33 ds-text-weight700 ds-text-primary100 ds-mt-8 ds-mb-16">
                    {langue.voucher_placeholder}
                </span>
                <div className="ds-flex ds-align-center ds-w-100 ds-mb-14">
                    <Input
                        label={langue.promo_code_label}
                        {...CommonFunction.getInputProps(props?.form?.coupon)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            props?.onChange({
                                key: "coupon",
                                value: e.target.value
                            })
                        }
                        containerClassName="ds-mr-10 ds-mb-20"
                    />
                    <Button
                        text={langue.apply}
                        className="ds-bg-primary10 ds-borad-8 modal-Button"
                        type={Type.quaternary}
                        onClick={() => props?.onValidateCodeCoupon()}
                    />
                </div>
                {!props?.isCouponValid && <div className="ds-flex-col ds-center ds-mb-40">
                    <img
                        src={slash}
                        height={48}
                    />
                    <span className="ds-text-size-16 ds-text-line-19 ds-text-weight500  ds-text-primary40">{langue.no_promotion}</span>
                </div>}
                <Button
                    text={baskett?.discount ? langue.modify: langue.validate}
                    className="ds-w-100"
                    onClick={() => props?.onVerifCodeCoupon()}
                />
            </div>
        )
        return (<>{list}</>)
    }
    return (renderAddCoupon())
}
export default TemplateCouponReductionModal