import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Icon, Text } from '../../Atoms';
import { IMoleculeDropdown } from '../../Interfaces/Molecule/IMoleculeDropdown/IMoleculeDropdown'
import { TextType } from '../../Interfaces';
import { DEFAULTDROPDOWNPROPS } from '../../DefaultProps/Molecules';
import { colors } from '../../Constant';
function MoleculeDropdown(props: IMoleculeDropdown): JSX.Element {
    const [clicked, setClicked] = useState<number | null>()
    const containerClassName: string[] = ['ds-w-100 ds-flex ds-align-center'];
    const labelClassName: string[] = ['ds-link text-overflow-sidebar'];
    const primaryIconClassName: string[] = ['ds-mr-7 ds-mt-3'];
    const secondaryIconClassName: string[] = [''];
    const renderItem = () => {
        const list: JSX.Element[] = [];
        const listDiv: JSX.Element[] = [];
        const listSubItem: JSX.Element[] = [];
        if (props.primaryIcon) {
            listDiv.push(
                <Icon icon={props.primaryIcon} className={primaryIconClassName.join(' ')} size={props.primaryIconSize} />
            )
        }
        if (props.path) {
            listDiv.push(
                <Link to={props.path} style={{ color: props.open ? colors.primary100 : colors.neutral100 }} className={labelClassName.join(' ')}>{props.label}</Link>
            )
        } else {
            listDiv.push(
                <Text text={props.label} style={{ color: props.open ? colors.primary100 : colors.neutral100 }} isSpan className={labelClassName.join(' ')} type={TextType['subtitle-2']} />
            )
        }
        if (props.secondaryIcon) {
            if (props.isLeftSecondaryIcon) {
                list.push(
                    <Icon icon={props.secondaryIcon} className={secondaryIconClassName.join(' ')} size={props.secondaryIconSize} />
                )

                list.push(<div className='ds-flex ds-align-center ds-p-15'>{listDiv}</div>)

            } else {
                containerClassName.push('ds-justify-between')
                list.push(<div className='ds-flex ds-align-center ds-p-15'>{listDiv}</div>)
                list.push(
                    <Icon icon={props.secondaryIcon} className={secondaryIconClassName.join(' ')} size={props.secondaryIconSize} />
                )

                list.push(<div className='ds-w-100 ds-flex-col'> {listSubItem}</div>)
            }
        } else {
            list.push(<div className='ds-flex ds-align-center ds-p-15'>{listDiv}</div>)
        }

        return list;

    }
    if (props.primaryIconClassName) primaryIconClassName.push(props.primaryIconClassName);
    if (props.secondaryIconClassName) secondaryIconClassName.push(props.secondaryIconClassName);
    if (props.dropDownClassName) containerClassName.push(props.dropDownClassName);
    if (props.labelClassName) labelClassName.push(props.labelClassName);

    const onClick = () => {
        if (props.onClick) {
            props.onClick()
        }
        setClicked(null)
    }

    const clickedSubIemClassName = 'ds-link ds-hp-28 ds-my-3 ds-w-100 ds-flex ds-center ds-text-primary900 ds-pointer ds-bg-primary50'
    const notClickedSubIemClassName = 'ds-link ds-hp-28 ds-my-3 ds-flex ds-pointer ds-center ds-text-neutral900 ds-w-100 ds-bg-white'
    return (
        <>
            <div className={containerClassName.join(' ')} onClick={onClick}>
                {renderItem()}
            </div>

            {props.open ? props?.subItems?.map((el, i) =>
                <div key={i} className={`ds-w-100 ds-relative ds-border-radius-8 ${i === 0 && 'ds-mt-10'}`}>
                    {clicked === i ? <div className='selected-left-border'></div> : <></>}
                    {el.path ?
                        <Link to={el.path} className={
                            clicked === i ?
                                clickedSubIemClassName
                                : notClickedSubIemClassName
                        } onClick={() => { setClicked(i) }}>{el.label}</Link>
                        : <Text
                            text={el.label}
                            className={
                                clicked === i ?
                                    clickedSubIemClassName
                                    : notClickedSubIemClassName
                            }
                            type={TextType['subtitle-2']}
                            onClick={() => { setClicked(i) }}
                        />}

                </div>

            )
                : null}
        </>
    )
}
MoleculeDropdown.defaultProps = {
    ...DEFAULTDROPDOWNPROPS,
}
export default MoleculeDropdown