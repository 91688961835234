import React, { useState } from 'react';
import { DEFAULTTABPROPS } from '../../DefaultProps/Molecules';
import { IMoleculeTab, IlistElement, List } from '../../Interfaces/Molecule/IMoleculeTab/IMoleculeTab';
import { Icon } from '../../Atoms';
import { useUpdateEffect } from '../../../CustomHooks';

const MoleculeTab = (props: IMoleculeTab) => {
    const tabClassName = ['']
    const [listTab, setListTab] = useState<List>(props.list)
    const [min, setMin] = useState<number | undefined>(props.selectedIndex)

    React.useEffect(() => {
        setListTab(props.list)
    }, [props.list])
    React.useEffect(() => {
        if (!props.withArrow) {
            setMin(props.selectedIndex)
        }
    }, [props.selectedIndex])
    useUpdateEffect(() => {
        if (props.onClick) {
            props.onClick(min)
        }
    }, [min])

    if (props.bsPrefix) {
        tabClassName.push(props.bsPrefix)
    } else {
        tabClassName.push("ds-flex")
    }
    const tabSlideClassName = ['ds-flex tab-class']
    if (props.tabClassName) {
        tabSlideClassName.push(props?.tabClassName)
    }
    const handleClick = (key: number) => {
        const list1 = listTab.slice(key)
        const list2 = listTab.slice(0, key)
        const aux = [...list1, ...list2]
        props.onGetCategoriesProducts(aux[0].index)
        setListTab(aux)
        setMin(0)
    }
    if (props.isTopTab) {
        tabSlideClassName.push('width-tab ds-overflow-xy')
    } else {
        tabSlideClassName.push('ds-overflow-y ds-w-100')
    }
    const renderTab = () => {
        const list: JSX.Element[] = []
        if (props.list) {
            listTab?.map((item: IlistElement, key: number) => {
                const textClassName = ["ds-pointer white-space-nowrap ds-text-primary60"]
                const tabClassName = ['ds-flex-col ds-align-center tab-element-class ds-px-12 gradient-text']
                if(props.textClassName) tabClassName.push(props.textClassName)
                if (props.isTopTab) {
                    tabClassName.push('tab-slide')
                }
                if (key === min) {
                    textClassName.push("ds-text-primary100 ds-pb-4")
                    if(props.selectedTextClassName) tabClassName.push(props.selectedTextClassName)
                }
                list.push(<div
                    key={key}
                    className={tabClassName.join(' ')}
                >
                    <span onClick={window.innerWidth >= 992 && props.withArrow ?
                        () => handleClick(key) :
                        () => setMin(key)
                    }
                        className={textClassName.join(' ')} >{item.label}</span>
                    {min === key && <div className='border-Bottom'></div>}
                </div>
                )
            })
        }
        return list

    }
    const clickOnNext = () => {
        if (listTab.length) {
            const aux = [...listTab, listTab[0]]
            aux.shift()
            props.onGetCategoriesProducts(aux[0].index)
            setListTab(aux)
        }
    }

    return (
        <>
            <div className={tabSlideClassName.join(' ')}
            >
                {renderTab()}
            </div >
            {props.withArrow && <div className="next-category ds-ml-16 ds-mr-16 ds-bg-white ds-pointer ds-flex ds-center box-shadow-50 ds-wp-40 ds-hp-40 ds-radius-50">
                <Icon
                    icon="arrow-right-11"
                    size={"24px"}
                    onClick={() => { clickOnNext() }}
                />
            </div>}
        </>
    )
};
MoleculeTab.defaultProps = {
    ...DEFAULTTABPROPS,
}
export default MoleculeTab;