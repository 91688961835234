import React, { useContext, useState } from "react";
import { IFiltredProduct, ITemplateMenuCommercant, RootState } from "../../Interfaces"
import { Button, Checkbox, Icon, Spinner } from "../../DesignSystem"
import { CardSize, CardType, Type } from "../../DesignSystem/Interfaces"
import { Card } from "../../DesignSystem/Organisms"
import { useSelector } from "react-redux";
import { CommonFunction } from "../../Common";
import { LangContext } from "../../Lang";
import { HorizontalMenu } from "../../DesignSystem/Molecules";

const TemplateMenuCommercant = (props: ITemplateMenuCommercant): JSX.Element => {
    const { width } = useSelector((state: RootState) => state?.screen)
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const { commercant } = useSelector((state: RootState) => state.commercant);
    const { delievryMode } = useSelector((state: RootState) => state.paiement);
    const langue = useContext(LangContext)
    const classNameOptions = ['ds-flex ds-w-100 gap-20 ds-justify-end ']
    if (showOptions) {
        classNameOptions.push('ds-flex ds-align-center ds-justify-start ds-flex-grow1 ds-w-100')
    }
    const renderInfoSite = () => {
        if (width > 600) {
            return (
                <div className="ds-flex ds-align-center ds-flex-grow1 ds-w-100 gap-5">
                    <Icon
                        icon="magic-star1"
                        size={"16px"}
                        className="ds-mb-5 star-class"
                    />
                    <span className="ds-text-size-14 ds-text-line-16 ds-text-weight500 ds-text-primary60 text-nowrap">{'4.0 (87 ' + langue.avis + ')'}</span>
                    <Icon
                        icon="clock1"
                        size={"16px"}
                        className="ds-mb-5 star-class"
                    />
                    <span className={`ds-text-size-14 ds-text-line-16 ds-text-weight500 text-nowrap ${props.commercant?.active ? 'ds-text-success100' : 'ds-text-error100'}`}>{`${props.commercant?.active ? langue.open_now : langue.closed_now}`}</span>
                    {props.commercant?.delivery_end && <span className="ds-text-size-14 ds-text-line-16 ds-text-weight400 ds-text-primary60 white-space-nowrap">{langue.last_order + ` ${CommonFunction.convertTimeFormat(props.commercant?.delivery_end)}`}</span>}
                </div>
            )
        } else {
            return (
                <div className="ds-flex-col">
                    <div className="ds-flex ds-align-center ds-flex-grow1 ds-w-100 gap-5">
                        <Icon
                            icon="magic-star1"
                            size={"16px"}
                            className="ds-mb-5 star-class"
                        />
                        <span className="ds-text-size-14 ds-text-line-16 ds-text-weight500 ds-text-primary60 text-nowrap">{'4.0 (87 ' + langue.avis + ')'}</span>
                        <Icon
                            icon="clock1"
                            size={"16px"}
                            className="ds-mb-5 star-class"
                        />
                        <span className={`ds-text-size-14 ds-text-line-16 ds-text-weight500 text-nowrap ${props.commercant?.active ? 'ds-text-success100' : 'ds-text-error100'}`}>{`${props.commercant?.active ? langue.open_now : langue.closed_now}`}</span>
                    </div>
                    {props.commercant?.delivery_end && <span className="ds-text-size-14 ds-text-line-16 ds-text-weight400 ds-text-primary60 ">{langue.last_order + ` ${CommonFunction.convertTimeFormat(props.commercant?.delivery_end)}`}</span>}
                </div>
            )
        }
    }
    const renderActions = () => {
        return (
            <div className={classNameOptions.join(' ')}>
                <Icon
                    icon="discount-shape1"
                    size={"24px"}
                    className="ds-text-primary60 ds-pointer"
                    onClick={() => props.onClickAccueilSite()}
                />
                <Icon
                    icon="info-circle"
                    size={"24px"}
                    className="ds-text-primary60 ds-pointer"
                    onClick={() => props.onShowSiteDetails()}
                />
                <Icon
                    icon="map"
                    size={"24px"}
                    className="ds-text-primary60 ds-pointer"
                    onClick={() => props.onRedirectToGoogleMaps()}
                />
                <Icon
                    icon="share1"
                    size={"24px"}
                    className="ds-text-primary60 ds-pointer"
                    onClick={() => props.onShareFacebook()}
                />
                <Icon
                    icon="call1"
                    size={"24px"}
                    className="ds-text-primary60 ds-pointer"
                    onClick={() => props.onClickAppeler()}
                />
                <Icon
                    icon="sms"
                    size={"24px"}
                    className="ds-text-primary60 ds-pointer"
                    onClick={() => props.onContactUs()}
                />
            </div>
        )
    }
    const renderMenuCommercant = () => {
        const list: JSX.Element[] = []
        if (props.commercant) {
            {
                list.push(
                    <div className="ds-flex-col ds-w-100 ds-relative ">
                        <div className="ds-flex-col ds-w-100 ds-relative">
                            <div className="ds-w-100 ds-hp-200" style={{
                                backgroundImage: `url(${props.commercant?.background?.large_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}></div>
                            <div className="ds-w-100 ds-bg-secondary100 ds-hp-8"></div>
                            <img src={props.commercant?.logo?.xsmall_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")} className="ds-radius-50 ds-absolute small-picture ds-wp-80 ds-hp-80" />
                        </div>
                        <div className="ds-flex-col info-site ds-w-100 ds-px-40 ds-mt-50 city-class">
                            <span className="ds-text-size-36 ds-text-line-43 ds-text-weight700 ds-text-primary100">{`${props.commercant.company} `}
                                <span className="ds-text-size-24 ds-text-line-28 ds-text-weight600 ds-text-primary100 ">{props.commercant.city}</span>
                            </span>
                            {window.innerWidth < 800 ? <div className="ds-flex ds-justify-between ds-flex-grow1 ds-w-100 ">
                                {!showOptions && renderInfoSite()}
                                {showOptions && renderActions()}
                                <Icon onClick={() => setShowOptions(!showOptions)} icon={showOptions ? "close" : "more2"} size={"24px"} className="ds-text-primary20 ds-flex ds-w-100 ds-justify-end ds-pointer " />
                            </div> :
                                <div className="ds-flex ds-justify-between ds-flex-grow1 ds-w-100">
                                    {renderInfoSite()}
                                    {renderActions()}
                                </div>
                            }
                            <div className="ds-w-100 horizontal-line ds-mt-16 ds-mb-16 options-delivery"></div>
                        </div>
                    </div >
                )
            }
        }
        return <>{list}</>
    }

    const showFormattedTime = (time: any) => {
        if (time > 59) {
            return `${time / 60} h`
        }
        return `${time} min`
    }
    const renderCenterMenuCommercant = () => {
        const list: JSX.Element[] = []
        list.push(
            <div className="menu-delivery ds-flex  box-shadow-8 ds-py-10 ds-px-40">
                <div className="ds-flex ds-align-center ds-justify-between tab-category">
                    {props?.tabListCategories &&
                        <HorizontalMenu
                            items={props?.tabListCategories}
                            onGetCategoriesProducts={(i: Number) => props.onGetCategoriesProducts(i)}
                            selectedIndex={props.selectedCategory} />
                    }
                </div>
                {props.showMenu && <div className="planify-checkbox ds-flex ds-align-center">
                    <Button
                        text={window.innerWidth < 1024 ? "" : langue.plan}
                        icon="calendar-2"
                        type={Type.tertiary}
                        className="ds-mr-18 ds-hp-48 ds-borad-16 ds-text-primary70 parimary-70-btn"
                        onClick={() => props.onClickPlanifier()}
                    />
                    <div className="delivery-mode ">
                        <Checkbox
                            isSwitch
                            deliveryTime={showFormattedTime(props.commercant?.delivery_options?.delivery_min_time)}
                            takeawayTime={showFormattedTime(props.commercant?.collect_min_time)}
                            labelBefore={langue.takeaway}
                            labelAfter={commercant?.delivery_points?.length !== 0 ? langue.delivery_method_delivery_point : langue.delivery} containerClassName="ds-pointer"
                            checked={delievryMode}
                            onChange={() => props.onSetModeDelivery()}
                            onClick={() => { props.onSetModeDelivery() }}
                        />
                    </div>
                </div>}
            </div>
        )

        return list
    }
    const renderMenu = () => {
        const list: JSX.Element[] = [];
        const cardList: JSX.Element[] = [];

        if (props.filtredProducts?.length) {
            list.push(
                <span className="category-name ds-text-size-24 ds-text-line-28 ds-text-weight700 ds-text-primary100 ds-mt-16 ds-mb-12">
                    {props?.tabListCategories && props?.tabListCategories[props.selectedCategory]?.label && props?.tabListCategories[props.selectedCategory]?.label}
                </span>
            )
            {
                Object.values(props.filtredProducts).map((element: IFiltredProduct) => {
                    cardList.push(
                        <div key={element?.uniq} onClick={() => { props.onAddProductToBasket(element?.uniq) }}>
                            <Card
                                key={element?.uniq ? element?.uniq + element?.name : element?.name}
                                type={CardType.productCard}
                                cardClassName={"ds-relative ds-mr-5 ds-ml-5 ds-mb-10"}
                                image={element?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")}
                                size={CardSize.small}
                                nameProduct={element?.name}
                                nameProductClassName="product-name-ellipsis"
                                descriptionProduct={element?.description}
                                discountPriceProduct={element?.attribute?.promo !== null
                                    ? `${CommonFunction.setFormatNumber(element?.attribute?.price)} €`
                                    : null}
                                priceProduct={`${element?.attribute?.promo === null ? CommonFunction.setFormatNumber(element?.attribute?.price) :
                                    CommonFunction.setFormatNumber(
                                        element?.attribute?.promo)
                                    } €`}
                                availability={element?.attribute?.availability && element?.attribute?.availability?.label}
                                discountOption={element?.discount_rule !== null && element?.discount_rule?.display_name}
                                onClickCardBtn={() => { props.onAddProductToBasket(element?.uniq) }}
                            />
                        </div>
                    )
                })
            }
        } else {
            cardList.push(<Spinner className="ds-m-auto ds-flex ds-center ds-justify-center  ds-hp-50 ds-wp-50" type={Type.secondary} />)
        }
        return (
            <div className="ds-flex-col ds-px-40 ds-pt-16 ds-w-100 ds-h-100 ">
                {list}
                <div className="ds-flex ds-w-100 ds-flex-wrap card-list">
                    {cardList}
                </div>
            </div>
        )
    }

    return (
        <div className="ds-overflow-x ds-w-100 menu-class  ds-height-124">
            {renderMenuCommercant()}
            {renderCenterMenuCommercant()}
            {renderMenu()}
        </div>
    )
}
export default TemplateMenuCommercant