
import { Api, CommonFunction, Config } from "../../Common"
import { ICommon, IPartnership, IUserChange } from "../../Interfaces"

class UserAPI {
  _api: Api
  constructor() {
    this._api = new Api(Config.getInstance().API_URL)
  }

  async getUserLoyalty(params: ICommon) {
    return await this._api.get(`loyalty/discount?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }));
  }
  async getCustomerMember(params: ICommon) {
    return await this._api.get(`customer/member/${params?.userId}?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }));
  }
  async getCustomerProfile() {
    return await this._api.get(`customer/profile/is_major`, CommonFunction.createHeaders({ withToken: true }));
  }
  async putCustomerProfile(params: ICommon, body: IUserChange) {
    return await this._api.put(`customer/profile?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }));
  }
  async addCodePromo(params: ICommon, body: IPartnership) {
    return await this._api.postNotJson(`customer/member/partnership?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }));
  }
  async getEmailDeleteProfile(params: ICommon) {
    return await this._api.get(`customer/email/delete/user/NA8ySJe4xNotOQQc?suid=${params.suid}&lang=${params.lang}`, CommonFunction.createHeaders({ withToken: true }));
  }
  async deleteUser(params: ICommon, body: any) {
    return await this._api.post(`customer/delete/user?lang=${params.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }));
  }
  async resetPassword(params: ICommon, body: any) {
    return await this._api.post(`public/user/password/reset?suid=${params.suid}&lang=${params.lang}`, JSON.stringify(body));
  }
}
export { UserAPI }