import { Modal, ModalRefType } from "../../DesignSystem";
import { IModalCouponReduction } from "../../Interfaces/Modal";
import React, { Ref, forwardRef, useContext, useState } from "react";
import { EListFunction, IUseFormResult, useForm } from "../../CustomHooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Interfaces";
import { ShopAPI } from "../../Api/Shop/shop";
import { TemplateCouponReductionModal } from "../../Template";
import { openAlert, openModal, resetToken, setBasket, setUserLoyalty } from "../../Redux/Reducers";
import { LangContext } from "../../Lang";
import { UserAPI } from "../../Api/User/user";


const ModalCouponReduction = (props: IModalCouponReduction, ref: Ref<ModalRefType | undefined>) => {
    const { lang } = useSelector((state: RootState) => state.setting)
    const { commercant } = useSelector((state: RootState) => state.commercant)
    const { data } = useSelector((state: RootState) => state.modal)
    const shoApi = new ShopAPI()
    const userApi = new UserAPI()
    const [isCouponValid, setIsCouponValid] = useState<boolean>(false)
    const dispatch = useDispatch();
    const langue=useContext(LangContext);
    const {
        state: form,
        onChange,
        onUpdateState,
    }: IUseFormResult = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "coupon",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: false
            }
        ]
    })
    
    React.useEffect(() => {
        if (data) {
            onUpdateState({
                ...form,
                coupon: {
                    ...form.coupon,
                    value: '',
                    isValid: false,
                    isInvalid: false
                }
            })
        }
    }, [data])
    const onCloseModal = () => {
        if (props.onExit) {
            props.onExit()
        }
    }
    const getUserLoloyalty = async () => {
        const response = await userApi.getUserLoyalty({
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            dispatch(setUserLoyalty({ userLoyalty: response.data }))
        }
    }
    const onVerifCodeCoupon = async () => {
        const response = await shoApi.setCodeCoupon({
            suid: commercant?.suid,
            lang: lang
        },
            { voucher: form?.coupon?.value }
        )
        if (response.status === 200) {
            onUpdateState({
                ...form,
                coupon: {
                    ...form.coupon,
                    value: "",
                    isValid: false,
                    isInvalid: false,
                }
            })
            if (response?.data?.error) {
                onCloseModal()
                dispatch(openAlert({ type: 'info', message: langue.amount_insufficient }))
            } else {
                await getUserLoloyalty()
                setIsCouponValid(true)
                dispatch(setBasket({ baskett: response?.data,basketId:response?.data?.uniq  }))
                onCloseModal()
                dispatch(openAlert({ type: 'info', message: langue.u_have + ' '+ response?.data?.voucher_infos?.amount + ' ' + langue.discount_percent }))
            }

        } else if (response.status === 404) {
            onUpdateState({
                ...form,
                coupon: {
                    ...form.coupon,
                    isValid: false,
                    isInvalid: true,
                }
            })
            setIsCouponValid(false)
        } else if (response?.status === 401) {
            if (props.onExit) {
                props.onExit()
            }
            onUpdateState({
                ...form,
                coupon: {
                    ...form.coupon,
                    value: "",
                    isValid: false,
                    isInvalid: false,
                }
            })
            dispatch(resetToken())
        }
    }
    const onValidateCodeCoupon = () => {
        if (form.coupon.value !== "") {
            onVerifCodeCoupon()
        }
    }
   
    return (
        <Modal
            contentClassName="ds-borad-14 modal-coupon overflow-hidden ds-flex ds-pointer"
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
        >

            <TemplateCouponReductionModal
                form={form}
                onChange={onChange}
                onVerifCodeCoupon={onVerifCodeCoupon}
                isCouponValid={isCouponValid}
                onValidateCodeCoupon={onValidateCodeCoupon}
            />
        </Modal>
    )
}
export default forwardRef(ModalCouponReduction)