import React, { Ref } from "react"
import { PlaceHolder } from "..";
import { DEFAULTTEXTPROPS } from "../../DefaultProps/Atoms";
import { ITextProps } from "../../Interfaces/Atoms/IAtomText/AtomText";
const AtomText = React.forwardRef((props: ITextProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const className = [];
    if (!props.bsPrefix) {
        className.push("default-text")
    }
    else {
        className.push(props.bsPrefix)
    }
    if (props.type) {
        switch (props.type) {
            case 'type-1':
                className.push('ds-text-size-64 ds-text-line-77')
                break;
            case 'type-2':
                className.push('ds-text-size-48 ds-text-line-58')
                break;
            case 'type-3':
                className.push('ds-text-size-36 ds-text-line-43')
                break;
            case 'type-4':
                className.push('ds-text-size-28 ds-text-line-34')
                break;
            case 'type-5':
                className.push('ds-text-size-24 ds-text-line-29')
                break;
            case 'type-6':
                className.push('ds-text-size-20 ds-text-line-24')
                break;
            case 'subtitle-1':
                className.push('ds-text-size-16 ds-text-line-19')
                break;
            case 'subtitle-2':
                className.push('ds-text-size-14 ds-text-line-17')
                break;
            case 'body-1':
                className.push('ds-text-size-16 ds-text-line-19')
                break;
            case 'body-2':
                className.push('ds-text-size-14 ds-text-line-17')
                break;
            case 'body-3':
                className.push('ds-text-size-12 ds-text-line-14')
                break;
            case 'body-4':
                className.push('ds-text-size-10 ds-text-line-12')
                break;
            case 'btn-text-1':
                className.push('ds-text-size-16 ds-text-line-19')
                break;
            case 'btn-text-2':
                className.push('ds-text-size-14 ds-text-line-17')
                break;
            case 'btn-text-3':
                className.push('ds-text-size-12 ds-text-line-14')
                break;
            case 'caption':
                className.push('ds-text-size-12 ds-text-line-20')
                break;
            case 'overline':
                className.push('ds-text-size-12 ds-text-line-32')
                break;
        }
    }

    if (props.weight) className.push('ds-text-weight' + props.weight);
    if (props.className) className.push(props.className);
    if (props.type) className.push(props.type);
    const PROPSOBJ = {
        onClick: props.onClick,
        id: props.id,
        className: className.join(' '),
        style: props.style,
    }

    if (props.isLoading) {
        return (
            <PlaceHolder />
        );
    }
    if (props.isLabel) {
        return (
            <label {...PROPSOBJ}>
                {props.text}
            </label>
        );
    }
    if (props.isSpan) {
        return (
            <span {...PROPSOBJ} ref={ref}>
                {props.text}
            </span>
        );
    }
    return (
        <p {...PROPSOBJ} ref={ref}>
            {props.text}
        </p>
    );
})
AtomText.defaultProps = {
    ...DEFAULTTEXTPROPS
}
export default AtomText