import { createSlice } from '@reduxjs/toolkit';
import { IPaiementReducer } from '../../../Interfaces';

const initiaState: IPaiementReducer = {
    paymentMethodes: {id:""},
    cardDetails: {},
    delievryMode: true,
    deliveryDate: Math.floor(new Date().getTime() /1000),
    deliveryAddress: "",
    paymentDetails: {
        amount: 0,
        amount_capturable: 0,
        amount_received: 0,
        application_fee_amount: 0,
        capture_method: '',
        client_secret: '',
        confirmation_method: '',
        created: 0,
        currency: '',
        customer: '',
        description: '',
        id: '',
        livemode: false,
        object: '',
        on_behalf_of: '',
        payment_method: '',
        payment_method_types: [],
        statement_descriptor_suffix: '',
        status: '',
        transfer_data: { destination: '' }
    },
    detailsCommande: [],
    methodePaiment:"",
    historyCommandes:[],
    cardSaved:false,
    generalCondition:false,
};

const PaiementReducer = createSlice({
    name: 'paiement',
    initialState: initiaState,
    reducers: {
        setPayementMethodes: (state: IPaiementReducer, action) => {
            state.paymentMethodes = action.payload.paymentMethodes
        },
        setCardDetails: (state: IPaiementReducer, action) => {
            state.cardDetails = action.payload.cardDetails
        },
        setDelievryMode: (state: IPaiementReducer, action) => {
            state.delievryMode = action.payload.delievryMode
        },
        setDeliveryAddress: (state: IPaiementReducer, action) => {
            state.deliveryAddress = action.payload.deliveryAddress
        },
        setPreparePayment: (state: IPaiementReducer, action) => {
            state.paymentDetails = action.payload.paymentDetails
        },
        setDeliveryDate: (state: IPaiementReducer, action) => {
            state.deliveryDate = action.payload.deliveryDate
        },
        setDetailsCommande: (state: IPaiementReducer, action) => {
            state.detailsCommande = action.payload.detailsCommande
        },
        setMethodePiement: (state: IPaiementReducer, action) => {
            state.methodePaiment = action.payload.methodePaiment
        },
        setHistoryCommande: (state: IPaiementReducer, action) => {
            state.historyCommandes = action.payload.historyCommandes
        },
        setCardSaved: (state: IPaiementReducer) => {
            state.cardSaved = !state.cardSaved
        },
        setGeneralConditon: (state: IPaiementReducer) => {
            state.generalCondition = !state.generalCondition
        },
    }
});

export const { setPayementMethodes,
    setCardDetails,
    setDelievryMode,
    setDeliveryAddress,
    setPreparePayment,
    setDeliveryDate,
    setDetailsCommande,
    setMethodePiement,
    setHistoryCommande,
    setCardSaved,
    setGeneralConditon,
} = PaiementReducer.actions;

export default PaiementReducer.reducer;
