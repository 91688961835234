import { Api, CommonFunction, Config } from "../../Common"
import { ICommon, IConfirmBancontactPaiement, IConfirmPaiment, IGraphql, IOrderItem, IPaymentBancontact, IPaymentCreditAccount, IPreparePaiment } from "../../Interfaces"
import Stripe from 'stripe'
class PaiementApi {
    _api_stripe: Api
    _api: Api
    stripeKeyBETest: string
    config: any
    _api_graphql: Api
    constructor() {
        this._api_stripe = new Api(Config.getInstance().stripe_url)
        this._api = new Api(Config.getInstance().API_URL)
        this.stripeKeyBETest = Config.getInstance().stripeKeyBETest
        this._api_graphql = new Api(Config.getInstance().url_graphql)
    }


    async getStripeKey(body: any) {
        return await this._api_stripe.post(`6`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async getPaiementMethode(params: ICommon) {
        return await this._api.get(`bank/stripe/payment_methods?suid=${params?.suid}&lang=${params?.lang}`, CommonFunction.createHeaders({ withToken: true }))
    }
    async setPreparePaiement(params: ICommon, body: IPreparePaiment) {
        return await this._api.post(`bank/stripe/prepare?suid=${params?.suid}&lang=${params?.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }

    async confirmBancontactPayment(paimentIntentId: string, body: IConfirmBancontactPaiement | any) {
        const stripe = new Stripe(this.stripeKeyBETest, this.config)
        return await stripe.paymentIntents.confirm(paimentIntentId, body)
    }

    async confirmPayment(paimentIntentId: string, body: IConfirmPaiment | any) {
        const stripe = new Stripe(this.stripeKeyBETest, this.config)
        return await stripe.paymentIntents.confirm(paimentIntentId,
            body)
    }
    async PaymentWithCreditAccount(params: ICommon, body: IPaymentCreditAccount) {
        return await this._api.post(`bank/basket/payment/credit?suid=${params?.suid}&lang=${params?.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async onPayOnSite(params: ICommon, body: IPaymentCreditAccount) {
        return await this._api.post(`bank/basket/upon-receipt?suid=${params?.suid}&lang=${params?.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async setPrepareBancontactPaiement(params: ICommon, body: IPaymentBancontact) {
        return await this._api.post(`bank/stripe/prepare_bancontact?suid=${params?.suid}&lang=${params?.lang}`, JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
    async gql_query(param:any, body:IGraphql) {
        return await this._api_graphql.post('graphql',JSON.stringify(body), CommonFunction.createHeaders({ withToken: true }))
    }
}

export { PaiementApi }