import { ClassNames } from "@storybook/theming"
import { IAtomInputFile, IAtomLabel, IAtomNumber, IAtomRadio, TypeLabel } from "../../Interfaces"
import { IAtomAvatar, SizeAvatar } from "../../Interfaces"
import { IAtomButton, Size, Type } from "../../Interfaces/Atoms/IAtomButton/IAtomButton"
import { IAtomCheckbox, Type as CheckboxType } from "../../Interfaces/Atoms/IAtomCheckbox/IAtomCheckbox"
import { IAtomIcon, IImportAtomIcon } from "../../Interfaces/Atoms/IAtomIcon/IAtomIcon"
import { ESizeInput, ETypesInput, IInputProps } from "../../Interfaces/Atoms/IAtomInput/IAtomInput"
import { TypeInputFile } from "../../Interfaces/Atoms/IAtomInputFile/IAtomInputFile"
import { IAtomPagination } from "../../Interfaces/Atoms/IAtomPagination/IAtomPagination"
import { IAtomPlaceHolder } from "../../Interfaces/Atoms/IAtomPlaceHolder/IAtomPlaceHolder"
import { IAtomSpinner } from "../../Interfaces/Atoms/IAtomSpinner/IAtomSpinner"
import { ITextProps, TextType } from "../../Interfaces/Atoms/IAtomText/AtomText"
import { ITextAreaProps } from "../../Interfaces/Atoms/IAtomTextArea/IAtomTextArea"
import { IMoleculeButton } from "../../Interfaces/Molecule/IMoleculeButton/IMoleculeButton"
import { ESizeProgressBar, ETypeProgressBar, IAtomProgressbar } from "../../Interfaces/Atoms/IAtomProgressbar/IAtomProgressbar"
import { EDisplayType, EthousandsGroupStyle } from "../../Interfaces/Atoms/IAtomNumber/IAtomNumber"


const DEFAULTTEXTPROPS = {
    isLoading: false,
    isLabel: false,
    isSpan: false,
    text: "",
    id: undefined,
    bsPrefix: undefined,
    weight: undefined,
    onClick: () => { return },
    className: "",
}
const DEFAULTPLCAHOLDERPROPS: IAtomPlaceHolder = {
    style: {},
    className: "",
}


const DEFAULTBUTTONPROPS: IAtomButton = {
    style: {},
    className: "",
    onClick: () => { return },
    children: "",
    type: Type.primary,
    disabled: false,
    isRadius: false,
    size: Size.medium,
    bsPrefix: "",
    to: ""
}

const DEFAULTMOLECULEBUTTONPROPS: IMoleculeButton = {
    isLoading: false,
    isRightIcon: false,
    text: "",
}

const DEFAULTATOMICONPROPS: IAtomIcon = {
    icon: "Setting",
    className: "",
    style: {},
    size: "12",
    color: "",
    onClick: () => { return }
}
const DEFAULTIMPORTATOMICONPROPS: IImportAtomIcon = {
    icon: "",
    iconClassName: "",
    iconStyle: {},
    iconSize: "12",
    iconColor: ""
}

const DEFAULTSPINNERPROPS: IAtomSpinner = {
    width: "12",
    height: "12",
    className: "",
    color: "",
    type: Type.primary
}
const DEFAULTINPUTATOMPROPS: IInputProps = {
    className: '',
    id: '',
    value: '',
    type: 'text' as ETypesInput,
    inputSize: 'medium' as ESizeInput,
    placeholder: '',
    onChange: () => { return },
    disabled: false,
    isInvalid: false,
    isValid: false,
    bsPrefix: "",
}

const DEFAULTCHECKBOXPROPS: IAtomCheckbox = {
    className: '',
    labelClassName: "",
    label: "",
    type: CheckboxType.checkbox,
    onClick: () => { return },
    disabled: false,
    checked: true,
    bsPrefix: "",
    labelBsPrefix: "",
    isValid: false,
    isInvalid: false,
    containerClassName: ""
}
const DEFAULTAVATARPROPS: IAtomAvatar = {
    className: "",
    textClassName: "",
    containerClassName: "",
    statusClassName: "",


    style: [],
    alt: "",
    src: "",
    size: SizeAvatar.xxlarge,
    user: {
        firstname: "",
        lastname: "",
        email: ""
    },

    isImage: false,
    status: false,
    isActive: false,

    bsPrefix: "",
    textBsPrefix: "",
    statusBsPrefix: "",
    containerBsPrefix: "",

}
const DEFAULTRADIOPROPS: IAtomRadio = {
    className: '',
    labelClassName: "",
    label: "",
    onClick: () => { return },
    disabled: false,
    bsPrefix: "",
    labelBsPrefix: "",
    isValid: false,
    isInvalid: false,
    containerClassName: "",
    name: "",
    data: [],
    value: ""
}

const DEFAULTLABELPROPS: IAtomLabel = {
    className: "",
    typeLabel: TypeLabel.Progress,
    bsPrefix: "",
    primary: true
}

const DEFAULTPAGINATIONPROPS: IAtomPagination = {
    page: 1,
    numberOfPage: 5,
    onClick: () => { },
    containerClassName: '',
    iconNavigation: true,
    maxElementInPagination: 5
}

const DEFAULTTEXTAREAPROPS: ITextAreaProps = {
    className: "",
    bsPrefix: "",
    disabled: false,
    isInvalid: false,
    isValid: false,
    rows: 10,
    id: '',
    value: '',
    placeholder: '',
    onChange: () => { return },
}

const DEFAULTINPUTFILEPROPS: IAtomInputFile = {
    className: '',
    containerClassName: "",
    bsPrefix: "",
    isValid: false,
    isInvalid: false,
    isDisabled: false,
    onChange: () => { return },
    onClickFile: () => { return },
    files: [],
    isMulti: false,
    id: "",
    text: "Click to Upload",
    accept: []
}

const DEFAULTATOMPROGRESSBAR: IAtomProgressbar = {
    className: "",
    width: undefined,
    height: undefined,
    size: ESizeProgressBar.medium,
    type: ETypeProgressBar.warning,
    percent: 50,
    withDot: false
}

const DEFAULTNUMBERPROPS: IAtomNumber = {
    thousandsGroupStyle: EthousandsGroupStyle.thousand,
    decimalSeparator: '.',
    thousandSeparator: ' ',
    prefix: '',
    suffix: '',
    displayType: EDisplayType.input
}

export {
    DEFAULTTEXTPROPS,
    DEFAULTPLCAHOLDERPROPS,
    DEFAULTBUTTONPROPS,
    DEFAULTMOLECULEBUTTONPROPS,
    DEFAULTATOMICONPROPS,
    DEFAULTIMPORTATOMICONPROPS,
    DEFAULTSPINNERPROPS,
    DEFAULTINPUTATOMPROPS,
    DEFAULTCHECKBOXPROPS,
    DEFAULTAVATARPROPS,
    DEFAULTRADIOPROPS,
    DEFAULTLABELPROPS,
    DEFAULTPAGINATIONPROPS,
    DEFAULTTEXTAREAPROPS,
    DEFAULTINPUTFILEPROPS,
    DEFAULTATOMPROGRESSBAR,
    DEFAULTNUMBERPROPS
}