import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PrivateRoute from "./PrivateRoute/PrivateRoute"
import PublicRoute from "./PublicRoute/PublicRoute"
import LangProvider from "../Providers/LangProvider/LangProvider"
import { useUpdateEffect, useWindowSize } from "../CustomHooks"
import { RootState } from "../Interfaces/Redux"
import { setScreen } from "../Redux/Reducers"
import { PageCommandeEnCour, PageDevenirCommercant, PageMenuCommercant, PageMesCommercants, PageMesRecharges, PagePublicCommercants } from "../Pages"
import ModalProvider from "../Providers/ModalProvider/ModalProvider"
import PageCompte from "../Pages/PageCompte/PageCompte"
import CustomRoute from "./CustomRoute/CustomRoute"
import { ModalContactUs } from "../Modal"
import AlerteProvider from "../Providers/AlerteProvider/AlerteProvider"
import PageRedirectAfterLogin from "../Pages/PageRedirectAfterLogin/PageRedirectAfterLogin"

const AppRoute = (): JSX.Element => {
  const auth = useSelector((state: RootState) => state?.auth)
  const dispatch = useDispatch()
  const { width, height } = useWindowSize()

  useUpdateEffect(() => {
    dispatch(setScreen({ width: width, height: height }))
  }, [width])

  function PrivateRouteRender(props: any) {
    return auth?.token === "" ? (
      <Navigate to="/" />
    ) : (
      props.children
    )
  }
  function PublicRouteRender(props: any) {
    return props.children
  }
  return (
    <div style={{ height }}>
      <LangProvider>
        <BrowserRouter>
          <ModalProvider />
          <AlerteProvider />
          <Routes>

            <Route
              element={
                <PublicRouteRender>
                  <PublicRoute />
                </PublicRouteRender>
              }
            >
              <Route path="/" element={<PagePublicCommercants />} />
              <Route path="/devenir-commercant" element={<PageDevenirCommercant />} />
            </Route>
            <Route
              element={
                <PrivateRouteRender >
                  <PrivateRoute />
                </PrivateRouteRender>
              }
            >
              <Route path="/compte" element={<PageCompte />} />
              <Route path="/mes-commercants" element={<PageMesCommercants />} />
              <Route path="/mes-commandes" element={<PageCommandeEnCour />} />
              <Route path="/mes-recharges" element={<PageMesRecharges />} />
              <Route path="/contact-us" element={<ModalContactUs />} />
            </Route>
            <Route
              element={
                <PublicRouteRender >
                  <CustomRoute />
                </PublicRouteRender>
              }
            >
              <Route path="/menuCommercant/:suid" element={<PageMenuCommercant />} />
              <Route path="/load-user" element={<PageRedirectAfterLogin />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </LangProvider>
    </div>
  )
}

export default AppRoute
