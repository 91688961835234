import { Button, TextArea } from "../../DesignSystem";
import infoCircle from '../../Assets/images/info-circle.svg';
import { CommonFunction } from "../../Common";
import { ITemplateAddNoteModal } from "../../Interfaces";
import { useContext } from "react";
import { LangContext } from "../../Lang";

const TemplateAddNoteModal=(props:ITemplateAddNoteModal):JSX.Element=>{
    const langue=useContext(LangContext);
    const renderAddNote = () => {
        const list: JSX.Element[] = []
        list.push(
            <div className="ds-flex-col ds-center ds-w-100 ds-p-24">
                <span className="ds-text-size-28 ds-text-line-33 ds-text-weight700 ds-text-primary100 ds-mt-8 ds-mb-16">
                    {langue.add_comments}
                </span>
                <span className="ds-text-size-16 ds-text-line-19 ds-text-weight400 ds-text-primary100 ds-flex ds-align-center">{langue.complaint_comments}</span>
                <div className="ds-flex ds-align-center ds-mt-8">
                    <img src={infoCircle} height={13} width={13} className="ds-mb-20 ds-mr-4" />
                    <span className="ds-text-size-14 ds-text-line-16 ds-text-weight400 ds-text-secondary100 ds-flex ds-align-center">{langue.committed_serving}</span>
                </div>
                <TextArea
                    placeholder={langue.insert_comments}
                    containerClassName="ds-mt-24 ds-mb-30 text-area-class"
                    className=" ds-hp-110 text-area-class"
                    {...CommonFunction.getInputProps(props?.form?.remarque)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        props?.onChange({
                            key: "remarque",
                            value: e.target.value
                        })
                    }
                />
                <Button
                    text={langue.add}
                    className="ds-w-100"
                    onClick={() => props?.onAddNote()}
                />

            </div>
        )
        return (<>{list}</>)
    }
return (renderAddNote())
}
export default TemplateAddNoteModal