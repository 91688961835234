import React from 'react';
import IcoMoon from 'react-icomoon';
import { DEFAULTATOMICONPROPS } from '../../DefaultProps/Atoms';
import { IAtomIcon } from '../../Interfaces/Atoms/IAtomIcon/IAtomIcon';
import iconSet from "../../../Assets/icommoon/selection.json"
const AtomIcon = (props: IAtomIcon) => {
    return <div onClick={props.onClick} id={props.id}>
        <IcoMoon
            className={props.className}
            icon={props.icon}
            iconSet={iconSet}
            size={props.size}
            style={props.style}
            color={props.color}
        />
    </div>
};

AtomIcon.defaultProps = {
    ...DEFAULTATOMICONPROPS
}
export default AtomIcon;