import React, { useRef } from "react"
import { ESizeProgressBar, ETypeProgressBar, IAtomProgressbar } from "../../Interfaces/Atoms/IAtomProgressbar/IAtomProgressbar"
import { DEFAULTATOMPROGRESSBAR } from "../../DefaultProps/Atoms"
function AtomProgressbar(props: IAtomProgressbar): JSX.Element {
    const className = ["ds-relative", "ds-bg-neutral200", "ds-border-radius-10"]
    const progressClassName = ["ds-absolute", "ds-border-radius-10"]
    const style = { width: "100%", height: "16px" }
    const ref = useRef<HTMLElement | null | undefined>() as React.MutableRefObject<HTMLInputElement>;

    let percent = props.percent
    if (props.size === ESizeProgressBar.small) {
        style.height = "12px"
    }
    if (props.size === ESizeProgressBar.larg) {
        style.height = "20px"
    }
    if (props.height) {
        style.height = props.height + "px"
    }
    if (props.width) {
        style.width = props.width + "px"
    }
    if (props.className) {
        className.push(props.className)
    }
    if (props.type === ETypeProgressBar.warning) {
        progressClassName.push('ds-bg-warning500')
    } else if (props.type === ETypeProgressBar.error) {
        progressClassName.push('ds-bg-error500')
    } else {
        progressClassName.push('ds-bg-success500')
    }
    if (percent < 10 && percent > 0) {
        percent = 10
    }
    if (percent) {
        progressClassName.push('ds-w-' + percent)
    }
    const getLeft = () => {
        const offsetWidth = ref?.current?.offsetWidth || 100;
        const one = offsetWidth / 100
        return (percent * one) - (Number(style.height.replace('px', '')) + 4) + "px"
    }
    return <div className={className.join(' ')} style={style} ref={ref}>
        {percent >= 0 && percent <= 100 && <div className={progressClassName.join(' ')} style={{ height: style.height, top: "0px", left: "0px" }} />}
        {props.withDot && <div className="ds-bg-white ds-absolute ds-border-radius-10" style={{
            height: (Number(style.height.replace('px', '')) + 4) + "px",
            width: (Number(style.height.replace('px', '')) + 4) + "px",
            left: getLeft(),
            top: "-2px"
        }} />}
    </div>
}
AtomProgressbar.defaultProps = {
    ...DEFAULTATOMPROGRESSBAR
}
export default AtomProgressbar