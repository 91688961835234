
class Config {
    SOCKET_URL = process.env.REACT_APP_SOCKET_URL || ""
    API_URL = process.env.REACT_APP_API_URL
    url = process.env.REACT_APP_URL
    version = process.env.REACT_APP_VERSION
    cdn = process.env.REACT_APP_CDN
    socket = process.env.REACT_APP_SOCKET
    url_graphql = process.env.REACT_APP_URL_GRAPHQL
    url_v3_rest = process.env.REACT_APP_URL_V3_REST
    stripe_url = process.env.REACT_STRIPE_URL
    urls = [
        'staging.app.digicommerce.be',
        'digicommerce.be',
        'localhost'
    ]
    stripeKey = 'pk_live_xxx';
    stripeKeyBE = 'pk_live_Rkvl8vhV48Ww852TbsUdT9oS00aiMphxwK';
    stripeKeyTest = 'pk_test_xxx';
    stripeKeyBETest = 'pk_test_uQluZgyACjb4Ji810nQXl5CD00UvPPnhO3';
    GOOGLE_MAP_API = "AIzaSyC_UVeXk4Dy29hLF-EJ9mH7OLMeWBU8Qro";
    suid = 'b0Rl4B0vWH4aZWjl';
    comsumerodr_url = "https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
    homePath = "/"
    guid= '832987eb-ee26-4ec3-afc5-2953056f2c94edc78b'
    muid= '6c4357d7-7bd1-433e-a988-dedebbba284fc968b3'
    sid= '375df5f5-4e54-4d0e-8bfb-bffb14366cca867d24'
    payment_user_agent= 'stripe.js/8c5d263850; stripe-js-v3/8c5d263850; raw-stripe-js-api'
    time_on_page= 362243
    host_url="stage.app.digicommerce.be"
    download_url="https://stage.api.digicommerce.be/v1/public/notice/download/"
    map_uri="https://www.google.com/maps/search/?api=1&query="
    private static instance: Config;
    constructor() { }
    public static getInstance(): Config {
        if (!Config.instance) {
            Config.instance = new Config();
        }
        return Config.instance;
    }
}
export { Config }
