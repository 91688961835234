import { useRef } from "react"
import { ModalAddNote, ModalBonPlan, ModalComposeFormuleOrSandwich, ModalContactUs, ModalCouponReduction, ModalDeliveryData, ModalDetailsCommandeOrRecharge, ModalDetailsProduct, ModalGeneralCondition, ModalMentionLegales, ModalMessageSent, ModalPaiment, ModalSiteDetails } from "../../Modal"
import { ModalRefType } from "../../DesignSystem"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../Interfaces";
import { useEffectOnce, useUpdateEffect } from "../../CustomHooks";
import { closeModal } from "../../Redux/Reducers/ModalReducer/ModalReducer";
import ModalConnexion from "../../Modal/ModalConnexion/ModalConnexion";
import ModalInscriptionEmail from "../../Modal/ModalInscription/ModalInscriptionEmail";
import ModalInscriptionConfirmation from "../../Modal/ModalInscription/ModalInscriptionConfirmation";
import ModalInscriptionFinalisation from "../../Modal/ModalInscription/ModalInscriptionFinalisation";
import ModalResetPassword from "../../Modal/ModalResetPassword/ModalResetPassword";
import ModalDeleteCompte from "../../Modal/ModalDeleteCompte/ModalDeleteCompte";
import ModalDeliveryOrder from "../../Modal/ModalDeliveryOrder/ModalDeliveryOrder";

function ModalProvider() {
    const mount = useRef(false)
    const { name, data } = useSelector((state: RootState) => state?.modal);
    const modalName = useRef("")
    const modalConnexionRef = useRef<ModalRefType>()
    const modalInscriptionRef = useRef<ModalRefType>()
    const modalInscriptionConfirmationRef = useRef<ModalRefType>()
    const modalInscriptionFinalisationRef = useRef<ModalRefType>()
    const modalDelivery = useRef<ModalRefType>()
    const modalAddNote = useRef<ModalRefType>()
    const modalCouponReduction = useRef<ModalRefType>()
    const modalPaiment = useRef<ModalRefType>()
    const modalContactUs = useRef<ModalRefType>()
    const modalSiteDetails = useRef<ModalRefType>()
    const modalProductDetails = useRef<ModalRefType>()
    const modalComposeFormule = useRef<ModalRefType>()
    const modalMessageSent = useRef<ModalRefType>()
    const modalBonPlan = useRef<ModalRefType>()
    const modalResetPassword = useRef<ModalRefType>()
    const generalConditionModal = useRef<ModalRefType>()
    const mentionsLegales = useRef<ModalRefType>()
    const detailsCommande = useRef<ModalRefType>()
    const modalDeleteCompteRef = useRef<ModalRefType>()
    const modalDeliveryOrder = useRef<ModalRefType>()

    const dispatch = useDispatch()
    const modalRefs: any = {
        modalDelivery: modalDelivery,
        modalAddNote: modalAddNote,
        modalCouponReduction: modalCouponReduction,
        modalPaiment: modalPaiment,
        modalConnexionRef: modalConnexionRef,
        modalInscriptionRef: modalInscriptionRef,
        modalInscriptionConfirmationRef: modalInscriptionConfirmationRef,
        modalInscriptionFinalisationRef: modalInscriptionFinalisationRef,
        modalContactUs: modalContactUs,
        modalSiteDetails: modalSiteDetails,
        modalProductDetails: modalProductDetails,
        modalComposeFormule: modalComposeFormule,
        modalMessageSent: modalMessageSent,
        modalBonPlan: modalBonPlan,
        modalResetPasswordRef: modalResetPassword,
        generalConditionModal: generalConditionModal,
        mentionsLegales: mentionsLegales,
        detailsCommande: detailsCommande,
        modalDeleteCompteRef: modalDeleteCompteRef,
        modalDeliveryOrder: modalDeliveryOrder
    };

    const onExit = (key: string) => {
        modalRefs[key] &&
            modalRefs[key].current?.onClose();

        dispatch(closeModal());
        modalName.current = "";
    };

    const onShow = (key: string) => {
        modalName.current = key;
        modalRefs[key] &&
            modalRefs[key].current?.onOpen();
    };

    useUpdateEffect(() => {
        if (mount.current) {
            if (name) {
                if (modalName.current === "") {
                    onShow(name)

                }
            } else {
                onExit(name)
            }
        }
    }, [name])
    useEffectOnce(() => {
        setTimeout(() => {
            mount.current = true
        }, 500);
        dispatch(closeModal())
    })
    return <>
        <ModalDeliveryData
            ref={modalDelivery}
            data={data}
            onExit={() => onExit("modalDelivery")}
            onShow={() => onShow("modalDelivery")}
            isOpen={"modalDelivery" === name}

        />
        <ModalAddNote
            ref={modalAddNote}
            data={data}
            onExit={() => onExit("modalAddNote")}
            onShow={() => onShow("modalAddNote")}
        />
        <ModalCouponReduction
            ref={modalCouponReduction}
            data={data}
            onExit={() => onExit("modalCouponReduction")}
            onShow={() => onShow("modalCouponReduction")}
        />
        <ModalPaiment
            ref={modalPaiment}
            data={data}
            onExit={() => onExit("modalPaiment")}
            onShow={() => onShow("modalPaiment")}
            isOpen={"modalPaiment" === name}

        />
        <ModalConnexion
            ref={modalConnexionRef}
            data={data}
            onExit={() => onExit("modalConnexionRef")}
            onShow={() => onShow("modalConnexionRef")}
            isOpen={"modalConnexionRef" === name}
        />
        <ModalInscriptionEmail
            ref={modalInscriptionRef}
            data={data}
            onExit={() => onExit("modalInscriptionRef")}
            onShow={() => onShow("modalInscriptionRef")}
            isOpen={"modalInscriptionRef" === name}

        />
        <ModalInscriptionConfirmation
            ref={modalInscriptionConfirmationRef}
            data={data}
            onExit={() => onExit("modalInscriptionConfirmationRef")}
            onShow={() => onShow("modalInscriptionConfirmationRef")}
        />
        <ModalInscriptionFinalisation
            ref={modalInscriptionFinalisationRef}
            data={data}
            onExit={() => onExit("modalInscriptionFinalisationRef")}
            onShow={() => onShow("modalInscriptionFinalisationRef")}
        />

        <ModalContactUs
            ref={modalContactUs}
            data={data}
            onExit={() => onExit("modalContactUs")}
            onShow={() => onShow("modalContactUs")}
        />
        <ModalSiteDetails
            ref={modalSiteDetails}
            data={data}
            onExit={() => onExit("modalSiteDetails")}
            onShow={() => onShow("modalSiteDetails")}
        />
        <ModalDetailsProduct
            ref={modalProductDetails}
            data={data}
            onExit={() => onExit("modalProductDetails")}
            onShow={() => onShow("modalProductDetails")}
        />
        <ModalComposeFormuleOrSandwich
            ref={modalComposeFormule}
            data={data}
            onExit={() => onExit("modalComposeFormule")}
            onShow={() => onShow("modalComposeFormule")}
        />
        <ModalMessageSent
            ref={modalMessageSent}
            data={data}
            onExit={() => onExit("modalMessageSent")}
            onShow={() => onShow("modalMessageSent")}
        />
        <ModalBonPlan
            ref={modalBonPlan}
            data={data}
            onExit={() => onExit("modalBonPlan")}
            onShow={() => onShow("modalBonPlan")}
        />
        <ModalResetPassword
            ref={modalResetPassword}
            data={data}
            onExit={() => onExit("modalResetPasswordRef")}
            onShow={() => onShow("modalResetPasswordRef")}
        />
        <ModalGeneralCondition
            ref={generalConditionModal}
            data={data}
            onExit={() => onExit("generalConditionModal")}
            onShow={() => onShow("generalConditionModal")}
        />
        <ModalMentionLegales
            ref={mentionsLegales}
            data={data}
            onExit={() => onExit("mentionsLegales")}
            onShow={() => onShow("mentionsLegales")}
        />
        <ModalDetailsCommandeOrRecharge
            ref={detailsCommande}
            data={data}
            onExit={() => onExit("detailsCommande")}
            onShow={() => onShow("detailsCommande")}
        />
        <ModalDeleteCompte
            ref={modalDeleteCompteRef}
            data={data}
            onExit={() => onExit("modalDeleteCompteRef")}
            onShow={() => onShow("modalDeleteCompteRef")}
        />
        <ModalDeliveryOrder
            ref={modalDeliveryOrder}
            data={data}
            onExit={() => onExit("modalDeliveryOrder")}
            onShow={() => onShow("modalDeliveryOrder")}
        />
    </>
}
export default ModalProvider