import React, { useState } from 'react'
import { Modal, ModalRefType, Tab } from "../../DesignSystem";
import { IModalSiteDetails } from "../../Interfaces/Modal";
import { Ref, forwardRef } from "react";
import {  useSelector } from "react-redux";
import { RootState } from '../../Interfaces';
import { TemplateSiteDetailsModal } from '../../Template';
import { Config } from '../../Common';

const ModalSiteDetails = (props: IModalSiteDetails, ref: Ref<ModalRefType | undefined>) => {
    const { commercant } = useSelector((state: RootState) => state.commercant);
    const { data } = useSelector((state: RootState) => state.modal);
    const className = ["ds-borad-14 site-modal  ds-flex ds-pointer ds-overflow-x"]
    const [isShowHoraire, setIsShowHoraire] = useState<boolean>(false)
    const [selctedTab, setSelctedTab] = useState<number>(0)
    const onCloseModal = () => {
        if(props.onExit){
            props.onExit()
            setIsShowHoraire(false)
        }
    }
    const OnClickAppeler = () => {
        window.location.href = `tel:${commercant?.phone}`;
    }
    const onRedirectToGoogleMaps = () => {
        if(props?.onExit){
            props?.onExit()
        }
        const url = `${Config.getInstance().map_uri+encodeURIComponent(commercant?.address)}`;
        window.open(url, "_blank");
    };
    React.useEffect(()=>{
        if(data?.siteInfo){
            if(isShowHoraire){
                setIsShowHoraire(!isShowHoraire)
            }
        }
    },[data])
    return (
        <Modal
            contentClassName={className.join(' ')}
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
        >
            <TemplateSiteDetailsModal
            OnClickAppeler={OnClickAppeler}
            setIsShowHoraire={setIsShowHoraire}
            isShowHoraire={isShowHoraire}
            commercant={commercant}
            onCloseModal={onCloseModal}
            onRedirectToGoogleMaps={onRedirectToGoogleMaps}
            setSelctedTab={setSelctedTab}
            selctedTab={selctedTab}
            />
        </Modal>
    )
}
export default forwardRef(ModalSiteDetails)