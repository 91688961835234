import React, { useState, useRef } from 'react';
import { Icon } from '../../Atoms';

interface HorizontalMenuProps {
    items: any[];
    selectedIndex?: number;
    onGetCategoriesProducts: Function;
}

const MoleculeHorizontalMenu: React.FC<HorizontalMenuProps> = ({ items, selectedIndex, onGetCategoriesProducts }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const dragStartX = useRef(0);
    const menuRef = useRef<HTMLDivElement>(null);

    const handleScroll = (scrollDirection: 'left' | 'right') => {
        const scrollAmount = 200;
        const container = menuRef.current;

        if (container) {
            if (scrollDirection === 'left') {
                container.scrollLeft -= scrollAmount;
            } else if (scrollDirection === 'right') {
                container.scrollLeft += scrollAmount;
            }

            setScrollPosition(container.scrollLeft);
        }
    };
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsDragging(true);
        dragStartX.current = e.clientX;
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (isDragging && menuRef.current) {
            const delta = dragStartX.current - e.clientX;
            menuRef.current.scrollLeft += delta;
            dragStartX.current = e.clientX;
            setScrollPosition(menuRef.current.scrollLeft);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <div
            className="ds-flex ds-w-100 ds-align-center user-select-none"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            {scrollPosition > 0 && (
                <div className="next-category ds-ml-16 ds-mr-16 ds-bg-white ds-pointer ds-flex ds-center box-shadow-50 ds-wp-40 ds-hp-40 ds-radius-50 ds-min-w-40">
                    <Icon
                        icon="arrow-left-1"
                        size={"24px"}
                        onClick={() => { handleScroll('left') }}
                    />
                </div>
            )}
            <div className="ds-flex no-scrollbar overflow-auto" ref={menuRef}>
                {items.map((item, index) => (
                    <div key={index} className="ds-flex-col ds-align-center ds-pr-12 tab-slide">
                        <span
                            className={`${selectedIndex === index && 'ds-fw-700 ds-color-black'} ds-pointer white-space-nowrap ds-text-line-19 ds-text-size-16 ds-text-primary60`}
                            onClick={() => onGetCategoriesProducts(index)}>
                            {item?.label}
                        </span>
                        {selectedIndex === index && <div className='border-Bottom'></div>}
                    </div>
                ))}
            </div>

            {menuRef.current && scrollPosition < menuRef.current?.scrollWidth - menuRef.current?.clientWidth - 12 && (
                < div className="next-category ds-ml-16 ds-mr-16 ds-bg-white ds-pointer ds-flex ds-center box-shadow-50 ds-wp-40 ds-hp-40 ds-radius-50 ds-min-w-40">
                    <Icon
                        icon="arrow-right-11"
                        size={"24px"}
                        onClick={() => { handleScroll('right') }}
                    />
                </div>
            )
            }
        </div >
    );
};

export default MoleculeHorizontalMenu