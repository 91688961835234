import React, { Ref, forwardRef } from "react";
import { Modal, ModalRefType } from "../../DesignSystem";
import { IModalContactUs } from "../../Interfaces/Modal";
import { useDispatch, useSelector } from "react-redux";
import { EListFunction, IUseFormResult, useForm, useUpdateEffect } from "../../CustomHooks";
import { ShopAPI } from "../../Api/Shop/shop";
import { RootState } from "../../Interfaces";
import { openModal } from "../../Redux/Reducers";
import { TemplateContactUsModal } from "../../Template";


const ModalContactUs = (props: IModalContactUs, ref: Ref<ModalRefType | undefined>) => {
    const dispatch = useDispatch()
    const shopApi = new ShopAPI()
    const { commercant } = useSelector((state: RootState) => state?.commercant)
    const { user } = useSelector((state: RootState) => state?.auth)
    const { data } = useSelector((state: RootState) => state?.modal)
    const className = ['ds-borad-14 ds-flex contact-us-modal overflow-hidden ds-pointer']

    const {
        state: form,
        onChange,
        onUpdateState,
        onUpdateData,
    }: IUseFormResult | any = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "email",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "lastname",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "firstname",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "message",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    },
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "file",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
        ]
    })
    const onCloseModal = () => {
        if (props.onExit) {
            props.onExit()
            onUpdateForm()
            if (form.message.value !== '') {
                onUpdateData({
                    ...form,
                    message: {
                        ...form.message,
                        value: ""
                    }
                })
            }
        }
    }
    const onUpdateForm = () => {
        onUpdateState({
            ...form,
            email: {
                ...form.email,
                value: user.username,
                isValid: true,
                isInvalid: false,
            },
            lastname: {
                ...form.lastname,
                value: user.lastname,
                isValid: true,
                isInvalid: false,
            },
            firstname: {
                ...form.firstname,
                value: user.firstname,
                isValid: true,
                isInvalid: false,
            },
            message: {
                ...form.message,
                value: "",
                isValid: false,
                isInvalid: false,
            },
            file: {
                ...form.file,
                value: "",
                isValid: false,
                isInvalid: false,
            },
        })
    }
    useUpdateEffect(() => {
        if (user !== null) {
            onUpdateForm()
        }
    }, [user])
    React.useEffect(() => {
        if (data?.contactUs) {
            onUpdateState({
                ...form,
                message: {
                    ...form.message,
                    value: "",
                    isValid: false,
                    isInvalid: false,
                    
                }
            })
        }
    }, [data])

    const onContactUs = async () => {
        if (form?.firstname?.value !== '' && form?.lastname?.value !== '' && form?.email?.value !== '' && form?.message?.value !== '') {
            const formData = new FormData()
            formData.append("file", form.file.value);
            const response = await shopApi.onContactUs(
                {
                    suid: commercant?.suid,
                    contact:
                    {
                        email: form?.email?.value,
                        firstname: form?.firstname?.value,
                        lastname: form?.lastname?.value,
                        message: form?.message?.value,
                    }
                },
                { formData }
            )
            if (response.status === 200) {
                onCloseModal()
                dispatch(openModal({ name: 'modalMessageSent' }))
            }
        } else {
            if (form.message.value === '') {
                onUpdateState({
                    ...form,
                    message: {
                        ...form.message,
                        isValid: false,
                        isInvalid: true,
                    }
                })
            } else if (form.file.value === '') {
                onUpdateState({
                    ...form,
                    file: {
                        ...form.file,
                        isValid: false,
                        isInvalid: true,
                    },
                })
            }
        }

    }


    return (
        <Modal
            contentClassName={className.join(' ')}
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
        >
            <TemplateContactUsModal
                form={form}
                onChange={onChange}
                onContactUs={onContactUs}
                onCloseModal={onCloseModal}
            />
        </Modal>
    )
}
export default forwardRef(ModalContactUs)