import { Ref, forwardRef, useContext, useEffect, useState } from "react"
import { IModalDeleteCompte } from "../../Interfaces/Modal"
import { Modal, ModalRefType } from "../../DesignSystem"
import TemplateModalDeleteCompte from "../../Template/TemplateModalDeleteCompte/TemplateModalDeleteCompte"
import { LangContext } from "../../Lang"
import { CommercantAPI } from "../../Api/Commercants/Commercants"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../Interfaces"
import { UserAPI } from "../../Api/User/user"
import { resetToken } from "../../Redux/Reducers"

const ModalDeleteCompte = (props: IModalDeleteCompte, ref: Ref<ModalRefType | undefined>) => {
    const langue = useContext(LangContext)
    const { lang } = useSelector((state: RootState) => state.setting)
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [myCredit, setMyCredit] = useState<number>(0)
    const commercantAPi = new CommercantAPI()
    const userAPi = new UserAPI()
    const { commercant } = useSelector((state: RootState) => state.commercant)
    const { user } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()

    const onClose = () => {
        if (props.onExit) {
            props.onExit()
        }
    }
    const deleteUser = async () => {
        const response = await userAPi.deleteUser({

            suid: commercant?.suid,
            lang: lang
        }, { username: user?.username })
        if (response.status === 200) {
            onClose();
            dispatch(resetToken());
            window.location.href = '/'
        }
    }
    const getUserSites = async () => {
        const response = await commercantAPi.getSites({
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            sumOfFirstValues(response?.data);
            setMyCredit(sumOfFirstValues(response?.data))
        }
    }

    const sumOfFirstValues = (credits: any) => {
        return credits?.reduce((a: number, b: any) => {

            return a + Number(b?.credit)

        }, 0);
    }
    useEffect(() => {
        getUserSites()
    }, [])

    return <Modal
        contentClassName="modal-reset-password ds-p-16 overflow-hidden"
        withCloseIcon
        ref={ref}
        onExit={props.onExit}
        onShow={props.onShow}
    >
        <TemplateModalDeleteCompte
            langue={langue}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            myCredit={myCredit}
            onClose={onClose}
            deleteUser={deleteUser}

        />
    </Modal >
}
export default forwardRef(ModalDeleteCompte)