import React from 'react';
import { DEFAULTSPINNERPROPS } from '../../DefaultProps/Atoms';
import { IAtomSpinner } from '../../Interfaces/Atoms/IAtomSpinner/IAtomSpinner';

function AtomSpinner(props: IAtomSpinner): JSX.Element {
    const className = ["ds-loader"];
    const { width, height } = props
    if (typeof width === 'string') {
        width.replace('px', "")
    }
    if (typeof height === 'string') {
        height.replace('px', "")
    }
    if (props.type) className.push(props.type)
    if (props.height) className.push("ds-hp-" + height);
    if (props.width) className.push("ds-wp-" + width);
    if (props.className) className.push(props.className)
    return <span className={className.join(' ')}></span>
}
AtomSpinner.defaultProps = {
    ...DEFAULTSPINNERPROPS
}
export default AtomSpinner
