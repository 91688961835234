import { useState, useEffect, useRef } from "react"
export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const ref = useRef<any>()
    const handleResize = () => {
        if (ref.current) {

            if (ref.current.width !== window.innerWidth && ref.current.height !== window.innerHeight) {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
                ref.current = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                }
            }
        }
    };

    useEffect(() => {
        ref.current = windowSize
        window.addEventListener("resize", () => handleResize());
        return () => {
            window.removeEventListener("resize", () => handleResize());
        };
    }, []);
    return windowSize
}
