import Recat, { useState } from 'react'
import { TemplateMesCommercants } from "../../Template"
import { CommercantAPI } from '../../Api/Commercants/Commercants'
import { useDispatch, useSelector } from 'react-redux'
import { IFilterSites, ISitesType, RootState } from '../../Interfaces'
import { setCategoryCommercant, setCommercant, setGoodDeals, setProductCommercant, setSites } from '../../Redux/Reducers'
import { Data } from '../../Common'
import { useNavigate } from 'react-router-dom'


const PageMesCommercants = () => {
    const { commercant } = useSelector((state: RootState) => state.commercant)
    const { lang } = useSelector((state: RootState) => state.setting)
    const { delievryMode, deliveryDate } = useSelector((state: RootState) => state.paiement);
    const navigate = useNavigate()
    const [mySites, setMySites] = useState<ISitesType>()
    const [filtredSites, setFiltredSites] = useState<ISitesType>(mySites)
    const commercantAPi = new CommercantAPI()
    const dispatch = useDispatch()
    const getUserSites = async () => {
        const response = await commercantAPi.getSites({
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            setMySites(response?.data)
            dispatch(setSites({ sites: response?.data }))
        }
    }
    Recat.useEffect(() => {
        getUserSites()
        setFiltredSites(mySites)
    }, [])
    Recat.useEffect(() => {
        if (mySites) {
            setFiltredSites(mySites)
        }
    }, [mySites])
    const onFilterSiteCategory = (tabSelected: number) => {
        Object?.values(Data?.getTabList())?.map((element, i: number) => {
            if (tabSelected === i) {
                if (tabSelected !== 0) {
                    const filteredData = Object.values(mySites)?.filter((el: ISitesType) => {
                        const sites = el.client.sites;
                        return sites.some((site: IFilterSites) => site.vocation.name === element.label);
                    });
                    setFiltredSites(filteredData)
                } else {
                    setFiltredSites(mySites)
                }
            }
        })
    }
    const getCatalogueCommercant = async (suid: string) => {
        const response = await commercantAPi.getCommercantCatalogue({
            suid: suid,
            lang: lang,
            collected_at: deliveryDate,
            delivery_method: delievryMode ? 1 : 3
        })
        if (response.status === 200) {
            dispatch(setGoodDeals({ goodDeals: response.data }))
            const aux: any = []
            Object.values(response?.data?.categories).map((el: any) => {
                aux.push(el?.products)
            })
            dispatch(setProductCommercant({ productCommercant: aux }))
            let arr: string[] = []
            if (response?.data?.categories) {
                Object.values(response?.data?.categories).map((el: any) => {
                    arr.push(el?.name)
                })
                const formattedData: { index: number, label: string }[] = arr.map((label: string, index: number) => ({ index, label }));
                dispatch(setCategoryCommercant({ categoryCommercant: formattedData }))
            }
        }
    }
    const onClickCardBtn = async (id: string) => {
        if (id) {
            const response = await commercantAPi.getCommercant({
                suid: id,
                lang: lang
            })
            if (response.status === 200) {
                dispatch(setCommercant({ commercant: response.data }))
                dispatch(setCategoryCommercant({ categoryCommercant: [] }))
                dispatch(setProductCommercant({ productCommercant: [] }))
                navigate(`/menuCommercant/${id}`)
                getCatalogueCommercant(id)
            }
        }
    }
    return (
        <TemplateMesCommercants
            mySites={mySites}
            onFilterSiteCategory={onFilterSiteCategory}
            filtredSites={filtredSites}
            onClickCardBtn={onClickCardBtn}
        />
    )
}
export default PageMesCommercants