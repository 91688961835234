import React, { useContext, useState } from 'react'
import { CommonFunction, Data } from "../../Common"
import { Button, Icon, Spinner, Tab } from "../../DesignSystem"
import { ICommande, ISitesType, ITemplateCommandeEnCour, RootState } from "../../Interfaces"
import { CardType, Type } from '../../DesignSystem/Interfaces'
import { Card } from '../../DesignSystem/Organisms'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../../Redux/Reducers'
import { LangContext } from '../../Lang'
import InfiniteScroll from 'react-infinite-scroll-component'
const TemplateCommandeEnCour = (props: ITemplateCommandeEnCour): JSX.Element => {
    const { width } = useSelector((state: RootState) => state?.screen)
    const [showDetails, setshowDetails] = useState<boolean>(false)
    const [first, setfirst] = useState(0)
    const dispatch = useDispatch()
    const langue = useContext(LangContext)
    let className = ['ds-mb-70 cmd-class']
    if (props?.selectedTab === 2) {
        className.push('ds-flex ds-flex-wrap justify-content')
    }

    const verifyMinutes = (date: string) => {
        const now: any = new Date();
        const target: any = new Date(date);
        let differenceInMilliseconds = target - now;
        let timeRemaining: any;
        if (differenceInMilliseconds < 60 * 60 * 1000) {
            timeRemaining = `${Math.ceil((differenceInMilliseconds / (1000 * 60)))} ` + langue.minutes;
        } else {
            timeRemaining = langue.in + ` ${Math.floor((differenceInMilliseconds / (1000 * 60 * 60)))} ` + langue.hours;
        }
        if (now >= target) {
            timeRemaining = langue.Order_ready
        }
        return timeRemaining;
    }

    const renderListCommande = () => {
        switch (props?.selectedTab) {
            case 0:
                return renderCommande(props?.commandeDetails);
            case 1:
                return renderCommande(props.cmd);
            case 2:
                return renderCommande(props?.historyCommandesDetails);
            default:
                break;
        }
    }
    const renderCommandeItems = (el: ICommande, index: number) => {
        return (
            <div className="ds-flex-col ds-mt-24 ">
                <div className="ds-flex ds-w-100 ">
                    <div className='ds-wp-136 ds-hp-94' style={{
                        backgroundImage: `url(${el?.site_details?.background?.medium_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center'
                    }}>

                    </div>
                    <div className="ds-flex-col  ds-w-100 ds-ml-16" onClick={() => { width < 1024 && dispatch(openModal({ name: 'detailsCommande', data: { data: el, selectedTab: props?.selectedTab } })) }}>
                        <div className="ds-w-100 ds-flex ds-justify-between">
                            <span className="ds-text-size-16 ds-text-height-19 ds-text-weight700 ds-text-primary100">
                                {`#${el?.ref}`}
                            </span>
                            <span className="ds-text-size-16 ds-text-height-19 ds-text-weight400 ds-text-primary100">
                                {`${el?.total_formated} €`}
                            </span>
                        </div>
                        <div className="ds-flex-col">
                            <span className="ds-text-size-20 ds-text-height-24 ds-text-weight700 ds-text-primary100">
                                {`${el?.site_details?.company} - `}
                                <span className="ds-text-size-14 ds-text-height-16 ds-text-weight700 ds-text-primary100">
                                    {`${el?.site_details?.city}`}
                                </span>
                            </span>
                            <div className="ds-flex ds-align-center">
                                <span className="ds-text-size-12 ds-text-height-14 ds-text-weight700 ds-text-secondary100">
                                    {`${el?.delivery_method_name.charAt(0).toUpperCase() + el?.delivery_method_name.slice(1).toLowerCase()}`}
                                </span>
                                <Icon
                                    icon="clock"
                                    className="ds-text-primary40 ds-mr-5 ds-ml-10 ds-mb-3"
                                    size={"16px"}
                                />
                                <span className="ds-text-size-12 ds-text-height-14 ds-text-weight700 ds-text-primary40">
                                    {CommonFunction.formatDate(el?.collected_at, false)}
                                </span>
                            </div>
                        </div>
                        <div className="ds-flex  ds-w-100 ds-align-center">
                            <div className="ds-flex ds-align-center ds-flex-grow1">
                                <span className="ds-text-size-14 ds-text-height-16 ds-text-weight700 ds-text-primary70">
                                    {el?.quantity}
                                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight500 ds-text-primary70 ds-mr-10">
                                        {` ${parseInt(el?.quantity) > 1 ? langue.articles : langue.article}`}
                                    </span>
                                </span>
                                <div className={`ds-hp-17 ds-borad-18 ds-flex ds-p-10 ds-center ${props?.selectedTab === 1 ? 'ds-bg-success10' : new Date() >= new Date(el?.collected_at) ? 'ds-bg-warning100' : 'ds-bg-primary10'}`}>
                                    <span className={`ds-text-size-10 ds-text-height-12 ds-text-weight700 
                                    ${props?.selectedTab === 1 ? 'ds-text-success100' : new Date() >= new Date(el?.collected_at) ? 'ds-text-warning10' : 'ds-text-primary70'}`}>
                                        {`${props?.selectedTab === 1 ? langue.delivered : verifyMinutes(el?.collected_at)}`}
                                    </span>
                                </div>
                            </div>
                            {width > 1024 && <Icon
                                icon={showDetails && first === index ? "arrow-up-1" : "arrow-down-1"}
                                size={"24px"}
                                className="ds-text-primary40 ds-pointer"
                                onClick={() => {
                                    setshowDetails((first !== index) || !showDetails)
                                    setfirst(index)
                                }}
                            />}
                        </div>
                        {el?.items.map((item: ISitesType, i: number) => {
                            if (showDetails && first === index) {
                                return (
                                    <div key={i} className="ds-flex-col ds-mt-16 ds-overflow-x">
                                        {item?.name !== "[livraison]" &&
                                            <>
                                                <div className="ds-flex ds-w-100 ds-justify-between ds-px-16">
                                                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight700 ds-text-primary100 ">
                                                        {`${item?.name !== "[livraison]" && (item?.product?.unity?.code === "weight" ? item?.quantity + 'Kg ' : item?.quantity + 'X ')}`}
                                                        <span className="ds-text-size-14 ds-text-height-16 ds-text-weight700 ds-text-primary100 ">
                                                            {`${item?.name !== "[livraison]" && item?.name}`}
                                                        </span>
                                                    </span>
                                                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight500 ds-text-primary100 ">
                                                        {`${item?.total_formated !== "0,00" && item?.total_formated} €`}
                                                    </span>
                                                </div>
                                                <div className="border-dashed ds-w-100 ds-mt-10 ds-mb-10"></div>
                                            </>
                                        }

                                        {i === el?.items?.length - 1 && (
                                            <div>
                                                <div className="ds-flex ds-w-100 ds-justify-between ds-mb-16 ds-px-16">
                                                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight700 ds-text-primary100 ">
                                                        {langue.item_delivery}
                                                    </span>
                                                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight500 ds-text-primary100 ">
                                                        {`${el?.delivery_fees !== null ? CommonFunction.setFormatNumber(el?.delivery_fees) : '0,00'} €`}
                                                    </span>
                                                </div>
                                                <div className="ds-flex ds-w-100 ds-justify-between ds-mb-16 ds-px-16">
                                                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight700 ds-text-primary100 ">
                                                        {langue.fees}
                                                    </span>
                                                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight500 ds-text-primary100 ">
                                                        {`${el?.fees !== null ? CommonFunction.setFormatNumber(el?.fees) : '0,00'} €`}
                                                    </span>
                                                </div>
                                                <div className="ds-p-16 ds-flex ds-w-100 ds-align-center ds-justify-between ds-bg-primary10 ds-hp-48">
                                                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight700 ds-text-primary100 ">
                                                        {langue.total}
                                                    </span>
                                                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight700 ds-text-primary100 ">
                                                        {`${el?.total_formated} €`}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            } else return null
                        })
                        }


                        {props?.selectedTab === 1 && showDetails && first === index && <div className='ds-flex ds-w-100 ds-align-end ds-justify-end ds-mt-16'>
                            <Button
                                icon='document-download'
                                text={langue.invoice_history}
                                className='ds-mr-5'
                                type={Type.tertiary}
                                onClick={() => props?.OnDowloadInvoice(el?.uniq)}
                            />
                            <Button
                                icon='rotate-left1'
                                text={langue.order_again_history}
                                className='ds-ml-5'
                                type={Type.tertiary}
                                onClick={() => props?.getCommandeExpress(el?.uniq)}
                            />
                        </div>}
                    </div>
                </div>
                <div className="horizontal-cmd-line ds-w-100"></div>
            </div>
        )
    }
    const renderCommande = (commande: ICommande[]) => {
        const list: JSX.Element[] = []
        if (commande !== undefined) {
            commande.forEach((el: ICommande, i: number) => {
                if (props?.selectedTab === 2) {
                    list.push(
                        <div key={i} className='ds-mt-24 '>
                            <Card
                                clientCountry={`${el?.site_details?.city}`}
                                nameClient={`${el?.site_details?.company} - `}
                                addressClient={`${el?.site_details?.address}`}
                                numeroInvoice={`#${el?.ref}`}
                                dateInvoice={CommonFunction.formatDate(el?.collected_at, true)}
                                nbreItem={`${el?.items.length}`}
                                type={CardType.invoiceCard}
                                btnText={langue.invoice}
                                onClickCardBtn={() => props?.OnDowloadInvoice(el?.uniq)}
                                image={el?.site_details?.background?.medium_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")}
                                cardClassName='ds-mr-24 ds-mb-24 ds-center'
                            />
                        </div>
                    )
                } else {
                    list.push(
                        renderCommandeItems(el, i)
                    )
                }
            })
        }
        return <div className={className.join(' ')}>{list} </div>
    }

    const renderTemplateCommande = (): JSX.Element => {
        return (<div className="ds-flex-col ds-w-100 ds-px-40 ds-py-24 commande-class">
            <span className="ds-text-size-28 ds-text-height-34 ds-text-weight700 ds-text-primary100">{langue.my_orders}</span>
            <div className="ds-pt-40 tab-command">
                <Tab
                    list={Data.getCommandeTabList(langue)}
                    isTopTab={false}
                    onClick={(i: number) => { props.setSelectedTab(i); setshowDetails(false) }}
                />
                <div className="horizontal-line ds-w-100"></div>
                {
                    props?.selectedTab === 1 ?
                        <InfiniteScroll
                            dataLength={props.cmd.length}
                            next={props.fetchMoreData}
                            hasMore={true}
                            loader={!props?.stopBottom && <Spinner className="ds-hp-30 ds-wp-30 color-spinner" />}
                        >
                            <div className='ds-w-100'>
                                {renderListCommande()}
                            </div>
                        </InfiniteScroll>
                        :
                        <div className='ds-w-100'>
                            {renderListCommande()}
                        </div>
                }
            </div>
        </div>
        )
    }
    return (
        <div className='ds-overflow-x ds-w-100'>
            {renderTemplateCommande()}
        </div>
    )
}
export default TemplateCommandeEnCour