
export enum CardType {
    productCard = "productCard",
    shopCard = "shopCard",
    itemCard = "itemCard",
    invoiceCard = "invoiceCard",
}
export enum CardSize {
    small = "small",
    medium = "medium",
    large = "large",
}


export interface IOrganismCard {
    type?: CardType,
    size?: CardSize,
    image?: string,
    imageClassName?: string,
    bsPrefix?: string,
    cardClassName?: string,
    nameProduct?: string,
    nameProductClassName?: string,
    descriptionProduct?: string,
    descriptionProductClassName?: string,
    priceProduct?: string,
    priceProductClassName?: string,
    discountPriceProduct?: any,
    discountPriceProductClassName?: string,
    discountOption?: string,
    discountOptionClassName?: string,
    onClickCardBtn?: any,
    onClick?: any,
    nameClient?: string,
    nameClientClassName?: string,
    addressClient?: string,
    addressClientClassName?: string,
    distance?: string,
    clientCountry?: string,
    clientCountryClassName?: string,
    numeroInvoice?: string,
    numeroInvoiceClassName?: string,
    dateInvoice?: any,
    nbreItem?: string,
    nbreItemClassName?: string,
    isChecked?:boolean,
    onChange?:React.ChangeEventHandler<HTMLInputElement> | undefined,
    btnText?:string
    disabled?:boolean
    availability?:string
    availabilityClassName?:string
    distanceClassName?: string
}
