enum ETypesInput {
    password = 'password',
    text = 'text'
}

enum ESizeInput {
    small = 'small',
    medium = 'medium',
    large = 'large',
}
interface IInputProps extends React.HTMLProps<HTMLElement> {
    type?: ETypesInput
    inputSize?: ESizeInput,
    isInvalid?: boolean,
    isValid?: boolean,
    ref?: React.Ref<HTMLInputElement>,
    bsPrefix?: string,
    isSelect?: boolean,
    helperText?: string,
    disabled?:boolean
}
export {
    ETypesInput,
    ESizeInput
}
export type {
    IInputProps
}