import React, { useContext, useRef, useState } from 'react'
import { Button, Input, AutoComplete } from '..';
import { Icon } from '../../Atoms';
import { DEFAULTTOPBARPROPS } from '../../DefaultProps/Molecules';
import { ESizeInput, IMoleculeNavbar, Type } from '../../Interfaces';
import logo from '../../../Assets/images/Logo_Digi-Medium.svg';
import icon from '../../../Assets/images/Icone_Digi-M.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Elang, RootState } from '../../../Interfaces';
import { CommonFunction, Config, Data } from '../../../Common';
import { openAlert, openModal, resetToken, setBasket, setBasketItems, setCategoryCommercant, setCommercant, setDeliveryAddress, setGoodDeals, setLang, setNbreItems, setProduct, setProductCommercant, setShowBasket, setShowRecharge, setSideBar, setTabOptions } from '../../../Redux/Reducers';
import { EListFunction, IUseFormResult, useForm } from '../../../CustomHooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommercantAPI } from '../../../Api/Commercants/Commercants';
import { LangContext } from '../../../Lang';
import { ShopAPI } from '../../../Api/Shop/shop';
function MoleculeNavbar(props: IMoleculeNavbar): JSX.Element {
    const location = useLocation();
    const { filtredMenu, commercant, productCommercant, lng, lat } = useSelector((state: RootState) => state.commercant)
    const { user, token } = useSelector((state: RootState) => state?.auth)
    const isHomePage = Config.getInstance().homePath === location.pathname
    const { showBasket, showRecharge, items, nbreItems, baskett,zone } = useSelector((state: RootState) => state?.basket)
    const { width } = useSelector((state: RootState) => state?.screen)
    const { lang } = useSelector((state: RootState) => state?.setting)
    const { show } = useSelector((state: RootState) => state?.sideBar)
    const { delievryMode, deliveryDate, deliveryAddress } = useSelector((state: RootState) => state.paiement);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const className = ["default-navbar ds-w-100"]
    const logoClassName: string[] = ['ds-mr-10']
    const commercantApi = new CommercantAPI()
    const inputSearch = useRef<HTMLInputElement>(null)
    const navbarClassName: string[] = ['navbar-auth ds-flex ds-align-center gap-navbar-20']
    const [widthConatiner, setwidthConatiner] = useState<any>(100)
    if (location?.pathname === `/menuCommercant/${commercant?.suid}`) {
        navbarClassName.push('ds-w-100 ds-justify-between')
    }
    const [showInputLocation, setShowInputLocation] = useState<boolean>(false)
    const [showInputSearch, setShowInputSearch] = useState<boolean>(false)
    const [clearData, setClearData] = useState<boolean>(false)
    const [searchResult, setSearchResult] = useState<any>([])
    const [selectedLang, setSelectedLang] = useState<any>(lang)
    const langue = useContext(LangContext)
    const [stickyClass, setStickyClass] = useState('sticky-nav');
    const [isOpenBasket, setIsOpenBasket] = useState<boolean>(false);
    const shopAPI = new ShopAPI()
    React.useEffect(() => {
        if (showBasket || showRecharge || show) {
            setIsOpenBasket(true);
        } else {
            setIsOpenBasket(false);
        }
    }, [showBasket, showRecharge, show]);
    React.useEffect(() => {
        if (!isOpenBasket) {
            window.addEventListener('scroll', stickNavbar);
            return () => window.removeEventListener('scroll', stickNavbar);
        }
    }, [isOpenBasket]);
    React.useEffect(() => {
        if (location.pathname === '/'){
            setStickyClass('')
        }
    }, []);
    const stickNavbar = () => {
        if (window !== undefined) {
            if (isOpenBasket) {
                return;
            }
           
        }
    };
    React.useEffect(() => {
        if (lang) {
            setSelectedLang(lang)
        }
    }, [lang])
    const {
        state: form,
        onChange,
        onUpdateState,
    }: IUseFormResult = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "search",
                value: baskett?.delivery_address,
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: false
            }
        ]
    })
    const {
        state: FilterMenu,
    }: IUseFormResult = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "filterMenu",
                value: "",
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: false
            }
        ]
    })
    React.useEffect(() => {
        if (deliveryAddress) {
            onUpdateState({
                ...form,
                search: {
                    value: deliveryAddress,
                }
            })
        }
    }, [deliveryAddress])
    React.useEffect(() => {
        if (deliveryAddress == "") {
            onUpdateState({
                ...form,
                search: {
                    value: "",
                }
            })
        }
    }, [deliveryAddress == ""])
    React.useEffect(() => {
        if (baskett) {
            onUpdateState({
                ...form,
                search: {
                    value: baskett?.delivery_address == null ? '' : baskett?.delivery_address,
                }
            })
        }
    }, [baskett])
    React.useEffect(() => {
        if (baskett?.delivery_address == null) {
            onUpdateState({
                ...form,
                search: {
                    value: '',
                }
            })
        }
    }, [baskett])
    React.useEffect(() => {
        if (clearData) {
            setSearchResult([])
            setClearData(false)
        }
    }, [clearData])
    const getCatalogueCommercant = async (suid: string) => {
        const response = await commercantApi.getCommercantCatalogue({
            suid: suid,
            lang: lang,
            collected_at: deliveryDate,
            delivery_method: delievryMode ? 1 : 3
        })
        if (response.status === 200) {
            dispatch(setGoodDeals({ goodDeals: response.data }))
            const aux: any = []
            Object.values(response?.data?.categories).map((el: any) => {
                aux.push(el?.products)
            })
            dispatch(setProductCommercant({ productCommercant: aux }))
            let arr: string[] = []
            if (response?.data?.categories) {
                Object.values(response?.data?.categories).map((el: any) => {
                    arr.push(el?.name)
                })
                const formattedData: { index: number, label: string }[] = arr.map((label: string, index: number) => ({ index, label }));
                dispatch(setCategoryCommercant({ categoryCommercant: formattedData }))
            }
        }
    }
    const onClickCardBtn = async (id: string) => {
        if (id) {
            const response = await commercantApi.getCommercant({
                suid: id,
                lang: lang
            })
            if (response.status === 200) {
                if (response.data.active) {
                    dispatch(setCommercant({ commercant: response.data }))
                    dispatch(setCategoryCommercant({ categoryCommercant: [] }))
                    dispatch(setProductCommercant({ productCommercant: [] }))
                    navigate(`/menuCommercant/${id}`)
                    getCatalogueCommercant(id)
                } else {
                    dispatch(openAlert({ type: 'info', message: langue.opening_soon }))
                }
            }
        }
    }
    const onClickRecharge = () => {
        dispatch(setShowRecharge(showRecharge))
    }
    const onClickBasket = () => {
        if (items === undefined || Object?.keys(items).length === 0) {
            dispatch(openAlert({ type: 'info', message: langue.empty_basket }))
        } else {
            if (Object?.keys(items).length > 0 || items !== undefined) {
                dispatch(setShowBasket(showBasket))
            }
        }
    }
    const renderCustomOption = () => {
        const list = []
        if (searchResult.length) {
            list.push(
                <>
                    {
                        searchResult?.map((item: any, i: number) => {
                            return (
                                <div key={i} onClick={() => onClickCardBtn(item?.suid)} className='custom-list-search-options'>
                                    <div className='ds-flex ds-w-100'>
                                        <div className='ds-wp-98 ds-hp-80' style={{ backgroundImage: `url(${item?.background?.large_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

                                        </div>

                                        <div className='ds-flex-col ds-w-100 ds-pl-16'>
                                            <span className='ds-text-secondary100 ds-text-size-14 ds-text-line-16 ds-text-weight400 ds-mb-8 custom-vocation'>{item?.vocation?.name}</span>
                                            <span className='ds-text-primary100 ds-text-size-20 ds-text-line-24 ds-text-weight700 custom-site'>{item?.name}</span>
                                            <div className='ds-flex ds-justify-between ds-mt-4 ds-w-100 '>
                                                <span className='ds-text-primary70 ds-text-size-14 ds-text-line-16 ds-text-weight400 align-self custom-address'>{item?.address}</span>
                                                <span className='ds-text-primary100 ds-text-size-14 ds-text-line-16 ds-text-weight400 align-end custom-distance'>{`${Math.floor((item?.distance / 1000))} km`}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='horizontal-line ds-w-100 ds-mt-16 ds-mb-16'></div>
                                </div>
                            )
                        })
                    }
                </>
            )
        }
        return <>{list}</>
    };
    const onGetOptionCategories = (data: any) => {
        let arr: string[] = []
        if (data) {
            Object.values(data).map((el: any) => {
                arr.push(el?.name)
            })
            const formattedData: { index: number, label: string }[] = arr.map((label: string, index: number) => ({ index, label }));
            return formattedData;
        }
    }
    const onAddProductToBasket = (uniq: string) => {
        dispatch(setProduct({ product: {} }))
        let filteredElement: any;
        productCommercant.some((innerArray: any) => {
            filteredElement = innerArray.find((element: any) => {
                return element.uniq === uniq;
            });

            return filteredElement !== undefined;
        });
        if (filteredElement?.attribute?.availability?.quantity === 0 && filteredElement?.attribute?.availability?.error) {
            dispatch(openAlert({ type: 'info', message: langue.empty_disponiblity }))
        } else {
            dispatch(setProduct({ product: filteredElement }))
            if (token !== null && user !== null) {

                if (filteredElement?.menu_categories.length !== 0) {
                    dispatch(setTabOptions({ listOptions: onGetOptionCategories(filteredElement?.menu_categories) }))
                    dispatch(openModal({ name: 'modalComposeFormule', data: { product: [filteredElement], item: null, fromMenu: true } }))
                } else {
                    dispatch(openModal({ name: 'modalProductDetails', data: { product: [filteredElement], item: null, fromMenu: true } }))
                }
            } else {
                dispatch(openModal({ name: 'modalConnexionRef' }))
            }
        }

    }
    const renderCustomOptionMenu = (filtredMenu: any) => {
        const list = []
        if (filtredMenu) {
            list.push(
                <>
                    {
                        Object.values(filtredMenu?.data)?.map((item: any, i: number) => {
                            return (
                                <div key={i} onClick={() => onAddProductToBasket(item?.uniq)} className='custom-menu-categories'>
                                    <div className='ds-flex ds-w-100'>
                                        <img className='ds-mr-16' height={80} width={98} />
                                        <div className='ds-flex-col ds-w-100'>
                                            <span>{item?.name}</span>
                                            <span className={'ds-text-neutral40 ds-text-size-14 ds-text-line-16 ds-text-weight400'}>
                                                {item?.description}
                                            </span>
                                            <div className='ds-w-100 ds-flex ds-align-center ds-flex-grow1'>
                                                <span className='ds-text-size-16 ds-text-line-19 ds-text-weight400 ds-mr-5'>
                                                    {`${item?.attribute?.price && item?.vat?.amount &&
                                                        (
                                                            item?.attribute?.price - (item?.attribute?.price * Number(item?.vat?.amount))
                                                        ).toFixed(2)
                                                        } €`}
                                                </span>
                                                <div className='dot ds-mr-4'>
                                                </div>
                                                <span className='ds-text-neutral40 ds-text-weight400 line-throught ds-text-size-16 ds-text-line-19 '>
                                                    {`${item?.attribute?.price} €`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='horizontal-line ds-w-100 ds-mt-16 ds-mb-16'></div>
                                </div>
                            )
                        })
                    }
                </>
            )
        }
        const inputElement = document.querySelector('.height-search');
        const menuElement = document.querySelector('.menu-filter');
        const inputWidth = inputElement instanceof HTMLElement ? inputElement.offsetWidth : '100%';
        const filterWidth = menuElement instanceof HTMLElement ? menuElement.offsetWidth : '100%';
        if (filterWidth) {
            setwidthConatiner(filterWidth + 'px')
        } else {
            setwidthConatiner(inputWidth + 'px')
        }
        if (width > 992) {
            setwidthConatiner(inputWidth + 'px')
        }
        return <>{list}</>
    };
    const inputSearchClassName = ['ds-borad-16']
    if (width > 1024) {
        inputSearchClassName.push('input-search')
    } else {
        inputSearchClassName.push('input-search-responsive')
    }
    const searchSites = async (keySearch: string) => {
        const result = await commercantApi.getSitesSearch({
            lang: lang,
            keySearch: keySearch,
            lat: lat,
            lng: lng
        })
        if (result.status === 200) {
            if (typeof result.data !== 'string') {
                setSearchResult(result?.data)
            }
        }
    }
    const renderSearchInput = () => {
        if (location.pathname === Config.getInstance().homePath) {
            return (
                <Input
                    ref={inputSearch}
                    placeholder={langue.search_address}
                    containerClassName={inputSearchClassName.join(' ')}
                    className={inputSearchClassName.join(' ')}
                    isSearch
                    isSelect
                    setClearData={(b: boolean) => setClearData(b)}
                    selectOption={[
                        { value: 'Snack', label: 'snacking', data: searchResult },
                    ]}
                    renderCustomOption={renderCustomOption}
                    containerOptionClassName='search-options-menu ds-overflow-x ds-hv-100 ds-flex-col'
                    {...CommonFunction.getInputProps(form?.search)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | undefined>) => {
                        if (e.target.value.length > 2) {
                            searchSites(e.target.value);
                        } else {
                            setSearchResult([])
                        }
                        onChange({
                            key: "search",
                            value: e.target.value,
                        });
                    }
                    }
                    listIcons={[
                        {
                            icon: "document3",
                            isLeft: false,
                            className: 'ds-mr-14'
                        },
                        {
                            icon: "search-normal1",
                            isLeft: true,
                            className: 'ds-mr-14',
                            style: { left: '10px' }
                        },
                    ]}
                />
            )
        } else return null

    }
    const onOpenMenu = () => {
        dispatch(setSideBar(show))
    }
    const renderIconOrInput = () => {
        return (
            <div className='ds-flex ds-align-center grow-1'>
                <Icon
                    icon='menu'
                    size={"32px"}
                    className={`ds-pointer ds-text-primary60 ${width >= 1024 ? 'ds-mr-24' : 'ds-mr-10'}`}
                    onClick={() => onOpenMenu()}
                />
                <img height={27} className={logoClassName.join(' ')} src={width < 1024 ? icon : logo} />
            </div>
        )
    }
    const onClickNavBarBtn = () => {
        if (location.pathname === `/menuCommercant/${commercant?.suid}`) {
            dispatch(openModal({ name: 'modalConnexionRef' }))
        } else {
            navigate('/devenir-commercant')
        }
    }
    const getBasket = async () => {
        const res = await shopAPI.getBasketOrder({ suid: commercant?.suid, lang: lang , zone :zone})
        if (res.status === 200) {
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
        }
        else if (res?.status === 404 && res.data.error.message === "Not Found") {
            dispatch(setNbreItems({ nbreItems: null }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
        }
    }
    const getDeliveryDetails = async (address: any) => {
        const response = await shopAPI.setCollectedAt({
            suid: commercant?.suid,
            lang: lang,
            zone:zone
        },
            {
                collected_at: deliveryDate,
                delivery: !delievryMode ? 3 : 1,
                delivery_address: address
            }
        )
        if (response.status === 200) {
            await getBasket()
            dispatch(setDeliveryAddress({ deliveryAddress: address }))
        } else if (response.status === 401) {
            dispatch(resetToken())
        }
    }
    const renderNavbar = (): JSX.Element => {
        const list: JSX.Element[] = []
        if (token !== "" && user !== null && !isHomePage) {
            if (showInputLocation && width < 992) {
                list.push(
                    <div className={'ds-flex ds-align-center menu-search-navbar align-content'}>
                        <AutoComplete
                            placeholder={langue.location_search}
                            className='ds-hp-32 ds-w-100 input-simple ds-pl-36 ds-pr-36'
                            {...CommonFunction.getInputProps(form?.search)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange({
                                    key: "search",
                                    value: e
                                });
                                getDeliveryDetails(e)
                            }
                            }
                            listIcons={[
                                {
                                    icon: "location",
                                    isLeft: true,
                                    size: width >= 992 ? "24px" : "16px",
                                    className: 'icon-location',
                                },
                            ]}
                        />
                        <Icon icon='close' size={'14px'} className='ds-pointer ds-text-primary60' onClick={() => setShowInputLocation(false)} />
                    </div>
                )
            }
            else if (showInputSearch && width < 992) {
                list.push(
                    <div className={'ds-flex ds-align-center menu-search-navbar align-content'}>
                        <Input
                            placeholder={langue.menu_search}
                            className='ds-hp-32 ds-w-100 menu-filter'
                            containerClassName=''
                            isSearch
                            isSelect
                            isMenu
                            selectOption={[
                                { value: 'Snack', label: 'snacking', data: filtredMenu },
                            ]}
                            renderCustomOption={renderCustomOptionMenu}
                            {...CommonFunction.getInputProps(FilterMenu.filterMenu)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onChange({
                                    key: "filterMenu",
                                    value: e.target.value,
                                })
                            }
                            containerOptionClassName='search-menu-logged ds-overflow-x ds-hv-100 ds-flex-col'
                            containerOptionStyle={{ width: widthConatiner }}
                        />
                        <Icon icon='close' size={'14px'} className='ds-pointer ds-text-primary60' onClick={() => setShowInputSearch(false)} />
                    </div>
                )
            } else {
                list.push(
                    <div className={'ds-flex ds-align-center navbar-element align-content'}>
                        {location?.pathname === `/menuCommercant/${commercant?.suid}` &&
                            <div className='ds-flex ds-align-center gap-navbar-20 ds-w-100 '>
                                {
                                    width > 992 ?
                                        <div className='drop-down-location' >
                                            <AutoComplete
                                                placeholder={width > 992 ? langue.location_search : ''}
                                                className='height-location'
                                                {...CommonFunction.getInputProps(form?.search)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (width >= 992) {
                                                        onChange({
                                                            key: "search",
                                                            value: e
                                                        });
                                                        getDeliveryDetails(e)
                                                    }
                                                }}
                                                listIcons={[
                                                    {
                                                        icon: "location",
                                                        isLeft: true,
                                                        size: width >= 992 ? "24px" : "20px",
                                                        className: 'icon-location',

                                                    },
                                                ]}
                                            />
                                        </div>
                                        : <Button icon='location' type={Type.tertiary} className='location-button' onClick={() => { setShowInputLocation(true) }} />
                                }
                                {width > 992 ?
                                    <div className='search-width' >
                                        <Input
                                            placeholder={width > 992 ? langue.menu_search : ''}
                                            className='height-search'
                                            isSearch
                                            isSelect
                                            isMenu
                                            selectOption={[
                                                { value: 'Snack', label: 'snacking', data: filtredMenu },
                                            ]}
                                            renderCustomOption={renderCustomOptionMenu}
                                            {...CommonFunction.getInputProps(FilterMenu.filterMenu)}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (width >= 1024) {
                                                    onChange({
                                                        key: "filterMenu",
                                                        value: e.target.value,
                                                    })
                                                }
                                            }
                                            }
                                            containerOptionClassName=' search-menu-logged ds-overflow-x ds-hv-100 ds-flex-col'
                                            containerOptionStyle={{ width: widthConatiner }}

                                        />
                                    </div>
                                    :
                                    <Button icon='search-normal2' type={Type.tertiary} className='location-button' onClick={() => setShowInputSearch(true)} />
                                }

                            </div>
                        }
                        <div className='ds-flex ds-align-center gap-navbar-20 '>
                            <Input
                                containerClassName='select-lang ds-borad-16 '
                                className='select-lang ds-borad-16'
                                isSelect={true}
                                inputSize={ESizeInput.large}
                                {...CommonFunction.getInputProps(selectedLang)}
                                onChangeSelect={(e: Elang) => dispatch(setLang(e))}
                                selectValue={selectedLang}
                                selectOption={Data.getSelectOption()}
                                containerOptionClassName='select-drop-down'
                            />
                            {
                                location.pathname !== '/devenir-commercant' && <>
                                    <Button
                                        type={Type.tertiary}
                                        icon='empty-wallet'
                                        className='walet-btn ds-borad-16 '
                                        onClick={() => onClickRecharge()}
                                    />
                                    <Button
                                        type={Type.secondary}
                                        icon='bag-21'
                                        text={nbreItems}
                                        className='panier-btn ds-borad-16'
                                        onClick={() => onClickBasket()}
                                    />
                                </>
                            }
                        </div>
                    </div>
                )
            }

        } else {
            if (location.pathname === `/menuCommercant/${commercant?.suid}`) {
                if (showInputSearch && width < 992) {
                    list.push(
                        <div className={'ds-flex ds-align-center menu-search-navbar align-content'}>
                            <Input
                                placeholder={langue.menu_search}
                                className='ds-hp-32 ds-w-100 menu-filter'
                                containerClassName=''
                                isSearch
                                isSelect
                                isMenu
                                selectOption={[
                                    { value: 'Snack', label: 'snacking', data: filtredMenu },
                                ]}
                                renderCustomOption={renderCustomOptionMenu}
                                {...CommonFunction.getInputProps(FilterMenu.filterMenu)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    onChange({
                                        key: "filterMenu",
                                        value: e.target.value,
                                    })
                                }
                                containerOptionClassName='search-menu-not-logged ds-overflow-x ds-hv-100 ds-flex-col'
                                containerOptionStyle={{ width: widthConatiner }}
                            />
                            <Icon icon='close' size={'14px'} className='ds-pointer ds-text-primary60' onClick={() => setShowInputSearch(false)} />
                        </div>
                    )
                } else {
                    list.push(<>
                        {width > 992 ?
                            <div className='search-width-not-logged ds-w-100' >
                                <Input
                                    placeholder={width > 992 ? langue.menu_search : ''}
                                    className='height-search'
                                    isSearch
                                    isSelect
                                    isMenu
                                    selectOption={[
                                        { value: 'Snack', label: 'snacking', data: filtredMenu },
                                    ]}
                                    renderCustomOption={renderCustomOptionMenu}
                                    {...CommonFunction.getInputProps(FilterMenu.filterMenu)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (width >= 1024) {
                                            onChange({
                                                key: "filterMenu",
                                                value: e.target.value,
                                            })
                                        }
                                    }
                                    }
                                    containerOptionClassName='search-menu-not-logged ds-overflow-x ds-hv-100 ds-flex-col'
                                    containerOptionStyle={{ width: widthConatiner }}
                                />
                            </div>
                            :
                            <Button icon='search-normal2' type={Type.tertiary} className='location-button' onClick={() => setShowInputSearch(true)} />
                        }
                        <Input
                            containerClassName='select-lang ds-borad-16 '
                            className='select-lang ds-borad-16'
                            isSelect={true}
                            inputSize={ESizeInput.large}
                            {...CommonFunction.getInputProps(selectedLang)}
                            onChangeSelect={(e: Elang) => dispatch(setLang(e))}
                            selectValue={selectedLang}
                            selectOption={Data.getSelectOption()}
                            containerOptionClassName='select-drop-down-not-logged'
                        />
                        {width > 992 && renderSearchInput()}

                        {
                            location.pathname !== '/devenir-commercant' &&
                            <div className='ds-flex ds-align-end ds-justify-end'>
                                <Button
                                    text={location.pathname === `/menuCommercant/${commercant?.suid}` ? langue.connect : langue.become_retailer}
                                    icon={location.pathname === `/menuCommercant/${commercant?.suid}` ? 'login1' : 'shop'}
                                    type={Type.secondary}
                                    className='login-btn ds-hp-46 ds-pointer'
                                    onClick={() => onClickNavBarBtn()}
                                />
                            </div>
                        }
                    </>
                    )
                }
            } else if (location.pathname === `/devenir-commercant`) {
                list.push(
                    <Input
                        containerClassName='select-lang ds-borad-16 '
                        className='select-lang ds-borad-16'
                        isSelect={true}
                        inputSize={ESizeInput.large}
                        {...CommonFunction.getInputProps(selectedLang)}
                        onChangeSelect={(e: Elang) => dispatch(setLang(e))}
                        selectValue={selectedLang}
                        selectOption={Data.getSelectOption()}
                        containerOptionClassName='select-drop-down-not-logged'
                    />
                )
            } else {
                list.push(
                    <>
                        {width > 992 && renderSearchInput()}
                        {
                            location.pathname !== '/devenir-commercant' &&
                            <div className='ds-flex ds-align-end ds-justify-end'>
                                <Button
                                    text={langue.become_retailer}
                                    icon={'shop'}
                                    type={Type.secondary}
                                    className='commercant-btn ds-hp-46 ds-pointer'
                                    onClick={() => onClickNavBarBtn()}
                                />
                            </div>
                        }
                    </>
                )
            }
        }
        return (
            <>
                {renderIconOrInput()}
                {list}
            </>
        )
    }
    if (props.className) className.push(props.className)
    if (props.logoClassName) logoClassName.push(props.logoClassName)

    return (
        <div className={`menu-planify padding-navbar ${stickyClass}`}>
            <div className='ds-flex ds-align-center ds-justify-between ds-w-100 gap-navbar-20'>
                {renderNavbar()}
            </div>
            {width <= 992 && renderSearchInput()}
        </div>

    )
}

MoleculeNavbar.defaultProps = {
    ...DEFAULTTOPBARPROPS,
}
export default MoleculeNavbar