import React, { useContext, useState } from 'react'
import { TemplateMesRecharges } from "../../Template"
import { ShopAPI } from '../../Api/Shop/shop'
import { IRechargeType, RootState } from '../../Interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateEffect } from '../../CustomHooks'
import { openAlert, resetToken, setCustomerMember, setSitesRecharges } from '../../Redux/Reducers'
import { UserAPI } from '../../Api/User/user'
import { LangContext } from '../../Lang'

const PageMesRecharges = () => {
    const { commercant, recharges } = useSelector((state: RootState) => state.commercant);
    const { lang } = useSelector((state: RootState) => state.setting);
    const { user } = useSelector((state: RootState) => state?.auth)
    const shopApi = new ShopAPI()
    const userApi = new UserAPI()
    const dispatch = useDispatch()
    const langue = useContext(LangContext)
    const [orders, setOrders] = useState<any[]>([]);
    const [stopBottom, setStopBottom] = useState<boolean>(false);
    const [recahrgesNotCompleted, setRecahrgesNotCompleted] = useState<IRechargeType>(recharges && recharges)
    useUpdateEffect(() => {
        if (recharges.length) {
            getCustomerMemeber()
        }
    }, [recharges.length])
    const getCustomerMemeber = async () => {
        const response = await userApi.getCustomerMember({
            userId: user?.uniq,
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            dispatch(setCustomerMember({ customerMember: response.data }))
        }
    }
    const getUserTransactions = async () => {
        setOrders([])
        const response = await shopApi.getUserTransactions(
            {
                suid: commercant?.suid,
                lang: lang
            },
            { offset: orders.length }
        )
        if (response.status === 200) {
            setStopBottom(false)
            setOrders(orders.concat(response?.data))
            getUserTransactionsnotCompleted(orders.concat(response?.data))
        }
        if (response.status === 401) {
            dispatch(resetToken())
        }
        if (!response.data.length) {
            setStopBottom(true);
            dispatch(openAlert({ type: 'info', message: langue.allLoaded }))
        }
    }
    const getUserTransactionsnotCompleted = async (data: any) => {
        const response = await shopApi.getUserTransactionsnotCompleted({
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            setRecahrgesNotCompleted(data.concat(response?.data))
            dispatch(setSitesRecharges({ recharges: data.concat(response?.data) }))
        } else {
            getUserTransactionsnotCompleted([])
        }
    }
    useUpdateEffect(() => {
        getUserTransactions()
    }, [])
    const fetchMoreData = () => {
        setTimeout(() => {
            doInfinite()
            setOrders(orders.concat(Array.from({ length: 0 })))
        }, 1500);
    };
    const doInfinite = async () => {
        await getUserTransactions();
    }
    return (
        <TemplateMesRecharges
            recahrgesNotCompleted={recahrgesNotCompleted}
            fetchMoreData={fetchMoreData}
            orders={orders}
            stopBottom={stopBottom}
        />
    )
}
export default PageMesRecharges