import { createSlice } from '@reduxjs/toolkit';
import { IAuthReducer } from '../../../Interfaces/Redux';

const initiaState: IAuthReducer = {
  user: null,
  token: "",
  userLoyalty: {},
  showCarousel: true,
  resetEmail: ''
};

const AuthReducer = createSlice({
  name: 'auth',
  initialState: initiaState,
  reducers: {
    setToken: (state: IAuthReducer, action) => {
      state.token = action.payload.token
    },
    resetToken: (state: IAuthReducer) => {
      state.user = null
      state.token = ""
    },
    setUserRegistration: (state: IAuthReducer, action) => {
      state.user = action.payload.user
    },
    setUserLoyalty: (state: IAuthReducer, action) => {
      state.userLoyalty = action.payload.userLoyalty
    },
    setShowCarouselItems: (state: IAuthReducer, action) => {
      state.showCarousel = false
    },
    setResetPasswordEmail: (state: IAuthReducer, action) => {
      state.resetEmail = action.payload.email
    }
  }
});

export const { setToken, resetToken, setUserRegistration, setUserLoyalty, setShowCarouselItems, setResetPasswordEmail } = AuthReducer.actions;

export default AuthReducer.reducer;
