import React, { useContext, useState } from 'react';
import { useSelector } from "react-redux"
import { Button, Checkbox, Icon, Input, Tab } from "../../DesignSystem"
import { CardType, Type } from "../../DesignSystem/Interfaces"
import { IFilterMenu, IMenuProduct, ISupplementCategory, ITemplateModalComposeFormuleOrSandwich } from "../../Interfaces/Modal"
import { RootState } from "../../Interfaces"
import { Card } from "../../DesignSystem/Organisms"
import { CommonFunction } from '../../Common';
import { LangContext } from '../../Lang';

const TemplateModalComposeFormuleOrSandwich = (props: ITemplateModalComposeFormuleOrSandwich): JSX.Element => {
    const priceProductClassName = ['modal-compose-price-text ds-text-primary100 ds-mr-10']
    const discountPriceProductClassName = ['modal-compose-discount-price-text ds-text-neutral40 line-throught']
    const productNameClassName = ['modal-compose-title-text ds-text-primary100 ds-w-100 ds-mt-10 ds-mb-8']
    const productDescriptionClassName = ['modal-compose-description-text ds-text-primary70 description-width ds-my-10']
    const productSubtitleClassName = ['modal-compose-subtitle-text ds-text-primary100 description-width']
    const className = ['ds-flex-col']
    const { listOptions, options, nameCategory } = useSelector((state: RootState) => state.commercant);
    const { data } = useSelector((state: RootState) => state.modal);
    const langue = useContext(LangContext)
    const classNameModal = ["ds-flex ds-w-100 ds-h-100 "]
    const renderIsDisabled = () => {
        const matchingCategories = props?.objectCategry.filter(
            (category: any) => !category.category_name.includes('(opt)') && category.category.length > 0
        );
        const nonOptCategoryCount = matchingCategories.reduce((count, category) => {
            return count + 1;
        }, 0);
        const areOptCategoriesChecked = props?.objectCategry.filter(
            (category: any) => { return (category.isChecked === true && category.index === 0 && category.category_name.includes('(opt)')) || (category.category_name.includes('(opt)') && category.isChecked === false && category.index !== 0) }
        );
        const isDisabled = (nonOptCategoryCount >= props?.objectCategry.filter(
            (category: any) => !category.category_name.includes('(opt)')).length) && areOptCategoriesChecked.length === listOptions.filter((ss: any) => ss.label.includes('(opt)')).length;
        return isDisabled
    }
    const renderProductPrice = () => {
        if (props.quantity > 1) {
            if (props?.myProduct?.attribute?.promo !== null) {
                return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.promo)) * props?.quantity);
            } else {
                return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.price)) * props?.quantity);
            }
        } else {
            if (props?.myProduct?.attribute?.promo !== null) {
                return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.promo)));
            } else {
                return CommonFunction.setFormatNumber(((props?.myProduct?.attribute?.price)));
            }
        }
    }
    const renderModifyPrice = () => {
        if (props.iteeee.product?.attribute?.promo !== null) {
            return CommonFunction.setFormatNumber(((props.iteeee.product?.attribute?.promo)) * props.iteeee?.quantity);
        } else {
            return CommonFunction.setFormatNumber(((props.iteeee.product?.attribute?.price)) * props.iteeee?.quantity);
        }
    }
    const renderOptionsList = () => {
        const list: JSX.Element[] = []
        if (options.length) {
            list.push(
                <div className='ds-w-100 ds-flex-col'>
                    <div className='ds-flex ds-w-100 ds-align-center'>
                        <Icon
                            icon='arrow-left'
                            size={'20px'}
                            onClick={() => { props?.setIsOptionList(false) }}
                        />
                        <span className='ds-text-primary100 ds-text-size-20 ds-text-weight700'>{nameCategory}</span>
                    </div>
                    <div className='horizontal-line ds-w-100 ds-mt-10 ds-mb-10'></div>
                    <div className='ds-flex-col ds-w-100 ds-pb-10'>
                        <span className='ds-text-primary100 ds-text-size-16 ds-text-weight700'>
                            {options[0]?.name}
                        </span>
                        <div className='ds-flex ds-align-center ds-w-100 ds-justify-between'>
                            <span className='ds-text-primary70 ds-text-size-14 ds-text-weight400'>{langue.selected_items}</span>
                            <span className='ds-text-primary70 ds-text-size-16 ds-text-weight600'>{`${props?.selectedSupplement}/${options[0].options.length}`}</span>
                        </div>
                    </div>
                    <div>
                        {options[0].options.map((option: any, index: number) => {
                            return (
                                <div key={index} className='ds-flex-col ds-w-100' onClick={() => { props?.onChooseSupplementOptions(index, option) }}>
                                    <div className='ds-flex ds-align-center ds-w-100'>
                                        <div className='ds-flex-col ds-w-100'>
                                            <span>{option?.name.split(' ')[1]}</span>
                                            <span>{`+ ${CommonFunction.setFormatNumber(option?.price)} €`}</span>
                                        </div>
                                        <Checkbox
                                            checked={props?.selectedOptions?.some((opt: any) => { return opt === option?.id })}
                                            onChange={() => props?.onChooseSupplementOptions(index, option)}
                                            className="ds-pointer checkbox-recharge"
                                            containerClassName="ds-pointer checkbox-recharge"
                                        />
                                    </div>
                                    <div className='horizontal-line ds-w-100 ds-mt-10 ds-mb-10'></div>
                                </div>
                            )
                        })}
                    </div>
                    <Button
                        text={`Sauvegarder - ${CommonFunction.setFormatNumber((props?.myProduct?.attribute?.price - (props?.myProduct?.attribute?.price * props?.myProduct?.vat?.amount)) + props?.supplementPrice)} `}
                        type={Type.primary}
                        className='modal-compose-button-text ds-w-100 ds-hp-48 ds-p-10'
                        onClick={() => { props?.setIsOptionList(false) }}
                    />
                </div>
            )
        }
        return list
    }
    const renderProductDetails = () => {
        const list = []
        if (data?.product !== null && data?.item === null) {
            list.push(
                <div className="ds-pr-24">
                    <div className={className.join(' ')}>
                        <div className='ds-w-100  img-class-1' style={{
                            backgroundImage: `url(${data?.product[0]?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center'
                        }}>
                        </div>
                        <span className={productNameClassName.join(' ')}>{data?.product[0]?.name}</span>
                        <div className="ds-w-100 ds-flex ds-align-center">
                            <span className={priceProductClassName.join(' ')}>
                                {data?.product[0]?.attribute?.promo !== null ? `${CommonFunction.setFormatNumber(data?.product[0]?.attribute?.promo)
                                    } €` : `${CommonFunction.setFormatNumber(data?.product[0]?.attribute?.price)
                                    } €`}
                                {data?.product[0]?.weight && <span>{'/kg'}</span>}
                            </span>
                            {data?.product[0]?.attribute?.promo !== null &&
                                <>
                                    <div className='dot ds-mr-4'>
                                    </div>
                                    <span className={discountPriceProductClassName.join(' ')}>
                                        {`${CommonFunction.setFormatNumber(data?.product[0]?.attribute?.price)} €`}
                                    </span>
                                </>
                            }
                        </div>
                        <span className={productDescriptionClassName.join(' ')}>{data?.product[0]?.description}</span>
                        <span className={productSubtitleClassName.join(' ')}>{langue.no_of_pieces}</span>
                        <div className="ds-flex ds-w-100 ds-pb-10 ds-mt-10 ">
                            <Button
                                type={Type.tertiary}
                                icon="minus"
                                className="ds-text-primary40 quantity-btn"
                                onClick={() => props.incrementOrDecrementQunatity(true)}
                            />
                            <Input
                                value={props.quantity}
                                className="quatity-input"
                                containerClassName="quatity-input ds-ml-10 ds-mr-10"
                                disabled={true}
                            />
                            <Button
                                type={Type.tertiary}
                                icon="add"
                                className="ds-text-primary40 quantity-btn"
                                onClick={() => props.incrementOrDecrementQunatity(false)}
                            />
                        </div>
                        <div className="ds-mb-32" onClick={() => props?.onAddComment()}>
                            <Input
                                label={langue.comments + " :"}
                                labelClassName={productSubtitleClassName.join(' ')}
                                className="input-note ds-hp-48 comment-input-mt-6 ds-mt-10"
                                value={props?.myProduct?.uniq === props?.itemId ? props?.commentItem : ''}
                            />
                        </div>
                        <Button
                            className="modal-compose-button-text input-note ds-hp-48"
                            text={langue.add_to_basket + ` - ${renderProductPrice()} €`}
                            onClick={() => props.onAddItemBasket()}
                            disabled={!renderIsDisabled()}
                            isLoading={props.isLoading}
                        />
                    </div>
                </div>
            )
        } else {
            list.push(
                <div className="ds-pr-24">
                    <div className={className.join(' ')}>
                        <div className='ds-w-100 img-class-2' style={{
                            backgroundImage: `url(${data?.item.product?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center'
                        }}>
                        </div>
                        <span className={productNameClassName.join(' ')}>{data?.item.product?.name}</span>
                        <div className="ds-w-100 ds-flex ds-align-center">
                            <span className={priceProductClassName.join(' ')}>
                                {data?.item.product?.attribute?.promo !== null ? `${CommonFunction.setFormatNumber(data?.item.product?.attribute?.promo)
                                    } €` : `${CommonFunction.setFormatNumber(data?.item.product?.attribute?.price)
                                    } €`}
                                {data?.item.product?.weight && <span>{'/kg'}</span>}
                            </span>
                            {data?.item.product?.attribute?.promo !== null &&
                                <>
                                    <div className='dot ds-mr-4'>
                                    </div>
                                    <span className={discountPriceProductClassName.join(' ')}>
                                        {`${CommonFunction.setFormatNumber(data?.item.product?.attribute?.price)} €`}
                                    </span>
                                </>
                            }
                        </div>
                        <span className={productDescriptionClassName.join(' ')}>{data?.item.product?.description}</span>
                        <span className={productSubtitleClassName.join(' ')}>{langue.no_of_pieces}</span>
                        <div className="ds-flex ds-w-100 ds-mb-10 ds-mt-10">
                            <Button
                                type={Type.tertiary}
                                icon="minus"
                                className="ds-text-primary40 quantity-btn"
                                onClick={() => props.onSetOrderQuantity(true)}
                            />
                            <Input
                                value={props?.iteeee?.quantity}
                                className="quatity-input"
                                containerClassName="quatity-input ds-ml-10 ds-mr-10"
                                disabled={true}
                            />
                            <Button
                                type={Type.tertiary}
                                icon="add"
                                className="ds-text-primary40 quantity-btn"
                                onClick={() => props.onSetOrderQuantity(false)}
                            />
                        </div>
                        <div className="" onClick={() => props?.onAddComment()}>
                            <Input
                                label={langue.comments + " :"}
                                labelClassName={productSubtitleClassName.join(' ')}
                                className="input-note ds-hp-48 comment-input-mt-6"
                                value={props?.iteeee?.product?.uniq === props?.itemId && data?.fromAddNote ? props?.commentItem : props?.iteeee?.remarque}
                            />
                        </div>
                        <Button
                            className="modal-compose-button-text input-note ds-mt-16 ds-hp-48"
                            text={langue.modify + ` - ${renderModifyPrice()}`}
                            onClick={() => props.onModifyItemBasket(props?.iteeee?.id, props?.iteeee?.quantity)}
                            isLoading={props.isLoading}
                        />
                    </div>
                </div>
            )
        }
        return list
    }
    const renderProductOptions = () => {
        const list = []
        const listCard: JSX.Element[] = []
        const listSupp: JSX.Element[] = []
        if (data?.product !== null && data?.item === null) {
            list.push(
                <div className="ds-flex-col ds-w-100">
                    <span className="ds-mb-24 modal-compose-composer-formule-text ds-text-primary70">{langue.compose_formula}</span>
                    <Tab
                        textClassName="modal-compose-tab-title-text"
                        selectedTextClassName="modal-compose-tab-title-text-selected"
                        list={listOptions}
                        isTopTab={false}
                        onClick={(i: number) => {
                            props.onSelectOptionTab(i); props?.setSelectedElementt(i)
                        }}
                        selectedIndex={props?.selectedElement}
                    />
                    <div className="horizontal-line ds-w-100"></div>
                    <div className="ds-flex-wrap ds-flex">
                        {data?.product[0]?.menu_categories?.filter((el: IFilterMenu, index: number) => {
                            if (el?.name === props.supplement) {
                                props?.objectCategry?.forEach((category: ISupplementCategory, indice: number) => {
                                    if (indice === index) {
                                        if (category.category_name && category?.category_name.includes('(opt)')) {
                                            listSupp.push(
                                                <Checkbox
                                                    label={langue.without_additional_sauce}
                                                    labelClassName="ds-text-primary100"
                                                    containerClassName="supplement-checkbox"
                                                    checked={category?.isChecked}
                                                    onChange={() => props.onChangeWithoutSauce(indice)}
                                                />
                                            )
                                        }
                                        listSupp.push(
                                            <span className="ds-text-size-14 ds-text-line-17 ds-text-weight500 ds-text-primary70 ds-mt-16 ds-mb-16">{langue.selected_sauces + ` ${category.index} / ${el?.products?.length}`}</span>
                                        )
                                    }
                                })
                                if (el?.products) {
                                    (el?.products.forEach((item: IMenuProduct, i: number) => {
                                        listCard.push(<Card
                                            key={i}
                                            type={CardType.itemCard}
                                            cardClassName={`ds-mr-24 ds-mb-16 ${(props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.category[0] === item?.product?.uniq })) && (props?.supplementList.some((sup: any) => { return sup?.uid === item?.product?.uniq })) ? 'shadoww-lue': ''}`}
                                            nameProduct={item?.product?.name}
                                            nameProductClassName="ds-text-size-14 ds-text-line-17 text-ellipsis"
                                            isChecked={(props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.category[0] === item?.product?.uniq })) && (props?.supplementList.some((sup: any) => { return sup?.uid === item?.product?.uniq }))}
                                            onChange={() => props.onChooseSupplement(el, item?.product, index, i)}
                                            disabled={props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.isChecked === true })}
                                            image={item?.product?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")}
                                        />)
                                    })
                                    )
                                }
                            }
                        })
                        }
                    </div>
                </div>
            )
        } else {
            list.push(
                <div className="ds-flex-col ds-w-100">
                    <span className="ds-mb-24 modal-compose-composer-formule-text ds-text-primary70">{langue.compose_formula}</span>
                    <Tab
                        textClassName="modal-compose-tab-title-text"
                        selectedTextClassName="modal-compose-tab-title-text-selected"
                        list={listOptions}
                        isTopTab={false}
                        onClick={(i: number) => {
                            props.onSelectOptionTab(i); props?.setSelectedElementt(i)
                        }}
                        selectedIndex={props?.selectedElement}
                    />
                    <div className="horizontal-line ds-w-100"></div>
                    <div className="ds-flex-wrap ds-flex">
                        {data?.item.product?.menu_categories?.filter((el: IFilterMenu, index: number) => {
                            if (el?.name === props.supplement) {
                                props?.objectCategry?.forEach((category: ISupplementCategory, indice: number) => {
                                    if (indice === index) {
                                        if (category.category_name && category?.category_name.includes('(opt)')) {
                                            listSupp.push(
                                                <Checkbox
                                                    label={langue.without_additional_sauce}
                                                    labelClassName="ds-text-primary100"
                                                    containerClassName="supplement-checkbox"
                                                    checked={category?.isChecked}
                                                    onChange={() => props.onChangeWithoutSauce(indice)}
                                                />
                                            )
                                        }
                                        listSupp.push(
                                            <span className="ds-text-size-14 ds-text-line-17 ds-text-weight500 ds-text-primary70 ds-mt-16 ds-mb-16">{langue.selected_sauces + ` ${category.index} / ${el?.products?.length}`}</span>
                                        )
                                    }
                                })
                                if (el?.products) {
                                    (el?.products.forEach((item: IMenuProduct, i: number) => {
                                        listCard.push(<Card
                                            key={i}
                                            type={CardType.itemCard}
                                            cardClassName={`ds-mr-24 ds-mb-16 ${(props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.category[0] === item?.product?.uniq })) && (props?.supplementList.some((sup: any) => { return sup?.uid === item?.product?.uniq })) ? 'shadoww-lue': ''}`}
                                            nameProduct={item?.product?.name}
                                            nameProductClassName="ds-text-size-14 ds-text-line-17 text-ellipsis"
                                            isChecked={(props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.category[0] === item?.product?.uniq })) && (props?.supplementList.some((sup: any) => { return sup?.uid === item?.product?.uniq }))}
                                            onChange={() => props.onChooseSupplement(el, item?.product, index, i)}
                                            disabled={props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.isChecked === true })}
                                            image={item?.product?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")}
                                        />)
                                    })
                                    )
                                }
                            }
                        })
                        }
                    </div>
                </div>
            )
        }
        return (
            <div className="ds-w-100 ds-h-100 ds-flex-col">{list}
                <div className="ds-flex-wrap ds-flex ds-overflow-x max-list-height ds-w-100">
                    <div className="ds-mt-5 ds-mb-5 ds-flex ds-justify-between ds-w-100">
                        {listSupp}
                    </div>
                    {listCard}
                </div>
            </div>
        )
    }
    const renderResponsiveComposerFormule = () => {
        const list = []
        const listCard: JSX.Element[] = []
        const listSupp: JSX.Element[] = []
        if (data) {
            if (props?.showOptions || (!props?.isAddingCommande && (data?.fromAddNote && data?.showOptions))) {
                if ((renderIsDisabled() && data?.fromMenu) || (renderIsDisabled() && (data?.fromAddNote && data?.showOptions)) || (!renderIsDisabled() && (data?.fromBasket))) {
                    if ((data?.fromMenu || (data?.fromAddNote && data?.showOptions)) && !data?.fromBasket && data?.product !== null) {
                        list.push(
                            <div className="ds-w-100 ds-h-100">
                                <div className={className.join(' ').concat(' ds-h-100 justify-content-between')}>
                                    <div className={className.join(' ')}>
                                        <div className='ds-w-100 ds-hp-200' style={{
                                            backgroundImage: `url(${data?.product[0]?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center'
                                        }}>
                                        </div>
                                        <span className={productNameClassName.join(' ')}>{data?.product[0]?.name}</span>
                                        <div className="ds-w-100 ds-flex ds-align-center">
                                            <span className={priceProductClassName.join(' ')}>
                                                {data?.product[0]?.attribute?.promo !== null ? `${CommonFunction.setFormatNumber(data?.product[0]?.attribute?.promo)
                                                    } €` : `${CommonFunction.setFormatNumber(data?.product[0]?.attribute?.price)
                                                    } €`}
                                                {data?.product[0]?.weight && <span>{'/kg'}</span>}
                                            </span>
                                            {data?.product[0]?.attribute?.promo !== null &&
                                                <>
                                                    <div className='dot ds-mr-4'>
                                                    </div>
                                                    <span className={discountPriceProductClassName.join(' ')}>
                                                        {`${CommonFunction.setFormatNumber(data?.product[0]?.attribute?.price)} €`}
                                                    </span>
                                                </>
                                            }
                                        </div>
                                        <span className={productDescriptionClassName.join(' ')}>{data?.product[0]?.description}</span>
                                        <span className={productSubtitleClassName.join(' ')}>{langue.no_of_pieces}</span>
                                        <div className="ds-flex ds-w-100 ds-mb-10 ds-mt-10 quatity-class">
                                            <Button
                                                type={Type.tertiary}
                                                icon="minus"
                                                className="ds-text-primary40 quantity-btn"
                                                onClick={() => props.incrementOrDecrementQunatity(true)}
                                            />
                                            <Input
                                                value={props?.quantity}
                                                className="quatity-input"
                                                containerClassName="quatity-input ds-ml-10 ds-mr-10"
                                                disabled={true}
                                            />
                                            <Button
                                                type={Type.tertiary}
                                                icon="add"
                                                className="ds-text-primary40 quantity-btn"
                                                onClick={() => props.incrementOrDecrementQunatity(false)}
                                            />
                                        </div>
                                        <div className="compose-note" onClick={() => props?.onAddComment()}>
                                            <Input
                                                label={langue.comments + " :"}
                                                labelClassName={productSubtitleClassName.join(' ')}
                                                className="input-note ds-hp-48 comment-input-mt-6"
                                                value={props?.myProduct?.uniq === props?.itemId ? props?.commentItem : ''}
                                            />
                                        </div>
                                    </div>
                                    <Button
                                        className="modal-compose-button-text ds-w-100 ds-mt-24 ds-hp-48"
                                        text={langue.add_to_basket + ` - ${renderProductPrice()} €`}
                                        onClick={() => props.onAddItemBasket()}
                                        disabled={!renderIsDisabled()}
                                        isLoading={props.isLoading}
                                    />
                                </div>
                            </div>
                        )
                    } else {
                        list.push(
                            <div className="ds-w-100">
                                <div className={className.join(' ')}>
                                    <div className='ds-w-100 ds-hp-200' style={{
                                        backgroundImage: `url(${data?.item?.product?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center'
                                    }}>
                                    </div>
                                    <span className={productNameClassName.join(' ')}>{data?.item?.product?.name}</span>
                                    <div className="ds-w-100 ds-flex ds-align-center">
                                        <span className={priceProductClassName.join(' ')}>
                                            {data?.item?.product?.attribute?.promo !== null ? `${CommonFunction.setFormatNumber(data?.item?.product?.attribute?.promo)
                                                } €` : `${CommonFunction.setFormatNumber(data?.item?.product?.attribute?.price)
                                                } €`}
                                            {data?.item?.product?.weight && <span>{'/kg'}</span>}
                                        </span>
                                        {data?.item?.product?.attribute?.promo !== null &&
                                            <>
                                                <div className='dot ds-mr-4'>
                                                </div>
                                                <span className={discountPriceProductClassName.join(' ')}>
                                                    {`${CommonFunction.setFormatNumber(data?.item?.product?.attribute?.price)} €`}
                                                </span>
                                            </>
                                        }
                                    </div>
                                    <span className={productDescriptionClassName.join(' ')}>{data?.item?.product?.description}</span>
                                    <span className={productSubtitleClassName.join(' ')}>{langue.no_of_pieces}</span>
                                    <div className="ds-flex ds-w-100 ds-mb-10 ds-mt-10 quatity-class">
                                        <Button
                                            type={Type.tertiary}
                                            icon="minus"
                                            className="ds-text-primary40 quantity-btn"
                                            onClick={() => props.onSetOrderQuantity(true)}
                                        />
                                        <Input
                                            value={props?.iteeee?.quantity}
                                            className="quatity-input"
                                            containerClassName="quatity-input ds-ml-10 ds-mr-10"
                                            disabled={true}
                                        />
                                        <Button
                                            type={Type.tertiary}
                                            icon="add"
                                            className="ds-text-primary40 quantity-btn"
                                            onClick={() => props.onSetOrderQuantity(false)}
                                        />
                                    </div>
                                    <div className="" onClick={() => props?.onAddComment()}>
                                        <Input
                                            label={langue.comments + " :"}
                                            labelClassName={productSubtitleClassName.join(' ')}
                                            className="input-note ds-hp-48 comment-input-mt-6"
                                            value={props?.myProduct?.uniq === props?.itemId ? props?.commentItem : props?.iteeee?.remarque}
                                        />
                                    </div>
                                    <Button
                                        className="modal-compose-button-text ds-w-100 ds-mt-16 ds-hp-48"
                                        text={langue.modify + ` - ${renderModifyPrice()} €`}
                                        onClick={() => props.onModifyItemBasket(props?.iteeee?.id, props?.iteeee?.quantity)}
                                        isLoading={props.isLoading}
                                    />
                                </div>
                            </div>
                        )
                    }
                } else {
                    if (((data?.fromMenu && !data?.fromBasket && data?.product !== null) || (data?.fromAddNote && !data?.fromBasket && data?.product !== null))) {
                        list.push(
                            <div className="ds-flex-col ds-w-100">
                                <div className='ds-flex ds-align-center'>
                                    <Icon
                                        icon='arrow-left'
                                        size={"24px"}
                                        className='ds-mb-13 ds-mr-7'
                                        onClick={() => props?.setShowOptions(false)}
                                    />
                                    <span className="ds-pb-16 modal-compose-composer-formule-text ds-text-primary100">{langue.compose_formula}</span>
                                </div>
                                <span className="modal-compose-title-text ds-text-primary100 ds-pb-10" >{data?.product[0]?.name}</span>
                                <Tab
                                    textClassName="modal-compose-tab-title-text"
                                    selectedTextClassName="modal-compose-tab-title-text-selected"
                                    list={listOptions}
                                    isTopTab={false}
                                    onClick={(i: number) => {
                                        props.onSelectOptionTab(i); props?.setSelectedElementt(i)
                                    }}
                                    selectedIndex={props.selectedElement}
                                />
                                <div className="horizontal-line ds-w-100"></div>
                                <div className="ds-flex-wrap ds-flex">
                                    {data?.product[0]?.menu_categories?.filter((el: IFilterMenu, index: number) => {
                                        if (el?.name === props.supplement) {
                                            props?.objectCategry?.forEach((category: ISupplementCategory, indice: number) => {
                                                if (indice === index) {
                                                    if (category.category_name && category?.category_name.includes('(opt)')) {
                                                        listSupp.push(
                                                            <Checkbox
                                                                label={langue.without_additional_sauce}
                                                                labelClassName="ds-text-primary100"
                                                                containerClassName="supplement-checkbox"
                                                                checked={category?.isChecked}
                                                                onChange={() => props.onChangeWithoutSauce(indice)}
                                                            />
                                                        )
                                                    }
                                                    listSupp.push(
                                                        <span className="ds-text-size-14 ds-text-line-17 ds-text-weight500 ds-text-primary70 ds-pt-12 ds-pb-7">{langue.selected_sauces + ` ${category.index} / ${el?.products?.length}`}</span>
                                                    )
                                                }
                                            })
                                            if (el?.products) {
                                                (el?.products.forEach((item: IMenuProduct, i: number) => {
                                                    listCard.push(<Card
                                                        key={i}
                                                        type={CardType.itemCard}
                                                        cardClassName={`ds-mr-4 ds-ml-4 ds-mb-8 ${(props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.category[0] === item?.product?.uniq })) && (props?.supplementList.some((sup: any) => { return sup?.uid === item?.product?.uniq })) ? 'shadoww-lue': ''}`}
                                                        nameProduct={item?.product?.name}
                                                        nameProductClassName="ds-text-size-14 ds-text-line-17 text-ellipsis"
                                                        isChecked={(props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.category[0] === item?.product?.uniq })) && (props?.supplementList.some((sup: any) => { return sup?.uid === item?.product?.uniq }))}
                                                        onChange={() => props.onChooseSupplement(el, item?.product, index, i)}
                                                        disabled={props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.isChecked === true })}
                                                        image={item?.product?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")}
                                                    />)
                                                })
                                                )
                                            }
                                        }
                                    })
                                    }
                                </div>
                            </div>
                        )
                    } else {
                        list.push(
                            <div className="ds-flex-col ds-w-100">
                                <div className='ds-flex ds-align-center'>
                                    <Icon
                                        icon='arrow-left'
                                        size={"24px"}
                                        className='ds-mb-20 ds-mr-7'
                                        onClick={() => props?.setShowOptions(false)}
                                    />
                                    <span className="ds-pb-16 modal-compose-composer-formule-text ds-text-primary100">{langue.compose_formula}</span>
                                </div>
                                <span className="modal-compose-title-text ds-text-primary100 ds-pb-8 ds-px-5" >{data?.product[0]?.name}</span>
                                <Tab
                                    textClassName="modal-compose-tab-title-text"
                                    selectedTextClassName="modal-compose-tab-title-text-selected"
                                    list={listOptions}
                                    isTopTab={false}
                                    onClick={(i: number) => {
                                        props.onSelectOptionTab(i); props?.setSelectedElementt(i)
                                    }}
                                    selectedIndex={props.selectedElement}
                                />
                                <div className="horizontal-line ds-w-100"></div>
                                <div className="ds-flex-wrap ds-flex">
                                    {data?.item?.product?.menu_categories?.filter((el: IFilterMenu, index: number) => {
                                        if (el?.name === props.supplement) {
                                            props?.objectCategry?.forEach((category: ISupplementCategory, indice: number) => {
                                                if (indice === index) {
                                                    if (category.category_name && category?.category_name.includes('(opt)')) {
                                                        listSupp.push(
                                                            <Checkbox
                                                                label={langue.without_additional_sauce}
                                                                labelClassName="ds-text-primary100"
                                                                containerClassName="supplement-checkbox"
                                                                checked={category?.isChecked}
                                                                onChange={() => props.onChangeWithoutSauce(indice)}
                                                            />
                                                        )
                                                    }
                                                    listSupp.push(
                                                        <span className="ds-text-size-14 ds-text-line-17 ds-text-weight500 ds-text-primary70 ds-pt-12 ds-pb-7">{langue.selected_sauces + ` ${category.index} / ${el?.products?.length}`}</span>
                                                    )
                                                }
                                            })
                                            if (el?.products) {
                                                (el?.products.forEach((item: IMenuProduct, i: number) => {
                                                    listCard.push(<Card
                                                        key={i}
                                                        type={CardType.itemCard}
                                                        cardClassName={`ds-mr-24 ds-mb-16 ${(props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.category[0] === item?.product?.uniq })) && (props?.supplementList.some((sup: any) => { return sup?.uid === item?.product?.uniq })) ? 'shadoww-lue': ''}`}
                                                        nameProduct={item?.product?.name}
                                                        nameProductClassName="ds-text-size-14 ds-text-line-17 text-ellipsis"
                                                        isChecked={(props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.category[0] === item?.product?.uniq })) && (props?.supplementList.some((sup: any) => { return sup?.uid === item?.product?.uniq }))}
                                                        onChange={() => props.onChooseSupplement(el, item?.product, index, i)}
                                                        disabled={props?.objectCategry?.some((ee: any) => { return ee.category_name === el.name && ee.isChecked === true })}
                                                        image={item?.product?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")}
                                                    />)
                                                })
                                                )
                                            }
                                        }
                                    })
                                    }
                                </div>
                            </div>
                        )
                    }
                }
            } else {
                if (data?.fromMenu || data?.fromAddNote) {
                    list.push(
                        <div className="ds-w-100">
                            <div className={className.join(' ')}>
                                <div className='ds-w-100 ds-hp-200' style={{
                                    backgroundImage: `url(${data?.product[0]?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center'
                                }}>
                                </div>
                                <span className={productNameClassName.join(' ')}>{data?.product[0]?.name}</span>
                                <div className="ds-w-100 ds-flex ds-align-center">
                                    <span className={priceProductClassName.join(' ')}>
                                        {data?.product[0]?.attribute?.promo !== null ? `${CommonFunction.setFormatNumber(data?.product[0]?.attribute?.promo)
                                            } €` : `${CommonFunction.setFormatNumber(data?.product[0]?.attribute?.price)
                                            } €`}
                                        {data?.product[0]?.weight && <span>{'/kg'}</span>}
                                    </span>
                                    {data?.product[0]?.attribute?.promo !== null &&
                                        <>
                                            <div className='dot ds-mr-4'>
                                            </div>
                                            <span className={discountPriceProductClassName.join(' ')}>
                                                {`${CommonFunction.setFormatNumber(data?.product[0]?.attribute?.price)} €`}
                                            </span>
                                        </>
                                    }
                                </div>
                                <span className={productDescriptionClassName.join(' ')}>{data?.product[0]?.description}</span>
                                <Button
                                    className="modal-compose-button-text ds-text-primary100 ds-w-100 ds-mt-16 ds-hp-40"
                                    text={langue.compose_formula}
                                    type={Type.tertiary}
                                    onClick={() => props?.setShowOptions(true)}
                                />
                            </div>
                        </div>
                    )
                } else {
                    list.push(
                        <div className="ds-w-100">
                            <div className={className.join(' ')}>
                                <div className='ds-w-100 ds-hp-200' style={{
                                    backgroundImage: `url(${data?.item?.product?.media?.small_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")})`, backgroundSize: 'cover', backgroundPosition: 'center'
                                }}>
                                </div>
                                <span className={productNameClassName.join(' ')}>{data?.item?.product?.name}</span>
                                <div className="ds-w-100 ds-flex ds-align-center">
                                    <span className={priceProductClassName.join(' ')}>
                                        {data?.item?.product?.attribute?.promo !== null ? `${CommonFunction.setFormatNumber(data?.item?.product?.attribute?.promo)
                                            } €` : `${CommonFunction.setFormatNumber(data?.item?.product?.attribute?.price)
                                            } €`}
                                        {data?.item?.product?.weight && <span>{'/kg'}</span>}
                                    </span>
                                    {data?.item?.product?.attribute?.promo !== null &&
                                        <>
                                            <div className='dot ds-mr-4'>
                                            </div>
                                            <span className={discountPriceProductClassName.join(' ')}>
                                                {`${CommonFunction.setFormatNumber(data?.item?.product?.attribute?.price)} €`}
                                            </span>
                                        </>
                                    }
                                </div>
                                <span className={productDescriptionClassName.join(' ')}>{data?.item?.product?.description}</span>
                                <Button
                                    className="modal-compose-button-text ds-text-primary100 ds-w-100 ds-mt-16 ds-hp-40"
                                    text={langue.compose_formula}
                                    type={Type.tertiary}
                                    onClick={() => props?.setShowOptions(true)}
                                />
                            </div>
                        </div>
                    )
                }

            }
        } else {
            return null
        }
        if (props?.showOptions) {
            return (
                <div className="ds-w-100 ds-h-100">{list}
                    <div className="ds-flex-wrap ds-flex ds-overflow-x max-list-height ds-w-100">
                        <div className="ds-mt-5 ds-mb-5 ds-flex ds-justify-between ds-w-100">
                            {listSupp}
                        </div>
                        {listCard}
                    </div>
                </div>
            )
        } else {
            return list
        }
    }

    return (
        <div className={classNameModal.join(' ')}>
            {window.innerWidth > 1024 ?

                props?.isOptionList ?
                    <div className='ds-px-32 ds-flex ds-w-100 '>
                        <>{renderOptionsList()}</>
                    </div> :
                    <div className='ds-p-32 ds-flex ds-w-100 ds-h-100 '>
                        <>{renderProductDetails()}</>
                        <>{renderProductOptions()}</>
                    </div>
                :
                props?.isOptionList ?
                    <div className='ds-px-32 ds-flex ds-w-100 '>
                        <>{renderOptionsList()}</>
                    </div> :
                    <div className='ds-p-16 ds-w-100 compose-form-class'>{renderResponsiveComposerFormule()}</div>
            }
        </div>

    )
}
export default TemplateModalComposeFormuleOrSandwich