import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage'
import ReduxLogger from "redux-logger"
import { AlertReducer, AuthReducer, BasketReducer, CommercantReducers, ModalReducer, PaiementReducer, ScreenReducer, SettingReducer, SideBarReducer } from '../Reducers';

const reducers = combineReducers({
  setting: SettingReducer,
  auth: AuthReducer,
  screen: ScreenReducer,
  sideBar: SideBarReducer,
  modal: ModalReducer,
  basket: BasketReducer,
  paiement: PaiementReducer,
  commercant: CommercantReducers,
  alert: AlertReducer,
}); 

const persistConfig = {
  key: 'digistore',
  storage: storage,
  whitelist: ['setting', 'auth', 'screen', 'modal', 'basket', 'paiement', 'commercant', 'alert'],
  blacklist: []
};

const _persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER
      ],
    },
  }).concat(ReduxLogger),
});
const persistor = persistStore(store)
export {
  store, persistor
}
