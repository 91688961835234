import React from 'react';
import { DEFAULTAVATARPROPS } from '../../DefaultProps/Atoms';
import { IAtomAvatar, Size } from '../../Interfaces/Atoms/IAtomAvatar/IAtomAvatar';
import { Icon, Text } from '..';

function AtomAvatar(props: IAtomAvatar): JSX.Element {
    const className: string[] = [];
    const containerClassName = ['ds-flex']
    const textClassName: string[] = []
    const statusClassName: string[] = []
    const nameTextClassName: string[] = ['ds-text-primary100 ds-text-weight700']
    const emailTextClassName: string[] = ['ds-text-primary40 ds-text-14']

    if (props.textBsPrefix) {
        textClassName.push(props.textBsPrefix)
    } else {
        textClassName.push("ds-text-avatar ds-text-weight-700 ds-borad-50 ds-bg-primary10 ds-text-secondary")
        if(props.size) {
            textClassName.push("ds-hp-" + props.size + " ds-wp-" + props.size)
        }
    }

    if (props.bsPrefix) {
        className.push(props.bsPrefix)
    } else {
        className.push("ds-hp-" + props.size + " ds-wp-" + props.size + " ds-borad-50")
    }

    if (props.containerBsPrefix) {
        containerClassName.push(props.containerBsPrefix)
    } else if (props.isNameText) {
        containerClassName.push(" ds-relative")
    } else {
        containerClassName.push('ds-container-avatar ds-relative')
    }

    if (props.statusBsPrefix) {
        statusClassName.push(props.statusBsPrefix)
    } else {
        statusClassName.push("ds-status-avatar ds-absolute ds-hp-7 ds-wp-7 ds-borad-8")
    }

    switch (props.size) {
        case Size.small:
            textClassName.push('ds-text-size-7 ds-text-line-7 ')
            break;
        case Size.medium:
            textClassName.push('ds-text-size-14 ds-text-line-14 ')
            statusClassName.push("ds-mb-1 ds-mr-7")
            emailTextClassName.push('ds-text-size-10 ds-text-line-12 ')
            nameTextClassName.push('ds-text-size-16 ds-text-line-19 ')
            break;
        case Size.large:
            textClassName.push('ds-text-size-20 ds-text-line-20')
            statusClassName.push("ds-mb-1 ds-mr-14")
            emailTextClassName.push('ds-text-size-14 ds-text-line-16 ')
            nameTextClassName.push('ds-text-size-24 ds-text-line-28 ')
            break;
        case Size.xlarge:
            textClassName.push('ds-text-size-24 ds-text-line-24 ')
            statusClassName.push("ds-mb-4 ds-mr-14")
            emailTextClassName.push('ds-text-size-16 ds-text-line-19 ')
            nameTextClassName.push('ds-text-size-28 ds-text-line-33 ')
            break;
        case Size.xxlarge:
            textClassName.push('ds-text-size-36 ds-text-line-43 ')
            statusClassName.push("ds-mb-4 ds-mr-14")
            emailTextClassName.push('ds-text-size-20 ds-text-line-24 ')
            nameTextClassName.push('ds-text-size-36 ds-text-line-43 ')
            break;
        case Size.customTable:
            textClassName.push('ds-text-size-14 ds-text-line-14 ')
            statusClassName.push("ds-mb-4 ds-mr-14")
            break;
        default:
            textClassName.push('ds-text-size-34 ds-text-line-34 ')
            statusClassName.push("ds-mb-7 ds-mr-14")
            emailTextClassName.push('ds-text-size-10 ds-text-line-12 ')
            nameTextClassName.push('ds-text-size-16 ds-text-line-19 ')
            break;
    }

    if (props.isActive) {
        statusClassName.push("ds-bg-success100")
    } else {
        statusClassName.push("ds-bg-error100")
    }

    const renderContent = () => {
        const list = []
        if (props.status && props.isImage) {
            list.push(<div className={statusClassName.join(' ')}></div>)
        }
        if (props.isImage) {
            list.push(<img src={props.src} alt={props.alt} style={props.style} className={className.join(' ')} id='avatar' />)
            if (props.isNameText) {
                list.push(renderUserStatus())
            }
        } else {
            list.push(<span id='avatar' className={textClassName.join(' ')}>{props.user.firstname?.charAt(0)}{props.user.lastname?.charAt(0)}</span>)
            if (props.isNameText) {
                list.push(renderUserStatus())
            }
        }
        return <>{list}</>
    }

    const renderUserStatus = () => {
        const list = []
        list.push(
            <div className='ds-flex ds-ml-16 ds-center'>
                <div className='ds-flex-col ds-mr-16'>
                    <span className={nameTextClassName.join(' ')}>{props.user.firstname}  {props.user.lastname}</span>
                    <span className={emailTextClassName.join(' ')}>{props.user.email}</span>
                </div>
                {props.chevronIcon && <Icon
                    icon='chevron-down'
                    className='ds-text-neutral40'
                    size={"20px"}
                />}
            </div>
        )
        return (<>{list}</>)
    }

    if (props.className) className.push(props.className)
    if (props.textClassName) textClassName.push(props.textClassName)
    if (props.containerClassName) containerClassName.push(props.containerClassName)
    if (props.statusClassName) statusClassName.push(props.statusClassName)

    return <div className={containerClassName.join(' ')} >
        {renderContent()}
    </div>
}

AtomAvatar.defaultProps = {
    ...DEFAULTAVATARPROPS
}
export default AtomAvatar
