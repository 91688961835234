import React, { useContext, useEffect, useState } from 'react'
import { TemplatePublicCommercants } from '../../Template'
import { CommercantAPI } from '../../Api/Commercants/Commercants'
import { IPublicSitesPage, RootState } from '../../Interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { openAlert, setCategoryCommercant, setCommercant, setCoords, setProductCommercant } from '../../Redux/Reducers'
import { useNavigate } from 'react-router-dom'
import { Data } from '../../Common'
import { useUpdateEffect } from '../../CustomHooks'
import { LangContext } from '../../Lang'

const PagePublicCommercants = (props: IPublicSitesPage): JSX.Element => {
  const { publicCommercants } = useSelector((state: RootState) => state?.commercant);
  const { showCarousel } = useSelector((state: RootState) => state?.auth);
  const { lang } = useSelector((state: RootState) => state?.setting);
  const { delievryMode, deliveryDate } = useSelector((state: RootState) => state.paiement);
  const [posLat, setPosLat] = useState<number>(0);
  const [posLng, setPosLng] = useState<number>(0);
  const [sites, setSites] = useState<any>([]);
  const [carousselIndex, setCarousselIndex] = useState<number>(20);
  const [filtredSites, setFiltredSites] = useState<any>(publicCommercants)
  const commercantApi = new CommercantAPI()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [showCarouselCard, setShowCarouselCard] = useState(false)
  const [shopCoords, setShopCoords] = useState<any>();
  const carouselRef = React.useRef<any>(null);
  const containerRef = React.useRef<any>(null);
  const langue = useContext(LangContext)
  let firstClientX: any, clientX: any;

  const preventTouch = (e: any) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  };

  const touchStart = (e: any) => {
    firstClientX = e.touches[0].clientX;
  };


  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (containerRef?.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
    };
  });

  useEffect(() => {
    if (posLat !== 0 && posLng !== 0) {
      getSitesWithDistance()
    }
  }, [posLat, posLng])

  useEffect(() => {
    const navbar = document.querySelector('.sticky-nav');
    if (navbar) {
      navbar.classList.remove('sticky-nav');
    }
  }, [])

  useUpdateEffect(() => {
    onFilterSiteCategory(selectedTab)
    setCarousselIndex(20)
  }, [selectedTab])
  useEffect(() => {
    if (navigator.geolocation && !posLat && !posLng) {
      navigator.geolocation.getCurrentPosition(getPosition,
        (error) => {
          setPosLng(4.381218)
          setPosLat(50.752639)
          dispatch(setCoords({ lng: 4.381218, lat: 50.752639 }))
          console.error('Error getting user location:', error);
        });
    }
  })

  const getPosition = (position: any) => {
    const { latitude, longitude } = position.coords;
    setPosLng(longitude);
    setPosLat(latitude)
    dispatch(setCoords({ lng: longitude, lat: latitude }))
  }
  const getSitesWithIds = async (elements: any) => {
    return await commercantApi.getPublicCommercantsItems({
      site: { siteIds: elements },
      lat: posLat,
      lng: posLng
    },)
  }
  const getSitesWithDistance = async () => {
    setShowCarouselCard(true)
    const response = await commercantApi.getPublicCommercantsV2({
      lat: posLat,
      lng: posLng,
      lang: lang
    })
    if (response.status === 200) {
      setSites(response.data)
      const listToSave = await getFirstElements(response.data)
      setFiltredSites(listToSave)
    }
  }
  const getFirstElements = async (data: any) => {
    const tenElements = data.slice(0, 20).map((el: any) => el.id);
    const result = await getSitesWithIds(tenElements);

    return result?.data?.concat(data.slice(20))
  }
  const onFilterSiteCategory = async (tabSelected: number) => {

    if (tabSelected !== 0) {
      const filteredArray = Object?.values(sites)?.filter((item: any) => { return item?.vocation?.name === Data.getTabList()[tabSelected]?.label });

      if (filteredArray.length > 0) {
        const listToSave = await getFirstElements(filteredArray)
        setFiltredSites(listToSave)
      }
    } else {
      const listToSave = await getFirstElements(sites)
      setFiltredSites(listToSave?.slice(0, 99))
    }
    if (carouselRef.current) {
      carouselRef?.current?.goToSlide(0);
    }
  }
  const getCarrouselItems = async () => {
    let newCarousselIndex = carousselIndex + 10
    if (newCarousselIndex < sites.length + 10) {
      if (newCarousselIndex <= sites.length) {
        let tenElementsFiltered = filtredSites.slice(carousselIndex, newCarousselIndex).map((el: any) => el.id);
        await getElements(tenElementsFiltered, newCarousselIndex)
      } else {
        let tenElementsFiltered = filtredSites.slice(carousselIndex).map((el: any) => el.id);
        await getElements(tenElementsFiltered, newCarousselIndex)
      }

    }
  }
  const getElements = async (elements: any, newCarousselIndex: number) => {
    const newTenElementsFiltered = await getSitesWithIds(elements)
    setFiltredSites(filtredSites.slice(0, carousselIndex).concat(newTenElementsFiltered?.data).concat(filtredSites.slice(newCarousselIndex)))
    setCarousselIndex(newCarousselIndex)
  }
  const getCatalogueCommercant = async (suid: string) => {
    const response = await commercantApi.getCommercantCatalogue({
      suid: suid,
      lang: lang,
      collected_at: deliveryDate,
      delivery_method: delievryMode ? 1 : 3
    })
    if (response.status === 200) {
      const aux: any = []
      Object.values(response?.data?.categories).forEach((el: any) => {
        aux.push(el?.products)
      })
      dispatch(setProductCommercant({ productCommercant: aux }))
      let arr: string[] = []
      if (response?.data?.categories) {
        Object.values(response?.data?.categories).forEach((el: any) => {
          arr.push(el?.name)
        })
        const formattedData: { index: number, label: string }[] = arr.map((label: string, index: number) => ({ index, label }));
        dispatch(setCategoryCommercant({ categoryCommercant: formattedData }))
      }
    }
  }
  const onClickCardBtn = async (id: string) => {
    if (id) {
      const response = await commercantApi.getCommercant({
        suid: id,
        lang: lang
      })
      if (response.status === 200) {
        if (response.data.active) {
          dispatch(setCommercant({ commercant: response.data }))
          dispatch(setCategoryCommercant({ categoryCommercant: [] }))
          dispatch(setProductCommercant({ productCommercant: [] }))
          navigate(`/menuCommercant/${id}`)
          getCatalogueCommercant(id)
        } else {
          dispatch(openAlert({ type: 'info', message: langue.opening_soon }))
        }
      }
    }
  }

  return (
    <TemplatePublicCommercants
      sites={sites}
      onClickCardBtn={onClickCardBtn}
      onFilterSiteCategory={onFilterSiteCategory}
      filtredSites={filtredSites}
      showCarousel={showCarousel}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      showCarouselCard={showCarouselCard}
      shopCoords={shopCoords}
      setShopCoords={setShopCoords}
      getCarrouselItems={getCarrouselItems}
      carouselRef={carouselRef}
      containerRef={containerRef}
    />
  )
}

export default PagePublicCommercants
