import { IFilterSites, ITemplatePublicCommercants } from '../../Interfaces'
import { Tab } from '../../DesignSystem'
import { Card, Map } from '../../DesignSystem/Organisms'
import { CardType } from '../../DesignSystem/Interfaces'
import { Data } from '../../Common'
import { IlistElement } from '../../DesignSystem/Interfaces/Molecule/IMoleculeTab/IMoleculeTab'
import { useContext, useEffect, useRef, useState } from 'react';
import { LangContext } from '../../Lang';
import { useWindowSize } from '../../CustomHooks';

const TemplatePublicCommercants = (props: ITemplatePublicCommercants): JSX.Element => {
    const langue = useContext(LangContext)
    const { width } = useWindowSize()

    const containerRef = useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startScrollLeft, setStartScrollLeft] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const scrollStep = 346; // Adjust the scroll step as needed

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                setScrollLeft(containerRef.current.scrollLeft);
            }
        };

        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.clientWidth);
            }
        };

        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
        }
        window.addEventListener('resize', handleResize);

        handleResize(); // Initialize container width

        // Attach events to window to capture outside the container
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUpOrTouchEnd);
        window.addEventListener('touchend', handleMouseUpOrTouchEnd);
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('scroll', handleScroll);
            }
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUpOrTouchEnd);
            window.removeEventListener('touchend', handleMouseUpOrTouchEnd);
        };
    }, []);

    useEffect(() => {
        handleResetClick()
    }, [props.filtredSites.length])

    const renderNextButton = () => {
        if (containerRef.current) {
            return (scrollLeft + containerWidth) <= containerRef.current.scrollWidth
        }
        return true
    }

    const handleTouchStart = (e: any) => {
        const touch = e.touches[0];
        setDragging(true);
        if (containerRef.current) {
            setStartX(touch.pageX - containerRef.current.offsetLeft);
            setStartScrollLeft(containerRef.current.scrollLeft);
        }
    };

    const handleTouchMove = (e: any) => {
        if (!dragging) return;
        const touch = e.touches[0];
        if (containerRef.current) {
            const x = touch.pageX - containerRef.current.offsetLeft;
            const walk = (x - startX) * 0.7;
            containerRef.current.scrollLeft = startScrollLeft - walk;
        }
    };

    const handleMouseDown = (e: any) => {
        setDragging(true);
        if (containerRef.current) {
            setStartX(e.pageX - containerRef.current.offsetLeft);
        }
    };

    const handleMouseMove = (e: any) => {
        if (!dragging) return;
        if (containerRef.current) {
            const x = e.pageX - containerRef.current.offsetLeft;
            const walk = (x - startX) * 0.2;
            containerRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    const handleMouseUpOrTouchEnd = () => {
        setDragging(false);
        if (containerRef.current) {
            const newScrollLeft = containerRef.current.scrollLeft;
            const remainder = newScrollLeft % scrollStep;
            if (remainder !== 0) {
                if (remainder < scrollStep / 2) {
                    containerRef.current.scrollLeft -= remainder;
                } else {
                    containerRef.current.scrollLeft += (scrollStep - remainder);
                }
            }
            props.getCarrouselItems()

            setScrollLeft(containerRef.current.scrollLeft);
        }
    };

    const handlePreviousClick = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= scrollStep;
            setScrollLeft(containerRef.current.scrollLeft);
        };
    }

    const handleNextClick = () => {
        props.getCarrouselItems()
        if (containerRef.current) {
            containerRef.current.scrollLeft += scrollStep;
            setScrollLeft(containerRef.current.scrollLeft);
        };
    }

    const handleResetClick = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft = 0;
        };
    }

    const renderCarouselCardList = () => {
        if (props.filtredSites)
            return Object?.values(props.filtredSites)?.map((el: IFilterSites) => <div key={el?.suid}>
                <Card
                    cardClassName={`ds-mr-24 ds-center ${el?.lat == props?.shopCoords?.lat && el?.lng == props?.shopCoords?.lng && 'shop-card-hovered'}`}
                    type={CardType.shopCard}
                    nameClient={el?.name}
                    addressClient={`${el?.vocation?.name}, ${el?.city}`}
                    distance={`${(el?.distance / 1000).toFixed(2)} km`}
                    distanceClassName='text-align-right'
                    image={el?.background?.medium_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")}
                    btnText={el?.active ? langue.order.toUpperCase() : langue.open_soon.toUpperCase()}
                    onClickCardBtn={() => props.onClickCardBtn(el.suid)}
                    onClick={() => props.setShopCoords({ lat: Number(el?.lat), lng: Number(el?.lng) })}
                />
            </div>
            )
    }



    const renderTemplatePublicCommercants = () => {
        const list: JSX.Element[] = []
        list.push(
            <>
                <div className='shadoww ds-flex-col'>
                    <div className={`${width >= 768 ? ' ds-mb-24 ds-px-40' : ' ds-p16-0 ds-mb-7'} ds-flex-col ds-w-100`}>
                        <Tab
                            list={Data.getTabList()}
                            isTopTab={false}
                            tabClassName='ds-w-100'
                            onClick={(item: IlistElement) => { props?.setSelectedTab(item) }}
                            selectedIndex={props?.selectedTab}
                        />
                        {width >= 768 && <div className='horizontal-line'></div>}
                    </div>
                </div>
                <div className='ds-relative map-style '>
                    <Map sites={props.sites} setShopCoords={props.setShopCoords} shopCoords={props.shopCoords} markers={props.sites} />
                </div>
                {props.showCarousel && <div className='card-position ds-px-40 ds-w-100' style={{ overflow: 'hidden' }}>
                    <div
                        role='none'
                        ref={containerRef}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleMouseUpOrTouchEnd}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUpOrTouchEnd}
                        style={{ WebkitOverflowScrolling: 'touch', userSelect: 'none' }}
                        className='ds-w-100 ds-flex overflow-auto hide-scrollbar'>
                        {renderCarouselCardList()}
                    </div>
                    {
                        width > 992 && <div style={{ marginTop: '10px' }}>
                            {scrollLeft > 0 && <button onClick={handlePreviousClick} className='my-carousel-arrow my-carousel-arrow-left'></button>}
                            {renderNextButton() && <button onClick={handleNextClick} className='my-carousel-arrow my-carousel-arrow-right'></button>}
                        </div>
                    }
                </div>}
            </>
        )
        return <>{list}</>
    }
    return (
        <div ref={props.containerRef}>
            {renderTemplatePublicCommercants()}
        </div>
    )
}

export default TemplatePublicCommercants
