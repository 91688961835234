import React, { useEffect, useState } from 'react';
import { IMap, IOrganismMap } from '../../Interfaces/Organisms/IOrganismMap/IOrganismMap';
import { DirectionsRenderer, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Config, Data } from '../../../Common';
import blueMarker from '../../../Assets/images/blue.svg'
import grayMarker from '../../../Assets/images/gray.svg'
import marker from '../../../Assets/images/marker.svg'
import marker_dest from '../../../Assets/images/marker_des.svg'
import { colors } from '../../Constant';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Interfaces';

const OrganismMap = (props: IOrganismMap): JSX.Element => {
    const { lat, lng } = useSelector((state: RootState) => state?.commercant)
    const containerStyle = {
        width: window.innerWidth,
        height: window.innerHeight
    };
    const center = {
        lat: lat,
        lng: lng
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Config.getInstance().GOOGLE_MAP_API,
        libraries: ['geometry', 'drawing'],
    });

    const onMapLoad = (map: IMap | any) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    map.setCenter({ lat: latitude, lng: longitude });
                    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=37.7749,-122.4194&key=AIzaSyC_UVeXk4Dy29hLF-EJ9mH7OLMeWBU8Qro`);
                    const ffff = await response.json()
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const [directionsResponse, setDirectionsResponse] = useState<any>(null)

    const calculateRoute = async () => {
        if (props.shopCoords) {
            const directionsService = new google.maps.DirectionsService()
            const results: any = await directionsService.route({
                origin: { lat: 48.8, lng: 2.2 },
                destination: props.shopCoords,
                travelMode: google.maps.TravelMode.DRIVING,
            }
            )

            setDirectionsResponse(results)
        }
    }

    useEffect(() => {
        calculateRoute()
    }, [props.shopCoords]);
    const originMarkerIcon = {
        url: ''
    };
    return (
        <div className='ds-w-100' >
            <div className='ds-w-100 '>
                <GoogleMap

                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={12}
                    options={{
                        styles: Data.getMapStyle(),
                        draggable: true,
                        fullscreenControl: false,
                        streetViewControl: false,
                        gestureHandling: "greedy"
                    }}
                    onLoad={onMapLoad}

                >
                    {isLoaded && props.markers && Object.keys(props?.markers)?.length !== 0 && Object?.values(props?.markers)?.map((marker: any, i: number) => (
                        <Marker
                            icon={marker.active ? blueMarker : grayMarker}
                            key={i}
                            visible={true}
                            position={{ lat: Number(marker?.lat), lng: Number(marker?.lng) }}
                            onClick={() => { props.setShopCoords({ lat: Number(marker?.lat), lng: Number(marker?.lng) }) }}
                        />
                    ))}

                    {directionsResponse && (
                        <>
                            <DirectionsRenderer directions={directionsResponse} options={{
                                polylineOptions: {
                                    strokeColor: colors.secondary100,

                                },
                                markerOptions: {
                                    icon: originMarkerIcon,
                                }
                            }} />
                            <Marker
                                position={directionsResponse.routes[0].legs[0].start_location}
                                icon={marker}
                            />
                            <Marker
                                position={directionsResponse.routes[0].legs[0].end_location}
                                icon={marker_dest}
                            />
                        </>
                    )}
                </GoogleMap>
            </div>
        </div>
    )
}
export default OrganismMap
