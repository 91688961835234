import { Tab } from "../../DesignSystem"
import { CardSize, CardType } from "../../DesignSystem/Interfaces"
import { ITemplateModalBonPlan } from "../../Interfaces/Modal"
import { IFiltredProduct } from "../../Interfaces"
import { Card } from "../../DesignSystem/Organisms"
import { CommonFunction } from "../../Common"


const TemplateModalBonPlan = (props: ITemplateModalBonPlan): JSX.Element => {
    const renderBonPlan = () => {
        const list = []
        const listCard: JSX.Element[] = []
        list.push(
            <div className="ds-flex-col ds-align-center ds-w-100">
                <div className="ds-flex-col ds-center ds-w-100 ">
                    <span className="ds-mb-24 ds-text-size-36 ds-text-line-42 ds-text-weight700 ds-text-primary100">{`${props?.commercant?.company}`}</span>
                    <Tab
                        list={props?.tabList}
                        tabClassName="tab-bonplan"
                        isTopTab={false}
                        onClick={(i: any) => props.onSelectOptionTab(i)}
                        selectedIndex={props.selectedTab}
                    />
                    <div className="horizontal-line horizontal-bonPlan ds-w-100 "></div>
                </div>
                <div className="ds-flex-wrap ds-flex ds-mt-30 ">
                    {props.cardList?.map((item: IFiltredProduct, index: number) => {
                        listCard.push(<Card
                            key={index}
                            type={CardType.productCard}
                            cardClassName="ds-relative ds-mr-12 ds-ml-12 ds-mb-24 product-card-bon-plan"
                            image={item?.media?.medium_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")}
                            size={CardSize.small}
                            nameProduct={item?.name}
                            descriptionProduct={item?.description}
                            discountPriceProduct={item?.attribute?.promo !== null
                                ? `${CommonFunction.setFormatNumber(item?.attribute?.price)} €`
                                : null}
                            priceProduct={`${item?.attribute?.promo === null ? CommonFunction.setFormatNumber(item?.attribute?.price) :
                                CommonFunction.setFormatNumber(
                                    item?.attribute?.promo)
                                } €`}
                            discountOption={item?.discount_rule !== null && item?.discount_rule?.display_name}
                            availability={item?.attribute?.availability && item?.attribute?.availability?.label}
                            onClickCardBtn={() => { props.onAddProductToBasket(item?.uniq) }}
                            onClick={() => { props.onAddProductToBasket(item?.uniq) }}
                        />)
                    })
                    }
                </div>
            </div>
        )
        return (
            <div className="ds-w-100 ds-h-100 ds-flex-col">{list}
                <div className="ds-flex-wrap ds-flex ds-overflow-y ds-w-100 ds-flex-grow1">
                    {listCard}
                </div>
            </div>
        )
    }

    return (
        <div className="ds-flex ds-w-100 ds-h-100 ds-px-10 ds-py-32">
            <>{renderBonPlan()}</>
        </div>

    )
}
export default TemplateModalBonPlan