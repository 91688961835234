import React from 'react'
import { DEFAULTLABELPROPS } from '../../DefaultProps/Atoms';
import { IAtomLabel } from '../../Interfaces/Atoms/IAtomLabel/IAtomLabel';
import { Icon } from '..';

function AtomLabel(props: IAtomLabel): JSX.Element {
    const className: string[] = [];
    const circleClassName = ["ds-hp-8 ds-wp-8 ds-borad-50 ds-mr-4 "]
    const textClassName = ["ds-flex ds-text-weight400 ds-center"]
    const iconClassName = [" ds-ml-4"]

    if (props.bsPrefix) {
        className.push(props.bsPrefix)
    } else {
        className.push("ds-flex ds-center")
    }

    switch (props.typeLabel) {
        case 'In Progress':
            className.push('ds-text-primary70')
            circleClassName.push('ds-bg-primary10')
            break;
        case 'Completed':
            className.push('ds-text-success100')
            circleClassName.push('ds-bg-success100')
            break;
        case 'Pending':
            className.push('ds-text-neutral70')
            circleClassName.push('ds-bg-neutral70')
            break;
        case 'Approved':
            className.push('ds-text-warning100')
            circleClassName.push('ds-bg-warning100')
            break;
        case 'Rejected':
            className.push('ds-text-error100')
            circleClassName.push('ds-bg-error100')
            break;
    }

    if (!props.primary) {
        textClassName.push("ds-py-2 ds-px-8 ds-borad-50")
        switch (props.typeLabel) {
            case 'In Progress':
                textClassName.push('ds-bg-secondary10')
                break;
            case 'Completed':
                textClassName.push('ds-bg-success10')
                break;
            case 'Pending':
                textClassName.push('ds-bg-neutral10')
                break;
            case 'Approved':
                textClassName.push('ds-bg-warning10')
                break;
            case 'Rejected':
                textClassName.push('ds-bg-error10')
                break;
        }
    }
    if (props.size) {
        switch (props.size) {
            case 'small':
                textClassName.push('ds-hp-16  ds-text-line-12 ds-text-size-10')
                break;
            case 'normal':
                textClassName.push('ds-hp-24  ds-text-line-14 ds-text-size-12')
                break;
            default:
                break;
        }
    }

    const renderCircle = () => {
        if (props.primary) return <div className={circleClassName.join(' ')}></div>
    }

    const renderIcon = () => {
        if (props.icon && !props.primary) return <Icon icon={props.icon} size={"16px"} className={iconClassName.join(' ')} />
    }

    if (props.className) className.push(props.className)
    if (props.iconClassName) iconClassName.push(props.iconClassName)

    return (
        <div className={className.join(' ')}>
            {renderCircle()}
            <div className={textClassName.join(' ')}>
                {props.labelText}
                {renderIcon()}
            </div>

        </div>
    )
}

AtomLabel.defaultProps = {
    ...DEFAULTLABELPROPS
}

export default AtomLabel