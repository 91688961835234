import { type } from "os"

export enum Size {
    small = "16",
    medium = "32",
    large = "48",
    xlarge = "64",
    xxlarge = "80",
    customTable = "24"
}
interface IUser {
    firstname?: string | undefined
    lastname?: string | undefined
    email?: string | undefined
    phone?:string |undefined
}

interface IAtomAvatar {
    className?: string | undefined
    textClassName?: string | undefined
    containerClassName?: string | undefined
    statusClassName?: string | undefined

    style?: object
    alt?: string | undefined
    src?: string | undefined
    size?: Size

    user: IUser
    chevronIcon?:boolean
    isImage?: boolean
    status?: boolean
    isActive?: boolean

    bsPrefix?: string | undefined
    textBsPrefix?: string | undefined
    statusBsPrefix?: string | undefined
    containerBsPrefix?: string | undefined
    isNameText?: boolean
}

export type {
    IAtomAvatar,
    IUser
}