import React, { useState } from 'react'
import { IMoleculeAlerte } from '../../Interfaces';
import { useUpdateEffect } from '../../../CustomHooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Interfaces';
import { openAlert } from '../../../Redux/Reducers';

const MoleculeAlerte = (props: IMoleculeAlerte) => {
    const { type } = useSelector((state: RootState) => state?.alert);
    const [visible, setVisible] = useState<boolean>(true);
    const dispatch = useDispatch()
    const className = ['alert-notification ds-absolute ds-px-40 ds-w-100']
    if (props?.type) {
        className.push(props?.type)
    }

    useUpdateEffect(() => {
        if (type !== "") {
            setVisible(true)
        }
    }, [type])
    useUpdateEffect(() => {
        if (visible) {
            setTimeout(() => {
                setVisible(false)
                dispatch(openAlert({ type: '', message: `` }))
            }, 3000);
        }
    }, [visible])
    if (visible) {
        return (
            <div className={className.join(' ')}>
                <span className="message">{props?.message}</span>
            </div>
        )
    }

    return null
};
export default MoleculeAlerte