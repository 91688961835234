import React, { useContext, useState } from 'react'
import { Avatar, Icon, Text } from '../../Atoms'
import { DEFAULTINPUTFILEPROPS } from '../../DefaultProps/Atoms'
import { ETextInput, IAtomInputFile, SizeAvatar } from '../../Interfaces'
import { DEFAULTINPUTMESSAGEPROPS } from '../../DefaultProps/Molecules'
import { EInputAccept, IFile } from '../../Interfaces/Atoms/IAtomInputFile/IAtomInputFile'
import { InputLabel } from '../../Molecules'
import { LangContext } from '../../../Lang'

const AtomInputFile = (props: IAtomInputFile) => {
    const className: string[] = ['ds-p-8'];
    const containerClassName: string[] = ['ds-w-100'];
    const textClassName: string[] = ['ds-flex-grow1 ds-text-size-16 ds-text-line-19 ds-text-weight300 '];
    if (props.containerClassName) {
        containerClassName.push(props.containerClassName)
    }
    if (props.type) {
        className.push(props.type)
    }
    if (props.bsPrefix) {
        className.push(props.bsPrefix)
    } else {
        className.push('ds-pointer default-input-file ds-center ds-flex')
    }
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<IFile[]>([]);
    const langue = useContext(LangContext)
    const onChangeFile = (event: any) => {
        if (event.target.files.length) {
            let aux = [...files]
            if (props.type === "type1") {
                if (props.isMulti) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        if (verifExtension(event.target.files[i].name)) {
                            aux.push(event.target.files[i])
                        }
                    }
                } else {
                    if (verifExtension(event.target.files[0].name)) {
                        aux = [event.target.files[0]]
                    }
                }
            } else {
                if (verifExtension(event.target.files[0].name)) {
                    aux = [event.target.files[0]]
                }
            }
            setFiles(aux)
            if (props.onChange) {
                props.onChange(aux)
            }
            if (inputRef.current) {
                inputRef.current.value = ""
            }
        }
    }
    const verifExtension = (name: string) => {
        const list = name.split('.')
        if (list.length) {
            const extension = list[list.length - 1].toLowerCase()
            if (props.accept?.length) {
                const index = props.accept.indexOf(extension as EInputAccept)
                if (index !== -1) {
                    return true
                }
            } else {
                return true
            }
        }
        return false
    }
    const onClick = () => {
        if (!props.isDisabled) {
            inputRef?.current?.click()
        }
    }
    const onDeleteFile = (i: number) => {
        const aux = [...files]
        aux.splice(i, 1);
        setFiles(aux)
        if (props.onChange) {
            props.onChange(aux)
        }
    }

    if (props.className) className.push(props.className);
    if (props.isInvalid) className.push('invalid');
    if (props.isValid) className.push('valid');
    if (props.isDisabled) className.push('ds-op-04 ds-cursor-not-allowed ')
    if (props.textClassName) textClassName.push(props?.textClassName)
    const onClickFile = (i: number) => {
        if (props.onClickFile) {
            props.onClickFile(files[i])
        }
    }
    const renderType1 = (): JSX.Element => {
        return <div onClick={onClick} className={className.join(' ')}>
            <Icon icon='upload' size={'18px'} className={''} />
            <Text className='ds-ml-10' text={props.text} />
        </div>
    }
    const renderType2 = (): JSX.Element => {
        if (files.length) {
            const img = files[0]
            return <div className='ds-flex-col ds-pointer ' onClick={onClick}>
                <Avatar className='ds-align-center' isImage src={renderImg(img)} size={SizeAvatar.xxlarge} />
                <Text isSpan text={img?.name} className='ds-flex ds-mt-5 ds-align-center' />
            </div>
        }
        return <div className={className.join(' ')}>
            <div className='ds-flex-col ds-center ds-w-1000' onClick={onClick}>
                <Icon icon='plus-sm' size={'18px'} className={''} />
                <Text className='' text={props.text} />
            </div>
        </div>
    }
    const renderType3 = (): JSX.Element => {
        let filess = files[0]
        return (
            <>
                <div className='ds-flex ds-flex-grow1  ds-w-100'>
                    {files.length === 0 && <span
                        className={textClassName.join(' ')}>
                        {langue.no_file_chosen}
                    </span>}
                    {filess && <span
                        className='ds-flex-grow1 ds-text-size-16 ds-text-line-19 ds-text-weight400 ds-text-primary100 '>
                        {`${filess?.name}`}
                    </span>}
                    <div onClick={onClick} className={className.join(' ')}>
                        <span className={'ds-text-size-12 ds-text-line-14 ds-text-weight400 ds-text-primary70'}>{props.text}</span>
                    </div>
                </div>
                <div className='horizontal-line ds-w-100 ds-mt-16'></div>
            </>
        )
    }
    const returnInputFile = (): JSX.Element => {
        if (props.type === "type1") {
            return renderType1()
        } else if (props.type === "type2") {
            return renderType2()
        }
        return renderType3()
    }
    const renderImg = (el: any) => {
        const img = el
        const url = img.path ? img.path : URL.createObjectURL(img)
        return url
    }
    const verifIsImg = (el: any) => {
        const listType = ['png', 'gif', 'jpeg', 'svg', 'jpg']
        const list = el.name.split('.')
        if (list.length) {
            const extension = list[list.length - 1].toLowerCase()
            const index = listType.indexOf(extension)
            if (index !== -1) {
                return true
            }
        }
        return false
    }
    const renderFiles = (): JSX.Element => {
        const list = [<></>]
        if (props.type === "type1") {
            for (let i = 0; i < files.length; i++) {
                const el = files[i];
                list.push(<div className='upload-file ds-borad-8 ds-hp-30 ds-p-10 ds-flex ds-align-center ds-justify-between ds-w-100 ds-mt-10 ds-pointer' >
                    <div className='ds-flex-grow1 ds-align-center ds-flex' onClick={() => onClickFile(i)}>
                        {verifIsImg(el) ? <img className='ds-wp-30 ds-hp-30' src={renderImg(el)} />
                            : <Icon icon={`document-text`} size={'12px'} />
                        }
                        <Text key={i} isSpan className='ds-ml-10 ds-mr-20' text={el?.name} />
                    </div>
                    <Icon icon='trash' onClick={() => onDeleteFile(i)} />
                </div>)
            }
        }
        return <>{list}</>
    }

    const getAccept = () => {
        let value = ""
        if (props.accept?.length) {
            for (let index = 0; index < props.accept.length; index++) {
                const element = props.accept[index];
                if (index === 0) {
                    value = "." + element
                } else {
                    value = value + " , ." + element
                }
            }
        } else {
            value = "*/*"
        }
        return value
    }
    const renderInput = () => {
        return <input
            ref={inputRef}
            type="file"
            onChange={onChangeFile}
            id={props.id}
            accept={getAccept()}
            multiple={props.type === "type1" ? props.isMulti : false}
            style={{ height: "0.0001px", width: "0.00001px", visibility: "hidden" }}
        />
    }
    return (
        <div className={containerClassName.join(' ')}>
            <InputLabel text={props.label} type={ETextInput.label} className={props.labelClassName} />
            {renderInput()}
            {returnInputFile()}
            {(props.type === "type1" || props.type === "type2") && renderFiles()}
            {props.isValid && <InputLabel text={props.success} type={ETextInput.success} className={props.successClassName} />}
            {!props.isValid && props.isInvalid && <InputLabel text={props.error} type={ETextInput.error} className={props.errorClassName} />}
        </div>
    )

}

AtomInputFile.defaultProps = {
    ...DEFAULTINPUTFILEPROPS,
    ...DEFAULTINPUTMESSAGEPROPS
}

export default AtomInputFile