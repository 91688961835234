import { ISitesType, ITemplateDetailsCommandeOrRecharge } from "../../Interfaces"
import { Button, Icon } from "../../DesignSystem"
import { CommonFunction } from "../../Common"
import { Type } from "../../DesignSystem/Interfaces"
import { useContext } from "react"
import { LangContext } from "../../Lang"

const TemplateDetailsCommandeOrRecharge = (props: ITemplateDetailsCommandeOrRecharge): JSX.Element => {
    const langue = useContext(LangContext)
    const verifyMinutes = (date: string) => {
        const now: any = new Date();
        const target: any = new Date(date);
        let differenceInMilliseconds = target - now;
        let timeRemaining: any;
        if (differenceInMilliseconds < 60 * 60 * 1000) {
            timeRemaining = `${Math.ceil((differenceInMilliseconds / (1000 * 60)))} ` + langue.minutes;
        } else {
            timeRemaining = langue.in + ` ${Math.floor((differenceInMilliseconds / (1000 * 60 * 60)))} ` + langue.hours;
        }
        if (now >= target) {
            timeRemaining = langue.Order_ready
        }
        return timeRemaining;
    }
    return (
        <div className="ds-flex-col ds-w-100">
            <img alt=""
                src={props?.data?.data?.site_details?.background?.medium_picture?.replace("https://stage.cdn.digicommerce.be", "https://cdn.digicommerce.be")} height={94} width={296} className="ds-mb-16" />
            <div className="ds-flex-col  ds-w-100" >
                <div className="ds-w-100 ds-flex ds-justify-between">
                    <span className="ds-text-size-16 ds-text-height-19 ds-text-weight700 ds-text-primary100">
                        {`#${props?.data?.data?.ref}`}
                    </span>
                    <span className="ds-text-size-16 ds-text-height-19 ds-text-weight400 ds-text-primary100">
                        {`${props?.data?.data?.total_formated} €`}
                    </span>
                </div>
                <div className="ds-flex-col">
                    <span className="ds-text-size-16 ds-text-height-19 ds-text-weight700 ds-text-primary100">
                        {`${props?.data?.data?.site_details?.company} - `}
                        <span className="ds-text-size-12 ds-text-height-14 ds-text-weight700 ds-text-primary100">
                            {`${props?.data?.data?.site_details?.city}`}
                        </span>
                    </span>
                    <div className="ds-flex ds-align-center">
                        <span className="ds-text-size-12 ds-text-height-14 ds-text-weight700 ds-text-secondary100">
                            {`${props?.data?.data?.delivery_method_name.charAt(0).toUpperCase() + props?.data?.data?.delivery_method_name.slice(1).toLowerCase()}`}
                        </span>
                        <Icon
                            icon="clock"
                            className="ds-text-primary40 ds-mr-5 ds-ml-10 ds-mb-3"
                            size={"16px"}
                        />
                        <span className="ds-text-size-12 ds-text-height-14 ds-text-weight700 ds-text-primary40">
                            {CommonFunction.formatDate(props?.data?.data?.collected_at, false)}
                        </span>
                    </div>
                </div>
                <div className="ds-flex  ds-w-100 ds-align-center">
                    <div className="ds-flex ds-align-center ds-flex-grow1">
                        <span className="ds-text-size-14 ds-text-height-16 ds-text-weight700 ds-text-primary70">
                            {props?.data?.data?.quantity}
                            <span className="ds-text-size-14 ds-text-height-16 ds-text-weight500 ds-text-primary70 ds-mr-10">
                                {` ${props?.data?.data?.quantity > 1 ? langue.articles : langue.article}`}
                            </span>
                        </span>
                        <div className={`ds-hp-17 ds-borad-18 ds-flex ds-p-10 ds-center ${props?.data?.selectedTab === 1 ? 'ds-bg-success10' : new Date() >= new Date(props?.data?.data?.collected_at) ? 'ds-bg-warning100' : 'ds-bg-primary10'}`}>
                            <span className={`ds-text-size-10 ds-text-height-12 ds-text-weight700 
                                    ${props?.data?.selectedTab === 1 ? 'ds-text-success100' : new Date() >= new Date(props?.data?.data?.collected_at) ? 'ds-text-warning10' : 'ds-text-primary70'}`}>
                                {`${props?.data?.selectedTab === 1 ? langue.delivered : verifyMinutes(props?.data?.data?.collected_at)}`}
                            </span>
                        </div>
                    </div>
                </div>
                {props?.data?.data?.items.map((item: ISitesType, i: number) => {
                    return (
                        <div key={i} className="ds-flex-col ds-mt-10 ds-overflow-x">
                            <div className="ds-flex ds-w-100 ds-justify-between ds-px-12">
                                <span className="ds-text-size-12 ds-text-height-14 ds-text-weight700 ds-text-primary100" style={{ maxWidth: 'calc(100% - 60px)' }}>
                                    {`${item?.name !== "[livraison]" ? item?.quantity + 'x ' : ''}`}
                                    <span className="ds-text-size-12 ds-text-height-14 ds-text-weight700 ds-text-primary100" >
                                        {`${item?.name !== "[livraison]" ? item?.name : langue.item_delivery}`}
                                    </span>
                                </span>
                                <span className="ds-text-size-14 ds-text-height-16 ds-text-weight500 ds-text-primary100 ">
                                    {`${item?.total_formated} €`}
                                </span>
                            </div>
                            <div className="border-dashed ds-w-100 ds-mt-10 ds-mb-10"></div>

                        </div>
                    )
                })
                }
                <div className="ds-flex ds-w-100 ds-justify-between ds-mb-16 ds-px-12">
                    <span className="ds-text-size-12 ds-text-height-14 ds-text-weight700 ds-text-primary100 ">
                        {langue.fees}
                    </span>
                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight500 ds-text-primary100 ">
                        {`${props?.data?.data?.fees} €`}
                    </span>
                </div>
                <div className="ds-p-12 ds-flex ds-w-100 ds-align-center ds-justify-between ds-bg-primary10 ds-hp-48">
                    <span className="ds-text-size-14 ds-text-height-16 ds-text-weight700 ds-text-primary100 ">
                        {langue.total}
                    </span>
                    <span className="ds-text-size-16 ds-text-height-19 ds-text-weight700 ds-text-primary100 ">
                        {`${props?.data?.data?.total_formated} €`}
                    </span>
                </div>
            </div>
            {props?.data?.selectedTab === 1 && <div className='ds-flex ds-w-100 ds-align-end ds-justify-end ds-mt-16'>
                <Button
                    icon='document-download'
                    text={langue.invoice_history}
                    className='ds-mr-5'
                    type={Type.tertiary}
                    onClick={() => props?.OnDowloadInvoice(props?.data?.data?.uniq)}
                />
                <Button
                    icon='rotate-left1'
                    text={langue.order_again_history}
                    className='ds-ml-5'
                    type={Type.tertiary}
                    onClick={() => props?.getCommandeExpress(props?.data?.data?.uniq)}
                />
            </div>}
        </div >
    )
}
export default TemplateDetailsCommandeOrRecharge