import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Modal, ModalRefType } from "../../DesignSystem";
import { Type } from "../../DesignSystem/Interfaces";
import { IDayTimeType, IModalDeliveryData } from "../../Interfaces/Modal";
import React, { Ref, forwardRef, useContext, useRef, useState } from "react";
import { EListFunction, IUseFormResult, useForm } from "../../CustomHooks";
import { CommonFunction } from "../../Common";
import { openAlert, openModal, resetToken, setBasket, setBasketItems, setDelievryMode, setDeliveryAddress, setDeliveryDate, setNbreItems, setPointRetrait, setShopTimes, setShowBasket, setUserLoyalty, setUserRegistration, setZone } from "../../Redux/Reducers";
import { RootState } from "../../Interfaces";
import { AutoComplete, Input } from "../../DesignSystem/Molecules";
import { CommercantAPI } from "../../Api/Commercants/Commercants";
import { ShopAPI } from "../../Api/Shop/shop";
import { ElementDatePicker, Icon, Radio } from "../../DesignSystem/Atoms";
import { LangContext } from "../../Lang";
import { UserAPI } from "../../Api/User/user";
import moment from "moment";

const DIV_Height = 52
const ModalDeliveryData = (props: IModalDeliveryData, ref: Ref<ModalRefType | undefined>) => {
    const topLigne = useRef<HTMLDivElement>(null)
    const { data } = useSelector((state: RootState) => state.modal);
    const { user } = useSelector((state: RootState) => state.auth);
    const { times, commercant } = useSelector((state: RootState) => state.commercant);
    const { delievryMode, deliveryAddress, deliveryDate } = useSelector((state: RootState) => state.paiement);
    const { lang } = useSelector((state: RootState) => state.setting);
    const { baskett, showBasket, pointRetrait, zone } = useSelector((state: RootState) => state.basket);
    const dispatch = useDispatch()
    const [shopHours, setShopHours] = useState<number[]>([])
    const [minutesShop, setMinutesShop] = useState<number[]>([])
    const [indexHour, setindexHour] = useState<number>(1)
    const [indexMinutes, setindexMinutes] = useState<number>(1)
    const [indexDay, setindexDay] = useState<number>(1)
    const shopApi = new ShopAPI()
    const [dayShop, setDayShop] = useState<number[]>([])
    const langue = useContext(LangContext)
    const userApi = new UserAPI()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [deliveryMethode, setDeliveryMethode] = useState<boolean>(delievryMode)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isButtonSavedAdr, setIsButtonSavedAdr] = useState<boolean>(false)
    const [savedAdress, setSavedAdress] = useState<any>("")
    const [validButton, setValidButton] = useState<boolean>(false)
    const [methodeIndex, setMethodeIndex] = useState<number>(3)
    const commercantApi = new CommercantAPI()
    const renderClassInfo = () => {
        if (!isButtonSavedAdr) { return "overflow-hidden info-class" }
        else { return "overflow-hidden info-class-btn" }
    }
    
    const setGeocoding = async (address: string) => {
        const response = await commercantApi.getGeocoding({ suid: commercant?.suid, lang: lang }, { address: address })
        if (response?.status === 200) {
            setSavedAdress(response.data.address)
        }
    }
    const getSavedAdress = async () => {

        const res = await commercantApi.getAutoCompletePlaces({
            suid: commercant.suid,
            lang: lang
        }, {
            address: user.delivery_address,
            addressNotEnougthPrecise: false,
            addressToFar: false,
            city: user.delivery_city,
            country: "Belgique",
            establishment: false,
            iso_country: "be",
            postcode: user.delivery_postcode,
            street: "",
            streetNumber: "",
            toLarge: false,
            validAddress: false
        })
        if (res.status === 200) {

            setGeocoding(res?.data.result[0].description)
        }
    }
    const setGeocodingNewAdr = async (address: string) => {
        const response = await commercantApi.getGeocoding({ suid: commercant?.suid, lang: lang }, { address: address })
        if (response?.status === 200) {
            const newAdress = response.data;
            onChangeProfile(newAdress)
        }
    }
    const onChangeProfile = async (newAdress: any) => {
        if (newAdress?.postcode === "") {
            dispatch(openAlert({ type: 'info', message: langue.incomplete_address }))

        }
        else {
            const response = await userApi.putCustomerProfile(
                { suid: commercant?.suid, lang: lang },
                {
                    user:
                    {
                        address: user?.address,
                        city: user?.city,
                        countryCode: user?.country_code,
                        delivery_address: newAdress?.street,
                        delivery_city: newAdress?.city,
                        delivery_postcode: newAdress?.postcode,
                        firstname: user?.firstname,
                        lastname: user?.lastname,
                        phone: user?.phone,
                        postcode: user?.postcode,
                    }
                }
            )
            if (response.status === 200) {
                dispatch(setUserRegistration({ user: response?.data }));
                setSavedAdress(form?.deliveryAddress?.value)
            }
        }
    }
    const {
        state: form,
        onChange,
        onUpdateState
    }: IUseFormResult = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "deliveryAddress",
                value: '',
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            },
            {
                key: "deliveryAddressSaved",
                value: savedAdress,
                rules: [
                    {
                        function: EListFunction.isNotEmpty,
                        messageError: "",
                        priority: 0
                    }
                ],
                successMessage: "",
                isRealTimeValidation: true
            }
        ]
    })
    const onGetTimesShop = async (param: any) => {
        const response = await shopApi.getShopTimes(
            {
                index: methodeIndex,
                suid: commercant?.suid,
                lang: lang,
                zone: param
            }
        )
        if (response.status === 200) {
            dispatch(setShopTimes({ times: response?.data }))
        }
    }
    const checkDeliveryOptionsByMeters = (distance: any) => {
        let isZoneValide = null;
        let isDistanceAreaValide = false;
        if (commercant?.delivery_options?.by_meters) {
            if (commercant?.delivery_options?.meters > distance.value) {
                isZoneValide = 1
                isDistanceAreaValide = true
            }
            else if (commercant?.delivery_options?.with_zone_2 && commercant?.delivery_options?.meters_2 > distance.value) {
                isZoneValide = 2
                isDistanceAreaValide = true
            }
            else if (commercant?.delivery_options?.with_zone_3 && commercant?.delivery_options?.meters_3 > distance.value) {
                isZoneValide = 3
                isDistanceAreaValide = true
            }
            else if (commercant?.delivery_options?.with_zone_4 && commercant?.delivery_options?.meters_4 > distance.value) {
                isZoneValide = 4
                isDistanceAreaValide = true
            }
        }
        dispatch(setZone({ zone: isZoneValide }))
        onGetTimesShop(isZoneValide)
        return isDistanceAreaValide;
    }

    const checkDeliveryOptionsByPostaux = async (param: string) => {
        const response = await commercantApi.getGeocoding({ suid: commercant?.suid, lang: lang }, { address: param })
        if (response?.status === 200) {
            let isZoneValide = null;
            let isCodePostalValide = false;
            if (commercant?.delivery_options?.postal_codes[0].postal_code.split(',')[0] === response?.data?.postcode) {
                isZoneValide = 1
                isCodePostalValide = true
            }
            else if (commercant?.delivery_options?.with_zone_2 && commercant?.delivery_options?.postal_codes[1].postal_code.split(',')[0] === response?.data?.postcode) {
                isZoneValide = 2
                isCodePostalValide = true
            }
            else if (commercant?.delivery_options?.with_zone_3 && commercant?.delivery_options?.postal_codes[2].postal_code.split(',')[0] === response?.data?.postcode) {
                isZoneValide = 3
                isCodePostalValide = true
            }
            else if (commercant?.delivery_options?.with_zone_4 && commercant?.delivery_options?.postal_codes[3].postal_code.split(',')[0] === response?.data?.postcode) {
                isZoneValide = 4
                isCodePostalValide = true

            }
            dispatch(setZone({ zone: isZoneValide }))
            if (isCodePostalValide) {
                onGetTimesShop(isZoneValide)
                dispatch(setDeliveryAddress({ deliveryAddress: form?.deliveryAddress?.value }))
                onClickCancel()
                onOpenModalTime()
            }
            else {
                dispatch(openAlert({ type: 'info', message: langue.distance_to_far }))
            }
        }

    }
    const checkDeliveryOptionsByCities = async (param: string) => {
        const response = await commercantApi.getGeocoding({ suid: commercant?.suid, lang: lang }, { address: param })
        if (response?.status === 200) {
            let isZoneValide = null;
            let isCityValide = false;
            if (commercant?.delivery_options?.cities[0].city.split(',')[0] === response?.data?.city) {
                isZoneValide = 1
                isCityValide = true
            }
            else if (commercant?.delivery_options?.with_zone_2 && commercant?.delivery_options?.cities[1].city.split(',')[0] === response?.data?.city) {
                isZoneValide = 2
                isCityValide = true
            }
            else if (commercant?.delivery_options?.with_zone_3 && commercant?.delivery_options?.cities[2].city.split(',')[0] === response?.data?.city) {
                isZoneValide = 3
                isCityValide = true
            }
            else if (commercant?.delivery_options?.with_zone_4 && commercant?.delivery_options?.cities[3].city.split(',')[0] === response?.data?.city) {
                isZoneValide = 4
                isCityValide = true

            }
            dispatch(setZone({ zone: isZoneValide }))
            if (isCityValide) {
                onGetTimesShop(isZoneValide)
                dispatch(setDeliveryAddress({ deliveryAddress: form?.deliveryAddress?.value }))
                onClickCancel()
                onOpenModalTime()
            }
            else {
                dispatch(openAlert({ type: 'info', message: langue.distance_to_far }))
            }
        }

    }
    const setDistanceMatrix = async (address: string) => {
        let isDistanceAreaValide = false;
        const response = await commercantApi.setDistanceMatrix({ suid: commercant?.suid, lang: lang }, { address: address, origin: commercant?.address })
        if (response?.status === 200) {
            if (response?.data?.code === 'OK' && response?.data?.elements && response?.data?.elements.length) {
                isDistanceAreaValide = checkDeliveryOptionsByMeters(response?.data?.elements[0]?.distance);
                if (isDistanceAreaValide) {
                    dispatch(setDeliveryAddress({ deliveryAddress: form?.deliveryAddress?.value }))
                    onClickCancel()
                    onOpenModalTime()
                }
                else {
                    dispatch(openAlert({ type: 'info', message: langue.distance_to_far }))
                }
            }
        }
    }
    React.useEffect(() => {
        if (baskett?.delivery_address !== null) {
            onUpdateState({
                ...form,
                deliveryAddress: {
                    value: baskett?.delivery_address,
                }
            })
        }
    }, [baskett?.delivery_address])
    React.useEffect(() => {
        onUpdateState({
            ...form,
            deliveryAddressSaved: {
                value: savedAdress,
            }
        })
    }, [savedAdress])
    React.useEffect(() => {
        if (delievryMode) {
            onUpdateState({
                ...form,
                deliveryAddress: {
                    value: '',
                }
            })
        }
        onGetTimesShop(zone)

    }, [delievryMode])
    React.useEffect(() => {
        if (deliveryAddress !== '') {
            setDeliveryMethode(false)
            onUpdateState({
                ...form,
                deliveryAddress: {
                    value: deliveryAddress,
                }
            })
        }
    }, [deliveryAddress])
    const onSelectDate = (index: number, key: string) => {
        if (key === "days") {
            setindexDay(index)
        }
        if (key === "hours") {
            setindexHour(index)
        }
        if (key === "minutes") {
            setindexMinutes(index)
        }

    }
    React.useEffect(() => {
        if (data?.switch) {
            setDeliveryMethode(data?.switch)
        }
    }, [data])
    React.useEffect(() => {
        setIsLoading(false)
        if (commercant?.delivery_points?.length !== 0) {
            commercant?.delivery_points?.map((e: any) => {
                if (baskett?.delivery_address === e.address) {
                    dispatch(setPointRetrait({ pointRetrait: e }))
                    setValidButton(true)
                }
            })
        }
        else {
            dispatch(setPointRetrait({ pointRetrait: '' }))
        }
        setAdrpoint(pointRetrait)
        if (baskett?.delivery_method === 1) {
            dispatch(setPointRetrait({ pointRetrait: '' }))
        }
        if (adrpoint !== "") { setValidButton(true) }

    }, [])

    const onOpenModalTime = () => {
        dispatch(openModal({ name: "modalDeliveryOrder" }))
    }
    React.useEffect(() => {
        if (user?.delivery_address) { getSavedAdress() }
        else { setSavedAdress("") }
    }, [user?.delivery_address])

    React.useEffect(() => {
        if (baskett.delivery_method === 3) { setDeliveryMethode(true) }
        else {
            setDeliveryMethode(data?.switch? data?.switch : false)
        }
        if (!props.isOpen && isOpen) {
            if (baskett?.delivery_method === 1) {
                dispatch(setPointRetrait({ pointRetrait: '' }))
            }
            setIsButtonSavedAdr(false)
            setValidButton(false)
            if (adrpoint !== "") { setValidButton(true) }
            if (form?.deliveryAddress.value !== "") { setValidButton(true) }
            onUpdateState({ 
                ...form,
                deliveryAddress: {
                    value: baskett?.delivery_address,
                }
            })
            setIsOpen(false)
        }
        else {
            setIsOpen(true)
        }
    }, [props.isOpen])
    const onSetModeDelivery = () => {
        if (commercant?.delivery_allowed) {
            if (!deliveryMethode) {
                setMethodeIndex(3)
                setDeliveryMethode(true)
            } else {
                setValidButton(false)
                onUpdateState({
                    ...form,
                    deliveryAddress: {
                        value: baskett?.delivery_address !== null ? baskett?.delivery_address : '',
                    }
                })
                dispatch(setPointRetrait({ pointRetrait: '' }))
                setMethodeIndex(1)
                setDeliveryMethode(false)
                setIsButtonSavedAdr(false)
            }
            setAdrpoint(pointRetrait)
        } else {
            dispatch(openAlert({ type: 'info', message: langue.delivery_not_allowed }))
        }
    }

    const deleteIfRepeated = (arr: number[], element: number) => {
        return arr.includes(element)
    }

    const onsetSHopHours = (date: string) => {
        const hoursArray: number[] = [-1]
        const minutesArray: number[] = [-1]
        const daysArray: number[] = [-1]
        if (Object.keys(times).length) {
            Object.values(times[2]).forEach((element: IDayTimeType | any) => {
                if (new Date(date).getDay() === new Date(element.value).getDay()) {
                    if (!deleteIfRepeated(hoursArray, element.hour)) {
                        hoursArray.push(element?.hour)
                    }
                    if (!deleteIfRepeated(minutesArray, element.text)) {
                        minutesArray.push(element?.text)
                    }
                    setMinutesShop(minutesArray)
                    setShopHours(hoursArray)
                }
            })
            Object.values(times[0]).forEach((element: IDayTimeType | any) => {
                daysArray.push(element?.text)
                setDayShop(daysArray)
            })
            for (let index = 0; index < 3; index++) {
                hoursArray.push(-1)
                minutesArray.push(-1)
                daysArray.push(-1)
            }
        }
    }

    function parseCustomDate(input: any) {
        const months: any = {
            "Jan": 0, "Feb": 1, "Mar": 2, "Apr": 3, "May": 4, "Jun": 5,
            "Jul": 6, "Aug": 7, "Sep": 8, "Oct": 9, "Nov": 10, "Dec": 11
        };

        const parts: any = input.split(" ");
        if (parts.length !== 3) {
            return new Date()
        }

        const day = parseInt(parts[1]);
        const month = months[parts[2]];
        if (isNaN(day) || month === undefined) {
            throw new Error("Invalid day or month");
        }

        const year = new Date().getFullYear();

        return new Date(year, month, day);
    }

    function getDayDifference(date1: any, date2: any) {
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());

        return Math.floor(timeDiff / (1000 * 3600 * 24));
    }


    const onClickPlanifier = () => {
        let date = parseCustomDate(dayShop[indexDay])
        date.setHours(shopHours[indexHour])
        date.setMinutes(minutesShop[indexMinutes])
        if (getDayDifference(date, new Date()) > 7) {
            date.setFullYear(new Date().getFullYear() + 1)
        }
        const dateDelivery = Math.floor(date.getTime() / 1000)
        dispatch(setDeliveryDate({ deliveryDate: dateDelivery }))
        setIsLoading(true)
        if (baskett?.error?.message !== "Not Found") {
            if (Object.keys(baskett).length !== 0) {
                dispatch(setZone({ zone: null }))
                getDeliveryDetails(dateDelivery)
            }
        }
    }
    const onClickCancel = () => {
        onCloseModal()
    }
    React.useEffect(() => {
        onsetSHopHours(new Date().toISOString())
    }, [times])

    const onCloseModal = () => {
        if (props?.onExit) {
            props?.onExit()
        }
        setIsLoading(false)
        setIsButtonSavedAdr(false)
        setValidButton(false)


    }

    const getUserLoloyalty = async () => {
        const response = await userApi.getUserLoyalty({
            suid: commercant?.suid,
            lang: lang
        })
        if (response.status === 200) {
            dispatch(setUserLoyalty({ userLoyalty: response.data }))
        }
    }
    const getBasket = async () => {
        const res = await shopApi.getBasketOrder({ suid: commercant?.suid, lang: lang, zone: zone })
        if (res.status === 200) {
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
        } else if (res?.status === 404) {
            dispatch(setNbreItems({ nbreItems: null }))
            dispatch(setBasketItems({ items: {} }))
            dispatch(setBasket({ baskett: {} }))
        }
    }
    const getCollectedDetails = async () => {
        const response = await shopApi.setCollectedAt({
            suid: commercant?.suid,
            lang: lang,
            zone: zone
        },
            {
                collected_at: deliveryDate,
                delivery: 1,
                delivery_address: ''
            }
        )
        if (response.status === 200) {
            await getBasket()
            dispatch(setDelievryMode({ delievryMode: true }))
            dispatch(setDeliveryAddress({ deliveryAddress: '' }))
        } else if (response.status === 401) {
            dispatch(resetToken())
        }
    }
    const getDeliveryDetails = async (dateDelivery: any) => {
        const response = await shopApi.setCollectedAt({
            suid: commercant?.suid,
            lang: lang,
            zone: zone
        },
            {
                collected_at: dateDelivery,
                delivery: !delievryMode ? 3 : 1,
                delivery_address: !delievryMode ? form?.deliveryAddress?.value : ''
            }
        )
        if (response.status === 200) {
            await getBasket()
            await getUserLoloyalty()
            const momentObj = moment(response?.data?.collected_at);
            const formatted = momentObj.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (heure normale d’Europe centrale)");
            const dateObj = new Date(formatted);
            const now = new Date();
            const futureTime = new Date(now.getTime() + (commercant?.collect_min_time * 60000)); // 180 minutes * 60 seconds * 1000 milliseconds
            if (futureTime > dateObj) {
                dispatch(openAlert({ type: 'info', message: langue.error_collected_at }))
            }
            if (form?.deliveryAddress?.value && !delievryMode) {
                dispatch(setDeliveryAddress({ deliveryAddress: form?.deliveryAddress?.value }))
            } else {
                dispatch(setPointRetrait({ pointRetrait: '' }))
                dispatch(setDeliveryAddress({ deliveryAddress: '' }))

            }

            setIsLoading(false)
        } else if (response.status === 401) {
            setIsLoading(false)
            onCloseModal()
            dispatch(resetToken())
            dispatch(setShowBasket(showBasket))
        }
        onCloseModal()
    }
    const onClickSavedAdr = () => {
        if (savedAdress) {
            onUpdateState({
                ...form,
                deliveryAddress: {
                    value: savedAdress,
                }
            })
            setValidButton(true)
        }
    }

    const [adrpoint, setAdrpoint] = useState<any>()
    const renderRetraitsPoint = () => {
        return (<div className="ds-mt-10">
            <div className="ds-mb-16">
                <span className="ds-text-size-12 ds-text-weight400">{langue.adresses}</span><span className="ds-text-size-12 ds-text-weight700">{langue.point_retrait}</span>
            </div>
            <div className="overflow-hidden point-address">
                {commercant?.delivery_points?.map((element: any) => {
                    return (

                        <>
                            <Radio
                                containerClassName="ds-mb-14"
                                bsPrefix="ds-hp-16"
                                labelBsPrefix="ds-ml-8 ds-text-size-12 ds-text-weight400 ds-text-primary70"
                                data={[{ label: element.address, value: element.id }]}
                                disabled={false}
                                onChange={() => {
                                    setAdrpoint(element)
                                    setValidButton(true)
                                }}
                                value={adrpoint?.id}
                            />
                            <div className="horizontal-line ds-mb-8"></div>
                        </>
                    )
                })}
            </div>
        </div>
        )
    }
    const renderDeliveryZone = () => {
        return (<> <div className="delivery-address ds-mt-10">
            {savedAdress && <Input
                disabled={true}
                label={langue.saved_delivery_address}
                placeholder={langue.saved_delivery_address}
                labelClassName="saved-address"
                className="color-saved-adr"
                containerClassName="ds-mb-10 ds-mt-18 delivery-address"
                {...CommonFunction.getInputProps(form?.deliveryAddressSaved)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange({
                        key: "deliveryAddressSaved",
                        value: e
                    });
                }}
                value={savedAdress}
                listIcons={[
                    { icon: 'location', isLeft: true },
                    { icon: 'arrow-right-11', isLeft: false, onClick: (() => { onClickSavedAdr() }) }
                ]}
            />}
        </div>
            <div className="delivery-address ds-mt-10">
                <AutoComplete
                    label={langue.delivery_address}
                    placeholder={langue.delivery_address}
                    {...CommonFunction.getInputProps(form?.deliveryAddress)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChange({
                            key: "deliveryAddress",
                            value: e
                        });
                        if (String(e) === '')
                            setIsButtonSavedAdr(false)
                        else
                            setIsButtonSavedAdr(true)
                        setValidButton(true)

                    }}
                />
            </div>
            {isButtonSavedAdr && <Button
                text={langue.saved_address}
                className="ds-w-100 ds-mt-16"
                onClick={() => {
                    if (form?.deliveryAddress?.value) {
                        setGeocodingNewAdr(String(form?.deliveryAddress?.value))
                    }
                    else
                        dispatch(openAlert({ type: 'info', message: langue.incomplete_address }))

                }

                }
                isLoading={isLoading}
            />}

            <div className="ds-mt-10 ds-mb-5 ds-flex ds-align-center ds-flex-grow1 ds-w-100 gap-5">

                <Icon
                    icon="info-circle"
                    size={"16px"}
                    className="ds-mb-5 star-class"
                />
                <span className="ds-text-size-14 ds-text-line-16 ds-text-weight500 ds-text-primary70" >{langue.infos_text}</span>
            </div>
            <div className={renderClassInfo()} >
                {commercant?.delivery_options?.by_meters && <>
                    <div className="ds-text-line-14 ds-mb-8">
                        <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70" >{langue.delivery_on} </span>
                        <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.meters / 1000} Km </span>
                        <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.des} </span>
                        <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket?.toFixed(2)}€ </span>
                        <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.achat_livraison} </span>
                        <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_price?.toFixed(2)}€ </span>
                        {commercant?.delivery_options?.delivery_free_basket !== null && <><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.offert_des} </span>
                            <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{parseFloat(commercant?.delivery_options?.delivery_free_basket)?.toFixed(2)}€ </span></>}
                        <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{`)`}</span>
                    </div>
                    {commercant?.delivery_options?.with_zone_2 && <div className="ds-text-line-14 ds-mb-8">
                        <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70" >{langue.delivery_on} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.meters_2 / 1000} Km </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.des} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket_2?.toFixed(2)}€ </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.achat_livraison} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_price_2?.toFixed(2)}€ </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.offert_des} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{parseFloat(commercant?.delivery_options?.delivery_free_basket_2)?.toFixed(2)}€ </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{`)`}</span>
                    </div>}
                    {commercant?.delivery_options?.with_zone_3 && <div className="ds-text-line-14 ds-mb-8">
                        <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70" >{langue.delivery_on} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.meters_3 / 1000} Km </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.des} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket_3?.toFixed(2)}€ </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.achat_livraison} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_price_3?.toFixed(2)}€ </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.offert_des} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{parseFloat(commercant?.delivery_options?.delivery_free_basket_3)?.toFixed(2)}€ </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{`)`}</span>
                    </div>}
                    {commercant?.delivery_options?.with_zone_4 && <div className="ds-text-line-14">
                        <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70" >{langue.delivery_on} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.meters_4 / 1000} Km </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.des} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket_4?.toFixed(2)}€ </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.achat_livraison} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_price_4?.toFixed(2)}€ </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.offert_des} </span><span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{parseFloat(commercant?.delivery_options?.delivery_free_basket_4)?.toFixed(2)}€ </span><span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{`)`}</span>
                    </div>}</>}
                {commercant?.delivery_options?.by_cities &&
                    <>
                        <span className="ds-text-size-12 ds-text-line-16 ds-text-weight500 ds-text-primary70 ds-mb-10" >{langue.possible_cities_3}</span>
                        <div className="ds-text-line-14 ds-mb-8">
                            <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.cities[0].city.split(',')[0]} </span>
                            <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.minimum} </span>
                            <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket}€ </span>
                        </div>
                        {commercant?.delivery_options?.with_zone_2 &&
                            <div className="ds-text-line-14 ds-mb-8">
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.cities[1].city.split(',')[0]} </span>
                                <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.minimum} </span>
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket_2}€ </span>
                            </div>}
                        {commercant?.delivery_options?.with_zone_3 &&
                            <div className="ds-text-line-14 ds-mb-8">
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.cities[2].city.split(',')[0]} </span>
                                <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.minimum} </span>
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket_3}€ </span>

                            </div>}
                        {commercant?.delivery_options?.with_zone_4 &&
                            <div className="ds-text-line-14">
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.cities[3].city.split(',')[0]} </span>
                                <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.minimum} </span>
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket_4}€ </span>

                            </div>}</>}
                {commercant?.delivery_options?.by_postal_codes &&
                    <>
                        <span className="ds-text-size-12 ds-text-line-16 ds-text-weight500 ds-text-primary70 ds-mb-10" >{langue.possible_postal_codes_3}</span>
                        <div className="ds-text-line-14 ds-mb-8">
                            <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.postal_codes[0].postal_code.split(',')[0]} </span>
                            <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.minimum} </span>
                            <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket}€ </span>
                        </div>
                        {commercant?.delivery_options?.with_zone_2 &&
                            <div className="ds-text-line-14 ds-mb-8">
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.postal_codes[1].postal_code.split(',')[0]} </span>
                                <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.minimum} </span>
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket_2}€ </span>
                            </div>}
                        {commercant?.delivery_options?.with_zone_3 &&
                            <div className="ds-text-line-14 ds-mb-8">
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.postal_codes[2].postal_code.split(',')[0]} </span>
                                <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.minimum} </span>
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket_3}€ </span>

                            </div>}
                        {commercant?.delivery_options?.with_zone_4 &&
                            <div className="ds-text-line-14">
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.postal_codes[3].postal_code.split(',')[0]} </span>
                                <span className="ds-text-size-12 ds-text-weight500 ds-text-primary70">{langue.minimum} </span>
                                <span className="ds-text-size-12 ds-text-weight700 ds-text-primary100">{commercant?.delivery_options?.delivery_min_basket_4}€ </span>

                            </div>}</>}
            </div>
        </>

        )
    }
    const renderDeliveryItems = () => {
        return (
            <div className="shop-times">
                <div className="ds-w-100 grid-times ds-relative height-300">
                    <div className="ds-absolute  horizontal-line-100 ds-w-100" style={{ top: 52 }} ref={topLigne}></div>
                    <div className="ds-absolute  horizontal-line-100 ds-w-100" style={{ top: 104 }}></div>
                    <ElementDatePicker
                        data={dayShop}
                        height={DIV_Height}
                        topLigne={topLigne}
                        onChange={onSelectDate}
                        type="days"
                        withTranslation
                    />
                    <ElementDatePicker
                        data={shopHours}
                        height={DIV_Height}
                        topLigne={topLigne}
                        onChange={onSelectDate}
                        type="hours"
                        hours
                    />
                    <ElementDatePicker
                        data={minutesShop}
                        height={DIV_Height}
                        topLigne={topLigne}
                        onChange={onSelectDate}
                        type="minutes"
                    />
                </div>
            </div>
        )
    }
    const onSetDeliveryData = () => {
        if (commercant?.delivery_points?.length !== 0) {

            return renderRetraitsPoint();
        }
        else if (commercant?.delivery_points?.length === 0) {
            return renderDeliveryZone();
        }

    }
    const disableButton = () => {
        if (deliveryMethode && (String(form?.deliveryAddress?.value) === '' || String(form?.deliveryAddress?.value) === 'null') && !adrpoint?.address)
            return true
        return deliveryMethode && !validButton
    }

    const renderDeliveryData = () => {
        const list: JSX.Element[] = []
        list.push(
            <div className="ds-flex-col ds-w-100 ds-justify-between ds-pointer ds-p-24 delivery-date">
                <div className="ds-flex-col ds-flex-grow1 ds-justify-between ds-w-100">
                    <span className="ds-text-size-28 ds-text-line-33 ds-text-weight700 ds-text-primary100 ds-mb-30 title-planning" style={{ letterSpacing: "0.5px" }}>
                        {langue.planning_order}
                    </span>
                    <div className="delivery-checkbox">
                        <Checkbox
                            isSwitch={true}
                            containerClassName="ds-pointer delivery-checkbox"
                            className="ds-pointer delivery-checkbox"
                            checked={!deliveryMethode}
                            onChange={() => onSetModeDelivery()}
                            labelBefore={langue.takeaway}
                            labelAfter={commercant?.delivery_points?.length !== 0 ? langue.delivery_method_delivery_point : langue.delivery}
                            onClick={() => { onSetModeDelivery() }}
                        />
                    </div>
                    {deliveryMethode ? <>{onSetDeliveryData()}</> : <>{renderDeliveryItems()}</>}

                </div>
                <div className="ds-flex-col ds-w-100">
                    <Button
                        text={langue.validate}
                        disabled={disableButton()}
                        className="ds-w-100 ds-mt-40"
                        onClick={() => {
                            if (deliveryMethode && commercant?.delivery_points?.length !== 0) {
                                dispatch(setDelievryMode({ delievryMode: false }))
                                dispatch(setPointRetrait({ pointRetrait: adrpoint }))
                                dispatch(setDeliveryAddress({ deliveryAddress: adrpoint.address }))
                                onClickCancel()
                                onOpenModalTime()
                            }
                            else if (deliveryMethode && commercant?.delivery_points?.length === 0) {
                                dispatch(setDelievryMode({ delievryMode: false }))
                                if (commercant?.delivery_options?.by_meters) {
                                    setDistanceMatrix(String(form?.deliveryAddress?.value))
                                }
                                if (commercant?.delivery_options?.by_postal_codes) {
                                    checkDeliveryOptionsByPostaux(String(form?.deliveryAddress?.value))
                                }
                                if (commercant?.delivery_options?.by_cities) {
                                    checkDeliveryOptionsByCities(String(form?.deliveryAddress?.value))
                                }
                            }
                            else {
                                getCollectedDetails()
                                onClickPlanifier()
                            }

                        }


                        }
                        isLoading={isLoading}
                    />
                    <Button
                        text={langue.cancel}
                        className="ds-w-100 ds-mt-8 ds-bg-primary10 ds-borad-8 modal-Button"
                        type={Type.quaternary}
                        onClick={() => onClickCancel()}
                    />
                </div>
            </div>
        )
        return (<>{list}</>)
    }

    return (
        <Modal
            contentClassName="overflow-hidden ds-borad-14 modal-Delivery-data ds-pointer"
            withCloseIcon={true}
            ref={ref}
            onExit={props.onExit}
            onShow={props.onShow}
        >
            <div>
                {renderDeliveryData()}
            </div>
        </Modal>
    )
}
export default forwardRef(ModalDeliveryData)