import { Ref, forwardRef, useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalRefType } from "../../DesignSystem";
import { IModalConnexion } from "../../Interfaces/Modal";
import { EListFunction, IUseFormResult } from "../../CustomHooks/interfaces";
import { useForm } from "../../CustomHooks/hooks/useForm/useForm"
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../Redux/Reducers/ModalReducer/ModalReducer";
import { AuthAPI } from "../../Api/Auth";
import { RootState } from "../../Interfaces";
import { TemplateModalConnexion } from "../../Template";
import { setBasket, setBasketItems, setNbreItems } from "../../Redux/Reducers";
import { setResetPasswordEmail, setToken, setUserRegistration } from "../../Redux/Reducers/AuthReducer/AuthReducer";
import { ShopAPI } from "../../Api/Shop/shop";
import { UserAPI } from "../../Api/User/user";
import { LangContext } from "../../Lang";

const ModalConnexion = (props: IModalConnexion, ref: Ref<ModalRefType | undefined>) => {
    const [showForgetPasswordElement, setShowForgetPasswordElement] = useState<boolean>(false)
    const [showErrorSpan, setshowErrorSpan] = useState<boolean>(false)
    const { commercant } = useSelector((state: RootState) => state.commercant)
    const { lang } = useSelector((state: RootState) => state.setting)
    const { zone } = useSelector((state: RootState) => state.basket)
    const langue = useContext(LangContext)
    const authApi = new AuthAPI()
    const shopAPI = new ShopAPI()
    const userApi = new UserAPI()
    const dispatch = useDispatch()
    const onClose = () => {
        setShowForgetPasswordElement(false)
        if (props.onExit) {
            props.onExit()
        }
    }
    const onOpenModal = () => {
        dispatch(openModal({ name: "modalInscriptionRef" }))
    }
    const onOpenModalResetPassword = () => {
        dispatch(openModal({ name: "modalResetPasswordRef" }))
    }
    const showErrorMsg = () => {
        onUpdateState({
            ...form,
            email: {
                ...form.email,
                isValid: false,
                isInvalid: true,
            },
            password: {
                ...form.password,
                isValid: false,
                isInvalid: true,
            },
        })
        setshowErrorSpan(true);
    }
    const { state: form, onChange, onUpdateState }: IUseFormResult = useForm({
        isRealTimeValidation: true,

        data: [
            {
                key: "email",
                value: "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.email_invalid,
                    priority: 1,

                },
                {
                    function: EListFunction.isMail,
                    messageError: langue.email_invalid,
                    priority: 2,
                }],

            },
            {
                key: "password",
                value: "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.password_required,
                    priority: 1,
                },
                ],
            },
        ]
    })

    const [isOpen, setIsOpen] = useState<boolean>(false)
    useEffect(() => {
        if (!props.isOpen && isOpen) {
            setShowForgetPasswordElement(false)
            setshowErrorSpan(false)
            onUpdateState({
                email: {
                    errorMessage: "",
                    successMessage: "",
                    value: "",
                    isInvalid: false,
                },
                password: {
                    errorMessage: "",
                    successMessage: "",
                    value: "",
                    isInvalid: false,
                },
            })
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }, [props.isOpen])
    const getUserProfile = async () => {
        const response = await authApi.getUserProfile({ suid: commercant?.suid });
        if (response.status === 200) {
            dispatch(setUserRegistration({ user: response.data }))
        }
    }
    const getBasket = async () => {
        const res = await shopAPI.getBasketOrder({ suid: commercant?.suid, lang: lang, zone: zone })
        if (res.status === 200) {
            dispatch(setNbreItems({ nbreItems: res.data.quantity }))
            dispatch(setBasket({ baskett: res.data, basketId: res?.data?.uniq }))
            dispatch(setBasketItems({ items: res.data.items }))
        }
    }
    const getCustomerProfileMajor = async () => {
        const res = await userApi.getCustomerProfile()
        if (res.status === 200) {
            return res
        }
    }
    const login = async () => {
        setshowErrorSpan(false);
        if (!showForgetPasswordElement) {
            const resCheckValidate = await authApi.checkValidate({
                email: form?.email?.value,
            },
                {
                    suid: commercant?.suid,
                    lang: lang
                })
            if (resCheckValidate.status === 200) {
                if (resCheckValidate.data === 1) {
                    try {
                        const resUserToken = await authApi.userToken({
                            username: form?.email?.value,
                            password: form?.password?.value
                        })
                        if (resUserToken.status === 200) {
                            onUpdateState({
                                ...form,
                                email: {
                                    ...form.email,
                                    isValid: true,
                                    isInvalid: false,
                                },
                                password: {
                                    ...form.password,
                                    isValid: true,
                                    isInvalid: false,
                                },
                            })
                            setshowErrorSpan(false);
                            dispatch(setToken({ token: resUserToken.data.token }));
                            getUserProfile()
                            getCustomerProfileMajor()
                            getBasket()
                            onClose();
                        } else { showErrorMsg() }
                    } catch {
                        showErrorMsg();
                    }
                } else {
                    showErrorMsg();
                }
            }
        }
        else {
            const resVerifUser = await authApi.VerifyUser({
                user: form?.email?.value,
            },
                {
                    suid: commercant?.suid,
                    lang: lang
                })
            if (resVerifUser.status === 200) {
                if (resVerifUser.data) {
                    const resSendPassword = await authApi.sendPassword({
                        user: { username: form?.email?.value },
                    },
                        {
                            suid: commercant?.suid,
                            lang: lang
                        })
                    if (resSendPassword.status === 200) {
                        dispatch(setResetPasswordEmail({ email: form?.email.value }))
                        onClose();
                        onOpenModalResetPassword();
                    }
                }
                else {
                    onUpdateState({
                        ...form,
                        email: {
                            ...form.email,
                            isValid: false,
                            isInvalid: true,
                        }
                    })
                    setshowErrorSpan(true);
                }
            }
        }
    }

    const handleOnClickProvider = async (providerName: string) => {
        switch (providerName) {
            case "facebook":
                authApi.connectWithFacebook()
                break;
            default:
                authApi.connectWithGoogle()
                break;
        }
    }
    
    return <Modal
        contentClassName={!showForgetPasswordElement ? "modal-connexion overflow-hidden" : "modal-forget-password  overflow-hidden"}
        withCloseIcon
        ref={ref}
        onExit={props.onExit}
        onShow={props.onShow}
    >
        <TemplateModalConnexion
            showForgetPasswordElement={showForgetPasswordElement}
            setShowForgetPasswordElement={setShowForgetPasswordElement}
            form={form}
            onSubmit={login}
            onChange={onChange}
            onClose={onClose}
            onOpenModal={onOpenModal}
            showErrorSpan={showErrorSpan}
            langue={langue}
            onClickProvider={handleOnClickProvider}
        />
    </Modal >

}
export default forwardRef(ModalConnexion)