import { IApi } from '../interface/IApi';
import { ApiResponse, HeaderObject, RefreshJson } from '../types';
import { Httpstatus } from '../interface';
import { Method } from '../interface/EMethod';
import { CommonFunction } from '../../Function/Function';
export class Api implements IApi {
  private apiURL: string;
  constructor(apiURL = 'http://localhost', port?: string) {
    if (port) this.apiURL = `${apiURL}:${port}/`;
    this.apiURL = `${apiURL}/`;
  }

  createHeader(headerObject: HeaderObject): Headers {
    const header: Headers = new Headers();
    for (const key of Object.keys(headerObject)) {
      header.set(key, headerObject[key]);
    }
    return header;
  }

  private async standardApi(
    method: Method,
    endPoint: string,
    body: any,
    headers: Headers,
    refrechCallback?: () => Promise<Response>,
  ) {
    try {
      const response = await fetch(this.apiURL + endPoint, {
        method,
        headers,
        body,
      });
      if (refrechCallback) {
        return this.processResponse(response, (header: Headers) =>
          this.get(endPoint, header, refrechCallback), refrechCallback
        );
      } else {
        const data = await this._getData(response)
        return {
          status: response.status,
          data: data,
        }
      }
    } catch (error: unknown) {
      throw error;
    }
  }
  private async processResponse(
    response: Response,
    callback: (headers: Headers) => Promise<ApiResponse>,
    refreshCallback: () => Promise<Response>,
  ): Promise<ApiResponse> {
    if (response.status === Httpstatus.Unauthorized || response.status === Httpstatus.Forbidden) {
      try {
        const refreshResponse = await refreshCallback();
        const refreshJson = (await refreshResponse.json()) as RefreshJson;
        if (refreshResponse.status === Httpstatus.SuccessOK) {
          const header = response.headers;
          header.set('Authorization', refreshJson.accessToken);
          const res = await callback(response.headers)
          return res
        } else {
          return { status: refreshResponse.status, data: refreshJson };
        }
      } catch (error: unknown) {
        return { status: Httpstatus.Internal, data: "" }
      }
    } else {
      let responseParsed = null;
      try {
        responseParsed = await response.json();
      } catch (_) {
        responseParsed = await response.blob();
      }
      return { status: response.status, data: responseParsed };
    }
  }

  async get(
    endPoint: string,
    headers: Headers = new Headers(),
    refrechCallback?: () => Promise<Response>,
  ): Promise<ApiResponse> {
    try {
      const response = await fetch(this.apiURL + endPoint, {
        method: Method.Get,
        headers,
      });
      if (refrechCallback) {
        return this.processResponse(response, (header) => this.get(endPoint, header, refrechCallback), refrechCallback);
      } else {
        const data = await this._getData(response)
        return {
          status: response.status,
          data: data,
        }
      }
    } catch (error: unknown) {
      throw error;
    }
  }
  async getNotJson(
    endPoint: string,
    headers: Headers = new Headers(),
    refrechCallback?: () => Promise<Response>,
  ): Promise<ApiResponse> {
    try {
      const response = await fetch(this.apiURL + endPoint, {
        method: Method.Get,
        headers,
      });
      if (refrechCallback) {
        return this.processResponse(response, (header) => this.get(endPoint, header, refrechCallback), refrechCallback);
      } else {
        let data = ""
        const {  value }: any = await response?.body?.getReader()?.read();
        if (value instanceof Uint8Array) {
          data += new TextDecoder().decode(value);
        } else if (typeof value === "string") {
          data += value;
        }
        return {
          status: response.status,
          data: data,
        }
      }
    } catch (error: unknown) {
      throw error;
    }
  }
  async post(
    endPoint: string,
    body: any,
    headers: Headers = new Headers(),
    refrechCallback?: () => Promise<Response>,
  ): Promise<ApiResponse> {
    try {
      const response = await fetch(this.apiURL + endPoint, {
        method: Method.Post,
        body,
        headers: CommonFunction.createHeaders({ withToken: true }),
      });
      if (refrechCallback) {
        return this.processResponse(response, (header) => this.post(endPoint, body, header, refrechCallback), refrechCallback);
      } else {
        const data = await this._getData(response)
        return {
          status: response.status,
          data: data,
        }
      }
    } catch (error: unknown) {
      throw error;
    }
  }
  async postNotJson(
    endPoint: string,
    body: any,
    headers: Headers = new Headers(),
    refrechCallback?: () => Promise<Response>,
  ): Promise<ApiResponse> {
    try {
      const response = await fetch(this.apiURL + endPoint, {
        method: Method.Post,
        body,
        headers: CommonFunction.createHeaders({ withToken: true }),
      });
      if (refrechCallback) {
        return this.processResponse(response, (header) => this.post(endPoint, body, header, refrechCallback), refrechCallback);
      } else {
        let data = ""
        const {  value }: any = await response?.body?.getReader()?.read();
        if (value instanceof Uint8Array) {
          data += new TextDecoder().decode(value);
        } else if (typeof value === "string") {
          data += value;
        }
        return {
          status: response.status,
          data: data,
        }
      }
    } catch (error: unknown) {
      throw error;
    }
  }
  async delete(
    endPoint: string,
    body: any,
    headers: Headers = new Headers(),
    refrechCallback?: () => Promise<Response>,
  ): Promise<ApiResponse> {
    try {
      const response = await fetch(this.apiURL + endPoint, {
        method: Method.Delete,
        headers: CommonFunction.createHeaders({ withToken: true }),
      });
      if (refrechCallback) {
        return this.processResponse(response, (header) => this.delete(endPoint, header), refrechCallback);
      } else {
        const data = await this._getData(response)
        return {
          status: response.status,
          data: data,
        }
      }
    } catch (error: unknown) {
      throw error;
    }
  }
  async put(
    endPoint: string,
    body: any,
    headers: Headers = new Headers(),
    refrechCallback?: () => Promise<Response>,
  ): Promise<ApiResponse> {
    return this.standardApi(Method.Put, endPoint, body, headers, refrechCallback);
  }
  async patch(
    endPoint: string,
    body: any,
    headers: Headers = new Headers(),
    refrechCallback?: () => Promise<Response>,
  ): Promise<ApiResponse> {
    return this.standardApi(Method.Patch, endPoint, body, headers, refrechCallback);
  }
  async _getData(response: Response) {
    let responseParsed = null;
    try {
      responseParsed = await response.json();
    } catch (_) {
      responseParsed = await response.blob();
    }
    return responseParsed
  }
}
