import { Button, Input } from "../../DesignSystem";
import { Text } from "../../DesignSystem/Atoms";
import { ESizeInput, Size, Type } from "../../DesignSystem/Interfaces";
import AppleIcon from '../../Assets/images/apple.svg';
import GoogleIcon from '../../Assets/images/icon_google.png';
import FacebookIcon from '../../Assets/images/icon_facebook.svg';
import { CommonFunction } from "../../Common";
import { ITemplateModalInscriptionMail } from "../../Interfaces";
import PhoneInput from 'react-phone-input-2';

const TemplateModalInscriptionMail = (props: ITemplateModalInscriptionMail): JSX.Element => {
    const socialIconClassName = ['ds-hp-24 ds-wp-24']

    const renderPhoneOrEmailInput = () => {
        if (props.show) {
            return <> <div className="ds-w-100 ds-flex ds-align-center">
                <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-mb-6 ds-flex ds-align-center">{props.langue.form_mobile}</span>
            </div>
                <PhoneInput
                    {...CommonFunction.getInputProps(props.form?.phone)}
                    onChange={(phone_number: string) => props.onChange({ key: "phone", value: phone_number })}
                    placeholder={props.langue.phone_placeholder}
                    country="be"
                    searchPlaceholder='Search'
                    enableSearch={true}
                    disableSearchIcon={true}
                    inputClass="phone-input"
                />
                <span className="ds-hp-30"></span>
            </>
        }
        return <Input
            {...CommonFunction.getInputProps(props.form?.email)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "email", value: e.target.value })
            }
            inputSize={ESizeInput.large}
            placeholder={props.langue.email}
            label={props.langue.email_address}
            containerClassName="ds-hp-100"
        />
    }
    return (
        <div className="ds-w-100 ds-p-30 ds-flex-col ds-center">
            <Text
                className="ds-mb-48 ds-text-line-34 ds-text-size-28 ds-text-weight700"
                text={props.langue.button_register}
            />
            {
                renderPhoneOrEmailInput()
            }
            <span className="ds-text-line-19 ds-text-size-16 ds-text-weight400 ds-text-primary50 ds-mb-28">
                {props.show ? props.langue.phone_code_info : props.langue.email_code_info}
            </span>
            {props.showErrorSpan && <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-error100 ds-mb-12">{props.show ? props.langue.phone_not_available : props.langue.mail_not_available}</span>}

            <Button
                size={Size.large}
                className="ds-w-100"
                text={props.langue.validate}
                onClick={() => { props.onSubmit() }}
            />
            <Button
                size={Size.large}
                type={Type.tertiary}
                className="ds-w-100 ds-mt-14"
                text={!props.show ? props.langue.use_phone : props.langue.use_email}
                onClick={() => {
                    props.switchPhoneEmailInput()
                }}
            />
            <div className="ds-w-100 ds-center ds-flex ds-pt-32">
                <div className="ds-border-primary30 ds-w-100"></div>
                <span className="continue-text ds-w-100 ds-flex ds-center ds-text-line-19 ds-text-size-16 ds-text-weight400 ds-text-primary50 ">
                    {props.langue.continue_with}
                </span>
                <div className="ds-border-primary30 ds-w-100"></div>
            </div>
            <div className="justify-content-evenly ds-flex ds-w-100 ds-pt-32">
                <div className="content-social ds-hp-48 ds-center ds-flex ds-borad-8 ds-border-primary30 ds-bg-white ds-pointer"
                    onClick={() => { props.onClickProvider("google") }}>
                    <img className={socialIconClassName.join('')} src={GoogleIcon} />
                </div>
                <div className="content-social ds-hp-48 ds-center ds-flex ds-borad-8 ds-border-primary30 ds-bg-white ds-pointer"
                    onClick={() => { props.onClickProvider("facebook") }}>
                    <img className={socialIconClassName.join('')} src={FacebookIcon} />
                </div>
                {/* <div className="content-social ds-hp-48 ds-center ds-flex ds-borad-8 ds-border-primary30 ds-bg-white ds-pointer">
                    <img className={socialIconClassName.join('')} src={AppleIcon} />
                </div> */}
            </div>
            <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-primary50 ds-pt-16">
                {props.langue.haveAccount}
                <span className="ds-text-line-17 ds-text-size-14 ds-text-weight700 ds-text-primary ds-pointer ds-ml-6"
                    onClick={() => {
                        props.onClose();
                        props.onOpenModalConnexion();
                    }}
                >

                    {props.langue.connect}
                </span>
            </span>
        </div>
    )
}
export default TemplateModalInscriptionMail