import React from 'react'
import { DEFAULTINPUTATOMPROPS } from '../../DefaultProps/Atoms'
import { IInputProps } from '../../Interfaces/Atoms/IAtomInput/IAtomInput';

const AtomInput = React.forwardRef((props: IInputProps, ref: React.Ref<HTMLInputElement>): JSX.Element => {
    const className: string[] = [];
    if (props.bsPrefix) {
        className.push(props.bsPrefix)
    } else {
        className.push('default-input ds-text-primary100 ds-px-12 ds-bg-white ds-text-weight400 ds-w-100 ds-borad-8')
    }
    switch (props.inputSize) {
        case 'small':
            className.push('ds-hp-36')
            break;
        case 'medium':
            className.push('ds-hp-40')
            break;
        case 'large':
            className.push('ds-hp-48')
            break;
        default:
            break;
    }

    if (props.isInvalid) className.push('invalid');
    if (props.isValid) className.push('valid');
    if (props.disabled) className.push('ds-op-7');
    if (props.className) className.push(props.className)
    if (props.isSelect) {
        return <div className={className.join(' ')} id={props.id} onClick={props.onClick}>
            {props.children}
        </div>
    }
    
    return (
        <input
            ref={ref}
            className={className.join(' ')}
            id={props.id}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            style={props.style}
            onKeyPress={props.onKeyPress}
            onClick={props.onClick}
        />
    )
});

AtomInput.defaultProps = {
    ...DEFAULTINPUTATOMPROPS
}

export default AtomInput