import React from 'react'
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { DEFAULTNUMBERPROPS } from '../../DefaultProps/Atoms';
import { IAtomNumber } from '../../Interfaces';

const AtomNumber = React.forwardRef((props: IAtomNumber, ref: React.Ref<HTMLInputElement>): JSX.Element => {
    const className: string[] = [];
    if (props.bsPrefix) {
        className.push(props.bsPrefix)
    } else {
        className.push('default-input ds-text-dark ds-px-12 ds-bg-white ds-text-weight400 ds-w-100 ds-borad-8')
    }

    switch (props.inputSize) {
        case 'small':
            className.push('ds-hp-36')
            break;
        case 'medium':
            className.push('ds-hp-40')
            break;
        case 'large':
            className.push('ds-hp-48')
            break;
        default:
            break;
    }

    if (props.isInvalid) className.push('invalid');
    if (props.isValid) className.push('valid');
    if (props.disabled) className.push('ds-op-7');
    if (props.className) className.push(props.className)

    const onValueChange = (values: NumberFormatValues) => {
        props.onChange && props.onChange(values);
    };

    return (
        <NumericFormat
            thousandsGroupStyle={props.thousandsGroupStyle}
            decimalSeparator={props.decimalSeparator}
            thousandSeparator={props.thousandSeparator}
            className={className.join(' ')}
            disabled={props.disabled}
            readOnly={props.readOnly}
            value={parseFloat(props.value ? props.value?.toString() : '')}
            onValueChange={onValueChange}
            placeholder={props.placeholder}
            id={props.id}
            getInputRef={ref}
            prefix={props.prefix}
            suffix={props.suffix}
            displayType={props.displayType}
            customInput={props.customInput}
            valueIsNumericString={props.valueIsNumericString}
            allowLeadingZeros={props.allowLeadingZeros}
            allowNegative={props.allowNegative}
        />
    )
})

AtomNumber.defaultProps = {
    ...DEFAULTNUMBERPROPS,
}

export default AtomNumber