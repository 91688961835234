import { Modal, ModalRefType } from "../../DesignSystem";
import { IModalInscriptionFinalisation } from "../../Interfaces/Modal";
import { Ref, forwardRef, useContext, useState } from "react";
import { EListFunction, IUseFormResult } from "../../CustomHooks/interfaces";
import { useForm } from "../../CustomHooks/hooks/useForm/useForm";
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Interfaces";
import { TemplateModalInscriptionFinalisation } from "../../Template";
import { LangContext } from "../../Lang";
import { AuthAPI } from "../../Api/Auth";
import { openModal, setToken, setUserRegistration } from "../../Redux/Reducers";

const ModalInscriptionFinalisation = (props: IModalInscriptionFinalisation, ref: Ref<ModalRefType | undefined>) => {
    const langue = useContext(LangContext)
    const [isCheckedReceiveDeals, setIsCheckedReceiveDeals] = useState<boolean>(false);
    const [isCheckedReceiveOffers, setIsCheckedReceiveOffers] = useState<boolean>(false);
    const [countryCode, setCountryCode] = useState<string>("");
    const [showErrorEmail, setShowErrorEmail] = useState<boolean>(false);
    const dispatch = useDispatch()
    const authApi = new AuthAPI()
    const { commercant } = useSelector((state: RootState) => state.commercant)
    const { user } = useSelector((state: RootState) => state.auth)
    const { lang } = useSelector((state: RootState) => state.setting)
    const { show } = useSelector((state: RootState) => state.modal)

    const { state: form, onChange, onUpdateState }: IUseFormResult = useForm({
        isRealTimeValidation: true,
        data: [
            {
                key: "prenom",
                value: "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.emptyMessage,
                    priority: 1,

                }],

            },
            {
                key: "nom",
                value: "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.emptyMessage,
                    priority: 1,
                },
                ],
            },
            {
                key: "email",
                value: user?.email ?? "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.emptyMessage,
                    priority: 1,

                },
                {
                    function: EListFunction.isMail,
                    messageError: langue.invalidMailMessage,
                    priority: 2,
                }],

            },
            {
                key: "phone",
                value: user?.phone ?? "",
                isRealTimeValidation: true,
                rules: [
                    {
                        function: EListFunction.IsEightCharLength,
                        messageError: langue.phone_invalid,
                        priority: 1,
                    }
                ],
            },
            {
                key: "password",
                value: "",
                isRealTimeValidation: true,
                rules: [{
                    function: EListFunction.isNotEmpty,
                    messageError: langue.password_required,
                    priority: 1,
                },
                ],
            }
        ]
    })

    const onUpdateInitialState = () => {
        onUpdateState({
            prenom: {
                errorMessage: "",
                successMessage: "",
                value: "",
                isInvalid: false,
            },
            nom: {
                errorMessage: "",
                successMessage: "",
                value: "",
                isInvalid: false,
            },
            email: {
                errorMessage: "",
                successMessage: "",
                value: "",
                isInvalid: false,
            },
            phone: {
                errorMessage: "",
                successMessage: "",
                value: "",
                isInvalid: false,
            },
            password: {
                errorMessage: "",
                successMessage: "",
                value: "",
                isInvalid: false,
            },
        })
        setShowErrorEmail(false)
        setIsCheckedReceiveDeals(false)
        setIsCheckedReceiveOffers(false)
    }

    const getUserProfile = async () => {
        const response = await authApi.getUserProfile({ suid: commercant?.suid });
        if (response.status === 200) {
            dispatch(setUserRegistration({ user: response.data }))
        }
    }
    const verifyEmail = async () => {
        const respVerifEmail = await authApi.VerifyUser({
            user: form?.email?.value,
        },
            {
                suid: commercant?.suid,
                lang: lang
            })
        if (respVerifEmail.status === 200) {
            if (respVerifEmail.data == false) {
                setShowErrorEmail(false)
                return true
            }
            else {
                onUpdateState({
                    ...form,
                    email: {
                        ...form.email,
                        isValid: false,
                        isInvalid: true,
                    }
                })
                setShowErrorEmail(true)
                return false
            }
        }
    }
    const verifyPhoneNumber = async () => {
        const respVerifPhone = await authApi.VerifyUserWithPhone({
            phone: user?.phone ? user?.phone : form?.phone?.value,
        },
            {
                suid: commercant?.suid,
                lang: lang
            })
        if (respVerifPhone.status === 200) {
            if (respVerifPhone.data == false) {
                return true
            }
            else {
                onUpdateState({
                    ...form,
                    phone: {
                        ...form.phone,
                        isValid: false,
                        isInvalid: true,
                    }
                })
                return false
            }
        }
    }

    const onFinalizeClick = async () => {
        if (form?.prenom?.value == "") {
            onUpdateState({
                ...form,
                prenom: {
                    ...form.prenom,
                    isValid: false,
                    isInvalid: true,
                }
            })
        } else if (form?.nom?.value == "") {
            onUpdateState({
                ...form,
                nom: {
                    ...form.nom,
                    isValid: false,
                    isInvalid: true,
                }
            })
        } else if (form?.password?.value == "") {
            onUpdateState({
                ...form,
                password: {
                    ...form.password,
                    isValid: false,
                    isInvalid: true,
                }
            })
        } else {
            const isEmailValid = await verifyEmail();
            const isPhoneNumberValid = await verifyPhoneNumber()
            if (isEmailValid && isPhoneNumberValid) {
                const respSubscription = await authApi.Subscribe({
                    user: {
                        accept: true,
                        countryCode: countryCode,
                        firstname: form?.prenom?.value,
                        lastname: form?.nom?.value,
                        godfatherCodeUsed: "",
                        optin: isCheckedReceiveDeals,
                        optout: isCheckedReceiveOffers,
                        partnershipCode: "",
                        password: form?.password?.value,
                        phone: user?.phone ? user?.phone : form?.phone?.value,
                        username: form?.email?.value,
                    },
                    userCreationId: ""
                },
                    {
                        suid: commercant?.suid,
                        lang: lang
                    })
                if (respSubscription.status == 200 && respSubscription.data) {
                    const resUserToken = await authApi.userToken({
                        username: form?.email?.value,
                        password: form?.password?.value
                    })
                    if (resUserToken.status === 200) {
                        dispatch(setToken({ token: resUserToken.data.token }));
                        getUserProfile()
                        props.onExit && props.onExit()
                    }
                }
            } else if (!isEmailValid) {
                onUpdateState({
                    ...form,
                    email: {
                        ...form.email,
                        isValid: false,
                        isInvalid: true,
                    }
                })

            } else if (!isPhoneNumberValid) {
                onUpdateState({
                    ...form,
                    phone: {
                        ...form.phone,
                        isValid: false,
                        isInvalid: true,
                    }
                })
            }
        }
    }

    const handleOpenConditions = () => {
        props.onExit && props.onExit()
        dispatch(openModal({ name: 'generalConditionModal', data: { fromSupscription: true } }));
    }

    const onCloseModal = () => {
        onUpdateInitialState()
        props.onExit && props.onExit()
    }


    return <Modal
        contentClassName="final-subscription-modal overflow-hidden"
        containerClassName=" "
        withCloseIcon
        ref={ref}
        onExit={() => onCloseModal}
        onShow={props.onShow}
    >
        <TemplateModalInscriptionFinalisation
            show={show}
            form={form}
            onChange={onChange}
            isCheckedReceiveOffers={isCheckedReceiveOffers}
            isCheckedReceiveDeals={isCheckedReceiveDeals}
            setIsCheckedReceiveDeals={setIsCheckedReceiveDeals}
            setIsCheckedReceiveOffers={setIsCheckedReceiveOffers}
            langue={langue}
            onFinalizeClick={onFinalizeClick}
            setCountryCode={setCountryCode}
            onOpenConditions={handleOpenConditions}
            showErrorEmail={showErrorEmail}
        />    </Modal >
}
export default forwardRef(ModalInscriptionFinalisation)
