import { createPortal } from 'react-dom';
import { IAtomPortal } from '../../Interfaces/Atoms/IAtomPortal/IAtomPortal';

const AtomPortal = (props: IAtomPortal): JSX.Element | null => {
    const { children, container = document.body } = props
    if (container) {
        return createPortal(children, container);
    }
    return null;
};
export default AtomPortal;
