import React from 'react';
import { DEFAULTBUTTONPROPS } from '../../DefaultProps/Atoms';
import { IAtomButton, Type } from '../../Interfaces/Atoms/IAtomButton/IAtomButton';
import { Link } from 'react-router-dom';

function AtomButton(props: IAtomButton): JSX.Element {
  const className = [];
  if (props?.type !== 'primaryLink' && props?.type !== 'secondaryLink') {
    className.push('ds-flex ds-align-center ds-justify-center');
  }

  if (props.bsPrefix) {
    className.push(props.bsPrefix);
  } else {
    if (props?.type !== 'primaryLink' && props?.type !== 'secondaryLink') {
      className.push('default-btn ds-text-line-14 ds-text-size-14 ds-px-16 ds-text-weight500');
    }
  }

  if (props.type) className.push(props.type);
  if (props?.type !== 'primaryLink' && props?.type !== 'secondaryLink') {
    if (props.isRadius) {
      className.push('ds-borad-50');
    } else {
      className.push('ds-borad-8');
    }
  }

  switch (props.type) {
    case 'primary':
      className.push('ds-bg-primary ds-text-white');
      break;
    case 'secondary':
      className.push('ds-bg-secondary30 ds-text-primary70');
      break;
    case 'tertiary':
      className.push('ds-bg-transparent ds-text-primary70');
      break;
    case 'quaternary':
      className.push('ds-bg-transparent ds-text-primary70');
      break;
    case 'primaryLink':
      className.push('ds-text-primary');
      break;
    case 'secondaryLink':
      className.push('ds-text-primary ds-px-8 ');
      break;
    default:
      break;
  }

  if (props.type === Type.primary && props.disabled) {
    className.push('ds-bg-primary10');
  } else if (props.type === Type.secondary && props.disabled) {
    className.push('ds-bg-secondary10 ds-text-white');
  } else if ((props.type === Type.tertiary || props.type === Type.quaternary) && props.disabled) {
    className.push('ds-text-primary10');
  }

  if (props.type !== Type.primaryLink && props.type !== Type.secondaryLink) {
    switch (props.size) {
      case 'xSmall':
        className.push('ds-hp-24');
        break;
      case 'small':
        className.push('ds-hp-32');
        break;
      case 'medium':
        className.push('ds-hp-40');
        break;
      case 'large':
        className.push('ds-hp-48 ds-px-24');
        break;
      default:
        className.push('ds-hp-32');
        break;
    }
  }

  if (props.className) className.push(props.className);
  if (props?.type === 'primaryLink' || props?.type === 'secondaryLink') {
    return (
      <Link to={props.to ? props.to : '#'} className={className.join(' ')}>
        {props.children}
      </Link>
    );
  } else
    return (
      <button
        className={className.join(' ')}
        style={props.style}
        disabled={props.disabled}
        onClick={props.onClick}>
        {props.children}
      </button>
    );
}

AtomButton.defaultProps = {
  ...DEFAULTBUTTONPROPS,
};

export default AtomButton;
