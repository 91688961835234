import { ITemplateModalConnexion } from "../../Interfaces/Template"
import { Button, Input } from "../../DesignSystem";
import { Text } from "../../DesignSystem/Atoms";
import { ESizeInput } from "../../DesignSystem/Interfaces";
import { CommonFunction } from "../../Common";
import AppleIcon from '../../Assets/images/apple.svg';
import GoogleIcon from '../../Assets/images/icon_google.png';
import FacebookIcon from '../../Assets/images/icon_facebook.svg';

const TemplateModalConnexion = (props: ITemplateModalConnexion): JSX.Element => {
    const socialIconClassName = ['ds-hp-24 ds-wp-24']
    return (<div className="ds-p-40 ds-flex-col ds-center">
        <Text
            className="ds-mb-28 ds-text-line-34 ds-text-size-28 ds-text-weight700"
            text={props.showForgetPasswordElement ? props.langue.lost_password : props.langue.connexion}
        >
        </Text>
        <Input
            {...CommonFunction.getInputProps(props.form?.email)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "email", value: e.target.value })
            }
            inputSize={ESizeInput.large}
            placeholder={props.langue.email}
            label={props.langue.email_address}
            containerClassName="ds-mb-8 ds-hp-90"
        />
        {!props.showForgetPasswordElement && <Input
            {...CommonFunction.getInputProps(props.form?.password)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: "password", value: e.target.value })}
            placeholder={props.langue.form_password}
            label={props.langue.form_password}
            isPassword
            inputSize={ESizeInput.large}
            containerClassName="ds-hp-90"

        />}
        {!props.showForgetPasswordElement && <div className="ds-flex ds-justify-end ds-w-100">
            <a
                onClick={() => props.setShowForgetPasswordElement(true)}
                className="ds-text-line-14 ds-text-size-12 ds-text-weight500 ds-pointer ds-text-primary50 ds-pt-4 ds-mb-14">
                {props.langue.forgetPass}</a>
        </div>}

        {props.showErrorSpan &&
            <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-error100 ds-mb-4">{props.showForgetPasswordElement ? props.langue.invalid_mail : props.langue.invalid_mail_password}</span>

        }

        <Button
            className="ds-w-100"
            text={props.showForgetPasswordElement ? props.langue.send : props.langue.connect2}
            onClick={() => {
                props.onSubmit();
            }
            }
        >

        </Button>

        {!props.showForgetPasswordElement && <>
            <div className="ds-w-100 ds-center ds-flex ds-pt-28">
                <div className="ds-border-primary30 ds-w-100"></div>
                <span className="continue-text ds-flex ds-center ds-text-line-19 ds-text-size-16 ds-text-weight400 ds-text-primary50 ">
                    {props.langue.continue_with}
                </span>
                <div className="ds-border-primary30 ds-w-100"></div>
            </div>
            <div className="justify-content-evenly ds-flex ds-w-100 ds-pt-32">
                <div className="content-social ds-hp-48 ds-center ds-flex ds-borad-8 ds-border-primary30 ds-bg-white ds-pointer"
                    onClick={()=>{props.onClickProvider("google")}}>
                    <img className={socialIconClassName.join('')} src={GoogleIcon} />
                </div>
                <div className="content-social ds-hp-48 ds-center ds-flex ds-borad-8 ds-border-primary30 ds-bg-white ds-pointer"
                    onClick={()=>{props.onClickProvider("facebook")}}>
                    <img className={socialIconClassName.join('')} src={FacebookIcon} />
                </div>
                {/* <div className="content-social ds-hp-48 ds-center ds-flex ds-borad-8 ds-border-primary30 ds-bg-white ds-pointer">
                    <img className={socialIconClassName.join('')} src={AppleIcon} />
                </div> */}
            </div>
        </>
        }
        {!props.showForgetPasswordElement && <span className="ds-text-center ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-primary50 ds-pt-16">
            {props.langue.haventAccount}
            <span className="ds-text-line-17 ds-text-size-14 ds-text-weight700 ds-text-primary ds-pointer ds-ml-6"
                onClick={() => {
                    props.onClose();
                    props.onOpenModal();
                }
                }
            >

                {props.langue.register}
            </span>
        </span>}
        {props.showForgetPasswordElement && <span className="ds-text-line-17 ds-text-size-14 ds-text-weight400 ds-text-primary50 ds-pt-16">
            {props.langue.haveAccount}
            <span className="ds-text-line-17 ds-text-size-14 ds-text-weight700 ds-text-primary ds-pointer ds-ml-6"
                onClick={() => {
                    props.setShowForgetPasswordElement(!props.showForgetPasswordElement)
                }
                }

            >

                {props.langue.connexion}

            </span>
        </span>}
    </div>)

}
export default TemplateModalConnexion
