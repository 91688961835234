import React from 'react'
import { DEFAULTSTEPPERPROPS } from '../../DefaultProps/Molecules';
import { IMoleculeStepper } from '../../Interfaces/Molecule/IMoleculeStepper/IMoleculeStepper';
import { Icon } from '../../Atoms/index';
function AtomStepper(props: IMoleculeStepper): JSX.Element {
  const className = [];
  const containerClassName = []
  const titleClassName: string[] = [];
  const textClassName: string[] = [];
  if (props.containerBsPrefix) {
    containerClassName.push(props.containerBsPrefix)
  } else {
    containerClassName.push("ds-flex ds-align-center ds-h-100")
  }
  if (props.bsPrefix) className.push(props.bsPrefix)

  const renderStepper = () => {
    if (!props?.isVertical) {
      if (props.withLike) {
        titleClassName.push('ds-text-size-20 ds-text-line-32')
      } else {
        titleClassName.push('ds-text-size-12 ds-text-line-32')
      }
    }
    return props.data?.map((item, i) => {
      if (props.isVertical) {
        if (props.withLike) {
          return <div className='ds-flex'>
            <div className="ds-step ds-relative ds-mt-7">
              <div className="ds-circle ds-relative ds-text-white ds-bg-primaryLighter">{i + 1}</div>
            </div>
            <div>
              <div className="ds-borad-100 ds-text-line-40 ds-relative ds-hp-40 ds-wp-40 ds-circle-icon ds-ml-20">
                <Icon className='ds-center ds-flex' color='#94CF33' icon='heart' />
              </div>
            </div>
            <div>
              <div className={titleClassName.join(' ')}>{item?.title}</div>
              <div className={textClassName.join(' ')}>{item?.text}</div>
            </div>
          </div>
        } else {
          return <div className='ds-flex'>
            <div className="ds-step ds-relative ds-mt-7">
              <div className="ds-circle ds-bg-primaryLighter ds-relative ds-text-white">
                <Icon className='ds-center ds-flex' color='white' icon='heart' />
              </div>
            </div>
            <div>
              <div className={titleClassName.join(' ')}>{item?.title}</div>
              <div className={textClassName.join(' ')}>{item?.text}</div>
            </div>
          </div>
        }
      } else {
        const activeClassName = ['step-counter ds-flex ds-center ds-relative ds-hp-40 ds-wp-40 ds-text-white ds-mb-6']
        if (i + 1 === props.step) {
          activeClassName.push('ds-bg-primary')
        } else {
          activeClassName.push('ds-bg-primary-100')

        }
        return <div className='stepper-item ds-relative ds-flex ds-align-center' >
          <div className={activeClassName.join(' ')}>{i + 1}</div>
        </div>
      }
    })
  }

  if (props.isVertical) {
    textClassName.push("ds-ml-20 ds-text-line-24 ds-text-size-16 ds-text-weight400 ds-text-neutral600")
    titleClassName.push("ds-ml-20 ds-text-line-32 ds-text-size-20 ds-text-weight700 ds-text-neutral900")
  }
  else {
    className.push("ds-mt-50 ds-flex ds-justify-between ds-mb-20 ds-w-100")
    containerClassName.push("ds-flex-col ds-center")
    textClassName.push("ds-text-weight400 ds-text-neutral600 ds-text-size-16 ds-text-line-24")
    titleClassName.push("ds-text-weight700 ds-text-neutral900")
  }

  if (props.className) className.push(props.className)
  if (props.containerClassName) containerClassName.push(props.containerClassName)
  if (props.textClassName) textClassName.push(props.textClassName)
  if (props.titleClassName) titleClassName.push(props.titleClassName)

  return (
    <div className={containerClassName.join(' ')}>
      <div className={className.join(' ')}>
        {renderStepper()}
      </div>
      {!props.isVertical && props.children}
    </div>
  )
}

AtomStepper.defaultProps = {
  ...DEFAULTSTEPPERPROPS
}

export default AtomStepper