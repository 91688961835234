import { useSelector } from "react-redux"
import { Alert } from "../../DesignSystem/Molecules"
import { RootState } from "../../Interfaces";

const AlerteProvider=()=> {
    const { type, message } = useSelector((state: RootState) => state?.alert);
    return <>
        <Alert message={message} type={type} />
    </>
}
export default AlerteProvider