import { Modal, ModalRefType } from "../../DesignSystem";
import { IModalInscriptionConfirmation } from "../../Interfaces/Modal";
import { Ref, forwardRef, useContext, useRef } from "react";
import { openModal, showOptionSignUpPhone } from "../../Redux/Reducers/ModalReducer/ModalReducer";
import { useDispatch, useSelector } from "react-redux";
import { IRefState, RootState } from "../../Interfaces";
import { AuthAPI } from "../../Api/Auth";
import { TemplateModalInscriptionConfirmation } from "../../Template";
import { Config } from "../../Common/Config/Config";
import { useState } from "react";
import { LangContext } from "../../Lang";

const ModalInscriptionConfirmation = (props: IModalInscriptionConfirmation, ref: Ref<ModalRefType | undefined>) => {

    const [form, setForm] = useState<IRefState>({
        code1: {
            value: "",
            ref: useRef()
        },
        code2: {
            value: "",
            ref: useRef()
        },
        code3: {
            value: "",
            ref: useRef()
        },
        code4: {
            value: "",
            ref: useRef()
        },
    })



    const onClose = () => {
        if (props.onExit) {
            props.onExit()
        }
    }
    const dispatch = useDispatch()
    const onOpenModal = () => {
        dispatch(openModal({ name: "modalInscriptionRef" }))
    }
    const onOpenModalFinalStep = () => {
        dispatch(openModal({ name: "modalInscriptionFinalisationRef" }))
    }
    const [showErrorSpan, setShowErrorSpan] = useState<boolean>(false)
    const { lang } = useSelector((state: RootState) => state.setting)
    const { show } = useSelector((state: RootState) => state.modal)
    const { user } = useSelector((state: RootState) => state.auth)
    const { commercant } = useSelector((state: RootState) => state.commercant)
    const langue = useContext(LangContext)
    const switchPhoneEmailInput = () => {
        dispatch(showOptionSignUpPhone({ show: !show }));
    }
    const authapi = new AuthAPI()
    const onChangeCodes = (index: number, value: string) => {
        const aux = { ...form }
        const key = "code" + index.toString()
        const reg = /^-?([0-9])?$/;
        if (reg.test(value)) {
            aux[key] = { ...aux[key], value: value.length ? value[value.length - 1] : "" }
            const nexIndex = value ? index + 1 : index - 1
            if (nexIndex > 0 && nexIndex < 5) {
                form["code" + nexIndex.toString()].ref?.current?.focus()
            }

            setForm(aux)
        }
    }

    const resetForm = () => {
        const aux = { ...form }
        aux["code1"] = { ...aux["code1"], value: "" }
        aux["code2"] = { ...aux["code2"], value: "" }
        aux["code3"] = { ...aux["code3"], value: "" }
        aux["code4"] = { ...aux["code4"], value: "" }
        setForm(aux)
    }


    const onSubmit = async () => {
        setShowErrorSpan(false);

        const response = await authapi.sendConfirmationCode
            ({
                uniq: user?.uniq,
                code: `${form.code1.value}${form.code2.value}${form.code3.value}${form.code4.value}`,

            },
                {
                    suid: commercant?.suid,
                    lang: lang
                })
        if (response.status === 200) {
            if (response.data.valid) {
                resetForm()
                onClose();
                onOpenModalFinalStep();
                setShowErrorSpan(false);
            } else {
                setShowErrorSpan(true);
            }
        }
        if (response.status === 400) {
            setShowErrorSpan(true);
        }
    }
    const onSubmitSendNewCode = async () => {
        await authapi.sendNewCode
            ({
                userCreationUniq: user?.uniq,
                isPhone: show
            },
                {
                    suid: commercant?.suid,
                    lang: lang
                }
            )
    }

    const closeModal = () => {
        props.onExit && props.onExit()
        resetForm()
        setShowErrorSpan(false);
    }

    return <Modal
        contentClassName="confirmation-code-modal ds-p-16 overflow-hidden"
        withCloseIcon
        ref={ref}
        onExit={closeModal}
        onShow={props.onShow}
    >
        <TemplateModalInscriptionConfirmation
            show={show}
            form={form}
            onChange={onChangeCodes}
            onSubmit={onSubmit}
            onSubmitSendNewCode={onSubmitSendNewCode}
            user={user}
            onClose={onClose}
            onOpenModal={onOpenModal}
            switchPhoneEmailInput={switchPhoneEmailInput}
            showErrorSpan={showErrorSpan}
            langue={langue}


        />    </Modal >
}
export default forwardRef(ModalInscriptionConfirmation)


